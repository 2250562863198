import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  resetAppData,
  resetAuthUrl,
  loginRequest,
  registerRequest,
  logoutRequest,
  loginRedirectError,
} from '@/stores/auth';
import { Language } from '@/types/setting';
import { RootState } from '../stores';

export default function useAuth() {
  const dispatch = useDispatch();

  const fetchResetAppData = useCallback(() => dispatch(resetAppData()), [dispatch]);

  const fetchResetAuthUrl = useCallback(() => dispatch(resetAuthUrl()), [dispatch]);

  const fetchLogin = useCallback((language: Language) => dispatch(loginRequest(language)), [dispatch]);

  // App에서는 사용하지 않음
  const fetchRegister = useCallback((language: Language) => dispatch(registerRequest(language)), [dispatch]);

  const fetchLogout = useCallback(
    (token: string, userId: string) => dispatch(logoutRequest(token, userId)),
    [dispatch],
  );

  const fetchLoginRedirectError = useCallback(
    (errorMessage: string, reduxState: RootState) => dispatch(loginRedirectError(errorMessage, reduxState)),
    [dispatch],
  );

  return {
    fetchResetAppData,
    fetchResetAuthUrl,
    fetchLogin,
    fetchRegister,
    fetchLogout,
    fetchLoginRedirectError,
  };
}
