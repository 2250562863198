import styled, { css } from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Button = styled.button<{ padding?: string; underline: boolean; fontSize: string; removeFlex?: boolean }>`
  font-family: 'Lato';
  font-size: ${(props) => props.fontSize};
  color: ${colors.primary};
  font-weight: 900;
  cursor: pointer;
  border: none;
  background-color: transparent;
  gap: 12px;
  border-radius: 8px;
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};

  ${(props) =>
    !props.removeFlex &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  ${media.lg`
    ${typography.sm};
  `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  &:hover {
    background-color: ${colors.hoverFocus};
  }
  &:focus {
    background-color: ${colors.hoverFocus};
    box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
