import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  display: grid;
  ${media.lg`
    grid-template-columns: 1fr 1.5fr;
  `}
`;

export const Header = styled.div`
  background-color: ${colors.white};
`;

export const BodyContainer = styled.div`
  padding: 15px;
  background-color: ${colors.background};
  display: flex;
  gap: 10px;
  flex-direction: column;

  ${media.xl`
    flex-direction: row;
  `}
`;
export const UsageGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;

  ${media.lg`
    padding: 20px 30px 30px 30px;
    justify-content: space-evenly;
  `}
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;

  ${media.lg`
    gap: 20px;
  `}
`;
