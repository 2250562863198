import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { nextScreenStep } from '@/helpers/NavigationHelper';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import MultipleSurveyScreenView from './index.view';

const MultipleSurveyScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  // How did you hear about this program?
  document.title = `${t('programs.PGE_ELRP_A6.singleSurveyScreen.question')} - ${t(
    `common.utilities.${enrollmentInfo.utility}.shortName`,
  )} - ${contents.name}`;

  const [state, setState] = useState({ disabledNext: true });

  const navigate = useNavigate();
  const { fetchSurvey } = useEnrollment();

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo]);

  const validationCheck = () => {
    let answerCount = 0;
    enrollmentInfo.multipleSurvey.map((survey) => {
      answerCount += survey.answer !== '' ? 1 : 0;
      return survey;
    });

    const disabled = enrollmentInfo.multipleSurvey.length !== answerCount;

    setState({ ...state, disabledNext: disabled });
  };

  const handleNext = () => {
    const payload = enrollmentInfo.multipleSurvey;
    fetchSurvey(payload, () => {
      const answers = enrollmentInfo.multipleSurvey.map((survey) => {
        return survey.answer;
      });
      sentEvent('enrollment_survey_submitted', {
        submit_status: 'success',
        submit_value: answers.toString(),
      });
      const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);
      navigate(nextScreen);
    });
  };

  const handleNoThanks = () => {
    sentEvent('enrollment_survey_submitted', {
      submit_status: 'failed',
      submit_value: 'skip',
    });
    const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);
    navigate(nextScreen);
  };

  return (
    <MultipleSurveyScreenView
      enrollmentProgram={currentProgram}
      disabledNext={state.disabledNext}
      onNext={handleNext}
      onNoThanks={handleNoThanks}
    />
  );
};

export default MultipleSurveyScreenContainer;
