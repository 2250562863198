import React, { RefObject, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useLocation } from 'react-router-dom';
import { useMisc } from '@/hooks/index';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import SidebarView from './index.view';

const SidebarContainer = ({ logoComponentRef }: { logoComponentRef: RefObject<HTMLDivElement> }) => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { pathname } = useLocation();
  const { fetchChangeHamburger } = useMisc();

  const programName = getProgramNameFromSite(currentSite);

  useEffect(() => {
    fetchChangeHamburger(true);
  }, [pathname]);

  return (
    <SidebarView
      logoComponentRef={logoComponentRef}
      path={pathname}
      displayMeterData={currentSite?.program?.attributes?.displayMeterData}
      programName={programName}
    />
  );
};

export default SidebarContainer;
