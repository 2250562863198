import React from 'react';
import { useTranslation } from 'react-i18next';
import { IImgProps } from './index.types';
import { Wrapper, Text } from './index.styles';

const ImgContainer = ({ src, style, altAttr }: IImgProps) => {
  const { t } = useTranslation('common');

  let imgSrc = '';
  let imgText = '';

  if (typeof src === 'string') {
    imgSrc = src;
    imgText = altAttr || '';
  } else if (typeof src === 'object') {
    imgSrc = src.image || '';
    imgText = src.text ? t(src.text) : '';
  }

  const renderError = () => {
    return (
      <Wrapper>
        <Text>{imgText}</Text>
      </Wrapper>
    );
  };

  return <img src={imgSrc} alt={imgText} style={style} /> || renderError();
};

export default ImgContainer;
