import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import GBCStatusView from './index.view';

const GBCStatusContainer = () => {
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  return <GBCStatusView programName={userInfo.userGbiRedirect.program} gbcStatus={userInfo.gbcStatus} />;
};

export default GBCStatusContainer;
