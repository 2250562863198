import styled, { css } from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface ButtonProps {
  padding?: string;
  warning?: boolean;
  iconFirst?: boolean;
  gap?: string;
  reverse?: boolean;
  selected?: boolean;
}

export const Grid = styled.div`
  display: flex;
  flex-direction: column;

  ${media.xl`
    display: grid;
    grid-template-areas:
      'gl'
      'gr';
    gap: 0px 20px;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const FirstColumn = styled.div`
  order: 2;
  display: flex;
  flex-direction: column;

  ${media.xl`
    grid-column: 1 / 3;
    grid-row: 1;
    order: unset;
  `}
`;

export const FirstColumnInnerFirst = styled.div`
  order: 2;
`;
export const FirstColumnInnerSecond = styled.div`
  order: 1;
`;

export const SecondColumn = styled.div`
  order: 1;

  ${media.xl`
    grid-column: 3 / 3;
    grid-row: 1;
    order: unset;
  `}
`;

export const TileMargin = styled.div`
  margin-top: 20px;
`;

export const SubmenuTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 20px;
`;

export const SubmenuTitle = styled.p`
  ${typography.md};
  font-weight: 400;
  line-height: 24px;
  color: ${colors.text};
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  padding: ${(props) => props.padding};
  width: 100%;
  background-color: ${colors.white};
  gap: 20px;
  justify-content: space-between;
  ${media.lg`
    width: unset;
    gap: 0;
  `};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${colors.white};
      box-shadow: unset;
      ${media.lg`
        background-color: ${colors.hoverFocus};
        box-shadow: ${'0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
      `}
    `}
  &:hover {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
    box-shadow: ${(props) =>
      props.warning ? '0px 0px 0px 4px rgba(215, 2, 26, 0.2);' : '0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
`;

export const ImgArrow = styled.img<{ width?: string; height?: string }>`
  display: block;
  ${media.lg`
    display: none;
  `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  margin-right: 20px;
`;
