import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useEnrollment from '@/hooks/useEnrollment';
import { moveChangeScreen, nextScreenStep } from '@/helpers/NavigationHelper';
import { RootState } from '@/stores/index';
import { ProgramName } from '@/types/enrollment';
import PATHS from '@/types/navigationPaths';
import NotVerifiedScreenView from './index.view';

const NotVerifiedScreenContainer = () => {
  const {
    enrollment: {
      enrollmentInfo,
      programs,
      programs: { currentProgram, prevProgram },
    },
  } = useSelector((state: RootState) => state);

  const [state, setState] = useState({
    textNext: '',
    descriptionText: '',
    descriptionText2: '',
    descriptionText3: '',
    descriptionText4: '',
  });
  const navigate = useNavigate();
  const { fetchRestoreProgram, fetchChangeProgram } = useEnrollment();
  useEffect(() => {
    handleValidation();
  }, []);

  const handleValidation = () => {
    const { verifyResponse } = enrollmentInfo;
    const currentProgramName = currentProgram.program;
    if (currentProgramName === ProgramName.PGE_ELRP_A6) {
      if (verifyResponse?.status === 'not_customer') {
        const descriptionText = `programs.${currentProgramName}.notVerifiedScreen.notCustomer`;
        const textNext = 'common.generalButtons.tryAgain';
        setState({
          ...state,
          descriptionText,
          descriptionText2: '',
          descriptionText3: '',
          descriptionText4: '',
          textNext,
        });
        return;
      }
      if (verifyResponse?.status === 'active_customer') {
        // Active && Ineligible
        if (verifyResponse.serviceAccounts) {
          const isEligible = verifyResponse.serviceAccounts.filter((sa) => sa.programs[0].eligible === true).length > 0;
          if (!isEligible) {
            const descriptionText = `programs.${currentProgramName}.notVerifiedScreen.activeIneligible`;
            const descriptionText2 = `programs.${currentProgramName}.notVerifiedScreen.activeIneligible2`;
            const descriptionText3 = `programs.${currentProgramName}.notVerifiedScreen.activeIneligible3`;
            const descriptionText4 = `programs.${currentProgramName}.notVerifiedScreen.activeIneligible4`;
            const textNext = 'common.generalButtons.tryAgain';
            setState({ ...state, descriptionText, descriptionText2, descriptionText3, descriptionText4, textNext });
            return;
          }
        }
      }
    }

    // Default try again
    const descriptionText = `programs.${currentProgramName}.notVerifiedScreen.description`;
    const textNext = 'common.generalButtons.tryAgain';
    setState({ ...state, descriptionText, textNext, descriptionText2: '', descriptionText3: '', descriptionText4: '' });
  };

  const handleTryAgain = () => {
    navigate(PATHS.SCREEN_ENROLLMENT_VERIFICATION.pathname);

    if (prevProgram.length > 1) {
      fetchRestoreProgram();
    }
  };

  const handleNoThanks = () => {
    const changedScreenSteps = moveChangeScreen(programs, ProgramName.CLIMATE_RESPONSE, fetchChangeProgram);
    const nextScreen = nextScreenStep(changedScreenSteps, window.location, enrollmentInfo);
    navigate(nextScreen);
  };

  return (
    <NotVerifiedScreenView
      enrollmentProgram={currentProgram}
      onTryAgain={handleTryAgain}
      onNoThanks={handleNoThanks}
      textNext={state.textNext}
      descriptionText={state.descriptionText}
      descriptionText2={state.descriptionText2}
      descriptionText3={state.descriptionText3}
      descriptionText4={state.descriptionText4}
    />
  );
};

export default NotVerifiedScreenContainer;
