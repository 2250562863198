import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import { Stroke } from '@/designSystem/components/buttons/Stroke';
import { PaginationWrapper, PageIndicator, CurrentPage, TotalPage } from './Pagination.styles';
import { PaginationTypes } from './Pagination.types';

const Pagination = ({ setPagination, page, itemLength }: PaginationTypes) => {
  const { t } = useTranslation('common');
  return (
    <PaginationWrapper>
      <Stroke
        onClick={() => {
          setPagination(page - 1);
        }}
        padding="4px"
        image={{
          active: {
            src: images.pagePrevious.image,
            alt: t(images.pagePrevious.text),
          },
          disabled: {
            src: images.pagePrevious.image,
            alt: t(images.pagePrevious.text),
          },
        }}
        disabled={page === 1}
      />
      <PageIndicator>
        <CurrentPage>{page}</CurrentPage>
        <TotalPage>of {Math.ceil(itemLength / 6)}</TotalPage>
      </PageIndicator>
      <Stroke
        onClick={() => {
          setPagination(page + 1);
        }}
        padding="4px"
        image={{
          active: {
            src: images.pageNext.image,
            alt: t(images.pageNext.text),
          },
          disabled: {
            src: images.pageNext.image,
            alt: t(images.pageNext.text),
          },
        }}
        disabled={page === Math.ceil(itemLength / 6)}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
