export enum OTPStatus {
  none = 'none',
  request = 'request',
  verification = 'verification',
  verified = 'verified',
  notVerified = 'notVerified',
  error = 'error',
  resent = 'resent',
  resendError = 'resendError',
}
