import styled from 'styled-components';
import { helpers, media, theme } from '@/assets/styles';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${media.lg`
    display: unset;
    gap: unset;
  `}
`;

export const Header = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
`;

export const Body = styled.div``;

export const Title = styled.p`
  ${helpers.textSet({ fontSize: theme.typography.title.fontSize, align: 'center' })};

  margin-top: 50px;
`;

export const GraphContainer = styled.div`
  flex: 1;
`;

export const LegendContainer = styled.div`
  ${helpers.flexSet('column')};
`;

export const HeadCont = styled.div`
  display: flex;
  justify-content: flex-end;
`;
export const GraphDescription = styled.a`
  ${helpers.colorSet({ color: theme.colors.link.default })};
  cursor: pointer;
`;

export const ChartContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  background-color: ${colors.background};
  border-radius: 8px;
  padding: 15px 10px;
  ${media.lg`
    display: unset;
    margin-top: unset;
    background-color: unset;
    padding: unset;
  `}
`;

export const GraphDescriptionContainer = styled.div`
  display: flex;
  justify-content: right;
  padding: 10px 0;
`;
