import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const styleButton = {
  padding: '12px 16px',
  maxWidth: '150px',
  flex: 1,
};

export const styleLinkButton = {
  padding: '2px 2px',
};

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export const Description = styled.p`
  ${typography.xs};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.blackGrey};
  ${media.lg`
    ${typography.sm};
    line-height: 20px;
  `}
`;
