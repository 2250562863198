import { ReqIntegrations, ReqVerify, ReqVerifyPge } from '@/apis/enrollment/types';
import {
  EnrollmentInfo,
  PgeVerifyResponse,
  ProgramRecent,
  Program,
  SurveyAnswer,
  UtilityPrograms,
  VerifyResponse,
  Attribute,
  ProgramName,
} from '@/types/enrollment';

// Types
//
// Reset
export const RESET_ENROLLMENT = 'enrollment/RESET';

// Enrollment
export const CHANGE_FIRST_NAME = 'enrollment/CHANGE_FIRST_NAME';
export const CHANGE_LAST_NAME = 'enrollment/CHANGE_LAST_NAME';
export const CHANGE_SERVICE_FIRST_NAME = 'enrollment/CHANGE_SERVICE_FIRST_NAME';
export const CHANGE_SERVICE_LAST_NAME = 'enrollment/CHANGE_SERVICE_LAST_NAME';
export const CHANGE_SERVICE_ACCOUNT_NUMBER = 'enrollment/CHANGE_SERVICE_ACCOUNT_NUMBER';
export const CHANGE_SERVICE_ADDRESS = 'enrollment/CHANGE_SERVICE_ADDRESS';
export const CHANGE_SERVICE_CITY = 'enrollment/CHANGE_SERVICE_CITY';
export const CHANGE_SERVICE_ZIP = 'enrollment/CHANGE_SERVICE_ZIP';
export const CHANGE_SERVICE_PHONE = 'enrollment/CHANGE_SERVICE_PHONE';
export const CHANGE_SERVICE_EMAIL = 'enrollment/CHANGE_SERVICE_EMAIL';
export const CHANGE_IS_BUSINESS = 'enrollment/CHANGE_IS_BUSINESS';
export const CHANGE_ZIP_CODE = 'enrollment/CHANGE_ZIP_CODE';
export const CHANGE_SITE_NAME = 'enrollment/CHANGE_SITE_NAME';
export const CHANGE_VERIFICATION_NAME = 'enrollment/CHANGE_VERIFICATION_NAME';
export const CHANGE_VERIFICATION_ACCOUNT_NUMBER = 'enrollment/CHANGE_ACCOUNT_NUMBER';
export const CHANGE_AGREE_AVAILABLE = 'enrollment/CHANGE_AGREE_AVAILABLE';
export const CHANGE_UTILITY = 'enrollment/CHANGE_UTILITY';
export const CHANGE_SINGLE_SURVEY = 'enrollment/CHANGE_SINGLE_SURVEY';
export const CHANGE_MULTI_SURVEY = 'enrollment/CHANGE_MULTI_SURVEY';

// Program
export const PROGRAM_REQUEST = 'enrollment/PROGRAM_REQUEST';
export const PROGRAM_SUCCESS = 'enrollment/PROGRAM_SUCCESS';
export const PROGRAM_FAILURE = 'enrollment/PROGRAM_FAILURE';

export const CHANGE_PROGRAM = 'enrollment/CHANGE_PROGRAM';
export const CHANGE_PROGRAMS = 'enrollment/CHANGE_PROGRAMS';
export const RESTORE_PROGRAM = 'enrollment/RESTORE_PROGRAM';
export const RESET_PROGRAM = 'enrollment/RESET_PROGRAM';

// Verification
export const VERIFY_REQUEST = 'enrollment/VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'enrollment/VERIFY_SUCCESS';
export const VERIFY_FAILURE = 'enrollment/VERIFY_FAILURE';

export const VERIFY_PGE_REQUEST = 'enrollment/VERIFY_PGE_REQUEST';
export const VERIFY_PGE_SUCCESS = 'enrollment/VERIFY_PGE_SUCCESS';
export const VERIFY_PGE_FAILURE = 'enrollment/VERIFY_PGE_FAILURE';

// Connect to utility
export const RESET_GBC = 'enrollment/RESET_GBC';

export const GBC_REQUEST = 'enrollment/GBC_REQUEST';
export const GBC_SUCCESS = 'enrollment/GBC_SUCCESS';
export const GBC_FAILURE = 'enrollment/GBC_FAILURE';

export const CHANGE_GBC_RESULT = 'enrollment/CHANGE_GBC_RESULT';

// Terms and Conditions
export const SUBMIT_TERMS_AND_CONDITIONS_REQUEST = 'main/SUBMIT_TERMS_AND_CONDITIONS_REQUEST';
export const SUBMIT_TERMS_AND_CONDITIONS_FAILURE = 'main/SUBMIT_TERMS_AND_CONDITIONS_FAILURE';
export const SUBMIT_TERMS_AND_CONDITIONS_SUCCESS = 'main/SUBMIT_TERMS_AND_CONDITIONS_SUCCESS';

// Survey
export const SURVEY_REQUEST = 'enrollment/SURVEY_REQUEST';
export const SURVEY_SUCCESS = 'enrollment/SURVEY_SUCCESS';
export const SURVEY_FAILURE = 'enrollment/SURVEY_FAILURE';

// Recent Program
export const RECENT_REQUEST = 'enrollment/RECENT_REQUEST';
export const RECENT_SUCCESS = 'enrollment/RECENT_SUCCESS';
export const RECENT_FAILURE = 'enrollment/RECENT_FAILURE';

// Edition Attributes
export const ATTRIBUTE_REQUEST = 'enrollment/ATTRIBUTE_REQUEST';
export const ATTRIBUTE_SUCCESS = 'enrollment/ATTRIBUTE_SUCCESS';
export const ATTRIBUTE_FAILURE = 'enrollment/ATTRIBUTE_FAILURE';

export const RESET_ENROLLMENT_ERROR = 'enrollment/RESET_ERROR';

interface ResetEnrollmentAction {
  type: typeof RESET_ENROLLMENT;
}

interface ChangeFirstNameAction {
  type: typeof CHANGE_FIRST_NAME;
  firstName: string;
}

interface ChangeLastNameAction {
  type: typeof CHANGE_LAST_NAME;
  lastName: string;
}

interface ChangeServiceFirstNameAction {
  type: typeof CHANGE_SERVICE_FIRST_NAME;
  serviceFirstName: string;
}

interface ChangeServiceLastNameAction {
  type: typeof CHANGE_SERVICE_LAST_NAME;
  serviceLastName: string;
}

interface ChangeServiceAccount {
  type: typeof CHANGE_SERVICE_ACCOUNT_NUMBER;
  serviceAccountNumber: string;
}

interface ChangeServiceAddress {
  type: typeof CHANGE_SERVICE_ADDRESS;
  serviceAddress: string;
}

interface ChangeServiceCity {
  type: typeof CHANGE_SERVICE_CITY;
  serviceCity: string;
}

interface ChangeServiceZip {
  type: typeof CHANGE_SERVICE_ZIP;
  serviceZipCode: string;
}

interface ChangeServicePhone {
  type: typeof CHANGE_SERVICE_PHONE;
  servicePhone: string;
}

interface ChangeServiceEmail {
  type: typeof CHANGE_SERVICE_EMAIL;
  serviceEmail: string;
}

interface ChangeIsBusinessAction {
  type: typeof CHANGE_IS_BUSINESS;
  isBusiness: boolean;
}

interface ChangeZipcodeAction {
  type: typeof CHANGE_ZIP_CODE;
  zipCode: string;
}

interface ChangeSiteNameAction {
  type: typeof CHANGE_SITE_NAME;
  siteName: string;
}

interface ChangeVerificationNameAction {
  type: typeof CHANGE_VERIFICATION_NAME;
  verificationName: string;
}

interface ChangeVerificationAccountNumberAction {
  type: typeof CHANGE_VERIFICATION_ACCOUNT_NUMBER;
  verificationAccountNumber: string;
}

interface ChangeAgreeAvailableAction {
  type: typeof CHANGE_AGREE_AVAILABLE;
  agreeAvailable: boolean;
}

interface ChangeUtilityAction {
  type: typeof CHANGE_UTILITY;
  utility: string;
}

interface SubmitTermsAndConditionsRequestAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_REQUEST;
  program: ProgramName;
}

interface SubmitTermsAndConditionsSuccessAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_SUCCESS;
}

interface SubmitTermsAndConditionsFailureAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_FAILURE;
  error: Error;
}

interface ChangeSingleSurveyAction {
  type: typeof CHANGE_SINGLE_SURVEY;
  answer: SurveyAnswer;
}

interface ChangeMultipleSurveyAction {
  type: typeof CHANGE_MULTI_SURVEY;
  answer: SurveyAnswer[];
}

interface ProgramRequestAction {
  type: typeof PROGRAM_REQUEST;
  zipCode: string;
  callback: () => unknown;
}

interface ProgramSuccessAction {
  type: typeof PROGRAM_SUCCESS;
  utilityPrograms: UtilityPrograms;
}

interface ProgramFailureAction {
  type: typeof PROGRAM_FAILURE;
}

interface ChangeProgramsAction {
  type: typeof CHANGE_PROGRAMS;
  programs: Program[];
}

interface ChangeProgramAction {
  type: typeof CHANGE_PROGRAM;
  enrollmentProgram: Program;
}

interface RestoreProgramAction {
  type: typeof RESTORE_PROGRAM;
}

interface ResetProgramAction {
  type: typeof RESET_PROGRAM;
  enrollmentProgram: Program;
}

interface VerifyRequestAction {
  type: typeof VERIFY_REQUEST;
}

interface VerifySuccessAction {
  type: typeof VERIFY_SUCCESS;
  verifyResponse: VerifyResponse;
}

interface VerifyFailureAction {
  type: typeof VERIFY_FAILURE;
}

interface VerifyPgeRequestAction {
  type: typeof VERIFY_PGE_REQUEST;
}

interface VerifyPgeSuccessAction {
  type: typeof VERIFY_PGE_SUCCESS;
  verifyResponse: VerifyResponse;
}

interface VerifyPgeFailureAction {
  type: typeof VERIFY_PGE_FAILURE;
}

interface ResetGbcAction {
  type: typeof RESET_GBC;
}

interface ChangeGbcAction {
  type: typeof CHANGE_GBC_RESULT;
  result: string;
}

interface GbcRequestAction {
  type: typeof GBC_REQUEST;
  payload: ReqIntegrations;
}

interface GbcSuccessAction {
  type: typeof GBC_SUCCESS;
  gbcUrl: string;
}

interface GbcFailureAction {
  type: typeof GBC_FAILURE;
}

interface SurveyRequestAction {
  type: typeof SURVEY_REQUEST;
  survey: SurveyAnswer[];
}

interface SurveySuccessAction {
  type: typeof SURVEY_SUCCESS;
  gbcUrl: string;
}

interface SurveyFailureAction {
  type: typeof SURVEY_FAILURE;
}

interface RecentRequestAction {
  type: typeof RECENT_REQUEST;
}

interface RecentSuccessAction {
  type: typeof RECENT_SUCCESS;
  recent: ProgramRecent;
}

interface RecentFailureAction {
  type: typeof RECENT_FAILURE;
}

interface AttributeSuccessAction {
  type: typeof ATTRIBUTE_SUCCESS;
  attribute: Attribute;
  attributeValue: boolean;
}

interface AttributeFailureAction {
  type: typeof ATTRIBUTE_FAILURE;
}

interface ResetEnrollmentErrorAction {
  type: typeof RESET_ENROLLMENT_ERROR;
}

type InitActionTypes =
  | ResetEnrollmentAction
  | ChangeFirstNameAction
  | ChangeLastNameAction
  | ChangeServiceFirstNameAction
  | ChangeServiceLastNameAction
  | ChangeServiceAccount
  | ChangeServiceAddress
  | ChangeServiceCity
  | ChangeServiceZip
  | ChangeServicePhone
  | ChangeServiceEmail
  | ChangeIsBusinessAction
  | ChangeZipcodeAction
  | ChangeSiteNameAction
  | ChangeVerificationNameAction
  | ChangeVerificationAccountNumberAction
  | ChangeAgreeAvailableAction
  | ChangeUtilityAction
  | ChangeSingleSurveyAction
  | ChangeMultipleSurveyAction
  | ProgramRequestAction
  | ProgramSuccessAction
  | ProgramFailureAction
  | ChangeProgramsAction
  | ChangeProgramAction
  | RestoreProgramAction
  | ResetProgramAction
  | VerifyRequestAction
  | VerifySuccessAction
  | VerifyFailureAction
  | VerifyPgeRequestAction
  | VerifyPgeSuccessAction
  | VerifyPgeFailureAction
  | ResetGbcAction
  | ChangeGbcAction
  | GbcRequestAction
  | GbcSuccessAction
  | GbcFailureAction
  | SubmitTermsAndConditionsRequestAction
  | SubmitTermsAndConditionsSuccessAction
  | SubmitTermsAndConditionsFailureAction
  | SurveyRequestAction
  | SurveySuccessAction
  | SurveyFailureAction
  | RecentRequestAction
  | RecentSuccessAction
  | RecentFailureAction
  | AttributeSuccessAction
  | AttributeFailureAction
  | ResetEnrollmentErrorAction;

// initial state
//
export interface EnrollmentState {
  enrollmentInfo: EnrollmentInfo;
  programs: {
    prevProgram: Program[];
    currentProgram: Program;
    availablePrograms: Program[];
  };
  utilityPrograms: UtilityPrograms;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialState: EnrollmentState = {
  enrollmentInfo: {
    firstName: '',
    lastName: '',
    serviceFirstName: '',
    serviceLastName: '',
    serviceAccountNumber: '',
    serviceAddress: '',
    serviceCity: '',
    serviceZipCode: '',
    servicePhone: '',
    serviceEmail: '',
    isBusiness: false,
    zipCode: '',
    siteName: '',
    program: '',
    verificationName: '',
    verificationAccountNumber: '',
    verifyResponse: <VerifyResponse>{},
    areAllServiceAccountsAuthorized: false,
    agreeAvailable: false,
    utility: '',
    gbcUrl: '',
    gbcUrlResult: '',
    singleSurvey: {
      program: '',
      question: '',
      answer: '',
      other: undefined,
      referralName: undefined,
      referralEmail: undefined,
      referralPhoneNumber: undefined,
    },
    multipleSurvey: [],
    attributes: {},
  },
  programs: {
    prevProgram: [],
    currentProgram: {
      type: '',
      logo: '',
      logoSmall: '',
      logoLarge: '',
      program: '' as ProgramName,
      accountMaxLength: 0,
      accountMinLength: 0,
      screenStep: [],
      utilityProvider: '',
      zipcodes: [],
      attributes: {
        moneyEarnedInProgram: false,
        moneyEarnedPerEvent: false,
        affirmativeOptIn: false,
        optInFromStart: false,
        optOutFromStart: false,
        optInCutoff: 0,
        optOutCutoff: 0,
        hideSupport: true,
        displayMeterData: false,
        optInMode: '',
      },
      // options
    },
    availablePrograms: [],
  },
  utilityPrograms: {},
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: EnrollmentState = initialState, action: InitActionTypes): EnrollmentState => {
  switch (action.type) {
    case RESET_ENROLLMENT:
      return {
        ...initialState,
      };

    case CHANGE_FIRST_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, firstName: action.firstName },
      };

    case CHANGE_LAST_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, lastName: action.lastName },
      };

    case CHANGE_SERVICE_FIRST_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceFirstName: action.serviceFirstName },
      };

    case CHANGE_SERVICE_LAST_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceLastName: action.serviceLastName },
      };

    case CHANGE_SERVICE_ACCOUNT_NUMBER:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceAccountNumber: action.serviceAccountNumber },
      };

    case CHANGE_SERVICE_ADDRESS:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceAddress: action.serviceAddress },
      };

    case CHANGE_SERVICE_CITY:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceCity: action.serviceCity },
      };

    case CHANGE_SERVICE_ZIP:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceZipCode: action.serviceZipCode },
      };

    case CHANGE_SERVICE_PHONE:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, servicePhone: action.servicePhone },
      };

    case CHANGE_SERVICE_EMAIL:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, serviceEmail: action.serviceEmail },
      };

    case CHANGE_IS_BUSINESS:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, isBusiness: action.isBusiness },
      };

    case CHANGE_ZIP_CODE:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, zipCode: action.zipCode },
      };

    case CHANGE_SITE_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, siteName: action.siteName },
      };

    case CHANGE_VERIFICATION_NAME:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, verificationName: action.verificationName },
      };

    case CHANGE_VERIFICATION_ACCOUNT_NUMBER:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, verificationAccountNumber: action.verificationAccountNumber },
      };

    case CHANGE_AGREE_AVAILABLE:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, agreeAvailable: action.agreeAvailable },
      };

    case CHANGE_UTILITY:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, utility: action.utility },
      };

    case CHANGE_SINGLE_SURVEY:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, singleSurvey: action.answer },
      };

    case CHANGE_MULTI_SURVEY:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, multipleSurvey: action.answer },
      };

    case VERIFY_SUCCESS:
    case VERIFY_PGE_SUCCESS:
      return {
        ...state,
        enrollmentInfo: {
          ...state.enrollmentInfo,
          verifyResponse: action.verifyResponse,
        },
      };

    case VERIFY_FAILURE:
    case VERIFY_PGE_FAILURE:
      return {
        ...state,
      };

    case RESET_GBC:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, gbcUrl: '' },
      };

    case CHANGE_GBC_RESULT:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, gbcUrlResult: action.result },
      };

    case GBC_SUCCESS:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, gbcUrl: action.gbcUrl },
      };

    case GBC_FAILURE:
      return {
        ...state,
      };

    case SURVEY_SUCCESS:
      return {
        ...state,
      };

    case SURVEY_FAILURE:
      return {
        ...state,
      };

    case PROGRAM_SUCCESS:
      return {
        ...state,
        utilityPrograms: action.utilityPrograms,
      };

    case PROGRAM_FAILURE:
      return {
        ...state,
      };

    case CHANGE_PROGRAMS: {
      const defaultProgram = action.programs.length > 0 ? action.programs[0] : initialState.programs.currentProgram;
      return {
        ...state,
        programs: {
          ...state.programs,
          availablePrograms: action.programs,
          prevProgram: [],
          currentProgram: action.programs.map((p) => p.program).includes(state.programs.currentProgram?.program)
            ? state.programs.currentProgram
            : defaultProgram,
        },
      };
    }
    case CHANGE_PROGRAM:
      return {
        ...state,
        programs: {
          ...state.programs,
          prevProgram: [...state.programs.prevProgram, action.enrollmentProgram],
          currentProgram: action.enrollmentProgram,
        },
      };

    case RESTORE_PROGRAM:
      state.programs.prevProgram.pop();
      return {
        ...state,
        programs: {
          ...state.programs,
          currentProgram: state.programs.prevProgram[state.programs.prevProgram.length - 1] || [],
          prevProgram: state.programs.prevProgram,
        },
      };

    case RESET_PROGRAM:
      return {
        ...state,
        programs: {
          ...state.programs,
          prevProgram: [],
          currentProgram: action.enrollmentProgram,
        },
      };

    case RECENT_SUCCESS:
      return {
        ...state,
        enrollmentInfo: { ...state.enrollmentInfo, program: action.recent.program, utility: action.recent.provider },
      };

    case RECENT_FAILURE:
      return {
        ...state,
      };

    case ATTRIBUTE_SUCCESS:
      return {
        ...state,
        enrollmentInfo: {
          ...state.enrollmentInfo,
          attributes: {
            ...state.enrollmentInfo.attributes,
            [action.attribute]: action.attributeValue,
          },
        },
      };

    case ATTRIBUTE_FAILURE:
      return {
        ...state,
      };
    case SUBMIT_TERMS_AND_CONDITIONS_FAILURE:
      return {
        ...state,
        error: {
          hasError: true,
          errorMessage: action.error.message,
        },
      };
    case RESET_ENROLLMENT_ERROR:
      return {
        ...state,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    default:
      return state;
  }
};

// Actions
//
export const changeFirstName = (firstName: string) => ({ type: CHANGE_FIRST_NAME, firstName });
export const changeLastName = (lastName: string) => ({ type: CHANGE_LAST_NAME, lastName });
export const changeServiceFirstName = (serviceFirstName: string) => ({
  type: CHANGE_SERVICE_FIRST_NAME,
  serviceFirstName,
});
export const changeServiceLastName = (serviceLastName: string) => ({ type: CHANGE_SERVICE_LAST_NAME, serviceLastName });
export const changeServiceAccount = (serviceAccountNumber: string) => ({
  type: CHANGE_SERVICE_ACCOUNT_NUMBER,
  serviceAccountNumber,
});
export const changeServiceAddress = (serviceAddress: string) => ({ type: CHANGE_SERVICE_ADDRESS, serviceAddress });
export const changeServiceCity = (serviceCity: string) => ({ type: CHANGE_SERVICE_CITY, serviceCity });
export const changeServiceZip = (serviceZipCode: string) => ({ type: CHANGE_SERVICE_ZIP, serviceZipCode });
export const changeServicePhone = (servicePhone: string) => ({ type: CHANGE_SERVICE_PHONE, servicePhone });
export const changeServiceEmail = (serviceEmail: string) => ({ type: CHANGE_SERVICE_EMAIL, serviceEmail });
export const changeIsBusiness = (isBusiness: boolean) => ({ type: CHANGE_IS_BUSINESS, isBusiness });
export const changeZipcode = (zipCode: string) => ({ type: CHANGE_ZIP_CODE, zipCode });
export const changeSiteName = (siteName: string) => ({ type: CHANGE_SITE_NAME, siteName });
export const changeVerificationName = (verificationName: string) => ({
  type: CHANGE_VERIFICATION_NAME,
  verificationName,
});
export const changeVerificationAccountNumber = (verificationAccountNumber: string) => ({
  type: CHANGE_VERIFICATION_ACCOUNT_NUMBER,
  verificationAccountNumber,
});
export const changeAgreeAvailable = (agreeAvailable: boolean) => ({ type: CHANGE_AGREE_AVAILABLE, agreeAvailable });
export const changeUtility = (utility: string) => ({ type: CHANGE_UTILITY, utility });
export const changeSingleSurvey = (answer: SurveyAnswer) => ({ type: CHANGE_SINGLE_SURVEY, answer });
export const changeMultipleSurvey = (answer: SurveyAnswer[]) => ({ type: CHANGE_MULTI_SURVEY, answer });

export const verifyRequest = (payload: ReqVerify, success: (res: VerifyResponse) => void, failure: () => void) => ({
  type: VERIFY_REQUEST,
  payload,
  success,
  failure,
});

export const verifyPgeRequest = (
  payload: ReqVerifyPge,
  success: (res: PgeVerifyResponse) => void,
  failure: () => void,
) => ({
  type: VERIFY_PGE_REQUEST,
  payload,
  success,
  failure,
});

export const gbcRequest = (payload: ReqIntegrations, success: (url: string) => void) => ({
  type: GBC_REQUEST,
  payload,
  success,
});

export const gbcResult = (result: string) => ({ type: CHANGE_GBC_RESULT, result });

export const resetGBC = () => ({ type: RESET_GBC });

export const submitTermsAndConditionsRequest = (program: ProgramName) => ({
  type: SUBMIT_TERMS_AND_CONDITIONS_REQUEST,
  program,
});

export const surveyRequest = (payload: SurveyAnswer[], callback: (success: boolean) => void) => ({
  type: SURVEY_REQUEST,
  payload,
  callback,
});

export const programRequest = (zipCode: string, callback?: (utilityPrograms: UtilityPrograms) => void) => ({
  type: PROGRAM_REQUEST,
  zipCode,
  callback,
});
export const changePrograms = (programs: Program[]) => ({ type: CHANGE_PROGRAMS, programs });
export const changeProgram = (enrollmentProgram: Program) => ({ type: CHANGE_PROGRAM, enrollmentProgram });
export const restoreProgram = () => ({ type: RESTORE_PROGRAM });
export const resetProgram = (enrollmentProgram: Program) => ({ type: RESET_PROGRAM, enrollmentProgram });

export const recentRequest = (callback?: (data: unknown) => void) => ({ type: RECENT_REQUEST, callback });

export const attributeRequest = (attribute: Attribute) => ({ type: ATTRIBUTE_REQUEST, attribute });
export const resetEnrollment = () => ({ type: RESET_ENROLLMENT });
export const resetEnrollmentError = () => ({ type: RESET_ENROLLMENT_ERROR });
