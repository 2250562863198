import { PlotData } from 'plotly.js';
import { accessibilityUsageLabel } from './AccessibilityHelper';

export const htmlTableRenderer = (
  title: string,
  labels: string[],
  values: PlotData[],
  unit: string,
  language: string,
  multi?: boolean,
) => {
  const header = headerRender(labels, values);
  const body = bodyRender(values, multi, labels);
  const fixedLanguage = language.slice(0, -1);
  const htmlTable = `
    <!DOCTYPE html>
    <html lang=${fixedLanguage}>
    <head>
      <meta charset="utf-8">
      <title>${title}</title>
      <style>
      html {
        font-family: sans-serif;
      }
  
      table {
        border-collapse: collapse;
        border: 2px solid rgb(200,200,200);
        letter-spacing: 1px;
        font-size: 0.8rem;
      }
  
      td, th {
        border: 1px solid rgb(190,190,190);
        padding: 10px 20px;
      }
  
      td {
        text-align: center;
      }
  
      caption {
        padding: 10px;
      }
      </style>
    </head>
    <body>
      <main>
        <h1>${title}</h1>
        <table tabIndex="0">
          <caption aria-label=>${title} / unit: ${unit}</caption>
            <thead>
              <tr>
                <td aria-hidden="true">&nbsp;</td>
                ${header}
              </tr>
            </thead>
            <tbody>
              ${body}
            </tbody>
        </table>
      </main>
    </body>
    </html>`;
  return htmlTable;
};

const accessibilityLabel = (value: PlotData, index: number, labels?: string[]) => {
  return labels?.includes('Typical') || labels?.includes('Energy Event')
    ? accessibilityUsageLabel(value)
    : `${labels && typeof labels[index] !== undefined ? labels[index] : ''} ${value} kilowatt hours`;
};

const headerRender = (labels: string[], values: PlotData[]) => {
  let htmlStr = '';
  labels.forEach((label: string, index: number) => {
    htmlStr += `<th scope="col" aria-label="${
      typeof values[index] !== undefined ? accessibilityLabel(values[index], index, labels) : label
    }" tabIndex="0">${label}</th>`;
  });
  return htmlStr;
};

const bodyRender = (values: PlotData[], multi?: boolean, labels?: string[]) => {
  if (multi) {
    let htmlStr = `<tr>`;
    for (let i = 0; i < values[0].x.length; i += 1) {
      htmlStr += `<th>${values[0].x[i]}</th>`;
      htmlStr += `<td>${values[0].y[i]}</td>`;
      htmlStr += `<td>${values[1].y[i]}</td>`;
    }
    htmlStr += `</tr>`;
    return htmlStr;
  }
  let htmlStr = '<tr><th scope="row">Values</th>';
  values.forEach((value: PlotData, index: number) => {
    htmlStr += `<td aria-label="${accessibilityLabel(value, index, labels)}" tabIndex="0">${value}</td>`;
  });
  htmlStr += '</tr>';
  return htmlStr;
};
