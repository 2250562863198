import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const styleButton = {
  padding: '12px 16px',
};

export const Header = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const UsageGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;

  ${media.lg`
    padding: 20px 30px 30px 30px;
    justify-content: space-evenly;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;

  ${media.lg`
    gap: 20px;
  `}
`;

export const InnerSection = styled.div`
  padding: 11px;
  background-color: ${colors.background};
  border-radius: 8px;
  margin-right: 5px;
  display: flex;
  gap: 20px;
  flex-direction: column;

  ${media.xl`
    flex-direction: row;
  `}
`;

export const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px;
  border-radius: 8px;
  background-color: ${colors.white};
  flex: 1;
  ${media.xl`
    max-width: 452px;
    min-width: 430px;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EventHistoryButtonContainer = styled.div`
  display: flex;
`;

export const Title = styled.p`
  ${typography.md};
  line-height: 22px;
  font-weight: 700;
  color: ${colors.text};

  ${media.lg`
    ${typography.xl};
    line-height: 26px;
    font-weight: 700;
  `}
`;

export const EventHistoryContainer = styled.div``;
