import { Language } from '@/types/setting';
import { LoginRedirectError } from '@/types/auth';
import { RootState } from '.';

// Types

// Login
export const RESET_APP_DATA = 'auth/RESET_APP_DATA';
export const RESET_AUTH = 'auth/RESET_AUTH';
export const RESET_AUTH_URL = 'auth/RESET_AUTH_URL';

export const LOGIN_REQUEST = 'auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'auth/LOGIN_FAILURE';

// Register
export const REGISTER_REQUEST = 'auth/REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'auth/REGISTER_FAILURE';

// Logout
export const LOGOUT_REQUEST = 'auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'auth/LOGOUT_FAILURE';

export const LOGIN_REDIRECT_ERROR_REQUEST = 'auth/LOGIN_REDIRECT_ERROR_REQUEST';
export const LOGIN_REDIRECT_ERROR_SUCCESS = 'auth/LOGIN_REDIRECT_ERROR_SUCCESS';
export const LOGIN_REDIRECT_ERROR_FAILURE = 'auth/LOGIN_REDIRECT_ERROR_FAILURE';

interface ResetAppDataAction {
  type: typeof RESET_APP_DATA;
}

interface ResetAuthAction {
  type: typeof RESET_AUTH;
}

interface ResetAuthUrlAction {
  type: typeof RESET_AUTH_URL;
}

interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
  language: Language;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  authUrl: string;
}

interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  errorMessage: string;
}

interface RegisterRequestAction {
  type: typeof REGISTER_REQUEST;
}

interface RegisterSuccessAction {
  type: typeof REGISTER_SUCCESS;
  authUrl: string;
}

interface RegisterFailureAction {
  type: typeof REGISTER_FAILURE;
  errorMessage: string;
}

interface LogoutRequestAction {
  type: typeof LOGOUT_REQUEST;
  token: string;
  userId: string;
}

interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

interface LogoutFailureAction {
  type: typeof LOGOUT_FAILURE;
  errorMessage: string;
}

interface LoginRedirectErrorRequestAction {
  type: typeof LOGIN_REDIRECT_ERROR_REQUEST;
}

interface LoginRedirectErrorSuccessAction {
  type: typeof LOGIN_REDIRECT_ERROR_SUCCESS;
  loginRedirectError: LoginRedirectError;
}

interface LoginRedirectErrorFailureAction {
  type: typeof LOGIN_REDIRECT_ERROR_FAILURE;
}

type InitActionTypes =
  | ResetAppDataAction
  | ResetAuthAction
  | ResetAuthUrlAction
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | RegisterRequestAction
  | RegisterSuccessAction
  | RegisterFailureAction
  | LogoutRequestAction
  | LogoutSuccessAction
  | LogoutFailureAction
  | LoginRedirectErrorRequestAction
  | LoginRedirectErrorSuccessAction
  | LoginRedirectErrorFailureAction;

// initial state
export interface AuthState {
  // Olivine Auth Url
  data: {
    authUrl?: string;
    loginRedirectError: LoginRedirectError;
  };
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  isRegistering: boolean;
  isRedirectingLoginError: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialState: AuthState = {
  data: {
    authUrl: undefined,
    loginRedirectError: {
      errorMessage: '',
      reduxState: null,
    },
  },
  isLoggingIn: false,
  isLoggingOut: false,
  isRegistering: false,
  isRedirectingLoginError: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
// eslint-disable-next-line default-param-last
export default (state: AuthState = initialState, action: InitActionTypes): AuthState => {
  switch (action.type) {
    case RESET_AUTH: {
      return {
        ...initialState,
      };
    }

    case RESET_AUTH_URL: {
      return {
        ...state,
        data: {
          ...state.data,
          authUrl: undefined,
        },
      };
    }

    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        data: {
          ...state.data,
          authUrl: action.authUrl,
        },
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          authUrl: undefined,
        },
        isLoggingIn: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case REGISTER_REQUEST:
      return {
        ...state,
        isRegistering: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case REGISTER_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          authUrl: action.authUrl,
        },
        isRegistering: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case REGISTER_FAILURE:
      return {
        ...state,
        isRegistering: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggingOut: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case LOGIN_REDIRECT_ERROR_REQUEST:
      return {
        ...state,
        isRedirectingLoginError: true,
        data: {
          ...state,
          loginRedirectError: {
            errorMessage: '',
            reduxState: null,
          },
        },
      };

    case LOGIN_REDIRECT_ERROR_SUCCESS:
      return {
        ...state,
        isRedirectingLoginError: false,
        data: {
          ...state,
          loginRedirectError: action.loginRedirectError,
        },
      };

    case LOGIN_REDIRECT_ERROR_FAILURE:
      return {
        ...state,
        isRedirectingLoginError: false,
        data: {
          ...state,
          loginRedirectError: {
            errorMessage: '',
            reduxState: null,
          },
        },
      };

    default:
      return state;
  }
};

// Actions
export const resetAppData = () => ({ type: RESET_APP_DATA });

export const resetAuth = () => ({ type: RESET_AUTH });

export const resetAuthUrl = () => ({ type: RESET_AUTH_URL });

export const loginRequest = (language: Language) => ({ type: LOGIN_REQUEST, language });

export const registerRequest = (language: Language) => ({ type: REGISTER_REQUEST, language });

export const logoutRequest = (token: string, userId: string) => ({
  type: LOGOUT_REQUEST,
  token,
  userId,
});

export const loginRedirectError = (errorMessage: string, reduxState: RootState) => ({
  type: LOGIN_REDIRECT_ERROR_REQUEST,
  errorMessage,
  reduxState,
});
