import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { OTPStatus } from '@/types/phoneOtp';
import { Input } from '@/designSystem/components';
import { RootState } from '@/stores/index';
import { hyphenPhoneNumber } from '@/helpers/RegexHelper';
import { images } from '@/assets/styles';
import { FillPrimary } from '@/designSystem/components/buttons/FillPrimary';
import { IconButton } from '@/designSystem/components/buttons';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import usePhoneOtp from '@/hooks/usePhoneOtp';
import {
  Container,
  Title,
  Description,
  InputContainer,
  Wrapper,
  HeadNavigation,
  NavigationTitle,
  Content,
  ButtonContainer,
  Button,
} from './SMSPhoneProfileScreen.styles';

export interface FormValues {
  phoneNumber?: string;
}

const SMSPhoneProfileScreen = ({ ariaControlsId }: { ariaControlsId: string }) => {
  const { t } = useTranslation('common');

  const { fetchPhoneOtpRequest, fetchChangePhoneOtp, fetchChangeNewPhoneNumber, fetchUnsetOldAndNewPhoneNumbers } =
    usePhoneOtp();

  const {
    phoneOtp: {
      data: { phoneStatus, oldPhoneNumber },
    },
  } = useSelector((state: RootState) => state);

  const {
    setValue,
    control,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitSuccessful, isSubmitted, errors },
    setError,
  } = useForm<FormValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      phoneNumber: '',
    },
  });

  useEffect(() => {
    if (!oldPhoneNumber) {
      reset({ phoneNumber: '' });
    } else {
      setValue('phoneNumber', hyphenPhoneNumber(oldPhoneNumber));
    }
  }, [oldPhoneNumber]);

  useEffect(() => {
    if (phoneStatus === OTPStatus.error && isSubmitted) {
      setError('phoneNumber', {
        type: 'error',
        message: t('screens.main.settings.account.smsPhoneProfileScreen.phoneInput.errorMessage'),
      });
    } else if (phoneStatus === OTPStatus.verification && isSubmitSuccessful) {
      reset({ phoneNumber: '' });
    }
  }, [phoneStatus]);

  const handleCloseModal = () => {
    fetchUnsetOldAndNewPhoneNumbers();
    fetchChangePhoneOtp(OTPStatus.none);
  };

  const onSubmit = (data: { phoneNumber?: string }) => {
    const unMaskedPhone = data.phoneNumber?.replaceAll(/[' '-]/g, '') || '';
    sentEvent('click', PATHS.SEND_CODE);
    fetchChangeNewPhoneNumber(unMaskedPhone);
    fetchPhoneOtpRequest({ phoneNumber: unMaskedPhone });
  };

  const isEdittingPhoneNumber = !!oldPhoneNumber;

  const descriptionText = isEdittingPhoneNumber
    ? t('screens.main.settings.account.smsPhoneProfileScreen.edittingPhoneHint')
    : t('screens.main.settings.account.smsPhoneProfileScreen.hintDescription');

  return (
    <Wrapper>
      <HeadNavigation>
        <IconButton
          onClick={handleCloseModal}
          image={{
            active: {
              src: images.headNavigationBack.image,
              alt: images.headNavigationBack.text,
            },
            disabled: {
              src: images.headNavigationBack.image,
              alt: images.headNavigationBack.text,
            },
          }}
          padding="4px"
        />
        <NavigationTitle>{`${t(
          'screens.main.settings.account.smsPhoneProfileScreen.navigationTitle',
        )}`}</NavigationTitle>
        <IconButton
          onClick={handleCloseModal}
          image={{
            active: {
              src: images.headNavigationClose.image,
              alt: images.headNavigationClose.text,
            },
            disabled: {
              src: images.headNavigationClose.image,
              alt: images.headNavigationClose.text,
            },
          }}
          padding="4px"
        />
      </HeadNavigation>
      <Container>
        <Title>{`${t('screens.main.settings.account.smsPhoneProfileScreen.screenTitle')}`}</Title>
        <Content>
          <Description>{descriptionText}</Description>
          <InputContainer>
            <Input
              type="text"
              validationRules={{
                required: true,
                minLength: 16,
                maxLength: 16,
                validate: (newPhoneNum: string) => newPhoneNum !== hyphenPhoneNumber(oldPhoneNumber),
              }}
              formatEntry={hyphenPhoneNumber}
              inputName="phoneNumber"
              control={control}
              label={t('screens.main.settings.account.smsPhoneProfileScreen.phoneInput.label')}
              disabled={false}
              error={errors.phoneNumber?.message}
              placeholder={t('screens.main.settings.account.smsPhoneProfileScreen.phoneInput.placeholder')}
              ariaLabel={ariaControlsId}
            />
          </InputContainer>
          <Description>{`${t(
            'screens.main.settings.account.smsPhoneProfileScreen.checkCarrierDescription',
          )}`}</Description>
        </Content>
        <ButtonContainer>
          <Button>
            <FillPrimary
              title={t('screens.main.settings.account.smsPhoneProfileScreen.sendCodeButton.title')}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              padding="12px 0"
            />
          </Button>
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

export default SMSPhoneProfileScreen;
