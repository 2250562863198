import { useQuery } from '@tanstack/react-query';
import { Program } from '@/types/enrollment';
import * as apiPrimary from '@/apis/primary';
import { GeneralHook, FetchingStatus } from './types';

const usePrograms = (): GeneralHook<Array<Program>> => {
  const { data, error, isError, isRefetching, isFetching, refetch, isInitialLoading } = useQuery({
    queryKey: ['programs'],
    queryFn: () => apiPrimary.getAllPrograms(),
    enabled: false,
    staleTime: 60 * 5 * 1000,
  });

  if (isInitialLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  // the endpoint returns another layer of "data" for some reason
  if (data?.data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data.data.allPrograms,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default usePrograms;
