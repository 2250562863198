import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';

export const Container = styled.div(
  ({ theme }) => css`
    ${helpers.flexSet('column', 'center')};
    ${helpers.borderSet({ borderRadius: theme.borders.default.radius })};
    ${css`
      box-shadow: ${theme.shadow.web.boxShadow};
    `};
    padding: 20px 10px;
    align-items: center;
    text-align: center;
  `,
);

export const Description = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 16, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 13px;
  `,
);

export const Title = styled.p`
  color: #47851e;
  font-size: 20px;
`;
