import React from 'react';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import { sentEvent } from '@/helpers/GAHelper';
import { images } from '@/assets/styles';
import { useAuth } from '@/hooks/index';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import { LinkButton, SecondaryButton } from '@/designSystem/components/buttons';
import {
  Wrapper,
  Container,
  Logo,
  Title,
  Description,
  ButtonWrapper,
  ItemWrapper,
  LinkDescription,
} from './NoEnrollmentErrorScreen.styles';

const NoEnrollmentErrorScreen = () => {
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const { fetchLogout } = useAuth();

  const supportEmail = t(`programs.SCE_ELRP_A1.support.email`);

  const handleMailLink = () => {
    sentEvent('click', PATHS.EMAIL_SUPPORT);
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'supportmail', message: `mailto:${supportEmail}` });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(`mailto:${supportEmail}`);
    }
  };

  const handleLogout = () => {
    sentEvent('click', PATHS.LOGOUT_FROM_REDIRECT);

    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ to: 'logout' });
      window.ReactNativeWebView.postMessage(data);
      return;
    }
    fetchLogout(userInfo.oidcIdToken, userInfo.id);
  };

  return (
    <Wrapper>
      <Container>
        <Logo>
          <Img src={images.warning} />
        </Logo>
        <Title>{t('screens.errorScreen.data.title')}</Title>
        <Description>{t('screens.noEnrollmentErrorScreen.description')}</Description>
        <ButtonWrapper>
          <ItemWrapper>
            <LinkDescription>{t('screens.noEnrollmentErrorScreen.contactSupport')}</LinkDescription>
            <LinkButton title={supportEmail} onClick={handleMailLink} padding="8px 12px" />
          </ItemWrapper>

          <SecondaryButton
            title={t('screens.auth.welcomeScreen.logout')}
            onClick={handleLogout}
            style={{ marginTop: 30 }}
          />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
};

export default NoEnrollmentErrorScreen;
