import styled, { css } from 'styled-components';
import { colors, typography } from '@/designSystem/index';
import { media } from '@/assets/styles';

export const SeasonSelectorContainer = styled.div<{ backgroundColor?: string }>(
  ({ backgroundColor }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: ${backgroundColor || colors.white};
    border-radius: 5px;
    padding: 7px 0;
    ${media.lg`
    justify-content: normal;
    background-color: ${backgroundColor || '#f6f6f6'};
  `}
  `,
);

export const SeasonYear = styled.p`
  ${typography.xs};
  display: flex;
  line-height: 18px;
  color: ${colors.primary};
  font-weight: 700;
  width: 100%;
  justify-content: center;
`;
