import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { getColorByLevel } from '@/helpers/AchievementIconHelper';
import { AchievementLevel } from '@/types/dashboard';

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const Value = styled.p`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: ${colors.text};
`;

export const ValueTitle = styled.p`
  ${typography.md};
  line-height: 22px;
  color: ${colors.grey};
  font-weight: 400;
`;

export const LevelTitleContainer = styled.div`
  position: relative;
`;

export const LevelTitle = styled.p<{ level: AchievementLevel }>`
  ${typography.md};
  line-height: 22px;
  font-weight: 400;
  color: ${(props) => getColorByLevel(props.level)};
`;

export const HelpContainer = styled.div`
  position: absolute;
  right: -25px;
  top: 0;
`;
