import { Message } from '@/types/message';

// Types
//
// Reset
export const RESET_MESSAGES = 'message/RESET_MESSAGES';

// Request All Data
export const MESSAGES_REQUEST = 'message/MESSAGES_REQUEST';
export const MESSAGES_SUCCESS = 'message/MESSAGES_SUCCESS';
export const MESSAGES_FAILURE = 'message/MESSAGES_FAILURE';

// Select Message
export const MESSAGE_SELECT = 'message/MESSAGE_SELECT';

interface ResetMessagesAction {
  type: typeof RESET_MESSAGES;
}

interface MessagesRequestAction {
  type: typeof MESSAGES_REQUEST;
}

interface MessagesSuccessAction {
  type: typeof MESSAGES_SUCCESS;
  messages: Message[];
}

interface MessagesFailureAction {
  type: typeof MESSAGES_FAILURE;
  errorMessage: string;
}

interface MessageSelectAction {
  type: typeof MESSAGE_SELECT;
  messageId: string;
}

type InitActionTypes =
  | ResetMessagesAction
  | MessagesRequestAction
  | MessagesSuccessAction
  | MessagesFailureAction
  | MessageSelectAction;

// initial state
//

export interface MessagesStore {
  data: MessagesState;
  isLoading: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

export interface MessagesState {
  messages: Message[];
  selectedMessageId?: string;
}

const messageInitialState: MessagesState = {
  messages: [],
  selectedMessageId: undefined,
};

const initialState = {
  data: { ...messageInitialState },
  isLoading: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: MessagesStore = initialState, action: InitActionTypes): MessagesStore => {
  switch (action.type) {
    case RESET_MESSAGES:
      return {
        ...initialState,
      };

    case MESSAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case MESSAGES_SUCCESS:
      return {
        data: {
          messages: action.messages,
          selectedMessageId: action.messages[0].id || undefined,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case MESSAGES_FAILURE:
      return {
        data: messageInitialState,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action?.errorMessage || '',
        },
      };

    case MESSAGE_SELECT:
      return {
        data: {
          ...state.data,
          selectedMessageId: action.messageId,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    default:
      return state;
  }
};

// Actions
//
export const messagesRequest = () => ({ type: MESSAGES_REQUEST });

export const messageSelect = (messageId: string) => ({ type: MESSAGE_SELECT, messageId });
