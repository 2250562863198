import styled from 'styled-components';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 20px;
  ${media.lg`
    grid-template-columns: 1fr 1.5fr;
  `}
`;

export const MenuWrapper = styled.div``;
