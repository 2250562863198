import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ReqPhoneOtpRequest, ReqPhoneOtpVerification } from '@/apis/enrollment/types';
import { OTPStatus } from '@/types/phoneOtp';
import {
  changePhoneOtpInterval,
  changePhoneOtp,
  phoneOtpRequest,
  phoneOtpVerification,
  phoneOtpResend,
  changeOldPhoneNumber,
  changeNewPhoneNumber,
  unsetOldAndNewPhoneNumbers,
} from '@/stores/phoneOtp';

export default function usePhoneOtp() {
  const dispatch = useDispatch();

  const fetchChangePhoneOtpInterval = useCallback(
    (status: OTPStatus) => dispatch(changePhoneOtpInterval(status)),
    [dispatch],
  );

  const fetchChangePhoneOtp = useCallback((status: OTPStatus) => dispatch(changePhoneOtp(status)), [dispatch]);

  const fetchPhoneOtpRequest = useCallback(
    (payload: ReqPhoneOtpRequest) => {
      dispatch(phoneOtpRequest(payload));
    },
    [dispatch],
  );

  const fetchPhoneOtpVerification = useCallback(
    (currentPhoneNumbers: string[], payload: ReqPhoneOtpVerification) => {
      dispatch(phoneOtpVerification(currentPhoneNumbers, payload));
    },
    [dispatch],
  );

  const fetchPhoneOtpResend = useCallback(
    (payload: ReqPhoneOtpRequest) => {
      dispatch(phoneOtpResend(payload));
    },
    [dispatch],
  );

  const fetchChangeOldPhoneNumber = useCallback(
    (oldPhoneNumber: string) => dispatch(changeOldPhoneNumber(oldPhoneNumber)),
    [dispatch],
  );

  const fetchChangeNewPhoneNumber = useCallback(
    (newPhoneNumber: string) => dispatch(changeNewPhoneNumber(newPhoneNumber)),
    [dispatch],
  );

  const fetchUnsetOldAndNewPhoneNumbers = useCallback(() => dispatch(unsetOldAndNewPhoneNumbers()), [dispatch]);

  return {
    fetchChangePhoneOtpInterval,
    fetchChangePhoneOtp,
    fetchPhoneOtpRequest,
    fetchPhoneOtpVerification,
    fetchPhoneOtpResend,
    fetchChangeOldPhoneNumber,
    fetchChangeNewPhoneNumber,
    fetchUnsetOldAndNewPhoneNumbers,
  };
}
