import React from 'react';
import { useTranslation } from 'react-i18next';
import contents from '@/assets/config/appInfo';
import SingleCheckbox from '@/atoms/SingleCheckbox';
import { isUtilityEdition } from '@/helpers/Edition';
import { Description, AgreeBox, Terms, More, Title, LinkText, UpperMore } from './SiteEnroll.styles';

interface IAvailableProgramViewProps {
  program: string;
  agree: boolean;
  onChangeAgree: () => void;
  onOpenLink: (url: string) => void;
}

const SiteTermsConditions = ({ program, agree, onChangeAgree, onOpenLink }: IAvailableProgramViewProps) => {
  const { t, i18n } = useTranslation('common');

  const isTerms = i18n.exists(`common:programs.${program}.availableProgramScreen.terms`);
  const isMore = i18n.exists(`common:programs.${program}.availableProgramScreen.more`);
  const isLink2 = i18n.exists(`common:programs.${program}.availableProgramScreen.terms.link2`);
  const isSentence3 = i18n.exists(`common:programs.${program}.availableProgramScreen.terms.sentence3`);
  const isUpperMore = i18n.exists(`common:programs.${program}.availableProgramScreen.upperMore`);
  const isDesc2 = i18n.exists(`common:programs.${program}.availableProgramScreen.description2`);
  const isDesc3 = i18n.exists(`common:programs.${program}.availableProgramScreen.description3`);

  document.title = `${t('screens.main.settings.mySites.enrollmentScreen.title')} - ${contents.name}`;

  const titleText = () => {
    return isUtilityEdition ? t('programs.title.Utility') : t('programs.title.Olivine');
  };

  return (
    <div>
      <Title>{titleText()}</Title>
      <Description dangerouslySetInnerHTML={{ __html: t(`programs.${program}.availableProgramScreen.description`) }} />
      {isDesc2 && (
        <UpperMore>
          {t(`programs.${program}.availableProgramScreen.description2.sentence1`)}
          <LinkText
            onClick={() => onOpenLink(t(`programs.${program}.availableProgramScreen.description2.link`))}
            role="link"
            tabIndex={0}
          >
            {t(`programs.${program}.availableProgramScreen.description2.sentenceBtn`)}
          </LinkText>
          {t(`programs.${program}.availableProgramScreen.description2.sentence2`)}
        </UpperMore>
      )}
      {isDesc3 && <Description>{t(`programs.${program}.availableProgramScreen.description3`)}</Description>}
      {isUpperMore && (
        <UpperMore>
          {t(`programs.${program}.availableProgramScreen.upperMore.sentence1`)}
          <LinkText
            onClick={() => onOpenLink(t(`programs.${program}.availableProgramScreen.upperMore.link`))}
            role="link"
            tabIndex={0}
          >
            {t(`programs.${program}.availableProgramScreen.upperMore.sentenceBtn`)}
          </LinkText>
          {t(`programs.${program}.availableProgramScreen.upperMore.sentence2`)}
        </UpperMore>
      )}
      {isTerms && (
        <AgreeBox>
          <SingleCheckbox
            items={[{ id: 1, name: 1, text: '' }]}
            value={agree ? 1 : 0}
            onChange={onChangeAgree}
            checkboxLabel={t(`programs.${program}.availableProgramScreen.terms.checkboxLabel`)}
          />
          <Terms>
            {t(`programs.${program}.availableProgramScreen.terms.sentence1`)}
            <LinkText
              onClick={() => onOpenLink(t(`programs.${program}.availableProgramScreen.terms.link`))}
              role="link"
              tabIndex={0}
            >
              {t(`programs.${program}.availableProgramScreen.terms.sentenceBtn`)}
            </LinkText>
            {t(`programs.${program}.availableProgramScreen.terms.sentence2`)}
            {isLink2 && (
              <LinkText
                onClick={() => onOpenLink(t(`programs.${program}.availableProgramScreen.terms.link2`))}
                role="link"
                tabIndex={0}
              >
                {t(`programs.${program}.availableProgramScreen.terms.sentenceBtn2`)}
              </LinkText>
            )}
            {isSentence3 && t(`programs.${program}.availableProgramScreen.terms.sentence3`)}
          </Terms>
        </AgreeBox>
      )}
      {isMore && (
        <More>
          {t(`programs.${program}.availableProgramScreen.more.sentence1`)}
          <LinkText
            onClick={() => onOpenLink(t(`programs.${program}.availableProgramScreen.more.link`))}
            role="link"
            tabIndex={0}
          >
            {t(`programs.${program}.availableProgramScreen.more.sentenceBtn`)}
          </LinkText>
          {t(`programs.${program}.availableProgramScreen.more.sentence2`)}
        </More>
      )}
    </div>
  );
};

export default SiteTermsConditions;
