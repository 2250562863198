import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import { WEB_HOST } from '@/constants/environment';
import { getUA, isMobile } from 'react-device-detect';
import { getBreakLineCharacter } from '@/helpers/GetBreakLineCharacter';
import { Wrapper, Container, Title, Share, Buttons, ShareButton, ShareTitle } from './index.styles';
import { IGBCPendingViewProps } from './index.types';

const GBCPendingView = ({
  name,
  programName,
  onEmail,
  onSms,
  shareTextColor,
  minHeight,
  containerPadding,
  titleMargin,
}: IGBCPendingViewProps) => {
  const { t, i18n } = useTranslation('common');
  const breakCharacter = getBreakLineCharacter();
  const isEmail = i18n.exists(`common:programs.${programName}.dashboardScreen.pending.email`);

  const isSms = i18n.exists(`common:programs.${programName}.dashboardScreen.pending.sms`);
  const appLink = WEB_HOST;

  const emailTitle = t(`programs.${programName}.dashboardScreen.pending.email.subject`);
  const emailBody = t(`programs.${programName}.dashboardScreen.pending.email.body`, {
    name,
    appLink,
    breakCharacter,
  });

  const smsContent = t(`programs.${programName}.dashboardScreen.pending.sms.body`, {
    name,
    appLink,
  });

  return (
    <Wrapper minHeight={minHeight}>
      <Container padding={containerPadding}>
        <Title margin={titleMargin}>{t('screens.main.dashboard.dashboardScreen.pending.title')}</Title>
        <Share color={shareTextColor}>{t('screens.main.dashboard.dashboardScreen.pending.shareLinkVia')}</Share>
        <Buttons>
          {isEmail && (
            <ShareButton>
              <IconButton
                icon={images.shareEmail}
                onClick={() => onEmail(emailTitle, emailBody)}
                role="button"
                ariaLabelledby="emailLabel"
              />
              <ShareTitle aria-hidden id="emailLabel">
                {t('screens.main.dashboard.dashboardScreen.pending.email')}
              </ShareTitle>
            </ShareButton>
          )}
          {isSms && (isMobile || getUA === 'AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75') && (
            <ShareButton>
              <IconButton
                icon={images.shareSms}
                onClick={() => onSms(smsContent)}
                role="button"
                ariaLabelledby="smsLabel"
              />
              <ShareTitle aria-hidden id="smsLabel">
                {t('screens.main.dashboard.dashboardScreen.pending.sms')}
              </ShareTitle>
            </ShareButton>
          )}
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default GBCPendingView;
