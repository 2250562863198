import React from 'react';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import SignUpCompletedContainer from '@/organisms/SignUpCompleted';

const SingUpCompletedScreenView = () => {
  return (
    <EnrollmentTemplate bodyPadding="0 20px" hideBack>
      <SignUpCompletedContainer />
    </EnrollmentTemplate>
  );
};

export default SingUpCompletedScreenView;
