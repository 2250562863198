import React, { AriaRole } from 'react';
import { Button, Img } from './linkButton.styles';

type LinkButtonProps = {
  title?: string;
  onClick: () => void;
  padding?: string;
  disabled?: boolean;
  underline?: boolean;
  image?: {
    active: {
      src: string;
      alt: string;
    };
    disabled: {
      src: string;
      alt: string;
    };
    width?: string;
    height?: string;
    imagePlace?: 'left';
  };
  fontSize?: string;
  ariaLabel?: string;
  role?: AriaRole;
  removeFlex?: boolean;
};

export const LinkButton = ({
  title,
  onClick,
  padding,
  disabled,
  image,
  underline = true,
  fontSize = '15px',
  ariaLabel = title,
  role,
  removeFlex = false,
}: LinkButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
      fontSize={fontSize}
      padding={padding}
      disabled={disabled}
      underline={underline}
      aria-label={ariaLabel}
      role={role}
      removeFlex={removeFlex}
    >
      {image && image.imagePlace === 'left' && (
        <Img
          width={image.width}
          height={image.height}
          src={disabled ? image.disabled.src : image.active.src}
          alt={disabled ? image.disabled.alt : image.active.alt}
          onClick={(e) => {
            ((e.target as HTMLImageElement).parentElement as HTMLButtonElement).blur();
          }}
        />
      )}
      {title}
      {image && image.imagePlace !== 'left' && (
        <Img
          width={image.width}
          height={image.height}
          src={disabled ? image.disabled.src : image.active.src}
          alt={disabled ? image.disabled.alt : image.active.alt}
          onClick={(e) => {
            ((e.target as HTMLImageElement).parentElement as HTMLButtonElement).blur();
          }}
        />
      )}
    </Button>
  );
};
