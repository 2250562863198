import React from 'react';
import { Button } from './FillPrimary.styles';

type PrimaryButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  padding?: string;
  warning?: boolean;
  maxWidth?: string;
  ariaLabel?: string;
  flex?: number | 'unset';
};

export const FillPrimary = ({
  title,
  ariaLabel = title,
  onClick,
  disabled,
  padding,
  warning = false,
  maxWidth,
  flex = 1,
}: PrimaryButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
      aria-label={ariaLabel}
      disabled={disabled}
      padding={padding}
      warning={warning}
      maxWidth={maxWidth}
      flex={flex}
    >
      {title}
    </Button>
  );
};
