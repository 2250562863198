import { TimePeriod } from '@/types/usagePattern';
import { SavingsIdea } from '@/organisms/EnergySavingIdeas/EnergySavingIdeas.types';
import { HistoryTabs, PerformanceToDateEnum } from '@/types/dr';

export enum TabButtonType {
  Icon = 'icon',
  Text = 'text',
}

export type TabIconImageType = {
  active: {
    src?: string;
    alt?: string;
  };
  disabled?: {
    src?: string;
    alt?: string;
  };
  width?: string;
  height?: string;
  imagePlace?: 'left';
};

export type TabType = {
  id: string | number;
  title?: string;
  tabName: HistoryTabs | TimePeriod | SavingsIdea | PerformanceToDateEnum;
  link?: string;
  image?: TabIconImageType;
  padding?: string;
  ariaLabel?: string;
  ariaSelected?: boolean;
};

export interface ITabsProps {
  fontSize?: number;
  tabs: TabType[];
  selected: HistoryTabs | string;
  onClick: (tabName: HistoryTabs | TimePeriod | SavingsIdea | PerformanceToDateEnum, link?: string) => void;
  hideBorder?: boolean;
  fullWidth?: boolean;
  tabButtonType?: TabButtonType;
}
