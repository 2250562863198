import { all, fork, take, put, call } from 'redux-saga/effects';
import * as apiAuth from '@/apis/auth';
import {
  RESET_APP_DATA,
  RESET_AUTH,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_REDIRECT_ERROR_SUCCESS,
  LOGIN_REDIRECT_ERROR_FAILURE,
  LOGIN_REDIRECT_ERROR_REQUEST,
} from '@/stores/auth';
import { RESET_DASHBOARD } from '@/stores/dashboard';
import { RESET_DR } from '@/stores/dr/events';
import { RESET_MESSAGES } from '@/stores/message';
import { RESET_ENROLLMENT } from '@/stores/enrollment';
import { SITES_RESET } from '@/stores/sites';
import { Language } from '@/types/setting';
import { LOADED, LOADING } from '@/stores/loading';
import { removeLocalToken } from '@/services/token';
import { RESET_PHONE_OTP } from '@/stores/phoneOtp';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';
import { USER_INFO_RESET } from '@/stores/userInfo';
import { RESET_EARNINGS_HISTORY } from '@/stores/dr/earningsHistory';
import { RESET_EVENTS_HISTORY } from '@/stores/dr/eventsHistory';
import { RESET_HISTORY_SCREEN_STATE } from '@/stores/dr/historyScreen';
import { RootState } from '../stores';

function* login(language: Language) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiAuth.login, language);
    if (!response.success) {
      yield put({ type: LOGIN_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;

    yield put({ type: RESET_ENROLLMENT });
    yield put({ type: LOGIN_SUCCESS, authUrl: data.authUrl });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: LOGIN_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* register(language: Language) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiAuth.register, language);
    if (!response.success) {
      yield put({ type: REGISTER_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;

    yield put({ type: RESET_ENROLLMENT });
    yield put({ type: REGISTER_SUCCESS, authUrl: data.authUrl });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: REGISTER_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* resetAppData() {
  yield put({ type: RESET_ENROLLMENT });
  yield put({ type: RESET_DASHBOARD });
  yield put({ type: RESET_DR });
  yield put({ type: RESET_MESSAGES });
  yield put({ type: RESET_AUTH });
  yield put({ type: RESET_PHONE_OTP });
  yield put({ type: SITES_RESET });
  yield put({ type: USER_INFO_RESET });
  yield put({ type: RESET_EVENTS_HISTORY });
  yield put({ type: RESET_EARNINGS_HISTORY });
  yield put({ type: RESET_HISTORY_SCREEN_STATE });
  yield call(removeLocalToken);
}

function* logout(token: string, userId: string) {
  try {
    yield put({ type: LOADING });

    const url = apiAuth.logoutOlivineAcct(token, userId);

    // this must be done prior to redirecting via window.location.href since a
    // cookie is set with the final redirect url
    yield call(apiAuth.logoutOlivineAuth);

    yield* resetAppData();

    yield put({ type: LOGOUT_SUCCESS });
    yield call(removeLocalToken);

    window.location.href = url;
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* loginRedirectError(errorMessage: string, reduxState: RootState) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiAuth.loginRedirectError, errorMessage, reduxState);

    if (!response.success) {
      yield put({ type: LOGIN_REDIRECT_ERROR_FAILURE, errorMessage: response.error?.message });
      return;
    }

    yield put({ type: LOGIN_REDIRECT_ERROR_SUCCESS });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: LOGIN_REDIRECT_ERROR_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchResetAppData() {
  while (true) {
    yield take(RESET_APP_DATA);
    yield fork(resetAppData);
  }
}

export function* watchLogin() {
  while (true) {
    const { language } = yield take(LOGIN_REQUEST);
    yield fork(login, language);
  }
}

export function* watchRegister() {
  while (true) {
    const { language } = yield take(REGISTER_REQUEST);
    yield fork(register, language);
  }
}

export function* watchLogout() {
  while (true) {
    const { token, userId } = yield take(LOGOUT_REQUEST);
    yield fork(logout, token, userId);
  }
}

export function* watchLoginRedirectError() {
  while (true) {
    const { errorMessage, reduxState } = yield take(LOGIN_REDIRECT_ERROR_REQUEST);
    yield fork(loginRedirectError, errorMessage, reduxState);
  }
}

export default function* authSaga() {
  yield all([
    fork(watchResetAppData),
    fork(watchLogin),
    fork(watchRegister),
    fork(watchLogout),
    fork(watchLoginRedirectError),
  ]);
}
