import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import { useZohoChat } from '@/hooks/index';
import { LinkButton } from '@/designSystem/components';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import contents from '@/assets/config/appInfo';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { Wrapper, Container, Description, TextValue, ItemWrapper, TitleContainer, Title } from './Support.styles';

const SupportScreen = () => {
  const { t } = useTranslation('common');
  const {
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  // Support - ClimateResponse®
  document.title = `${t('screens.main.settings.support.title')} - ${contents.name}`;
  const programName = getProgramNameFromSite(currentSite, userInfo.program);
  const { setShowChat, hasChatAccess } = useZohoChat(programName);

  const supportEmail = t(`programs.${programName}.support.email`);
  const supportPhoneNumber = t(`programs.${programName}.support.phone`);

  const emailExists = !supportEmail.endsWith('support.email');
  const phoneExists = !supportPhoneNumber.endsWith('support.phone');

  const handleMailLink = () => {
    sentEvent('click', PATHS.EMAIL_SUPPORT);
    window.open(`mailto:${supportEmail}`);
  };

  const handleShowChat = () => {
    sentEvent('click', PATHS.SHOW_CHAT_SUPPORT);
    setShowChat(true);
  };

  useEffect(() => {
    sentEvent('screen_view', PATHS.SCREEN_SETTING_SUPPORT.gaparam);
  }, []);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t('screens.main.settings.support.title')}</Title>
      </TitleContainer>
      <Wrapper>
        <Container tabIndex={0}>
          <Description>{t('screens.main.settings.support.description')}</Description>
          {emailExists && (
            <ItemWrapper>
              <Description>{t('screens.main.settings.support.labels.email')}</Description>
              <LinkButton title={supportEmail} onClick={handleMailLink} padding="8px 12px" />
            </ItemWrapper>
          )}
          {phoneExists && (
            <ItemWrapper>
              <Description>{t('screens.main.settings.support.labels.phone')}</Description>
              <TextValue>{supportPhoneNumber}</TextValue>
            </ItemWrapper>
          )}
          {hasChatAccess && (
            <ItemWrapper>
              <LinkButton
                title={t('screens.enrollment.modalSupport.chat.chatText')}
                onClick={handleShowChat}
                padding="12px 16px"
              />
            </ItemWrapper>
          )}
        </Container>
      </Wrapper>
    </SettingTemplate>
  );
};

export default SupportScreen;
