import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { PerformanceToDateEnum } from '@/types/dr';
import { UnitHistoryDetail } from '@/helpers/Edition';
import DRDetailViewView from './index.view';

const DRDetailViewContainer = ({ unit }: { unit: UnitHistoryDetail }) => {
  const {
    dr: {
      history: {
        events: {
          data: { selectedEvent },
        },
        earnings: {
          data: { selectedEarningsEvent },
        },
      },
      historyScreen: { selectedTab },
    },
  } = useSelector((state: RootState) => state);

  const isEmissions = selectedTab === PerformanceToDateEnum.emissions;

  return (
    <DRDetailViewView
      unit={unit}
      items={
        selectedTab === PerformanceToDateEnum.reduction || isEmissions
          ? selectedEvent?.detailData.graphData
          : selectedEarningsEvent?.detailData.graphData
      }
    />
  );
};

export default DRDetailViewContainer;
