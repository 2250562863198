import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  Title,
  Container,
  ProgramInformationContainer,
  EventTypeTitle,
  styleEventIcon,
  InnerContainer,
} from '@/organisms/DRNoEvent/DRNoEvent.styles';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';

const DRNoEvent = () => {
  const { t } = useTranslation('common');
  return (
    <Wrapper>
      <ProgramInformationContainer>
        <Img src={images.iconUpcoming} style={styleEventIcon} />
        <EventTypeTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.upcomingEvents`)}</EventTypeTitle>
      </ProgramInformationContainer>
      <Container>
        <InnerContainer>
          <Title>{t('screens.main.dashboard.dashboardScreen.reminders.noUpcoming')}</Title>
        </InnerContainer>
      </Container>
    </Wrapper>
  );
};

export default DRNoEvent;
