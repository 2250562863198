import styled, { css } from 'styled-components';
import { colors, typography } from '@/designSystem/index';

interface InputComponentProps {
  isFocused?: boolean;
  error?: string;
}

export const Label = styled.p`
  ${typography.sm};
  color: ${colors.text};
  margin-bottom: 8px;
  line-height: 24px;
`;

export const InputComponent = styled.input<InputComponentProps>`
  ${typography.sm};
  width: 100%;
  height: 50px;
  padding: 8px 16px;
  color: ${colors.text};
  margin: 1px 1px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;
  line-height: 24px;

  &:hover {
    border: 2px solid ${colors.hoverFocus};
    margin: 1px 0;
  }

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid ${colors.primary};
      margin: 1px 0;
      &:hover {
        border: 2px solid ${colors.primary};
      }
    `};

  ${(props) =>
    props.error &&
    css`
      border: 2px solid ${colors.bad};
      margin: 1px 0;
      &:hover {
        border: 2px solid ${colors.bad};
      }
    `};

  ${(props) =>
    props.isFocused &&
    props.error &&
    css`
      border: 2px solid ${colors.bad};
      margin: 1px 0;
      &:hover {
        border: 2px solid ${colors.bad};
      }
    `};

  outline: none;
`;

export const ErrorText = styled.div`
  ${typography.xs};
  color: ${colors.bad};
  margin-top: 8px;
  padding: 0 12px;
`;
