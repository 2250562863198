import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const defaultModalStyle = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: '#fff',
    overflow: 'initial',
    outline: 'none',
    borderRadius: '16px',
  },
  overlay: {
    backgroundColor: 'rgba(74, 74, 74, 0.5)',
  },
};

export const Container = styled.div`
  border-radius: 16px;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  position: relative;
  padding: 26px 16px;
  width: 400px;
`;

export const Header = styled.div`
  margin-bottom: 8px;
`;

export const Title = styled.p`
  color: ${colors.text};
  ${typography.lg};
  font-weight: 900;
  line-height: 24px;
`;

export const More = styled.div`
  position: absolute;
  top: 26px;
  right: 16px;
`;

export const Body = styled.div`
  margin: 8px 0;
`;

export const Message = styled.p`
  color: ${colors.blackGrey};
  ${typography.sm};
  font-weight: 400;
  line-height: 24px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
