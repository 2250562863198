import React from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from './index.styles';
import { NotVerifiedViewProps } from './index.types';

const NotVerifiedView = ({
  descriptionText,
  descriptionText2,
  descriptionText3,
  descriptionText4,
}: NotVerifiedViewProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Description>{t(descriptionText)}</Description>
      <Description>{t(descriptionText2)}</Description>
      <Description>{t(descriptionText3)}</Description>
      <Description>{t(descriptionText4)}</Description>
    </>
  );
};

export default NotVerifiedView;
