import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 0 16px;
  height: 100vh;
  ${media.lg`
    padding: 0 20px;  
    height: unset;
  `}
`;

export const TitleContainer = styled.div`
  padding: 0 16.5px;
  display: none;
  margin-bottom: 40px;
  ${media.lg`
    display: block;
  `}
`;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const Container = styled.div`
  ${helpers.flexSet('column', 'center', 'flex-start')};
  padding: 20px 0;
  gap: 20px;
`;

export const ItemWrapper = styled.div`
  ${helpers.flexSet('column', 'center', 'flex-start')};
  gap: 8px;
`;

export const Description = styled.p`
  ${typography.sm};
  font-weight: 400;
  color: ${colors.text};
  line-height: 24px;
`;

export const TextValue = styled.p`
  ${typography.sm};
  font-weight: 400;
  color: ${colors.text};
  line-height: 24px;
  padding: 8px 0;
`;
