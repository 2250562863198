import { take, put, call, fork, all } from 'redux-saga/effects';
import * as api from '@/apis/enrollment';
import * as apiPrimary from '@/apis/primary';
import {
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE,
  VERIFY_PGE_REQUEST,
  VERIFY_PGE_SUCCESS,
  VERIFY_PGE_FAILURE,
  SUBMIT_TERMS_AND_CONDITIONS_REQUEST,
  SUBMIT_TERMS_AND_CONDITIONS_SUCCESS,
  SUBMIT_TERMS_AND_CONDITIONS_FAILURE,
  GBC_REQUEST,
  GBC_SUCCESS,
  GBC_FAILURE,
  SURVEY_REQUEST,
  SURVEY_SUCCESS,
  SURVEY_FAILURE,
  PROGRAM_REQUEST,
  PROGRAM_SUCCESS,
  PROGRAM_FAILURE,
  RECENT_REQUEST,
  RECENT_SUCCESS,
  RECENT_FAILURE,
  RESET_PROGRAM,
  ATTRIBUTE_SUCCESS,
  ATTRIBUTE_FAILURE,
  ATTRIBUTE_REQUEST,
} from '@/stores/enrollment';
import {
  PgeVerifyResponse,
  Program,
  ProgramName,
  UtilityPrograms,
  VerifyResponse,
  Attribute,
} from '@/types/enrollment';
import { ReqIntegrations, ReqSurveyAnswer, ReqVerify, ReqVerifyPge } from '@/apis/enrollment/types';
import { LOADED, LOADING } from '@/stores/loading';
import { USER_INFO_REQUEST } from '@/stores/userInfo';

function* verify(payload: ReqVerify, success: (data: VerifyResponse) => void, failure: () => void) {
  try {
    yield put({ type: LOADING });

    const { data } = yield call(api.postVerify, payload);

    yield put({ type: VERIFY_SUCCESS, verifyResponse: data as VerifyResponse });

    yield put({ type: USER_INFO_REQUEST });

    success(data);
  } catch (error) {
    failure();
    yield put({ type: VERIFY_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* verifyPge(payload: ReqVerifyPge, success: (data: PgeVerifyResponse) => void, failure: () => void) {
  try {
    yield put({ type: LOADING });

    const { data } = yield call(api.postVerifyPge, payload);
    const { platformValidateResponseData, sites } = data as PgeVerifyResponse;

    yield put({ type: VERIFY_PGE_SUCCESS, verifyResponse: platformValidateResponseData });

    yield put({ type: USER_INFO_REQUEST });

    success({ platformValidateResponseData, sites });
  } catch (error) {
    failure();
    yield put({ type: VERIFY_PGE_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* gbc(payload: ReqIntegrations, success: (url: string) => void) {
  try {
    yield put({ type: LOADING });

    const { data } = yield call(api.postIntegrations, payload);
    const { loginUrl } = data;

    success(loginUrl);

    yield put({ type: GBC_SUCCESS, gbcUrl: loginUrl });
  } catch (error) {
    yield put({ type: GBC_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* survey(payload: ReqSurveyAnswer[], callback: (success: boolean) => void) {
  try {
    yield put({ type: LOADING });
    yield call(api.postSurvey, payload);

    callback(true);
    yield put({ type: SURVEY_SUCCESS });
  } catch (error) {
    yield put({ type: SURVEY_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* recentRequest() {
  try {
    yield put({ type: LOADING });
    const { data } = yield call(api.recent);

    yield put({ type: RECENT_SUCCESS, recent: data });
  } catch (error) {
    yield put({ type: RECENT_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchVerify() {
  while (true) {
    const { payload, success, failure } = yield take(VERIFY_REQUEST);
    yield fork(verify, payload, success, failure);
  }
}

export function* watchVerifyPge() {
  while (true) {
    const { payload, success, failure } = yield take(VERIFY_PGE_REQUEST);
    yield fork(verifyPge, payload, success, failure);
  }
}

export function* watchGbc() {
  while (true) {
    const { payload, success } = yield take(GBC_REQUEST);
    yield fork(gbc, payload, success);
  }
}

export function* watchSurvey() {
  while (true) {
    const { payload, callback } = yield take(SURVEY_REQUEST);
    yield fork(survey, payload, callback);
  }
}

function* programRequest(zipCode?: string, callback?: (utilityPrograms: UtilityPrograms) => void) {
  try {
    yield put({ type: LOADING });
    const { data } = yield call(apiPrimary.programs, zipCode || '');
    const utilityPrograms = data.data;
    yield put({ type: PROGRAM_SUCCESS, utilityPrograms });
    if (callback) {
      callback(utilityPrograms);
    }
  } catch (error) {
    yield put({ type: PROGRAM_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

function* programReset(program: Program, callback: () => void) {
  yield put({ type: RESET_PROGRAM, program });

  callback();
}

export function* watchProgramRequest() {
  while (true) {
    const { zipCode, callback } = yield take(PROGRAM_REQUEST);
    yield fork(programRequest, zipCode, callback);
  }
}

export function* watchResetProgram() {
  while (true) {
    const { currentProgram, callback } = yield take('PROGRAMS/RESET_PROGRAM_REQUEST');
    yield fork(programReset, currentProgram, callback);
  }
}

export function* watchRecentRequest() {
  while (true) {
    yield take(RECENT_REQUEST);
    yield fork(recentRequest);
  }
}

function* getAttribute(attribute: Attribute) {
  try {
    yield put({ type: LOADING });

    const { data } = yield call(apiPrimary.attribute, attribute);

    // Getting result as boolean
    const attributeValue = data.data === 'true';

    yield put({ type: ATTRIBUTE_SUCCESS, attribute, attributeValue });
  } catch (error) {
    yield put({ type: ATTRIBUTE_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchAttribute() {
  while (true) {
    const { attribute } = yield take(ATTRIBUTE_REQUEST);
    yield fork(getAttribute, attribute);
  }
}

export function* submitTermsAndConditions(program: ProgramName) {
  try {
    yield put({ type: LOADING });
    yield call(apiPrimary.submitTermsAndConditions, program);
    yield put({ type: SUBMIT_TERMS_AND_CONDITIONS_SUCCESS });
  } catch (error) {
    yield put({ type: SUBMIT_TERMS_AND_CONDITIONS_FAILURE, error });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchSubmitTermsAndConditions() {
  while (true) {
    const { program } = yield take(SUBMIT_TERMS_AND_CONDITIONS_REQUEST);
    yield fork(submitTermsAndConditions, program);
  }
}

export default function* enrollmentSaga() {
  yield all([
    fork(watchProgramRequest),
    fork(watchResetProgram),
    fork(watchVerify),
    fork(watchVerifyPge),
    fork(watchGbc),
    fork(watchSurvey),
    fork(watchRecentRequest),
    fork(watchAttribute),
    fork(watchSubmitTermsAndConditions),
  ]);
}
