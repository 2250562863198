// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import { hyphenPhoneNumber } from '@/helpers/RegexHelper';
import VerificationView from './index.view';
import { IVerificationProps } from './index.types';

const VerificationContainer = ({ disabled, onNext, errors, setErrors }: IVerificationProps) => {
  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  const [modal, setModal] = useState({ accountNumber: false });

  const {
    fetchIsBusiness,
    fetchVerificationName,
    fetchVerificationAccountNumber,
    fetchServiceFirstName,
    fetchServiceLastName,
    fetchServicePhone,
    fetchServiceAccountNumber,
    fetchServiceAddress,
    fetchServiceCity,
    fetchServiceEmail,
    fetchServiceZip,
  } = useEnrollment();

  useEffect(() => {
    fetchIsBusiness(userInfo.isLocationBusiness);
  }, []);

  const onChangeField = (field: string) => {
    if (errors?.includes(field)) {
      const newErrorArr = errors?.filter((errorField: string) => errorField !== field);
      setErrors(newErrorArr);
    }
  };

  const handleClickAccountNumber = () => {
    setModal({ ...modal, accountNumber: true });
  };

  const handleChangeName = (value: string) => {
    fetchVerificationName(value);
  };

  const handleChangeAccountNumber = (value: string) => {
    fetchVerificationAccountNumber(value);
  };

  const handleModalAccountNumberClose = () => {
    setModal({ ...modal, accountNumber: false });
  };

  const handleModalAccountNumberOk = () => {
    setModal({ ...modal, accountNumber: false });
  };

  const onChange = (field) => (value) => {
    const isNum = /^[0-9\b]+$/;
    onChangeField(field);
    switch (field) {
      case 'serviceFirstName':
        return fetchServiceFirstName(value);
      case 'serviceLasttName':
        return fetchServiceLastName(value);
      case 'servicePhone':
        return fetchServicePhone(hyphenPhoneNumber(value));
      case 'serviceAccountNumber':
        return fetchServiceAccountNumber(value === '' || isNum.test(value) ? value : value.slice(0, -1));
      case 'serviceAddress':
        return fetchServiceAddress(value);
      case 'serviceCity':
        return fetchServiceCity(value);
      case 'serviceZipCode':
        return fetchServiceZip(value === '' || isNum.test(value) ? value : value.slice(0, -1));
      case 'serviceEmail':
        return fetchServiceEmail(value);
      default:
        return fetchServiceLastName(value);
    }
  };

  return (
    <VerificationView
      programName={currentProgram.program}
      isBusiness={enrollmentInfo.isBusiness}
      accountName={enrollmentInfo.verificationName}
      accountNumber={enrollmentInfo.verificationAccountNumber}
      accountMinLength={currentProgram.accountMinLength}
      accountMaxLength={currentProgram.accountMaxLength}
      onClickAccountNumber={handleClickAccountNumber}
      onChangeName={handleChangeName}
      onChangeAccountNumber={handleChangeAccountNumber}
      modalAccountNumber={modal.accountNumber}
      modalAccountNumberClose={handleModalAccountNumberClose}
      modalAccountNumberOk={handleModalAccountNumberOk}
      values={enrollmentInfo}
      onChange={onChange}
      onNext={onNext}
      disabled={disabled}
      errors={errors}
    />
  );
};

export default VerificationContainer;
