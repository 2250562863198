import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/colors';
import { Modal, PrimaryButton } from '@/designSystem/components';
import { Container, Description } from './ErrorModal.style';

const ErrorModal = ({
  title,
  show,
  description,
  onTryAgain,
  onClose,
  isLoading,
}: {
  title: string;
  show: boolean;
  description: string;
  onTryAgain: () => void;
  onClose: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal title={title} show={show} onClose={onClose} showClose>
      <Container>
        <Description>{description}</Description>
        {isLoading ? (
          <ReactLoading type="spin" color={colors.primary} height="35px" width="35px" />
        ) : (
          <PrimaryButton title={t('common.generalButtons.tryAgain')} onClick={onTryAgain} />
        )}
      </Container>
    </Modal>
  );
};

export default ErrorModal;
