import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReqSetting } from '@/apis/auth/types';
import { changeMomentLocale } from '@/helpers/LanguageHelper';
import { Language, TempScale } from '@/types/setting';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import useSetting from '@/hooks/useSetting';
import Radio from '@/atoms/Radio';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import { FillPrimary } from '@/designSystem/components/buttons';
import {
  Wrapper,
  Title,
  TitleContainer,
  Container,
  Description,
  ButtonContainer,
  Button,
} from './LanguageSelectionScreen.styles';

const LanguageSelectionScreen = () => {
  const { t, i18n } = useTranslation('common');
  const {
    setting: { data: setting },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  document.title = `${t('screens.main.settings.languageSelectionScreen.title')} - ${contents.name}`;
  const navigate = useNavigate();
  const { fetchSetting } = useSetting();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLanguageState, setSelectedLanguageState] = useState(setting.language);

  const handleLanguageState = (name: string | number) => {
    const language = name as Language;
    if (selectedLanguageState === language) {
      return;
    }
    setSelectedLanguageState(language);
  };

  const handleLanguage = async () => {
    setIsLoading(true);
    i18n.changeLanguage(selectedLanguageState.toLowerCase());
    changeMomentLocale(selectedLanguageState);

    const payload: ReqSetting = {
      language: selectedLanguageState,
      tempScale: TempScale.celsius,
    };
    await fetchSetting(payload);
    setIsLoading(false);
  };

  const languages = t('common.languages', { returnObjects: true });

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'Language Selection',
      screen_class: 'language_selection',
    });
  }, []);

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  }, [userInfo.isDeleted, navigate]);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t('screens.main.settings.languageSelectionScreen.title')}</Title>
      </TitleContainer>
      <Wrapper>
        <Container>
          <Description>{t('screens.main.settings.languageSelectionScreen.language')}</Description>
          <Radio
            items={(Object.values(languages) as string[]).map((key: string) => {
              const keyValuePairs = Object.entries(key);
              return {
                id: keyValuePairs[0][0] as string,
                name: keyValuePairs[0][0].toUpperCase(),
                text: keyValuePairs[0][1] as string,
              };
            })}
            value={selectedLanguageState}
            onChange={handleLanguageState}
          />
        </Container>
        <ButtonContainer>
          <Button>
            <FillPrimary
              title={t('common.generalButtons.save')}
              onClick={handleLanguage}
              disabled={setting.language === selectedLanguageState || isLoading}
              padding="12px 0"
            />
          </Button>
        </ButtonContainer>
      </Wrapper>
    </SettingTemplate>
  );
};

export default LanguageSelectionScreen;
