import React, { CSSProperties } from 'react';
import { Button, Title } from './primaryButton.styles';

type PrimaryButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  small?: boolean;
  style?: CSSProperties;
};

export const PrimaryButton = ({ title, onClick, small, style, disabled = false }: PrimaryButtonProps) => {
  return (
    <Button disabled={disabled} onClick={onClick} small={small} style={style}>
      <Title>{title}</Title>
    </Button>
  );
};
