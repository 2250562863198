import React from 'react';
import { images } from '@/assets/styles';
import { Wrapper, Radio, Text } from './Radio.styles';

interface IRadioProps {
  items: { id: string; name: string; text: string }[];
  value: string | number;

  onChange?: (name: string | number, text: string) => void;
}

const RadioContainer = ({ items, value, onChange }: IRadioProps) => {
  return (
    <Wrapper>
      {items.map(({ id, name, text }) => {
        const selected = name === value;
        return (
          <Radio
            key={id}
            onClick={() => {
              if (onChange) {
                onChange(name, text);
              }
            }}
            role="radio"
            aria-checked={selected}
            aria-labelledby={id.toString()}
            onMouseOver={(e) => {
              (e.currentTarget.firstChild as HTMLImageElement).src = selected
                ? images.radioCheckedHover.image
                : images.radioHover.image;
            }}
            onFocus={(e) => {
              (e.currentTarget.firstChild as HTMLImageElement).src = selected
                ? images.radioCheckedHover.image
                : images.radioHover.image;
            }}
            onMouseOut={(e) => {
              (e.currentTarget.firstChild as HTMLImageElement).src = selected
                ? images.radioChecked.image
                : images.radio.image;
            }}
            onBlur={(e) => {
              (e.currentTarget.firstChild as HTMLImageElement).src = selected
                ? images.radioChecked.image
                : images.radio.image;
            }}
          >
            <img
              src={selected ? images.radioChecked.image : images.radio.image}
              alt={selected ? images.radioChecked.text : images.radio.text}
            />

            <Text id={id.toString()} selected={selected}>
              {text}
            </Text>
          </Radio>
        );
      })}
    </Wrapper>
  );
};

export default RadioContainer;
