import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Container = styled.div`
  display: flex;
  position: relative;
`;
export const GraphDescriptionContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const Header = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  // Without this y-axis labels are cutoff
  svg.main-svg {
    overflow: visible;
  }
`;

export const UsageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const UsageItem = styled.div`
  display: flex;
  align-item: center;
  flex-direction: column;
  gap: 4px;
`;

export const UsageTitle = styled.p`
  ${typography.xs};
  font-weight: 800;
  line-height: normal;
  color: ${colors.text};
`;

export const Body = styled.div``;

export const Footer = styled.div``;

export const Help = styled.div``;

export const NoDataContainer = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  height: 100%;
  padding-top: 5px;
`;

export const NoData = styled.p`
  ${typography.sm};
  font-weight: 400;
  line-height: normal;
  color: ${colors.grey};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SectionTitle = styled.p`
  ${typography.md};
  font-weight: 700;
  line-height: normal;
  color: ${colors.text};
`;

export const ChartContainer = styled.div`
  flex: 1;
  width: 400px;
  ${media.xxl`
    flex: 0.7;
  `}
`;

export const ChartDescriptionContainer = styled.div`
  display: none;
  ${media.xxl`
    flex: 0.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const ChartUnit = styled.p`
  ${typography.xxs};
  font-weight: 400;
  line-height: normal;
  width: 95%;
  margin-left: auto;
  color: ${colors.blackGrey};
`;

export const Line = styled.div`
  border-left: 1px solid ${colors.lightGrey};
  display: none;

  ${media.xxl`
    display: block;
  `}
`;

export const EnergyUsageButtonContainer = styled.div`
  display: flex;
`;
