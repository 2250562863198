import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReqSurveyAnswer } from '@/apis/enrollment/types';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { sentEvent } from '@/helpers/GAHelper';
import SingleSurveyScreenView from './index.view';

const SingleSurveyScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    enrollment: { enrollmentInfo },
  } = useSelector((state: RootState) => state);
  // How did you hear about this program?
  document.title = `${t('programs.PGE_ELRP_A6.singleSurveyScreen.question')} - ${t(
    `common.utilities.${enrollmentInfo.utility}.shortName`,
  )} - ${contents.name}`;

  const [state, setState] = useState({ disabledNext: true });

  const navigate = useNavigate();
  const { fetchSurvey } = useEnrollment();

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo.singleSurvey]);

  const validationCheck = () => {
    const disabled = enrollmentInfo.singleSurvey.answer === '';

    setState({ ...state, disabledNext: disabled });
  };

  const handleNext = () => {
    const payload: ReqSurveyAnswer[] = [enrollmentInfo.singleSurvey];
    fetchSurvey(payload, () => {
      sentEvent('enrollment_survey_submitted', {
        submit_status: 'success',
        submit_value: enrollmentInfo.singleSurvey.answer,
      });
      navigate(PATHS.SCREEN_ENROLLMENT_SINGUP_COMPLETE.pathname);
    });
  };

  const handleSkip = () => {
    sentEvent('enrollment_survey_submitted', {
      submit_status: 'failed',
      submit_value: 'skip',
    });
    navigate(PATHS.SCREEN_ENROLLMENT_SINGUP_COMPLETE.pathname);
  };

  return <SingleSurveyScreenView disabledNext={state.disabledNext} onNext={handleNext} onSkip={handleSkip} />;
};

export default SingleSurveyScreenContainer;
