import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';
import { InputComponentProps } from './SearchFilter.types';

export const Wrapper = styled.div``;
export const InputComponent = styled.input<InputComponentProps>`
  ${typography.xs};
  width: 100%;
  padding: 16px 16px;
  align-items: center;
  border-radius: 8px;
  color: ${colors.text};
  border: 2px solid transparent;

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid ${colors.primary};
      margin: 0;
    `};

  ${(props) =>
    props.isFocused &&
    props.error &&
    css`
      border: 2px solid ${colors.bad};
    `};

  ${(props) =>
    props.leftPadding &&
    css`
      padding-left: 48px;
    `};

  outline: none;
  ${media.lg`
    margin: 1px 1px;
    border: 1px solid ${colors.lightGrey};
  `}
`;

export const ArraySearchContainer = styled.div<InputComponentProps>`
  ${(props) =>
    props.isFocused &&
    css`
      display: flex;
      flex-direction: row;
    `};
`;

export const InputWrapper = styled.div<InputComponentProps>`
  position: relative;
  ${(props) =>
    props.isFocused &&
    css`
      flex: 8;
    `};
`;

export const LeftImage = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;
export const RightImage = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ErrorText = styled.div`
  ${typography.xs};
  color: ${colors.bad};
  margin-top: 8px;
  margin-left: 12px;
`;

export const ArraySearchButtonContainer = styled.div`
  margin-left: 15px;
  flex: 2;
  align-self: center;
  justify-content: center;
`;

export const SearchList = styled.div<{ isExistArray?: boolean }>`
  ${(props) =>
    props.isExistArray &&
    css`
      margin-top: 20px;
    `}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
export const SearchItem = styled.div`
  display: flex;
  background-color: ${colors.background};
  border-radius: 8px;
  padding: 8px 20px;
  margin: 10px 10px 0 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const SearchItemName = styled.p`
  ${typography.sm};
  color: ${colors.text};
  margin-right: 20px;
`;
export const TextRemove = styled.button`
  display: flex;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;
export const Img = styled.img``;
