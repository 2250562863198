import React from 'react';
import { HistoryTabs } from '@/types/dr';
import TabItem from './modules/TabItem';
import { Wrapper } from './index.styles';
import { ITabsProps, TabButtonType, TabType } from './index.types';

const Tabs = ({ tabs, selected, onClick, fullWidth = false, tabButtonType = TabButtonType.Text }: ITabsProps) => {
  return (
    <Wrapper fullWidth={fullWidth}>
      {tabs.map(({ id, title, tabName, link, image, padding, ariaLabel }: TabType) => (
        <TabItem
          fullWidth={fullWidth}
          key={id}
          title={title}
          selected={tabName === selected}
          onClick={() => onClick(tabName || HistoryTabs.QuickView, link)}
          image={image}
          tabButtonType={tabButtonType}
          padding={padding}
          ariaLabel={ariaLabel}
        />
      ))}
    </Wrapper>
  );
};

export default Tabs;
