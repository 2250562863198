import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import { useUserInfo, useEnrollment } from '@/hooks/index';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import { moveChangeScreen, nextScreenStep } from '@/helpers/NavigationHelper';
import LocationSetupScreenView from './index.view';

const LocationSetupScreenContainer = () => {
  const { t } = useTranslation('common');
  // Location setup - ClimateResponse®
  document.title = `${t('screens.enrollment.locationSetupScreen.title')} - ${contents.name}`;
  const {
    enrollment: { enrollmentInfo },
  } = useSelector((state: RootState) => state);

  const [state, setState] = useState({ disabledNext: true });
  const [modal, setModal] = useState({ zipCode: false, noPrograms: false });

  const navigate = useNavigate();
  const { fetchUpdateUserInfo } = useUserInfo();
  const { fetchProgramRequest, fetchChangePrograms, fetchUtility, fetchChangeProgram } = useEnrollment();

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo]);

  const validationCheck = () => {
    const disabled = enrollmentInfo.zipCode.length !== 5 || enrollmentInfo.siteName.length < 2;
    setState({ ...state, disabledNext: disabled });
  };

  const handleNext = () => {
    setModal((modalState) => ({ ...modalState, zipCode: true }));
  };

  const handleModalZipcodeOk = () => {
    const payload: ReqUpdateUserInfo = {
      isLocationBusiness: enrollmentInfo.isBusiness,
      zipcode: enrollmentInfo.zipCode,
      siteName: enrollmentInfo.siteName,
    };

    fetchUpdateUserInfo(payload, () => {
      handleWebViewLoading(true);

      fetchProgramRequest(enrollmentInfo.zipCode ?? '', (utilityPrograms) => {
        if (Object.keys(utilityPrograms).length > 1) {
          sentEvent('zipcode_submitted', { location_status: 'ineligible and eligible_overlap' });
          navigate(PATHS.SCREEN_ENROLLMENT_SELECT_UTILITY.pathname);
        } else {
          const programs = Object.values(utilityPrograms);
          // if there's only one utility, it selects its programs, otherwise it goes to no programs screen
          if (programs.length === 1) {
            if (programs[0].length === 0) {
              setModal({ zipCode: false, noPrograms: true });
              handleWebViewLoading(false);
              return;
            }
            fetchChangePrograms(programs[0]);
            const utility = Object.keys(utilityPrograms)[0];
            fetchUtility(utility);
            const programName = programs[0][0].program;
            const newPayload: ReqUpdateUserInfo = {
              utility,
              program: programName,
            };
            fetchUpdateUserInfo(newPayload);
            sentEvent('zipcode_submitted', { location_status: 'eligible_nooverlap' });

            const changedScreenSteps = moveChangeScreen(
              { availablePrograms: programs[0] },
              programs[0][0].program,
              fetchChangeProgram,
            );
            const nextScreen = nextScreenStep(changedScreenSteps, window.location, enrollmentInfo);
            navigate(nextScreen);
          } else {
            setModal({ zipCode: false, noPrograms: true });
            handleWebViewLoading(false);
          }
        }
      });
    });
  };

  const handleWebViewLoading = (loading: boolean) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ loading });
      window.ReactNativeWebView.postMessage(data);
    }
  };

  const handleModalZipcodeCancel = () => {
    setModal((modalState) => ({ ...modalState, zipCode: false }));
  };

  const handleNoProgramsOk = () => {
    setModal((modalState) => ({ ...modalState, noPrograms: false }));
  };

  return (
    <LocationSetupScreenView
      disabledNext={state.disabledNext}
      onNext={handleNext}
      modalZipcode={modal.zipCode}
      modalZipcodeClose={handleModalZipcodeCancel}
      modalZipcodeOk={handleModalZipcodeOk}
      modalZipcodeCancel={handleModalZipcodeCancel}
      modalNoPrograms={modal.noPrograms}
      modalNoProgramsOk={handleNoProgramsOk}
    />
  );
};

export default LocationSetupScreenContainer;
