import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  margin-top: 30px;
  ${media.lg`
    grid-template-columns: 1fr 1.5fr;
  `}
`;

export const MenuWrapper = styled.div`
  padding: 0 16px;
  ${media.lg`
    padding: 0;
  `}
`;

export const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  gap: 8px;

  background-color: ${colors.white};
  border-radius: 12px;
  padding: 8px;
  height: max-content;

  margin-bottom: 26px;
`;

export const Divider = styled.div`
  margin: 0 16px;
  border-top: 0.5px solid ${colors.lightGrey};
`;
export const EtcMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
`;

export const Header = styled.div`
  overflow: auto;
`;

export const Body = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 20px 0;
`;
