import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0px;
`;
export const Description = styled.p`
  ${typography.md}
  color: ${colors.text};
  margin: 16px 0px 20px 0px;
`;
