import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const PopupHeader = styled.div`
  margin-bottom: 20px;
`;
export const PopupTitle = styled.p`
  ${typography.xl1}
  line-height: 32px;
  font-weight: 900;
  color: ${colors.text};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
`;

export const HightlightSpan = styled.span`
  ${typography.sm};
  line-height: 24px;
  color: ${colors.text};
  font-weight: 700;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ValidationText = styled.p`
  padding: 0 12px;
  ${typography.xxs};
  color: ${colors.bad};
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 5px;
`;
