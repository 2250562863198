import React from 'react';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import NotFoundScreenView from './index.view';

const NotFoundScreenContainer = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.errorScreen.404.title')} - ${contents.name}`;
  return <NotFoundScreenView />;
};

export default NotFoundScreenContainer;
