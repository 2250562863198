import { ReqEventParticipate, resDR, ReqEventNotified } from '@/apis/primary/types';
import { DrNearestEvent, EventStatusEnum, OptStateEnum, UserStatusEnum } from '@/types/dr';

// Types
//
// Reset
export const RESET_DR = 'dr/RESET_DR';

// Request All Data
export const DR_REQUEST = 'dr/DR_REQUEST';
export const DR_SUCCESS = 'dr/DR_SUCCESS';
export const DR_FAILURE = 'dr/DR_FAILURE';

// Event Participate
export const EVENT_PARTICIPATE_REQUEST = 'dr/EVENT_PARTICIPATE_REQUEST';
export const EVENT_PARTICIPATE_SUCCESS = 'dr/EVENT_PARTICIPATE_SUCCESS';
export const EVENT_PARTICIPATE_FAILURE = 'dr/EVENT_PARTICIPATE_FAILURE';

// Updated Event Notified
export const EVENT_NOTIFIED_REQUEST = '/dr/EVENT_NOTIFIED_REQUEST';
export const EVENT_NOTIFIED_SUCCESS = '/dr/EVENT_NOTIFIED_SUCCESS';
export const EVENT_NOTIFIED_FAILURE = '/dr/EVENT_NOTIFIED_FAILURE';

interface ResetDrAction {
  type: typeof RESET_DR;
}

interface DrRequestAction {
  type: typeof DR_REQUEST;
  siteId: string;
}

interface DrSuccessAction {
  type: typeof DR_SUCCESS;
  dr: resDR;
}

interface DrFailureAction {
  type: typeof DR_FAILURE;
  errorMessage: string;
}

interface EventParticipateRequestAction {
  type: typeof EVENT_PARTICIPATE_REQUEST;
  siteId: string;
  payload: ReqEventParticipate;
}

interface EventParticipateSuccessAction {
  type: typeof EVENT_PARTICIPATE_SUCCESS;
  userEventId: string;
  userStatus: UserStatusEnum;
}

interface EventParticipateFailureAction {
  type: typeof EVENT_PARTICIPATE_FAILURE;
  errorMessage: string;
}

interface EventNotifiedRequestAction {
  type: typeof EVENT_NOTIFIED_REQUEST;
  userEventId: string;
  hasBeenNotified?: boolean;
  recentlyEndedEventNotified?: boolean;
  optState?: OptStateEnum;
}

interface EventNotifiedSuccessAction {
  type: typeof EVENT_NOTIFIED_SUCCESS;
  userEventId: string;
  hasBeenNotified?: boolean;
  recentlyEndedEventNotified?: boolean;
}

interface EventNotifiedFailureAction {
  type: typeof EVENT_NOTIFIED_FAILURE;
  errorMessage: string;
}

type InitActionTypes =
  | ResetDrAction
  | DrRequestAction
  | DrSuccessAction
  | DrFailureAction
  | EventParticipateRequestAction
  | EventParticipateSuccessAction
  | EventParticipateFailureAction
  | EventNotifiedRequestAction
  | EventNotifiedSuccessAction
  | EventNotifiedFailureAction;

// initial state
//
export interface DrState {
  data: {
    eventStatus: EventStatusEnum;
    recentlyEndedEvent: {
      pastUserEventId: string;
      eventParticipatedRecentlyEnded: boolean;
      recentlyEndedEventNotified?: boolean;
      start: string;
      end: string;
    };
    nearestDrEvents: DrNearestEvent[];
  };
  isLoading: boolean;
  isLoadingParticipate: boolean;
  isLoadingNotified: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialDrState = {
  eventStatus: EventStatusEnum.NoEvent,
  nearestDrEvents: [
    {
      eventTimeRange: '',
      length: '',
      end: '',
      canceled: false,
      userEventId: '',
      start: '',
      hasBeenNotified: true,
      userStatus: UserStatusEnum.DefaultEvent,
    },
  ],
  recentlyEndedEvent: {
    pastUserEventId: '',
    eventParticipatedRecentlyEnded: false,
    recentlyEndedEventNotified: false,
    start: '',
    end: '',
  },
};

const initialState: DrState = {
  data: initialDrState,
  isLoading: false,
  isLoadingParticipate: false,
  isLoadingNotified: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: DrState = initialState, action: InitActionTypes): DrState => {
  switch (action.type) {
    case RESET_DR:
      return {
        ...initialState,
      };

    case DR_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DR_SUCCESS:
      return {
        ...state,
        data: {
          eventStatus: action.dr.eventStatus,
          recentlyEndedEvent: {
            pastUserEventId: action.dr.recentlyEndedEvent.pastUserEventId,
            eventParticipatedRecentlyEnded: action.dr.recentlyEndedEvent.eventParticipatedRecentlyEnded,
            recentlyEndedEventNotified: action.dr.recentlyEndedEvent.recentlyEndedEventNotified,
            start: action.dr.recentlyEndedEvent.start,
            end: action.dr.recentlyEndedEvent.end,
          },
          nearestDrEvents: action.dr.nearestDrEvents,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DR_FAILURE:
      return {
        ...state,
        data: initialDrState,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case EVENT_PARTICIPATE_REQUEST:
      return {
        ...state,
        isLoadingParticipate: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EVENT_PARTICIPATE_SUCCESS: {
      const { nearestDrEvents } = state.data;

      const tmp = nearestDrEvents.map((nearestDrEvent) => {
        if (nearestDrEvent.userEventId === action.userEventId) {
          Object.assign(nearestDrEvent, { userStatus: action.userStatus });
        }

        return nearestDrEvent;
      });

      return {
        ...state,
        data: {
          ...state.data,
          nearestDrEvents: tmp,
        },
        isLoadingParticipate: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };
    }

    case EVENT_PARTICIPATE_FAILURE:
      return {
        ...state,
        isLoadingParticipate: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case EVENT_NOTIFIED_REQUEST:
      return {
        ...state,
        isLoadingNotified: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EVENT_NOTIFIED_SUCCESS: {
      const { nearestDrEvents } = state.data;

      const tmp = nearestDrEvents.map((nearestDrEvent) => {
        if (nearestDrEvent.userEventId === action.userEventId) {
          Object.assign(nearestDrEvent, {
            hasBeenNotified: action.hasBeenNotified,
          });
        }

        return nearestDrEvent;
      });

      return {
        ...state,
        data: {
          ...state.data,
          nearestDrEvents: tmp,
          recentlyEndedEvent: {
            ...state.data.recentlyEndedEvent,
            recentlyEndedEventNotified: action.recentlyEndedEventNotified,
          },
        },
        isLoadingNotified: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };
    }

    case EVENT_NOTIFIED_FAILURE: {
      return {
        ...state,
        isLoadingNotified: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };
    }

    default:
      return state;
  }
};

// Actions
//
export const drRequest = (siteId: string) => ({ type: DR_REQUEST, siteId });

export const eventParticipate = (siteId: string, payload: ReqEventParticipate) => ({
  type: EVENT_PARTICIPATE_REQUEST,
  siteId,
  payload,
});

export const eventNotifiedRequest = (siteId: string, payload: ReqEventNotified) => ({
  type: EVENT_NOTIFIED_REQUEST,
  siteId,
  payload,
});
