import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 10px 15px 10px;
`;

export const ModalHeader = styled.div`
  ${typography.xl1};
  align-self: flex-start;
  font-weight: 900;
  margin-bottom: 20px;
  color: ${colors.text};
`;

export const ModalText = styled.div`
  ${typography.sm};
  margin-top: 20px;
  color: ${colors.blackGrey};
`;

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-end;
  width: 100%;
  padding: 5px;
  margin-top: 20px;
  gap: 20px;
`;
