import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import Level from '@/molecules/Level';
import ModalReward from '@/organisms/ModalReward';
import { UnitHelper } from '@/helpers/UnitHelper';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { FillPrimary, IconButton } from '@/designSystem/components';
import { images } from '@/assets/styles';
import { useNavigate } from 'react-router-dom';
import { ProgramName } from '@/types/enrollment';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { AchievementProps } from './Achievement.types';
import {
  Container,
  Item,
  Description,
  MoneyValue,
  MoneyDescription,
  Section,
  SectionHeader,
  SectionTitle,
  Help,
  DescriptionContainer,
  ButtonContainer,
} from './Achievement.styles';

const Achievement = ({ ariaControls, disclaimer, disclaimerSymbol }: AchievementProps) => {
  const { t } = useTranslation('common');
  const {
    dashboard: {
      data: { achievement },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  const [stateModal, setStateModal] = useState({ reward: false });

  const programName = getProgramNameFromSite(currentSite);

  const handleHelp = () => {
    sentEvent('view_achievement');
    sentEvent('click', PATHS.HELP_ACHIEVEMENT);
    setStateModal({ ...stateModal, reward: true });
  };

  const handleReward = () => {
    setStateModal({ ...stateModal, reward: false });
  };

  const moneyFixed = currentSite?.program?.attributes?.moneyEarnedPerEvent ? 2 : 0;
  const formattedMoneyEarned = UnitHelper.formatMoneyValue(achievement.moneyEarned, false, moneyFixed);

  const handleNavigate = () => {
    navigate(PATHS.SCREEN_MOBILE_EVENT.pathname);
  };

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionTitle>{t('screens.main.dashboard.dashboardScreen.achievement.title')}</SectionTitle>
          {programName !== ProgramName.DATA_ONLY && (
            <Help>
              <IconButton
                image={{
                  active: {
                    src: images.helpBtn.image,
                    alt: t('screens.main.dashboard.dashboardScreen.achievement.help'),
                  },
                  width: '30px',
                  height: '30px',
                }}
                onClick={handleHelp}
                ariaControls={ariaControls}
                ariaExpanded={stateModal.reward}
                ariaLabel={t('screens.main.dashboard.dashboardScreen.achievement.help')}
                borderRadius="20px"
              />
            </Help>
          )}
        </SectionHeader>
        {programName === ProgramName.DATA_ONLY ? (
          <DataOnlyMessage />
        ) : (
          <Section>
            <Container>
              <Item tabIndex={0}>
                <MoneyValue aria-label={accessibilityUsageLabel(formattedMoneyEarned)}>
                  {formattedMoneyEarned}
                </MoneyValue>
                <MoneyDescription>{`${t(
                  'screens.main.dashboard.dashboardScreen.achievement.earn',
                )}${disclaimerSymbol}`}</MoneyDescription>
              </Item>
              <Item>
                <Level level={achievement.level} />
              </Item>
            </Container>

            <ButtonContainer>
              <FillPrimary
                title={t('screens.main.dashboard.dashboardScreen.achievement.viewAllText')}
                onClick={handleNavigate}
                flex={1}
                padding="15px"
              />
            </ButtonContainer>
            {!!disclaimer && (
              <DescriptionContainer>
                <Description tabIndex={0}>{disclaimer}</Description>
              </DescriptionContainer>
            )}
          </Section>
        )}
      </Section>
      <ModalReward show={stateModal.reward} onClose={handleReward} ariaControlsId={ariaControls} />
    </>
  );
};

export default Achievement;
