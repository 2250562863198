import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { helpers, media, theme } from '@/assets/styles';
import { IStyleWrapperProps } from './DRUpcomingEvent.types';

export const styleCalendarIcon = { width: '17px', height: '18px' };
export const styleClockIcon = { width: '18px', height: '17px' };
export const styleEventIcon = { width: '32px', height: '32px' };

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgramInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 8px 8px 0px 0px;
  gap: 8px;
  border-bottom: 1px solid rgba(114, 116, 118, 0.2);
`;

export const EventTypeTitle = styled.p`
  ${typography.xl};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.accent};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
  gap: 20px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  padding: 15px;
  border-radius: 8px;
  gap: 20px;
  ${media.xxl`
    gap: unset;
    flex-direction: row;
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  flex: 1;

  ${media.xxl`
    display: flex;
    flex-direction: column;
    align-items: start;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  ${media.lg`
    justify-content: start;
  `}
`;

export const Title = styled.p`
  ${typography.lg};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.text};
  text-transform: capitalize;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;

  ${media.lg`
    gap: 15px;
    align-items: center;
    flex-direction: row;
  `}
`;

export const Divider = styled.div`
  height: 100%;
  border-left: 2px solid rgba(114, 116, 118, 0.2);
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const DateText = styled.p`
  ${typography.md};
  font-weight: 700;
  line-height: 24px;
  color: ${colors.text};
`;

export const Logo = styled.div<IStyleWrapperProps>(
  ({ isMultiple }) => css`
    width: ${isMultiple ? '30px' : '100px'};
    height: ${isMultiple ? '30px' : '109px'};
    & > img {
      width: 100%;
      height: 100%;
    }
  `,
);

export const Upcoming = styled.div`
  margin-top: 10px;
`;

export const DateStyles = styled.p`
  ${helpers.textSet({ fontSize: theme.typography.h2.fontSize, fontWeight: theme.font.weight.bold, align: 'center' })};
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Time = styled.p`
  ${typography.xs};
  font-weight: 600;
  line-height: 16px;
  color: ${colors.grey};
  text-transform: uppercase;
`;
export const Body = styled.div`
  margin-top: 20px;
`;

export const RightSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const More = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
