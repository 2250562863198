import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';

const OriginDescription = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
  `,
);

export const Container = styled.div``;
export const Description = styled(OriginDescription)`
  margin-bottom: 30px;
`;
export const SecondDescription = styled(OriginDescription)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ListContainer = styled.ul`
  color: #69686a;
  padding-left: 24px;
  padding-top: 12px;
`;

export const ListContainerCheckbox = styled.ul`
  color: #69686a;
  list-style: none;
  padding-top: 25px;
`;
