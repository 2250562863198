import { ADOBE_ANALYTICS_ID_SCE, GOOGLE_ANALYTICS_ID_SCE } from '@/constants/environment';

// Exclusive for SCE, when the query parameter is set

const createScript = ({ scriptText, src, async }: { scriptText?: string; src?: string; async?: boolean }) => {
  const script = document.createElement('script');

  if (src) {
    script.setAttribute('src', src);
  }

  if (async) {
    script.async = async;
  }

  if (scriptText) {
    script.appendChild(document.createTextNode(scriptText));
  }

  try {
    document.body.appendChild(script);
  } catch (e) {
    script.text = scriptText || '';
    document.body.appendChild(script);
  }
};

export const initializeGAForSCE = () => {
  createScript({ async: true, src: `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID_SCE}` });
  createScript({ scriptText: `window.dataLayer = window.dataLayer || [];` });
  createScript({
    scriptText: `
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${GOOGLE_ANALYTICS_ID_SCE}');
      `,
  });
};

export const initializeMetaPixel = () => {
  createScript({
    scriptText: `
        !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '177860451227887');
      fbq('track', 'PageView');
        `,
  });
};

export const initializeAdobeTracking = () => {
  createScript({
    src: `https://assets.adobedtm.com/fb794ec2b3c5/a9ea3c731cfd/launch-${ADOBE_ANALYTICS_ID_SCE}.min.js`,
    async: true,
  });
};

export const noScriptMetaPixelForSCE = () => {
  const script = document.createElement('noscript');
  script.setAttribute('id', GOOGLE_ANALYTICS_ID_SCE);
  const img = document.createElement('img');
  img.setAttribute('src', `https://www.facebook.com/tr?id=177860451227887&ev=PageView&noscript=1`);
  img.setAttribute('width', '1');
  img.setAttribute('height', '1');
  img.setAttribute('style', 'display: none');
  script.appendChild(img);

  const body = document.getElementsByTagName('body')[0];
  body.insertBefore(script, body.firstChild);
};

export const noScriptGAForSCE = () => {
  const script = document.createElement('noscript');
  script.setAttribute('id', GOOGLE_ANALYTICS_ID_SCE);
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', `https://googletagmanager.com/ns.html?id=${GOOGLE_ANALYTICS_ID_SCE}`);
  iframe.setAttribute('width', '0');
  iframe.setAttribute('height', '0');
  iframe.setAttribute('style', 'display:none;visibility:hidden');
  script.appendChild(iframe);

  const body = document.getElementsByTagName('body')[0];
  body.insertBefore(script, body.firstChild);
};
