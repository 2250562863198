import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${media.lg`
    overflow: auto;
    max-height: 434px;
    padding: 10px 5.5px 30px 5.5px;
    flex: 1;
  `}
`;

export const NoDataWrapper = styled.div`
  overflow: auto;
  padding: 10px 5.5px 30px 5.5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 434px;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize, fontWeight: theme.typography.title.fontWeight })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
    margin-top: 20px;
  `,
);
