import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.text[0]};
  `,
);

export const Container = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  height: 100%;
`;

export const Logo = styled.div`
  margin-top: 54px;
  margin-bottom: 38px;
`;

export const Title = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 36, fontWeight: theme.font.weight.bold, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
  `,
);

export const Description = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 28, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 20px;
  `,
);

export const ButtonWrapper = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  margin-top: 30px;
`;

export const ItemWrapper = styled.div`
  ${helpers.flexSet('column', 'center', 'flex-start')};
  gap: 8px;
`;

export const LinkDescription = styled.div`
  ${typography.sm};
  font-weight: 400;
  color: ${colors.text};
  line-height: 24px;
  align-self: center;
`;
