import { take, put, call, fork, all } from 'redux-saga/effects';
import * as apiPrimary from '@/apis/primary';
import {
  DR_REQUEST,
  DR_SUCCESS,
  DR_FAILURE,
  EVENT_PARTICIPATE_REQUEST,
  EVENT_PARTICIPATE_SUCCESS,
  EVENT_PARTICIPATE_FAILURE,
  EVENT_NOTIFIED_REQUEST,
  EVENT_NOTIFIED_SUCCESS,
  EVENT_NOTIFIED_FAILURE,
} from '@/stores/dr/events';
import { ReqEventNotified, ReqEventParticipate } from '@/apis/primary/types';
import { LOADED, LOADING } from '@/stores/loading';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* dr(siteId: string) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.getDr, { siteId });

    if (!response.success) {
      yield put({ type: DR_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data: dr } = response;

    yield put({ type: DR_SUCCESS, dr });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: DR_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* eventParticipate(siteId: string, payload: ReqEventParticipate) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.postDrEventParticipate, siteId, payload);
    // return value : 'Response to DR request successfully recorded in db'
    if (!response.success) {
      yield put({ type: EVENT_PARTICIPATE_FAILURE, errorMessage: response.error?.message });
      return;
    }

    yield put({
      type: EVENT_PARTICIPATE_SUCCESS,
      userEventId: payload.userEventId,
      userStatus: payload.userStatus,
      optState: payload.optState,
    });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EVENT_PARTICIPATE_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* eventNotifiedUpdate(siteId: string, payload: ReqEventNotified) {
  try {
    yield put({ type: LOADING });

    if (
      payload.userEventId !== '' &&
      (payload.hasBeenNotified !== undefined || payload.recentlyEndedEventNotified !== undefined)
    ) {
      const response: IResponse = yield call(apiPrimary.patchEventNotified, siteId, payload);

      if (!response.success) {
        yield put({ type: EVENT_NOTIFIED_FAILURE, errorMessage: response.error?.message });
        return;
      }

      yield put({
        type: EVENT_NOTIFIED_SUCCESS,
        userEventId: payload.userEventId,
        hasBeenNotified: payload.hasBeenNotified,
        recentlyEndedEventNotified: payload.recentlyEndedEventNotified,
        optState: payload.optState,
      });

      return;
    }

    yield put({ type: EVENT_NOTIFIED_FAILURE, errorMessage: 'ALREADY_NOTIFIED_OR_EVENT_ENDED' });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EVENT_NOTIFIED_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchDr() {
  while (true) {
    const { siteId } = yield take(DR_REQUEST);
    yield fork(dr, siteId);
  }
}

export function* watchEventParticipate() {
  while (true) {
    const { siteId, payload } = yield take(EVENT_PARTICIPATE_REQUEST);
    yield fork(eventParticipate, siteId, payload);
  }
}

export function* watchEventNotifiedUpdate() {
  while (true) {
    const { siteId, payload } = yield take(EVENT_NOTIFIED_REQUEST);
    yield fork(eventNotifiedUpdate, siteId, payload);
  }
}

export default function* eventsSaga() {
  yield all([fork(watchDr), fork(watchEventParticipate), fork(watchEventNotifiedUpdate)]);
}
