import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, PrimaryButton } from '@/designSystem/components';
import { Container, Description } from './ReturningFromReAuthModal.style';

const ReturningFromReAuthModal = ({
  title,
  show,
  onClose,
  description,
}: {
  title: string;
  show: boolean;
  onClose: () => void;
  description: string;
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal title={title} show={show} onClose={onClose} showClose>
      <Container>
        <Description>{description}</Description>
        <PrimaryButton title={t('common.generalButtons.close')} onClick={onClose} />
      </Container>
    </Modal>
  );
};

export default ReturningFromReAuthModal;
