import { DetailGrpahItem, TypicalEmissions, TypicalUsage, UserEmissions, UserUsage } from '@/types/dr';
import { UnitHistoryDetail } from './Edition';

export type DetailScreenEmissionsData = {
  unit: UnitHistoryDetail.lbs;
  data: { typical: Array<TypicalEmissions>; user: Array<UserEmissions> };
};

export type DetailScreenEnergyData = {
  unit: UnitHistoryDetail.kwh;
  data: { typical: Array<TypicalUsage>; user: Array<UserUsage> };
};

export type DetailData = DetailScreenEnergyData | DetailScreenEmissionsData;

export const setDetailData = ({ data, unit }: { data?: DetailGrpahItem; unit: UnitHistoryDetail }) => {
  if (!data) {
    return { unit, data: { typical: [], user: [] } };
  }
  if (unit === UnitHistoryDetail.lbs) {
    return { unit, data: { typical: data.typicalEmission, user: data.userEmission } };
  }

  return { unit, data: { typical: data.typicalUsage, user: data.userEnergyUsage } };
};

export const getFormattedDetailData = (details: DetailData) => {
  if (details.unit === UnitHistoryDetail.lbs) {
    const typical = details.data.typical.map((item) => ({ value: item.emission, ...item }));
    const user = details.data.user.map((item) => ({ value: item.emission, ...item }));

    return { typical, user };
  }

  const typical = details.data.typical.map((item) => ({ value: item.usage, ...item }));
  const user = details.data.user.map((item) => ({ value: item.usage, ...item }));

  return { typical, user };
};
