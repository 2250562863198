import React from 'react';
import { Link } from 'react-router-dom';
import Img from '@/atoms/Img';
import { sentEvent } from '@/helpers/GAHelper';
import { Wrapper, Container, Logo, Title } from './index.styles';
import { SidebarItemViewProps } from './index.types';

const SidebarItemView = ({ link, selected, title, img, selectedImg, eventParams }: SidebarItemViewProps) => {
  const handleClick = () => {
    sentEvent('click', eventParams);
  };
  return (
    <Wrapper selected={selected}>
      <Link to={link} onClick={handleClick}>
        <Container>
          <Logo>
            <Img src={selected ? selectedImg : img} />
          </Logo>
          <Title selected={selected}>{title}</Title>
        </Container>
      </Link>
    </Wrapper>
  );
};

export default SidebarItemView;
