import React from 'react';
import { EnrollmentStatus } from '@/types/enrollment';
import { useTranslation } from 'react-i18next';
import { colors } from '@/designSystem/colors';
import StatusLabel from '@/designSystem/components/statusLabel';

export interface Props {
  enrollmentStatus: EnrollmentStatus | undefined;
}

const EnrollmentStatusLabel = ({ enrollmentStatus }: Props) => {
  const { t } = useTranslation('common');

  const backgroundColor = enrollmentStatus === EnrollmentStatus.Disenrolled ? colors.bad : colors.pending;

  if (
    !enrollmentStatus ||
    (enrollmentStatus && ![EnrollmentStatus.Pending, EnrollmentStatus.Disenrolled].includes(enrollmentStatus))
  ) {
    return null;
  }

  return (
    <StatusLabel
      ariaLabel={t(`common.enrollmentStatus.${enrollmentStatus}`)}
      backgroundColor={backgroundColor}
      text={t(`common.enrollmentStatus.${enrollmentStatus}`)}
      color={colors.white}
    />
  );
};

export default EnrollmentStatusLabel;
