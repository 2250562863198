import React from 'react';
import { useTranslation } from 'react-i18next';
import AvailableProgram from '@/organisms/AvailableProgram';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import { FillPrimary } from '@/designSystem/components';
import { ModalBanner } from '@/organisms/ModalBanner';
import { IAvailableProgramScreenViewProps } from './index.types';

const AvailableProgramScreenView = ({
  disabledNext,
  onNext,
  onNoThanks,
  isNoThanks,
  utility,
  failureModal,
  failureModalClose,
}: IAvailableProgramScreenViewProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <EnrollmentTemplate
        disabled={disabledNext}
        textNext={t('common.generalButtons.next')}
        onNext={onNext}
        onOther={isNoThanks ? onNoThanks : undefined}
        textOther={isNoThanks ? t('common.generalButtons.noThanks') : undefined}
        navigationPath={PATHS.SCREEN_ENROLLMENT_AVAILABLE_PROGRAM.pathname}
        showBoxShadow={false}
        wrapperMargin="0px 300px"
        wrapperMaxWidth="none"
        appLogin={false}
        buttonsContainerPadding="0 60px"
        utility={utility}
      >
        <AvailableProgram />
      </EnrollmentTemplate>
      <ModalBanner
        headerImage
        ariaControlsId="terms-and-condition-notification-popup"
        show={failureModal || false}
        title={t('screens.enrollment.availableProgramScreen.error.title')}
        onClose={failureModalClose}
        showClose
        message={t('screens.enrollment.availableProgramScreen.error.description')}
      >
        <FillPrimary
          title={t('common.generalButtons.tryAgain')}
          onClick={failureModalClose}
          padding="4px 12px"
          flex="unset"
        />
      </ModalBanner>
    </>
  );
};

export default AvailableProgramScreenView;
