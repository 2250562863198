import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@/designSystem/components';
import {
  Container,
  DeleteModalContainer,
  DeleteModalHeader,
} from '@/organisms/ModalProgramInformation/ModalProgramInformation.styles';
import { ProgramName } from '@/types/enrollment';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import ProgramContentsDefault from './ProgramContents/ProgramContents.default';
import { IModalProgramInformationProps } from './ModalProgramInformation.types';
import ProgramContentsClimateResponse from './ProgramContents/ProgramContents.climate_response';

const ModalProgramInformation = ({ programName, show, onClose, ariaControlsId }: IModalProgramInformationProps) => {
  const { t } = useTranslation('common');

  const renderProgramDetail = (programName: ProgramName) => {
    switch (programName) {
      case ProgramName.DATA_ONLY:
        return <DataOnlyMessage />;
      case ProgramName.CLIMATE_RESPONSE:
        return <ProgramContentsClimateResponse programName={programName} />;
      default:
        return <ProgramContentsDefault programName={programName} />;
    }
  };

  return (
    <Modal show={show} onClose={onClose} ariaControlsId={ariaControlsId} showClose>
      <DeleteModalContainer>
        <DeleteModalHeader>{t(`programs.${programName}.title`)}</DeleteModalHeader>
      </DeleteModalContainer>
      <Container>{renderProgramDetail(programName)}</Container>
    </Modal>
  );
};

export default ModalProgramInformation;
