import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { eventsHistoryRequest, eventHistorySelectRequest, eventsHistorySelectReset } from '@/stores/dr/eventsHistory';
import { DrHistoryItem } from '@/types/dr';

export default function useEventsHistory() {
  const dispatch = useDispatch();

  const fetchEventsHistoryRequest = useCallback((siteId: string) => dispatch(eventsHistoryRequest(siteId)), [dispatch]);

  const fetchEventHistorySelect = useCallback(
    (siteId: string, event: DrHistoryItem) => dispatch(eventHistorySelectRequest(siteId, event)),
    [dispatch],
  );

  const fetchEventHistorySelectReset = useCallback(() => dispatch(eventsHistorySelectReset()), [dispatch]);

  return {
    fetchEventsHistoryRequest,
    fetchEventHistorySelect,
    fetchEventHistorySelectReset,
  };
}
