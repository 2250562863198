import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const styleEventIcon = { width: '32px', height: '32px' };

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgramInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 8px 8px 0px 0px;
  gap: 8px;
  border-bottom: 1px solid rgba(114, 116, 118, 0.2);
`;

export const EventTypeTitle = styled.p`
  ${typography.xl};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.accent};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
  gap: 20px;
`;

export const InnerContainer = styled.div`
  display: flex;
  background-color: ${colors.background};
  padding: 15px;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 8px;
`;

export const Title = styled.p`
  ${typography.lg};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.grey};
`;
