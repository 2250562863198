import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '@/assets/styles';
import Checkbox from '@/atoms/Checkbox';
import InputText from '@/molecules/InputText';
import { isOlivineEdition } from '@/helpers/Edition';
import { ILocationSetupViewProps } from './index.types';
import { Validate } from '../Verification/index.styles';

const LocationSetupView = ({
  isBusiness,
  zipCode,
  siteName,
  onChangeBusiness,
  onChangeZipcode,
  onChangeSiteName,
  errorArr,
  onNext,
  disabled,
}: ILocationSetupViewProps) => {
  const { t } = useTranslation('common');

  return (
    <div>
      {isOlivineEdition && (
        <Checkbox
          items={[{ id: 1, name: 1, text: t('screens.enrollment.locationSetupScreen.business') }]}
          values={[isBusiness ? 1 : 0]}
          onChange={onChangeBusiness}
        />
      )}
      <InputText
        labelId="location-setup-zipcode"
        label={t('common.validations.zipCode.placeholder')}
        type="text"
        border="underline"
        value={zipCode}
        fontSize={theme.typography.body.fontSize}
        maxLength={5}
        onChangeText={onChangeZipcode}
        onNext={onNext}
        triggerSubmit={false}
        disabled={disabled}
        inputMode="numeric"
      />
      {errorArr.includes('zipcodeError') && <Validate>{t('screens.enrollment.locationSetupScreen.validate')}</Validate>}
      <InputText
        labelId="location-setup-sitename"
        label={
          isBusiness
            ? t('common.validations.businessNickname.placeholder')
            : t('common.validations.homeNickname.placeholder')
        }
        type="text"
        border="underline"
        value={siteName}
        fontSize={theme.typography.body.fontSize}
        marginTop={50}
        onChangeText={onChangeSiteName}
        onNext={onNext}
        triggerSubmit
        disabled={disabled}
      />
    </div>
  );
};

export default LocationSetupView;
