import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface ButtonProps {
  padding?: string;
  warning: boolean;
  maxWidth?: string;
  flex?: number | 'unset';
}

const warningBoxShadow = '0px 0px 0px 4px rgba(215, 2, 26, 0.2);';
const primaryBoxShadow = '0px 0px 0px 4px rgba(69, 129, 29, 0.2);';

export const Button = styled.button<ButtonProps>`
  ${typography.sm};
  color: ${colors.white};
  font-weight: 700;
  border-radius: 8px;
  border: none;
  background-color: ${(props) => (props.warning ? colors.bad : colors.primary)};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  flex: ${(props) => props.flex};
  padding: ${(props) => props.padding};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  &:hover {
    background-color: ${(props) => (props.warning ? colors.badHover : colors.primaryHover)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badHover : colors.primaryHover)};
    box-shadow: ${(props) => (props.warning ? warningBoxShadow : primaryBoxShadow)};
  }
  &:disabled {
    cursor: default;
    background-color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: ${colors.grey};
  }
`;
