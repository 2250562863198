export const typography = {
  number: {
    fontFamily: 'Lato',
    fontSize: '15px',
  },
  xxs: {
    fontFamily: 'Lato',
    fontSize: '13px',
  },
  xs: {
    fontFamily: 'Lato',
    fontSize: '15px',
  },
  sm: {
    fontFamily: 'Lato',
    fontSize: '17px',
  },
  md: {
    fontFamily: 'Lato',
    fontSize: '18px',
  },
  lg: {
    fontFamily: 'Lato',
    fontSize: '20px',
  },
  xl: {
    fontFamily: 'Lato',
    fontSize: '22px',
  },
  xl1: {
    fontFamily: 'Lato',
    fontSize: '24px',
  },
  xl2: {
    fontFamily: 'Lato',
    fontSize: '26px',
  },
  xl3: {
    fontFamily: 'Lato',
    fontSize: '36px',
    letterSpacing: -0.5,
  },
  xl4: {
    fontFamily: 'Lato',
    fontSize: '40px',
    letterSpacing: -1,
  },
  xl5: {
    fontFamily: 'Lato',
    fontSize: '45px',
    letterSpacing: -1,
  },
  xl6: {
    fontFamily: 'Lato',
    fontSize: '50px',
    letterSpacing: -1.5,
  },
};
