import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { RootState } from '@/stores/index';
import { OptStateEnum, UserStatusEnum } from '@/types/dr';
import { ReqEventNotified } from '@/apis/primary/types';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import DRParticipationControl from '@/organisms/DRParticipationControl';
import ProgramInformationTooltip from '@/organisms/ProgramInformationTooltip';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { useEvents } from '@/hooks/dr';
import {
  Time,
  Title,
  Wrapper,
  DateTimeContainer,
  DateContainer,
  DateText,
  TimeContainer,
  Container,
  ProgramInformationContainer,
  EventTypeTitle,
  styleCalendarIcon,
  styleClockIcon,
  styleEventIcon,
  LeftSection,
  RightSection,
  InnerContainer,
  Divider,
  TitleContainer,
} from './DRUpcomingEvent.styles';
import { IDRUpcomingEventProps } from './DRUpcomingEvent.types';

const DRUpcomingEvent = ({ event, multipleDrEvent }: IDRUpcomingEventProps) => {
  const { t } = useTranslation('common');
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { fetchEventNotifiedUpdate } = useEvents();

  const programName = getProgramNameFromSite(currentSite);

  const handleEventPopupClosed = (userEventId?: string) => {
    const payload: ReqEventNotified = {
      userEventId,
      hasBeenNotified: true,
      optState: OptStateEnum.acknowledged,
    };
    if (currentSite) {
      fetchEventNotifiedUpdate(currentSite.id, payload);
    }
  };

  return (
    <Wrapper>
      <ProgramInformationContainer>
        <Img src={images.iconUpcoming} style={styleEventIcon} />
        <EventTypeTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.upcomingEvents`)}</EventTypeTitle>
      </ProgramInformationContainer>
      <Container>
        <InnerContainer>
          <LeftSection>
            <TitleContainer>
              <Title>
                {t('screens.main.savingsEvents.savingsEventsScreen.programEvent', {
                  program: t(`programs.${programName}.title`),
                })}
              </Title>
              <ProgramInformationTooltip programName={programName} />
            </TitleContainer>
            <DateTimeContainer>
              <DateContainer>
                <Img src={images.calendarBlack} style={styleCalendarIcon} />
                <DateText>{moment(event?.start).format('dddd, MMMM DD')}</DateText>
              </DateContainer>
              <Divider />
              <TimeContainer>
                <Img src={images.clockGrey} style={styleClockIcon} />
                <Time>{`${moment(event?.start).format('h:mm a')} - ${moment(event?.end).format('h:mm a')}`}</Time>
              </TimeContainer>
            </DateTimeContainer>
          </LeftSection>
          <RightSection>
            <DRParticipationControl
              currentSiteId={currentSite?.id || ''}
              userStatus={event?.userStatus || UserStatusEnum.DefaultEvent}
              userEventId={event?.userEventId || ''}
              startDate={event?.start ? new Date(event.start) : undefined}
              endDate={event?.end ? new Date(event.end) : undefined}
              hasBeenNotified={event?.hasBeenNotified}
              eventPopupClosed={() => handleEventPopupClosed(event?.userEventId)}
              optInMode={event?.programAttributes?.optInMode}
              programName={programName}
            />
          </RightSection>
        </InnerContainer>
        {multipleDrEvent && (
          <InnerContainer>
            <LeftSection>
              <TitleContainer>
                <Title>
                  {t('screens.main.savingsEvents.savingsEventsScreen.programEvent', {
                    program: t(`programs.${programName}.title`),
                  })}
                </Title>
                <ProgramInformationTooltip programName={programName} />
              </TitleContainer>
              <DateTimeContainer>
                <DateContainer>
                  <Img src={images.calendarBlack} style={styleCalendarIcon} />
                  <DateText>{moment(multipleDrEvent?.start).format('dddd, MMMM DD')}</DateText>
                </DateContainer>
                <TimeContainer>
                  <Img src={images.clockGrey} style={styleClockIcon} />
                  <Time>{`${moment(multipleDrEvent?.start).format('h:mm a')} - ${moment(multipleDrEvent?.end).format(
                    'h:mm a',
                  )}`}</Time>
                </TimeContainer>
              </DateTimeContainer>
            </LeftSection>
            <RightSection>
              <DRParticipationControl
                currentSiteId={currentSite?.id || ''}
                userStatus={multipleDrEvent?.userStatus || UserStatusEnum.DefaultEvent}
                userEventId={multipleDrEvent?.userEventId || ''}
                startDate={multipleDrEvent?.start ? new Date(multipleDrEvent.start) : undefined}
                endDate={multipleDrEvent?.end ? new Date(multipleDrEvent.end) : undefined}
                hasBeenNotified={multipleDrEvent?.hasBeenNotified}
                eventPopupClosed={() => handleEventPopupClosed(event?.userEventId)}
                optInMode={multipleDrEvent?.programAttributes?.optInMode}
                programName={programName}
              />
            </RightSection>
          </InnerContainer>
        )}
      </Container>
    </Wrapper>
  );
};

export default DRUpcomingEvent;
