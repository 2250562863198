import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface InputComponentProps {
  isFocused?: boolean;
  error?: string;

  arraySearch?: boolean;
  leftPadding?: boolean;
  right?: React.ReactNode;
}

export const PhoneNumbersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const Label = styled.div`
  ${typography.sm};
  color: ${colors.text};
  margin-top: 40px;
  margin-bottom: 20px;
`;

export const AddPhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
  padding: 0 16.5px;
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0 20px;
  margin: 40px 0 12px 0;
`;

export const Content = styled.div``;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const Buttons = styled.div`
  ${helpers.flexSet('row', 'center', 'center')};
  margin-top: 20px;
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
`;

export const InputLabel = styled.p`
  ${typography.sm}
  color: ${colors.text};
  font-weight: 400;
`;
export const InputComponent = styled.input<InputComponentProps>`
  ${typography.xs};
  width: 100%;
  padding: 16px 16px;
  align-items: center;
  border: 1px solid ${colors.lightGrey};
  border-radius: 12px;
  color: ${colors.text};
  margin: 1px 1px;

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid ${colors.primary};
      margin: 0;
    `};

  ${(props) =>
    props.isFocused &&
    props.error &&
    css`
      border: 2px solid ${colors.bad};
    `};

  ${(props) =>
    props.leftPadding &&
    css`
      padding-left: 48px;
    `};
  outline: none;
`;

export const EmailValue = styled.p`
  ${typography.sm}
  color: ${colors.text};
  font-weight: 400;
  padding: 8px 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
`;
