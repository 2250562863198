import React from 'react';
import { useTranslation } from 'react-i18next';
import MoneyEarnedItem from '@/molecules/MoneyEarnedItem';
import { Wrapper, NoData, NoDataWrapper } from './index.styles';
import { IMoneyEarnedListViewProps } from './index.types';

const MoneyEarnedListView = ({ items, handleSelectEarning, selectedEarning }: IMoneyEarnedListViewProps) => {
  const { t } = useTranslation('common');

  if (!items || items?.length === 0) {
    return (
      <NoDataWrapper>
        <NoData>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.noData')}</NoData>
      </NoDataWrapper>
    );
  }

  return (
    <Wrapper>
      {items?.map((item, index) => (
        <MoneyEarnedItem
          key={item.eventId}
          index={items.length - index}
          item={item}
          onClick={handleSelectEarning}
          selectedEarning={selectedEarning}
        />
      ))}
    </Wrapper>
  );
};

export default MoneyEarnedListView;
