import { all, fork } from 'redux-saga/effects';
import auth from './auth';
import enrollment from './enrollment';
import dashboard from './dashboard';
import message from './message';
import setting from './setting';
import phoneOtp from './phoneOtp';
import sites from './sites';
import userInfo from './userInfo';
import events from './dr/events';
import eventsHistory from './dr/eventsHistory';
import earningsHistory from './dr/earningsHistory';

function* rootSaga() {
  yield all([
    fork(auth),
    fork(enrollment),
    fork(sites),
    fork(dashboard),
    fork(message),
    fork(phoneOtp),
    fork(setting),
    fork(userInfo),
    fork(events),
    fork(eventsHistory),
    fork(earningsHistory),
  ]);
}

export default rootSaga;
