export const colors = {
  primary: '#45811D',
  primaryGreen: '#428500',
  primaryHover: '#356416',
  hoverFocus: '#EDFFE0',
  accent: '#0B5FFB',
  accentHover: '#1C50B0',
  white: '#FFFFFF',
  background: '#F5F5F5',
  lightGrey: '#EBEBEB',
  grey: '#727476',
  blackGrey: '#4A4A4A',
  text: '#000000',
  good: '#178732',
  alert: '#BB5808',
  bad: '#D70015',
  badHover: '#A10010',
  badFocus: '#F9D9DC',
  gold: '#C69200',
  silver: '#C1C1C1',
  bronze: '#D88B56',
  pending: '#E86409',
};
