import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MoneyEarnedItem, PendingMoneyEarnedItem } from '@/types/dr';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { useEarningsHistory } from '@/hooks/index';

import { useNavigate } from 'react-router-dom';
import MoneyEarnedListView from './index.view';

const MoneyEarnedListContainer = ({
  earningsHistory,
}: {
  earningsHistory: Array<MoneyEarnedItem | PendingMoneyEarnedItem>;
}) => {
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const {
    sites: {
      data: { currentSite },
    },
    dr: {
      history: {
        earnings: {
          data: { selectedEarnings },
        },
      },
    },
  } = useSelector((state: RootState) => state);

  const [localSelectedEarning, setLocalSelectedEarning] = useState<{ earningId?: string }>({
    earningId: selectedEarnings?.earningId ?? undefined,
  });
  const { fetchEarningsHistorySelect, fetchEarningsHistorySelectReset } = useEarningsHistory();
  const navigate = useNavigate();

  const handleSelectEarning = (item: MoneyEarnedItem, name: string) => {
    if (!isMobileWindowSize && localSelectedEarning.earningId === item.earningId) {
      return;
    }

    setLocalSelectedEarning({ ...localSelectedEarning, earningId: item.earningId });

    fetchEarningsHistorySelect(currentSite?.id || '', item, name);

    sentEvent('selected_money_earned', PATHS.SELECT_MONEY_EARNED(localSelectedEarning.earningId));

    if (isMobileWindowSize) {
      navigate(PATHS.SCREEN_MOBILE_HISTORY_DETAIL);
    }
  };

  useEffect(() => {
    if (!selectedEarnings && earningsHistory?.length > 0) {
      const firstNonPendingEarning = earningsHistory.find((earning): earning is MoneyEarnedItem => {
        return !('isPending' in earning) || !earning.isPending;
      });
      if (firstNonPendingEarning) {
        if ('earningId' in firstNonPendingEarning) {
          setLocalSelectedEarning({ earningId: firstNonPendingEarning.earningId });
        }
        fetchEarningsHistorySelect(currentSite?.id || '', firstNonPendingEarning, '');
      } else {
        fetchEarningsHistorySelectReset();
      }
    } else {
      setLocalSelectedEarning({ earningId: selectedEarnings?.earningId });
    }
  }, [selectedEarnings, earningsHistory, currentSite]);

  return (
    <MoneyEarnedListView
      items={earningsHistory}
      handleSelectEarning={handleSelectEarning}
      selectedEarning={localSelectedEarning}
    />
  );
};

export default MoneyEarnedListContainer;
