import styled, { css } from 'styled-components';
import { media } from '@/assets/styles';
import { BodyStyleProps } from './index.types';

export const defaultModalStyle = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: '#fff',
    overflow: 'initial',
    outline: 'none',
    borderRadius: '16px',
  },
  overlay: {
    backgroundColor: 'rgba(74, 74, 74, 0.5)',
  },
};
interface ModalProps {
  small?: boolean;
  fullWidth?: boolean;
}

export const Container = styled.div<ModalProps>`
  border-radius: 16px;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  position: relative;
  padding: 10px 24px 10px 24px;
  width: ${(props) => {
    if (props.fullWidth) {
      return 'unset';
    }
    return props.small ? '425px' : '535px';
  }};

  ${(props) => {
    if (props.fullWidth) {
      return css``;
    }

    return css`
      ${media.md`
        min-width: '400px';
        max-width: '550px';
      `}
    `;
  }}
`;

export const Header = styled.div<ModalProps>`
  padding: ${(props) => (props.small ? `10px 10px 0` : `30px 30px 0`)};
`;

export const Title = styled.p(
  ({ theme }) => css`
    font-size: ${theme.typography.h2.fontSize};
    font-weight: ${theme.typography.h2.fontWeight};
    text-align: center;
  `,
);

export const More = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Body = styled.div<BodyStyleProps>(
  ({ small }) => css`
    overflow-y: auto;
    max-height: 500px;
    padding: 0 20px 0 20px;

    ${media.md`
    max-height: 600px;
    padding: ${small ? `0` : `0 25px 0 25px`}
  `};
  `,
);

export const Footer = styled.div`
  padding: 0 20px 30px 20px;

  ${media.md`
    padding: 0 100px 30px 100px;
  `};
`;
