import moment from 'moment';
import { Language } from '@/types/setting';
import spanishStrings from 'react-timeago/lib/language-strings/es';
import chineseStrings from 'react-timeago/lib/language-strings/zh-CN';
import englishStrings from 'react-timeago/lib/language-strings/en';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

export const localeDetect = (language: Language) => {
  switch (language) {
    case Language.cn:
      return 'zh_cn';
    case Language.esp:
      return 'es';
    case Language.eng:
    default:
      return 'en';
  }
};

export const changeMomentLocale = (language: Language) => {
  const locale: string = localeDetect(language);
  moment.locale(locale);
};

export const timeagoFormatter = (language: Language) => {
  switch (language) {
    case Language.cn:
      return buildFormatter(chineseStrings);
    case Language.esp:
      return buildFormatter(spanishStrings);
    case Language.eng:
    default:
      return buildFormatter(englishStrings);
  }
};
