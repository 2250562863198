import React, { useEffect, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useEnrollment, useMisc } from '@/hooks/index';
import { GBCStatus } from '@/types/userInfo';
import { Outlet } from 'react-router-dom';
import MainTemplateView from './index.view';

interface MainTemplateProps {
  children?: ReactNode;
}

const MainTemplateContainer = ({ children }: MainTemplateProps) => {
  const {
    userInfo: {
      data: {
        userInfo: { gbcStatus },
      },
    },
    enrollment: {
      enrollmentInfo: { gbcUrlResult, zipCode },
      utilityPrograms,
    },
    misc: { hamburgerOpen },
  } = useSelector((state: RootState) => state);

  const { fetchChangeHamburger } = useMisc();
  const { fetchProgramRequest, fetchGBCResult } = useEnrollment();

  useEffect(() => {
    fetchChangeHamburger(false);

    if (gbcUrlResult !== '') {
      fetchGBCResult('');
    }
  }, []);

  useEffect(() => {
    if (gbcStatus !== GBCStatus.completed && zipCode && Object.keys(utilityPrograms).length === 0) {
      fetchProgramRequest(zipCode);
    }
  }, [zipCode]);

  return (
    <MainTemplateView hamburgerOpen={hamburgerOpen}>
      <Outlet />
      {children}
    </MainTemplateView>
  );
};

export default MainTemplateContainer;
