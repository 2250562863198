import React from 'react';
import TimeAgo from 'react-timeago';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { timeagoFormatter } from '@/helpers/LanguageHelper';
import { IMessageItemProps } from './MessageItem.types';
import { Body, Content, DateText, Header, SubTitle, Title, Wrapper } from './MessageItem.styles';

const MessageItem = ({ message, selected, onClick }: IMessageItemProps) => {
  const {
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);
  const { name, time, isNewMessage, content } = message;

  return (
    <Wrapper onClick={() => onClick(message.id)} selected={selected}>
      <Header>
        <Title selected={selected} newMessage={isNewMessage}>
          {name}
        </Title>
        <SubTitle newMessage={isNewMessage}>&nbsp;</SubTitle>
        <Content selected={selected}>{content}</Content>
      </Header>
      <Body>
        <DateText selected={selected}>
          <TimeAgo date={time} formatter={timeagoFormatter(language)} />
        </DateText>
      </Body>
    </Wrapper>
  );
};

export default MessageItem;
