import { AchievementLevel } from '@/types/dashboard';
import { DrHistoryItem, EventSummary } from '@/types/dr';

type Seasons = {
  [year: string]: {
    summary: EventSummary;
    history: Array<DrHistoryItem>;
  };
};

export const seasonsUpdateEvents = (state: Seasons, seasons: Seasons): Seasons => {
  const seasonsYears = Object.keys(seasons);
  if (!seasonsYears.length) {
    return state;
  }

  const dummyEventSummary = {
    level: AchievementLevel.noAct,
    electricitySaved: 0,
    moneyEarned: 0,
    numRequestsTotal: 0,
    emissionImpact: 0,
  };

  const updatedSeasons: Seasons = {};

  seasonsYears.forEach((seasonYear: string) => {
    const selectedSeason = seasons[seasonYear];
    const selectedSeasonProperties = Object.keys(selectedSeason);
    if (!selectedSeasonProperties.length) {
      updatedSeasons[seasonYear] = {
        summary: dummyEventSummary,
        history: [],
      };
      return;
    }
    updatedSeasons[seasonYear] = selectedSeason;
  });

  return updatedSeasons;
};
