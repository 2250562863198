import { take, put, fork, all, call, delay, select } from 'redux-saga/effects';
import * as apiAuth from '@/apis/auth';
import * as apiPrimary from '@/apis/primary';
import { LOADED, LOADING } from '@/stores/loading';
import {
  SITES_REQUEST,
  SITES_SUCCESS,
  SITES_FAILURE,
  CHANGE_SITE_REQUEST,
  CHANGE_SITE_SUCCESS,
  CHANGE_SITE_FAILURE,
  SELECT_SITES_FOR_ENROLLMENT_REQUEST,
  SELECT_SITES_FOR_ENROLLMENT_SUCCESS,
  SELECT_SITES_FOR_ENROLLMENT_FAILURE,
  UPDATE_SITE_REQUEST,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAILURE,
  DELETE_SITE_REQUEST,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAILURE,
  SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST,
  SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS,
  SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE,
} from '@/stores/sites';
import { SiteItem, Sites } from '@/types/sites';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

import { RESET_DR } from '@/stores/dr/events';
import { ProgramName } from '@/types/enrollment';
import { RootState } from '@/stores/index';

function* sites(siteName?: string, callback?: (site: Sites) => void) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.getSites);
    if (!response.success) {
      yield put({ type: SITES_FAILURE, error: response.error?.message });
      return;
    }
    const { data } = response;
    const currentSiteId: string | undefined = yield select((state: RootState) => state.sites.data?.currentSite?.id);

    const currentSite =
      data.sites.find(({ name, id }: SiteItem) =>
        siteName !== undefined ? name === siteName : id === currentSiteId,
      ) ?? data?.sites[0];

    const { data: siteData } = yield call(apiPrimary.selectSite, currentSite?.id || '');

    const { data: updatedUserInfo } = yield call(apiAuth.updateUserInfo, { siteName: siteData.name });
    const success = updatedUserInfo === 'OK';

    if (success) {
      yield put({ type: SITES_SUCCESS, sites: data, currentSite: siteData });
    }
    callback?.(data);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: SITES_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* changeSite(siteId: string) {
  try {
    yield put({ type: LOADING });
    const response: IResponse = yield call(apiPrimary.selectSite, siteId);

    if (!response.success) {
      yield put({ type: CHANGE_SITE_FAILURE, error: response.error?.message });
      return;
    }

    const { data: siteData } = response;

    const userInfoResponse: IResponse = yield call(apiAuth.updateUserInfo, { siteName: siteData.name });
    const success = userInfoResponse.data === 'OK';

    if (success) {
      yield put({ type: RESET_DR });
      yield put({ type: CHANGE_SITE_SUCCESS, currentSite: siteData });
      return;
    }

    yield put({ type: CHANGE_SITE_FAILURE, error: userInfoResponse.error?.message });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: CHANGE_SITE_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* updateSite(siteId: string, payload: { [k: string]: string }) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.updateSite, siteId, payload);

    if (!response.success) {
      yield put({ type: UPDATE_SITE_FAILURE, error: response.error?.message || '' });
      return;
    }
    yield put({ type: UPDATE_SITE_SUCCESS, siteId, payload });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: UPDATE_SITE_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* selectSitesForEnrollment(
  siteIds: Array<string>,
  gbcIntegrationId: string,
  cb?: (item: Error | IResponse) => void,
) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.selectSitesForEnrollment, siteIds, gbcIntegrationId);

    if (!response.success) {
      throw new Error(response.error?.message || 'Failed to select site for enrollment.');
    }

    yield put({ type: SELECT_SITES_FOR_ENROLLMENT_SUCCESS });
    cb?.(response);
  } catch (error) {
    const errorMessage = getErrorMessage(error);

    yield put({ type: SELECT_SITES_FOR_ENROLLMENT_FAILURE, errorMessage });
    cb?.(error instanceof Error ? error : new Error(errorMessage));
  } finally {
    yield put({ type: LOADED });
  }
}

export function* submitTermsAndConditionsInSites(programName: ProgramName, cb?: (item: Error | IResponse) => void) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.submitTermsAndConditions, programName);

    if (!response.success) {
      throw new Error(response.error?.message || 'Submit Terms and Conditions failed.');
    }

    yield put({ type: SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS });
    cb?.(response);
  } catch (error) {
    const errorMessage = getErrorMessage(error); // this is returning [object object]

    yield put({ type: SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE, errorMessage });
    cb?.(error instanceof Error ? error : new Error(errorMessage));
  } finally {
    yield put({ type: LOADED });
  }
}

function* deleteSite(siteId: string) {
  try {
    yield put({ type: LOADING });
    const response: IResponse = yield put({ type: DELETE_SITE_SUCCESS, siteId });

    if (!response.success) {
      yield put({ type: DELETE_SITE_FAILURE, error: response.error?.message || '' });
      return;
    }

    yield put({ type: DELETE_SITE_SUCCESS, siteId });

    delay(100);

    yield fork(sites);
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: DELETE_SITE_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchSubmitTermsAndConditionsInSites() {
  while (true) {
    const { programName, cb } = yield take(SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST);
    yield fork(submitTermsAndConditionsInSites, programName, cb);
  }
}

export function* watchSites() {
  while (true) {
    const { siteName, callback } = yield take(SITES_REQUEST);
    yield fork(sites, siteName, callback);
  }
}

export function* watchChangeSite() {
  while (true) {
    const { siteId } = yield take(CHANGE_SITE_REQUEST);
    yield fork(changeSite, siteId);
  }
}

export function* watchUpdateSite() {
  while (true) {
    const { siteId, payload } = yield take(UPDATE_SITE_REQUEST);
    yield fork(updateSite, siteId, payload);
  }
}

export function* watchSelectSitesForEnrollment() {
  while (true) {
    const { siteIds, gbcIntegrationId, cb } = yield take(SELECT_SITES_FOR_ENROLLMENT_REQUEST);
    yield fork(selectSitesForEnrollment, siteIds, gbcIntegrationId, cb);
  }
}

export function* watchDeleteSite() {
  while (true) {
    const { siteId } = yield take(DELETE_SITE_REQUEST);
    yield fork(deleteSite, siteId);
  }
}

export default function* sitesSaga() {
  yield all([
    fork(watchSites),
    fork(watchChangeSite),
    fork(watchSelectSitesForEnrollment),
    fork(watchUpdateSite),
    fork(watchDeleteSite),
    fork(watchSubmitTermsAndConditionsInSites),
  ]);
}
