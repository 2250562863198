import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useUserInfo } from '@/hooks/index';
import { ReqDeleteUser } from '@/apis/auth/types';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import ModalDeleteAccountView from './index.view';
import { IModalDeleteAccountProps } from './index.types';

const ModalModalDeleteAccountContainer = ({ show, onClose, ariaControlsId }: IModalDeleteAccountProps) => {
  const {
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  const { fetchDeleteUser, fetchUserInfo } = useUserInfo();

  const [state, setState] = useState<{
    validationText: string;
    continueable: boolean;
    validation: boolean;
    correct: boolean;
    step: number;
  }>({
    validationText: '',
    continueable: false,
    validation: true,
    correct: false,
    step: userInfo.isDeleted ? 3 : 1,
  });

  const handleOnChangeValidationText = (value: string) => {
    const disabled = value.length > 0;
    const isCorrectWord = value === 'DELETE';
    setState({ ...state, validationText: value, continueable: disabled, correct: isCorrectWord });
  };

  const handleOnClose = (gaMessage?: string) => {
    setState({ ...state, validationText: '', validation: true, continueable: false, step: 1 });
    onClose(gaMessage);
  };

  const handleOnContinue = () => {
    if (state.step === 1 && state.validationText !== 'DELETE') {
      setState({ ...state, validation: false, continueable: false });
    } else {
      sentEvent('click', PATHS.POPUP_CONTINUE_DELETE_ACCOUNT);
      setState({ ...state, step: state.step + 1 });
    }
  };

  const handleDeleteUser = () => {
    sentEvent('click', PATHS.REQUEST_DELETE_MY_ACCOUNT);
    const payload: ReqDeleteUser = { id: userInfo.id, deletedBy: userInfo.id };
    fetchDeleteUser(payload, () => {
      fetchUserInfo(() => {
        setState({ ...state, step: state.step + 1 });
      });
    });
  };

  const programName = getProgramNameFromSite(currentSite, userInfo.program);

  return (
    <ModalDeleteAccountView
      show={show}
      onClose={handleOnClose}
      validationText={state.validationText}
      continueable={state.continueable}
      onChangeValidationText={handleOnChangeValidationText}
      onContinue={handleOnContinue}
      step={state.step}
      programName={programName}
      onDeleteUser={handleDeleteUser}
      validation={state.validation}
      ariaControlsId={ariaControlsId}
      isCorrectWord={state.correct}
    />
  );
};

export default ModalModalDeleteAccountContainer;
