import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const styleCalendarIcon = { width: '23px', height: '24px' };
export const styleClockIcon = { width: '17px', height: '16px' };
export const styleButton = {
  padding: '12px 16px',
  maxWidth: '100px',
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
`;

export const PopupHeaderContainer = styled.div``;
export const PopupTitle = styled.p`
  ${typography.xl1}
  line-height: 32px;
  font-weight: 900;
  color: ${colors.text};
`;

export const UpcomingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Upcoming = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const DateText = styled.p`
  ${typography.xl2};
  font-weight: 700;
  line-height: 24px;
  color: ${colors.text};
`;

export const Time = styled.p`
  ${typography.lg};
  font-weight: 600;
  line-height: 16px;
  color: ${colors.grey};
  text-transform: uppercase;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
`;
