import React, { useState } from 'react';
import { Controller, ValidationRule, Control } from 'react-hook-form';
import { InputComponent, Label, ErrorText } from './input.styles';

type InputProps = {
  type: string;
  control: Control;
  label: string;
  formatEntry: (value: string) => void;
  validationRules: {
    required?: ValidationRule<boolean>;
    minLength?: ValidationRule<number>;
    maxLength?: ValidationRule<number>;
    validate?: (item: string) => boolean;
  };
  placeholder?: string;
  inputName: string;
  disabled?: boolean;
  ariaLabel: string;
  error?: string;
};

export const Input = ({
  type,
  control,
  label,
  formatEntry,
  validationRules,
  inputName,
  placeholder,
  disabled,
  ariaLabel,
  error,
  ...inputProps
}: InputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <Label>{label}</Label>
      <Controller
        render={({ field: { onChange, onBlur, value } }) => (
          <InputComponent
            type={type}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
              onBlur();
            }}
            isFocused={isFocused}
            error={error}
            disabled={disabled}
            onChange={(e) => onChange(formatEntry(e.target.value))}
            value={value}
            placeholder={placeholder}
            aria-label={ariaLabel}
            {...inputProps}
          />
        )}
        name={inputName}
        rules={validationRules}
        control={control}
      />
      {!!error && <ErrorText>{error}</ErrorText>}
    </>
  );
};
