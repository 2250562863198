import { useQuery } from '@tanstack/react-query';
import * as api from '@/apis/enrollment';
import { GeneralHook, FetchingStatus } from './types';

const useIntegrations = (): GeneralHook<{ loginUrl: string }> => {
  const { data, error, isError, isRefetching, isFetching, refetch } = useQuery({
    queryKey: ['patchIntegrations'],
    queryFn: () => api.patchIntegrations(),
    enabled: false,
    retry: false,
  });

  if (isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default useIntegrations;
