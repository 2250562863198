import ReactGA4 from 'react-ga4';
import { SCREEN_PATHS, ScreenTypes } from '@/types/navigationPaths';
import { localeDetect } from '@/helpers/LanguageHelper';
import { Platform } from './GetActivePlatform';

interface AnalyticsEvent {
  screen_name?: string;
  screen_class?: string;
  user_id?: string;
  location_status?: string;
  program?: string;
  submit_status?: string;
  submit_value?: string;
  referral_type?: string;
  click_type?: string;
  click_description?: string;
  language?: string;
  platform?: string;
  site_id?: string;
  user_event_id?: string;
  active_platform?: Platform;
}

// TrackingCode is GA Measurement ID
export const initGA = (trackingCode?: string) => {
  if (typeof window !== 'undefined' && trackingCode) {
    ReactGA4.initialize([
      {
        trackingId: trackingCode,
      },
    ]);
  }
};
export const setUserId = (userId: string) => {
  ReactGA4.set({ userId });
};

interface UserProps {
  language: ReturnType<typeof localeDetect>;
  platform: string;
  program: string;
}
export const setUserProperties = (props: UserProps) => {
  ReactGA4.gtag('set', 'user_properties', props);
};

export const logPageView = () => {
  if (typeof window !== 'undefined') {
    ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  }
};

export const logEvent = (action: string, category: string, label?: string, value?: number) => {
  ReactGA4.event({ action, category, label, value });
};

export const sentEvent = (eventName: string, eventParameter?: AnalyticsEvent) => {
  ReactGA4.event(eventName, eventParameter);
};

export const getPathKeyByPathname = (value: string, program?: string) => {
  const screenPaths = SCREEN_PATHS as ScreenTypes;

  const screenName = Object.keys(screenPaths).find((key) => screenPaths[key].pathname === value);
  if (!screenName) {
    return screenPaths.SCREEN_NOT_FOUND.gaparam;
  }
  if (screenPaths[screenName].pathname === screenPaths.SCREEN_ENROLLMENT_VERIFICATION.pathname) {
    return { ...screenPaths[screenName].gaparam, program };
  }
  return screenPaths[screenName].gaparam;
};

export const isUtilityEventTaggingEnabled = (): boolean => {
  return false;
};

export default { initGA, logEvent, logPageView, getPathKeyByPathname, isUtilityEventTaggingEnabled };
