import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const styleButton = {
  padding: '12px 16px',
  maxWidth: '200px',
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
`;

export const PopupHeaderContainer = styled.div``;
export const PopupTitle = styled.p`
  ${typography.xl1}
  line-height: 32px;
  font-weight: 900;
  color: ${colors.text};
`;

export const PopupButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
`;

export const InputContainer = styled.div``;
