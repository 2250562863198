import React from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import { PlotData } from 'plotly.js';
import Legend from '@/molecules/Legend';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import '@/assets/styles/react-plotly.css';
import { LinkButton } from '@/designSystem/components';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { detailViewTextsByUnit } from '@/helpers/Edition';
import { getFormattedDetailData, setDetailData } from '@/helpers/HistoryDetailData';
import { IDDRDetailViewViewProps } from './index.types';
import {
  Wrapper,
  Title,
  GraphContainer,
  LegendContainer,
  ChartContainer,
  GraphDescriptionContainer,
} from './index.styles';

export interface PlotProps {
  data: Partial<Plotly.Data>[];
  layout: Partial<Plotly.Layout>;
}

const DRDetailViewView = ({ items, unit }: IDDRDetailViewViewProps) => {
  const { t, i18n } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const detailData = setDetailData({ data: items, unit });
  const formattedData = getFormattedDetailData(detailData);

  const textData = detailViewTextsByUnit[unit];

  const typicalColor = textData.typical.color;
  const beforeAfterEventColor = textData.beforeAndAfter.color;
  const energyEventColor = textData.event.color;

  const data: PlotProps['data'] = [
    {
      name: t(textData.typical.text),
      type: 'bar',
      x: formattedData.typical?.map(({ time }) => time) || [],
      y: formattedData.typical?.map(({ value }) => value) || [],
      hovertemplate: `%{y:.2f}${textData.unitText}`,
      marker: {
        color: typicalColor,
      },
    },
    {
      name: t(textData.event.text),
      type: 'bar',
      x: formattedData.user?.map(({ time }) => time) || [],
      y: formattedData.user?.map(({ value }) => value) ?? [],
      hovertemplate: `%{y:.2f}${textData.unitText}`,
      marker: {
        color:
          formattedData.user?.map(({ inRequestPeriod }) =>
            inRequestPeriod ? energyEventColor : beforeAfterEventColor,
          ) || [],
      },
    },
  ] as Plotly.Data[];

  const layout = {
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    dragmode: false,
    margin: {
      l: 20,
      r: 20,
      b: 45,
      t: 15,
      pad: 10,
    },
    legend: {
      x: 0.4,
      y: -0.5,
    },
    yaxis: {
      fixedrange: true,
      showticklabels: true,
      showgrid: false,
      zeroline: false,
      tickformat: '.2f',
      tickfont: {
        color: 'white', // color white to hide the tick labels
      },
    },
    showlegend: false,
    bargroupgap: 0.1,
  } as Partial<Plotly.Layout>;
  const config = { displayModeBar: false, responsive: true };
  const chartStyle = { width: '100%', height: 280 };

  const graphDescriptionTableHTML = htmlTableRenderer(
    t(textData.title),
    [t(textData.typical.text), t(textData.event.text)],
    data as PlotData[],
    textData.unitText,
    i18n.language.toLocaleLowerCase(),
    true,
  );

  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);
  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const noData = !formattedData?.typical?.length && !formattedData?.user?.length;

  return (
    <Wrapper>
      {noData ? (
        <Title
          dangerouslySetInnerHTML={{
            __html: t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.titleNoData'),
          }}
        />
      ) : (
        <>
          <ChartContainer>
            <>
              <GraphContainer>
                <Plotly data={data} layout={layout} config={config} style={chartStyle} />
              </GraphContainer>
              <GraphDescriptionContainer>
                <LinkButton
                  onClick={handleGraphDescription}
                  title={t('common.generalButtons.graphDescription')}
                  underline={false}
                  ariaLabel={t('common.generalButtons.graphDescription')}
                  role="link"
                  padding="12px 6px"
                  fontSize={isMobileWindowSize ? '17px' : '15px'}
                />
              </GraphDescriptionContainer>
            </>
          </ChartContainer>
          <LegendContainer>
            <Legend dotColor={typicalColor} title={t(textData.typical.text)} />
            <Legend dotColor={beforeAfterEventColor} title={t(textData.beforeAndAfter.text)} />
            <Legend dotColor={energyEventColor} title={t(textData.event.text)} />
          </LegendContainer>
        </>
      )}
    </Wrapper>
  );
};

export default DRDetailViewView;
