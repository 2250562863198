import { take, put, call, all, fork, delay } from 'redux-saga/effects';
import * as apiEnrollment from '@/apis/enrollment';
import * as apiAuth from '@/apis/auth';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { ReqPhoneOtpRequest, ReqPhoneOtpVerification } from '@/apis/enrollment/types';
import { LOADED } from '@/stores/loading';
import {
  CHANGE_PHONE_OTP_INTERVAL,
  CHANGE_PHONE_OTP,
  PHONE_OTP_REQUEST,
  PHONE_OTP_SUCCESS,
  PHONE_OTP_FAILURE,
  PHONE_OTP_VERIFICATION_REQUEST,
  PHONE_OTP_VERIFICATION_SUCCESS,
  PHONE_OTP_VERIFICATION_FAILURE,
  PHONE_OTP_RESEND_REQUEST,
  PHONE_OTP_RESEND_SUCCESS,
  PHONE_OTP_RESEND_FAILURE,
} from '@/stores/phoneOtp';
import { UPDATE_USER_INFO_FAILURE, UPDATE_USER_INFO_SUCCESS } from '@/stores/userInfo';
import { OTPStatus } from '@/types/phoneOtp';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* changePhoneOtpInterval(status: OTPStatus) {
  yield put({ type: CHANGE_PHONE_OTP, status: OTPStatus.none });
  yield delay(1000);

  yield put({ type: CHANGE_PHONE_OTP, status });
}

function* phoneOtpRequest(payload: ReqPhoneOtpRequest) {
  try {
    const response: IResponse = yield call(apiEnrollment.postPhoneOtpRequest, payload);
    if (!response?.success) {
      yield put({ type: PHONE_OTP_FAILURE, error: response.error?.message || '' });
      return;
    }

    // return One Time Passcode sent to user via SMS

    yield put({ type: PHONE_OTP_SUCCESS });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: PHONE_OTP_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* phoneOtpVerification(currentPhoneNumbers: Array<string>, payload: ReqPhoneOtpVerification) {
  try {
    yield put({ type: CHANGE_PHONE_OTP, status: OTPStatus.none });

    const response: IResponse = yield call(apiEnrollment.postPhoneOtpVerification, payload);
    if (!response?.success) {
      yield put({ type: PHONE_OTP_VERIFICATION_FAILURE, error: response.error?.message || '' });
      return;
    }
    yield delay(300);

    const { data } = response;

    if (data.verified) {
      const userInfoPayload: ReqUpdateUserInfo = {
        phoneNumbers: currentPhoneNumbers,
      };

      const response: IResponse = yield call(apiAuth.updateUserInfo, userInfoPayload);
      if (!response?.success) {
        yield put({ type: UPDATE_USER_INFO_FAILURE, error: response.error?.message || '' });
        return;
      }

      const success = response;
      if (success) {
        yield put({
          type: UPDATE_USER_INFO_SUCCESS,
          userInfo: { phoneNumbers: currentPhoneNumbers },
        });
        yield put({ type: PHONE_OTP_VERIFICATION_SUCCESS });
      } else {
        yield put({ type: PHONE_OTP_VERIFICATION_FAILURE });
      }
    } else {
      yield put({ type: PHONE_OTP_VERIFICATION_FAILURE });
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: PHONE_OTP_VERIFICATION_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* phoneOtpResend(payload: ReqPhoneOtpRequest) {
  try {
    const response: IResponse = yield call(apiEnrollment.postPhoneOtpRequest, payload);
    if (!response?.success) {
      yield put({ type: PHONE_OTP_RESEND_FAILURE, error: response.error?.message || '' });
      return;
    }
    yield put({ type: CHANGE_PHONE_OTP, status: OTPStatus.none });

    yield delay(300);

    // return One Time Passcode sent to user via SMS

    yield put({ type: PHONE_OTP_RESEND_SUCCESS });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: PHONE_OTP_RESEND_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchChangePhoneOtpInterval() {
  while (true) {
    const { status } = yield take(CHANGE_PHONE_OTP_INTERVAL);
    yield fork(changePhoneOtpInterval, status);
  }
}

export function* watchPhoneOtpRequest() {
  while (true) {
    const { payload } = yield take(PHONE_OTP_REQUEST);
    yield fork(phoneOtpRequest, payload);
  }
}

export function* watchPhoneOtpVerification() {
  while (true) {
    const { currentPhoneNumbers, payload } = yield take(PHONE_OTP_VERIFICATION_REQUEST);
    yield fork(phoneOtpVerification, currentPhoneNumbers, payload);
  }
}

export function* watchPhoneOtpResend() {
  while (true) {
    const { payload } = yield take(PHONE_OTP_RESEND_REQUEST);
    yield fork(phoneOtpResend, payload);
  }
}

export default function* phoneOTPSaga() {
  yield all([
    fork(watchChangePhoneOtpInterval),
    fork(watchPhoneOtpRequest),
    fork(watchPhoneOtpVerification),
    fork(watchPhoneOtpResend),
  ]);
}
