// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReqVerify } from '@/apis/enrollment/types';
import useEnrollment from '@/hooks/useEnrollment';
import { moveNoThanksScreen, nextScreenStep } from '@/helpers/NavigationHelper';
import { isRange } from '@/helpers/EnrollmentHelper';
import { RootState } from '@/stores/index';
import { ProgramName, VerifyResponse } from '@/types/enrollment';
import PATHS from '@/types/navigationPaths';
import VerificationScreenView from '@/screens/Enrollment/VerificationScreen/index.view';
import { IVerificationField } from '@/organisms/Verification/index.types';
import { sentEvent } from '@/helpers/GAHelper';

const VerificationScreenContainer = () => {
  const {
    enrollment: {
      enrollmentInfo,
      programs,
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  // why is there no errors array here too?
  const [state, setState] = useState({ disabledNext: false });
  const [modal, setModal] = useState({ verification: false });

  const navigate = useNavigate();
  const { fetchChangeProgram, fetchVerify } = useEnrollment();

  const { t, i18n } = useTranslation('common');

  const fields1exist = i18n.exists(`common:programs.${currentProgram.program}.verificationScreen.fields1`);
  const fields2exist = i18n.exists(`common:programs.${currentProgram.program}.verificationScreen.fields2`);
  const fields1 = t<string, IVerificationField[]>(
    `common:programs.${currentProgram.program}.verificationScreen.fields1`,
    { returnObjects: true },
  );
  const fields2 = t<string, IVerificationField[]>(
    `common:programs.${currentProgram.program}.verificationScreen.fields2`,
    { returnObjects: true },
  );
  const fields = [...(fields1 ?? []), ...(fields2 ?? [])];

  useEffect(() => {
    if (fields1exist || fields2exist) {
      validationCheck();
    }
  }, [enrollmentInfo]);

  const validationCheck = () => {
    const disabled =
      fields.length === 0
        ? !isRange(
            enrollmentInfo.verificationAccountNumber.length,
            currentProgram.accountMinLength,
            currentProgram.accountMaxLength,
          ) || enrollmentInfo.verificationName.length < 2
        : fields1.map((f) => !enrollmentInfo[f.name]).includes(true) ||
          (fields2exist && (fields2 ?? []).filter((f) => !!enrollmentInfo[f.name]).length < 2);
    setState({ ...state, disabledNext: disabled });
  };

  const handleNext = () => {
    const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ loading: true });
      window.ReactNativeWebView.postMessage(data);
    }
    setState((state) => ({ ...state, disabledNext: true }));
    const payload: ReqVerify =
      fields.length === 0
        ? {
            program: currentProgram.program,
            zipCode: enrollmentInfo.zipCode,
            lastName: enrollmentInfo.verificationName,
            accountNumber: enrollmentInfo.verificationAccountNumber,
          }
        : {
            program: currentProgram.program,
            zipCode: enrollmentInfo.zipCode,
            ...fields.reduce((prev, curr) => ({ ...prev, [curr.name]: enrollmentInfo[curr.name] }), []),
          };
    fetchVerify(
      payload,
      (data: VerifyResponse) => {
        if (data.status === 'success') {
          sentEvent('account_verification_submitted', {
            submit_status: 'maybe eligible',
          });

          navigate(nextScreen);
          return;
        }

        if (data.status !== 'active_customer') {
          sentEvent('account_verification_submitted', {
            submit_status: 'not eligible',
          });
          navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
          return;
        }

        if (data.serviceAccounts) {
          const isEligible = data.serviceAccounts.filter((sa) => sa.programs[0].eligible === true).length > 0;
          if (payload.program === ProgramName.PGE_ELRP_A6 && !isEligible) {
            sentEvent('account_verification_submitted', {
              submit_status: 'not eligible',
            });
            navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
            return;
          }
        }

        sentEvent('account_verification_submitted', {
          submit_status: 'eligible',
        });
        navigate(nextScreen);
      },
      () => {
        sentEvent('account_verification_submitted', {
          submit_status: 'system error',
        });
        navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
      },
    );
  };

  const handleNoThanks = () => {
    setModal({ ...modal, verification: true });
  };

  const handleModalVerificationClose = () => {
    setModal({ ...modal, verification: false });
  };

  const handleModalVerificationCancel = () => {
    const nextScreen = moveNoThanksScreen(currentProgram.screenStep, programs, fetchChangeProgram);
    navigate(nextScreen);
  };

  return (
    <VerificationScreenView
      enrollmentProgram={currentProgram}
      disabledNext={state.disabledNext}
      onNext={handleNext}
      onNoThanks={handleNoThanks}
      modalVerification={modal.verification}
      modalVerificationClose={handleModalVerificationClose}
      modalVerificationOk={handleModalVerificationClose}
      modalVerificationCancel={handleModalVerificationCancel}
      utility={enrollmentInfo.utility}
    />
  );
};

export default VerificationScreenContainer;
