import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export interface IStyleItemProps {
  underline?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: ${colors.white};
  border-radius: 8px;
  gap: 15px;
  padding: 15px;
  ${media.lg`
    margin-top: 20px;
    padding: 20px;
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`;

export const PerformanceTitle = styled.p`
  ${typography.xl};
  color: ${colors.text};
  line-height: 24px;
  font-weight: 700;
`;

export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: ${colors.white};
`;

export const Grid = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: space-evenly;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  background-color: ${colors.background};
  border-radius: 8px;
  flex: 1;
  padding: 11px 12px;
  gap: 10px;

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `}
`;

export const ReductionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.white};
  padding: 20px;
  flex: 1;

  ${media.md`
    grid-area: 1;
  `}
  ${media.xxl`
    padding: 20px 60px 20px 20px;
  `}
`;

export const EarningsRow = styled.div`
  display: flex;
  flex-direction: column; /* Change flex-direction to column */
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: ${colors.white};
  padding: 20px;
  flex: 1;

  ${media.md`
    grid-area: 2 / 1;
  `}
  ${media.xxl`
    padding: 20px 60px 20px 20px;
  `}

  /* Flex container for Label and UsageGroup */
  .label-usage-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
  }
`;

export const ImpactGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 20px 40px;
  gap: 20px;

  ${media.md`
    grid-area: 1 / 2 / 3 / 2;
  `}
`;

export const Label = styled.div`
  ${typography.md}
  line-height: 24px;
  color: ${colors.text};
  font-weight: 700;
  flex: 1 1 25px;
`;

export const ImpactLabel = styled.div`
  ${typography.md};
  line-height: 24px;
  color: ${colors.text};
  font-weight: 700;
`;

export const ImpactLabelGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const EnergyImpactLabelWrapper = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const EnergyImpactLabel = styled.div`
  ${typography.xs};
  color: ${colors.text};
  margin-left: 10px;
`;

export const UsageGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 5px;
`;

export const ImpactLine = styled.div`
  border-top: 1px solid ${colors.lightGrey};
`;

export const Description = styled.p`
  margin-top: 20px;
  ${typography.xxs};
  color: ${colors.grey};
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;
