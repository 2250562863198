import React, { useEffect, useRef, useState } from 'react';
import { useEarningsHistory, useEvents, useEventsHistory, useHistoryScreen, useSites } from '@/hooks/index';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { PerformanceToDateEnum } from '@/types/dr';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import SeasonSelector from '@/organisms/SeasonSelector/seasonSelector';
import Tabs, { TabType } from '@/molecules/Tabs';
import EventList from '@/organisms/EventList';
import MoneyEarnedList from '@/organisms/MoneyEarnedList';
import { useTranslation } from 'react-i18next';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { EventsSummary } from '@/organisms/EventsSummary';
import { MoneyEarnedSummary } from '@/organisms/MoneyEarnedSummary';
import ModalReward from '@/organisms/ModalReward';
import { GBCStatus } from '@/types/userInfo';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { keepScreenStateFlag } from '@/helpers/NavigationHelper';
import { billCreditPrograms, moneyEarnedDisclaimer } from '@/helpers/MoneyEarnedDisclaimer';
import { EmissionsImpactList } from '@/organisms/EmissionsImpactList';
import { suffixForCO2Languages } from '@/helpers/Edition';
import { Wrapper, Header, BodyContainer, Section, UsageGroup } from './MobileEventsScreen.styles';
import { setInitialEventHistoryYear } from '../SavingsEventsScreen';

const MobileEventsScreen = () => {
  const {
    dr: {
      historyScreen: { selectedTab },
      history: {
        events: {
          data: { seasons: eventsSeasons },
        },
        earnings: {
          data: { seasons: earningsSeasons },
        },
      },
    },
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const { fetchSites } = useSites();
  const { fetchDr } = useEvents();
  const { fetchEventsHistoryRequest, fetchEventHistorySelectReset } = useEventsHistory();
  const { fetchEarningsHistoryRequest, fetchEarningsHistorySelectReset } = useEarningsHistory();
  const { fetchTabSelect } = useHistoryScreen();

  const performanceToDateRef = useRef<HTMLDivElement>(null);

  const unfilteredSeasonYears = [...Object.keys(eventsSeasons), ...Object.keys(earningsSeasons)].sort();
  const seasonYears = unfilteredSeasonYears.filter((year, index) => unfilteredSeasonYears.indexOf(year) === index);
  const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(0);
  const selectedEventsSeason = Object.values(eventsSeasons)[selectedSeasonIndex];
  const selectedSeasonYear = seasonYears[selectedSeasonIndex] ?? new Date().getFullYear();

  const isLastSeasonSelected = selectedSeasonYear === seasonYears[0];
  const isCurrentSeasonSelected = selectedSeasonYear === seasonYears[seasonYears.length - 1];

  const selectedEarningsSeason = earningsSeasons[selectedSeasonYear];

  const programName = getProgramNameFromSite(currentSite);
  const hasEvents = selectedEventsSeason?.history?.length > 0;
  const moneyEarnedInProgram = currentSite?.program?.attributes?.moneyEarnedInProgram;

  const context = getProgramContext({ programName });
  const shouldDisplayMoneyEarned = context?.getShouldDisplayMoneyEarned(hasEvents);
  const shouldDisplayCO2FeatureChanges = context?.getShouldDisplayCO2Emissions();
  const moneyEarnedPerEvent = currentSite?.program?.attributes?.moneyEarnedPerEvent;

  const { disclaimerNamespace, symbol } = moneyEarnedDisclaimer({
    moneyEarnedPerEvent,
    summaryState: selectedEarningsSeason?.summary?.summaryState,
    isSceWithEvents: billCreditPrograms.includes(programName) && hasEvents,
  });

  const moneyEarnedDisclaimerText = t(disclaimerNamespace);

  useEffect(() => {
    setInitialEventHistoryYear(seasonYears, setSelectedSeasonIndex);
  }, [seasonYears.length]);

  const handleSeasonChange = (value: number | ((prevVar: number) => number)) => {
    setSelectedSeasonIndex(value);
    fetchEventHistorySelectReset();
    fetchEarningsHistorySelectReset();
  };

  const handleTab = (link: string) => {
    sentEvent('click', PATHS.TAB_LINK(link));
    fetchTabSelect(link as PerformanceToDateEnum);
    if (performanceToDateRef && performanceToDateRef?.current) {
      performanceToDateRef?.current?.focus();
    }
  };
  const [shouldDisplayRewardModal, setShouldDisplayRewardModal] = useState(false);

  const handleHelp = () => {
    sentEvent('view_achievement');
    sentEvent('click', PATHS.HELP_ACHIEVEMENT);
    setShouldDisplayRewardModal(true);
  };

  useEffect(() => {
    if (userInfo.gbcStatus === GBCStatus.completed && !currentSite) {
      fetchSites(userInfo.siteName);
    }
  }, []);

  useEffect(() => {
    if (currentSite) {
      fetchDr(currentSite.id);
      fetchEventsHistoryRequest(currentSite.id);
      fetchEarningsHistoryRequest(currentSite.id);
    }
  }, [currentSite?.id]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const keepScreenState = params.get(keepScreenStateFlag) === 'true';

    if (!keepScreenState) {
      setSelectedSeasonIndex(seasonYears.length - 1);
    }
  }, []);

  const possibleTabs = [
    {
      id: 0,
      title: t(`screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.title${suffixForCO2Languages}`),
      tabName: PerformanceToDateEnum.reduction,
    },
    shouldDisplayCO2FeatureChanges
      ? {
          id: 1,
          title: t('screens.main.savingsEvents.savingsEventsHistoryScreen.emissions.title'),
          tabName: PerformanceToDateEnum.emissions,
          padding: '6px',
        }
      : undefined,
    shouldDisplayMoneyEarned && moneyEarnedInProgram
      ? {
          id: 2,
          title: t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.title'),
          tabName: PerformanceToDateEnum.moneyEarned,
        }
      : undefined,
  ];

  const tabs = possibleTabs.filter((tab) => !!tab) as Array<TabType>;

  return (
    <Wrapper>
      <Header>
        <Tabs fullWidth tabs={tabs} selected={selectedTab} onClick={handleTab} />
      </Header>
      <BodyContainer>
        <SeasonSelector
          selectedSeason={selectedSeasonYear}
          onPressArrowLeft={() => handleSeasonChange(selectedSeasonIndex - 1)}
          onPressArrowRight={() => handleSeasonChange(selectedSeasonIndex + 1)}
          isLastSeasonSelected={isLastSeasonSelected}
          isCurrentSeasonSelected={isCurrentSeasonSelected}
        />
        <Section>
          <UsageGroup>
            {(selectedTab === PerformanceToDateEnum.reduction || selectedTab === PerformanceToDateEnum.emissions) && (
              <EventsSummary
                eventsSummary={selectedEventsSeason?.summary}
                onClickHelp={handleHelp}
                programName={programName}
              />
            )}
            {selectedTab === PerformanceToDateEnum.moneyEarned && (
              <MoneyEarnedSummary
                earningsSummary={selectedEarningsSeason?.summary}
                disclaimer={moneyEarnedDisclaimerText}
                disclaimerSymbol={symbol}
                programName={programName}
              />
            )}
          </UsageGroup>
        </Section>
        {selectedTab === PerformanceToDateEnum.reduction && (
          <EventList eventsHistory={selectedEventsSeason?.history || []} />
        )}
        {selectedTab === PerformanceToDateEnum.emissions && (
          <EmissionsImpactList emissionsHistory={selectedEventsSeason?.history || []} />
        )}
        {selectedTab === PerformanceToDateEnum.moneyEarned && (
          <MoneyEarnedList earningsHistory={selectedEarningsSeason?.history || []} />
        )}
      </BodyContainer>
      <ModalReward show={shouldDisplayRewardModal} onClose={() => setShouldDisplayRewardModal(false)} />
    </Wrapper>
  );
};

export default MobileEventsScreen;
