import { images } from '@/assets/styles';

export const leftButtonImage = {
  active: {
    src: images.arrowLeftGreen.image,
    alt: images.arrowLeftGreen.text,
  },
  disabled: {
    src: images.arrowLeftGreenDisabled.image,
    alt: images.arrowLeftGreenDisabled.text,
  },
};
export const rightButtonImage = {
  active: {
    src: images.arrowRightGreen.image,
    alt: images.arrowRightGreen.text,
  },
  disabled: {
    src: images.arrowRightGreenDisabled.image,
    alt: images.arrowRightGreenDisabled.text,
  },
};
