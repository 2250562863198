import React from 'react';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import IconButton from '@/atoms/IconButton';
import { images } from '@/assets/styles';
import { EventSelectorContainer, EventYear, SelectorArrow } from './eventYearsSelector.styles';

type EventHistorySelectorProps = {
  selectedEvent: string;
  onPressArrowLeft: () => void;
  onPressArrowRight: () => void;
  isLastEventSelected: boolean;
  isCurrentEventSelected: boolean;
};

const EventYearsSelector = ({
  selectedEvent,
  onPressArrowLeft,
  onPressArrowRight,
  isLastEventSelected,
  isCurrentEventSelected,
}: EventHistorySelectorProps) => {
  const { t } = useTranslation('common');

  return (
    <EventSelectorContainer>
      <SelectorArrow>
        {!isLastEventSelected && (
          <IconButton
            role="button"
            ariaLabel={t('screens.main.savingsEvents.savingsEventsHistoryScreen.seasons.previousButton')}
            style={{ height: 30, width: 30 }}
            onClick={() => {
              onPressArrowLeft();
              sentEvent('previous_season', PATHS.PREVIOUS_SEASON);
            }}
            icon={images.arrowLeftGreen}
          />
        )}
      </SelectorArrow>

      <EventYear tabIndex={0} aria-label={`${selectedEvent} season`}>
        {selectedEvent}
      </EventYear>

      <SelectorArrow>
        {!isCurrentEventSelected && (
          <IconButton
            role="button"
            ariaLabel={t('screens.main.savingsEvents.savingsEventsHistoryScreen.seasons.nextButton')}
            style={{ height: 30, width: 30 }}
            onClick={() => {
              onPressArrowRight();
              sentEvent('next_season', PATHS.NEXT_SEASON);
            }}
            icon={images.arrowRightGreen}
          />
        )}
      </SelectorArrow>
    </EventSelectorContainer>
  );
};

export default EventYearsSelector;
