import React, { CSSProperties } from 'react';
import { Button, Title } from './secondaryButton.styles';

type SecondaryButtonProps = {
  title: string;
  onClick: () => void;
  small?: boolean;
  center?: boolean;
  style?: CSSProperties;
};

export const SecondaryButton = ({ title, onClick, small, center, style }: SecondaryButtonProps) => {
  return (
    <Button onClick={onClick} small={small} center={center} style={style}>
      <Title>{title}</Title>
    </Button>
  );
};
