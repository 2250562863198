import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import { APP_ENV } from '@/constants/environment';
import { appEnv } from '@/helpers/CompnayHelper';
import contents from '@/assets/config/appInfo';
import PATHS from '@/types/navigationPaths';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import { LinkButton } from '@/designSystem/components';
import { Wrapper, Container, Description, Copyright, Env, TitleContainer, Title } from './AboutScreen.styles';

const AboutScreenContainer = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  // About - ClimateResponse®
  document.title = `${t('screens.main.settings.aboutScreen.title')} - ${contents.name}`;

  const handleTermsOfService = () => {
    sentEvent('click', PATHS.LINK_TERMS_OF_SERVICE);
    window.open(t('screens.main.settings.aboutScreen.terms.link'), '_blank');
  };

  const handlePrivacyPolicy = () => {
    sentEvent('click', PATHS.LINK_PRIVACY_POLICY);
    window.open(t('screens.main.settings.aboutScreen.privacy.link'), '_blank');
  };

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'About',
      screen_class: 'about',
    });
  }, []);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t('screens.main.settings.aboutScreen.title')}</Title>
      </TitleContainer>
      <Wrapper>
        <Container>
          <Description>{t('screens.main.settings.aboutScreen.description')}</Description>
          {APP_ENV !== 'production' && <Env>{appEnv()}</Env>}
          <LinkButton
            onClick={handleTermsOfService}
            title={t('screens.main.settings.aboutScreen.terms.title')}
            padding="12px 0"
            underline
          />
          <LinkButton
            onClick={handlePrivacyPolicy}
            title={t('screens.main.settings.aboutScreen.privacy.title')}
            padding="12px 0"
            underline
          />
          <Copyright>{t('screens.main.settings.aboutScreen.copyright') + new Date().getFullYear()}</Copyright>
        </Container>
      </Wrapper>
    </SettingTemplate>
  );
};

export default AboutScreenContainer;
