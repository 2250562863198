import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import Button from '@/atoms/Button';
import { theme, images } from '@/assets/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useAuth } from '@/hooks/index';
import { IErrorProps } from './index.types';
import { Wrapper, Container, Logo, Title, Description } from './index.styles';

const ErrorView = ({ errorCode, isRedirectError = false }: IErrorProps) => {
  const {
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { fetchLogout } = useAuth();
  const errorDescription =
    errorCode === '500' || errorCode === '403' ? t(`screens.errorScreen.${errorCode}.description`) : null;

  const description = errorDescription ?? t('screens.errorScreen.data.description');

  const goBack = () => navigate(-1);

  useEffect(() => {
    if (isRedirectError) {
      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ to: 'logout' });
        window.ReactNativeWebView.postMessage(data);
        return;
      }
      fetchLogout(userInfo.oidcIdToken, userInfo.id);
    }
  }, []);

  return (
    <Wrapper>
      <Container>
        <Logo>
          <Img src={images.warning} />
        </Logo>
        <Title>{t('screens.errorScreen.data.title')}</Title>
        <Description>{description || t('screens.errorScreen.data.description')}</Description>
        {isRedirectError && (
          <Button
            label={t(`screens.errorScreen.${errorCode}.goBack`)}
            borderRadius={100}
            backgroundColor={theme.colors.primary[100]}
            color={theme.colors.text[0]}
            fontSize={theme.typography.button.fontSize}
            width="150px"
            marginRight={20}
            marginTop={20}
            height={40}
            onClick={goBack}
          />
        )}
      </Container>
    </Wrapper>
  );
};

export default ErrorView;
