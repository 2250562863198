import React from 'react';
import EnvironmentalImpact from '@/organisms/EnvironmentalImpact';
import { Wrapper } from './MobileEnvironmentalImpact.styles';

const MobileEnvironmentalImpact = () => {
  return (
    <Wrapper>
      <EnvironmentalImpact ariaControls="environment-impact-help" />
    </Wrapper>
  );
};

export default MobileEnvironmentalImpact;
