import React, { useState } from 'react';
import { ReqEventParticipate } from '@/apis/primary/types';
import { OptInModeEnum, OptStateEnum, UserStatusEnum } from '@/types/dr';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useTranslation } from 'react-i18next';
import ModalNewEvent from '@/organisms/ModalNewEvent';
import ModalOptOut from '@/organisms/ModalOptOut';
import { FillPrimary, LinkButton, Stroke } from '@/designSystem/components/buttons';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { useEvents } from '@/hooks/index';
import { Description, styleButton, styleLinkButton, Wrapper } from './DRParticipationControl.styles';
import { IDRParticipationControlProps } from './DRParticipationControl.types';

const DRParticipationControl = ({
  currentSiteId,
  userStatus,
  userEventId,
  startDate,
  endDate,
  hasBeenNotified,
  eventPopupClosed,
  optInMode,
  programName,
}: IDRParticipationControlProps) => {
  const { t } = useTranslation('common');
  const [modal, setModal] = useState({ optOut: false });
  const { fetchEventParticipate } = useEvents();
  const isFirst = userStatus === UserStatusEnum.DefaultEvent;
  const accept = userStatus === UserStatusEnum.EventConfirmed;
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const handleClose = () => {
    eventPopupClosed();
  };

  const handleAccept = () => {
    handleClose();

    sentEvent('click', PATHS.EVENT_PARTICIPATION_ACCEPT(userEventId || ''));

    const payload: ReqEventParticipate = {
      userStatus: UserStatusEnum.EventConfirmed,
      userEventId,
      optState: OptStateEnum.optIn,
    };

    fetchEventParticipate(currentSiteId || '', payload);
  };

  const handleDecline = () => {
    handleClose();
    setTimeout(() => {
      setModal({ ...modal, optOut: true });
    }, 1300);
  };

  const handleModalOutOK = (answer: string, other: string) => {
    setModal({ ...modal, optOut: false });

    const payload: ReqEventParticipate = {
      userStatus: UserStatusEnum.OptOut,
      userEventId,
      optState: OptStateEnum.optOut,
      optOutReason: answer,
      optOutReasonOther: other,
    };

    sentEvent('click', PATHS.EVENT_PARTICIPATION_DECLINE(userEventId || ''));

    fetchEventParticipate(currentSiteId || '', payload);
  };

  const affirmativeButtonRender = () => {
    return (
      <Wrapper>
        <FillPrimary
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.accept`)}
          onClick={handleAccept}
          padding={styleButton.padding}
          maxWidth={isMobileWindowSize ? 'unset' : styleButton.maxWidth}
        />
        <Stroke
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.decline`)}
          onClick={handleDecline}
          padding={styleButton.padding}
          maxWidth={isMobileWindowSize ? 'unset' : styleButton.maxWidth}
          flex={styleButton.flex}
        />
      </Wrapper>
    );
  };

  const presumptiveButtonRender = () => {
    return (
      <Wrapper>
        <FillPrimary
          title={t('common.generalButtons.ok')}
          onClick={handleAccept}
          padding={styleButton.padding}
          maxWidth={isMobileWindowSize ? 'unset' : styleButton.maxWidth}
        />
        <Stroke
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.decline`)}
          onClick={handleDecline}
          padding={styleButton.padding}
          maxWidth={isMobileWindowSize ? 'unset' : styleButton.maxWidth}
          flex={styleButton.flex}
        />
      </Wrapper>
    );
  };

  const renderRequestEvent = () => {
    return (
      <Wrapper>
        {accept ? (
          <Description>
            {t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.decline.sentence1`)}
            <LinkButton
              removeFlex
              title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.decline.sentence2`)}
              onClick={handleDecline}
              padding={styleLinkButton.padding}
            />
            {t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.decline.sentence3`)}
          </Description>
        ) : (
          <Description>
            {t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.accept.sentence1`)}
            <LinkButton
              removeFlex
              title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.accept.sentence2`)}
              onClick={handleAccept}
              padding={styleLinkButton.padding}
            />
            {t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.accept.sentence3`)}
          </Description>
        )}
      </Wrapper>
    );
  };

  return (
    <>
      {isFirst && optInMode === OptInModeEnum.affirmative && affirmativeButtonRender()}
      {isFirst && optInMode === OptInModeEnum.presumptive && presumptiveButtonRender()}
      {!isFirst && optInMode !== OptInModeEnum.confirmationOnly && renderRequestEvent()}
      <ModalNewEvent
        show={!hasBeenNotified}
        onClose={handleClose}
        startDate={startDate ? new Date(startDate) : undefined}
        endDate={endDate ? new Date(endDate) : undefined}
        onDecline={handleDecline}
        onAccept={handleAccept}
        optInMode={optInMode}
      />
      <ModalOptOut
        show={modal.optOut}
        onClose={() => setModal({ ...modal, optOut: false })}
        onOk={handleModalOutOK}
        onCancel={() => setModal({ ...modal, optOut: false })}
        programName={programName}
      />
    </>
  );
};

export default DRParticipationControl;
