// Types
//

import { PerformanceToDateEnum } from '@/types/dr';

// Reset
export const RESET_HISTORY_SCREEN_STATE = 'dr/RESET_HISTORY_SCREEN_STATE';

// Select History Tab
export const TAB_SELECT = 'dr/TAB_SELECT';

interface ResetHistoryScreenStateAction {
  type: typeof RESET_HISTORY_SCREEN_STATE;
}
interface TabSelectAction {
  type: typeof TAB_SELECT;
  tab: PerformanceToDateEnum;
}

type InitActionTypes = ResetHistoryScreenStateAction | TabSelectAction;

// initial state
//
export interface HistoryScreenState {
  selectedTab: PerformanceToDateEnum;
}

const initialState: HistoryScreenState = {
  selectedTab: PerformanceToDateEnum.reduction,
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: HistoryScreenState = initialState, action: InitActionTypes): HistoryScreenState => {
  switch (action.type) {
    case RESET_HISTORY_SCREEN_STATE:
      return {
        ...initialState,
      };
    case TAB_SELECT:
      return {
        ...state,
        selectedTab: action.tab,
      };

    default:
      return state;
  }
};

// Actions
//

export const tabSelect = (tab: PerformanceToDateEnum) => ({ type: TAB_SELECT, tab });
