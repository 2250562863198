import { DrHistoryItem, EventSummary, Events } from '@/types/dr';
import { AchievementLevel } from '@/types/dashboard';
import { seasonsUpdateEvents } from '@/helpers/SeasonsUpdateEvents';

// Types
//
// Reset
export const RESET_EVENTS_HISTORY = 'dr/eventsHistory/RESET_EVENTS_HISTORY';

// Request Events
export const EVENTS_HISTORY_REQUEST = 'dr/eventsHistory/EVENTS_REQUEST';
export const EVENTS_HISTORY_SUCCESS = 'dr/eventsHistory/EVENTS_SUCCESS';
export const EVENTS_HISTORY_FAILURE = 'dr/eventsHistory/EVENTS_FAILURE';

// Select Event
export const EVENT_HISTORY_SELECT_REQUEST = 'dr/eventsHistory/EVENT_SELECT_REQUEST';
export const EVENT_HISTORY_SELECT_SUCCESS = 'dr/eventsHistory/EVENT_SELECT_SUCCESS';
export const EVENT_HISTORY_SELECT_FAILURE = 'dr/eventsHistory/EVENT_SELECT_FAILURE';
export const EVENT_HISTORY_SELECT_RESET = 'dr/eventsHistory/EVENT_SELECT_RESET';

interface ResetEventsHistoryAction {
  type: typeof RESET_EVENTS_HISTORY;
}

interface EventsHistoryRequestAction {
  type: typeof EVENTS_HISTORY_REQUEST;
}

interface EventsHistorySuccessAction {
  type: typeof EVENTS_HISTORY_SUCCESS;
  events: Events;
}

interface EventsHistoryFailureAction {
  type: typeof EVENTS_HISTORY_FAILURE;
  errorMessage: string;
}

interface EventHistorySelectRequestAction {
  type: typeof EVENT_HISTORY_SELECT_REQUEST;
  siteId: string;
  event: DrHistoryItem;
}

interface EventHistorySelectSuccessAction {
  type: typeof EVENT_HISTORY_SELECT_SUCCESS;
  event: DrHistoryItem;
}

interface EventHistorySelectFailureAction {
  type: typeof EVENT_HISTORY_SELECT_FAILURE;
  errorMessage: string;
}

interface EventHistorySelectResetAction {
  type: typeof EVENT_HISTORY_SELECT_RESET;
}

type InitActionTypes =
  | ResetEventsHistoryAction
  | EventsHistoryRequestAction
  | EventsHistorySuccessAction
  | EventsHistoryFailureAction
  | EventHistorySelectRequestAction
  | EventHistorySelectSuccessAction
  | EventHistorySelectFailureAction
  | EventHistorySelectResetAction;

// initial state
//
interface EventsSeason {
  summary: EventSummary;
  history: Array<DrHistoryItem>;
}

export interface EventsHistorySeasons {
  [year: string]: EventsSeason;
}
export interface EventsHistoryState {
  data: {
    seasons: EventsHistorySeasons;
    currentSeason: EventsSeason;
    selectedEvent?: DrHistoryItem;
  };
  isLoading: boolean;
  isSelectingEvent: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const eventsHistoryInitialState = {
  seasons: {},
  currentSeason: {
    summary: {
      level: AchievementLevel.noAct,
      electricitySaved: 0,
      moneyEarned: 0,
      numRequestsTotal: 0,
      emissionImpact: 0,
    },
    history: [],
  },
  selectedEvent: undefined,
};

const initialState: EventsHistoryState = {
  data: eventsHistoryInitialState,
  isLoading: false,
  isSelectingEvent: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: EventsHistoryState = initialState, action: InitActionTypes): EventsHistoryState => {
  switch (action.type) {
    case RESET_EVENTS_HISTORY:
      return {
        ...initialState,
      };

    case EVENTS_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EVENTS_HISTORY_SUCCESS: {
      const seasons = seasonsUpdateEvents(state.data.seasons, action.events);
      const seasonsYears = Object.keys(seasons);
      const currentSeasonYear = seasonsYears[seasonsYears.length - 1];
      return {
        ...state,
        data: {
          seasons,
          currentSeason: action?.events[currentSeasonYear],
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };
    }

    case EVENTS_HISTORY_FAILURE:
      return {
        ...state,
        data: eventsHistoryInitialState,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case EVENT_HISTORY_SELECT_REQUEST:
      return {
        ...state,
        isSelectingEvent: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EVENT_HISTORY_SELECT_SUCCESS:
      return {
        ...state,
        data: { ...state.data, selectedEvent: action.event },
        isSelectingEvent: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case EVENT_HISTORY_SELECT_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          selectedEvent: undefined,
        },
        isSelectingEvent: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case EVENT_HISTORY_SELECT_RESET:
      return {
        ...state,
        data: {
          ...state.data,
          selectedEvent: undefined,
        },
      };

    default:
      return state;
  }
};

// Actions
//
export const eventsHistoryRequest = (siteId: string) => ({ type: EVENTS_HISTORY_REQUEST, siteId });

export const eventHistorySelectRequest = (siteId: string, event: DrHistoryItem) => ({
  type: EVENT_HISTORY_SELECT_REQUEST,
  siteId,
  event,
});

export const eventsHistorySelectReset = () => ({ type: EVENT_HISTORY_SELECT_RESET });
