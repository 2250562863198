import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';

export const Container = styled.div`
  position: relative;
`;

export const ImpactGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 25px;

  ${media.lg`
    padding: 20px;
    gap: 40px;
  `}
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SectionTitleConatiner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  display: none;
  ${typography.md};
  font-weight: 700;
  line-height: normal;
  color: ${colors.text};
  ${media.lg`
    display: block;
  `}
`;

export const SectionDescription = styled.p`
  ${typography.md};
  font-weight: 400;
  line-height: normal;
  color: ${colors.grey};
`;

export const Help = styled.div``;

export const ImpactLine = styled.div`
  border-top: 1px solid ${colors.lightGrey};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 580px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ErrorDescription = styled.p`
  ${typography.sm};
  margin: 18px;
  margin-bottom: 50px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: ${colors.grey};
`;
