import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { nextScreenStep } from '@/helpers/NavigationHelper';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import { Description, Wrapper } from '@/screens/Enrollment/NoThanksScreen/NoThanksScreen.styles';

// No Thanks screen(App Only Screen)
const NoThanksScreen = () => {
  const { t } = useTranslation('common');

  const {
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram, availablePrograms },
    },
  } = useSelector((state: RootState) => state);

  // App Only - ClimateResponse®
  document.title = contents.name;

  const navigate = useNavigate();

  /**
   * If this is the first program related screen the user sees.
   */
  const isDefaultPath = availablePrograms[0].program === currentProgram.program;

  const handleNext = () => {
    const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);

    sentEvent('no_thanks_next');
    navigate(nextScreen);
  };

  return (
    <EnrollmentTemplate
      navigationPath={PATHS.SCREEN_ENROLLMENT_AVAILABLE_PROGRAM_NO_THANKS.pathname}
      onNext={handleNext}
      textNext={t('common.generalButtons.next')}
    >
      {!isDefaultPath ? (
        <Wrapper>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.description')}</Description>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.description2')}</Description>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.description3')}</Description>
        </Wrapper>
      ) : (
        <Wrapper>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.altDescription')}</Description>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.altDescription2')}</Description>
          <Description>{t('screens.enrollment.availableProgramScreenNoThanks.altDescription3')}</Description>
        </Wrapper>
      )}
    </EnrollmentTemplate>
  );
};

export default NoThanksScreen;
