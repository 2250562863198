import { take, put, call, fork, all } from 'redux-saga/effects';
import * as apiPrimary from '@/apis/primary';
import {
  EARNINGS_HISTORY_REQUEST,
  EARNINGS_HISTORY_SUCCESS,
  EARNINGS_HISTORY_FAILURE,
  EARNING_HISTORY_SELECT_REQUEST,
  EARNING_HISTORY_SELECT_SUCCESS,
  EARNING_HISTORY_SELECT_FAILURE,
} from '@/stores/dr/earningsHistory';
import { MoneyEarnedItem } from '@/types/dr';
import { LOADED, LOADING } from '@/stores/loading';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';
import { formatPendingEarnings } from '@/helpers/FormatPendingEarnigns';

function* earnings(siteId: string) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.getDrEarnings, { siteId });

    if (!response.success) {
      yield put({ type: EARNINGS_HISTORY_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;
    const formattedData = formatPendingEarnings(data);

    yield put({ type: EARNINGS_HISTORY_SUCCESS, earnings: formattedData });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EARNINGS_HISTORY_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* earningSelect(siteId: string, earnings: MoneyEarnedItem, name: string) {
  try {
    yield put({ type: LOADING });
    if (earnings.eventId !== '') {
      const response: IResponse = yield call(apiPrimary.getDrEvent, siteId, earnings.eventId);

      if (!response.success) {
        yield put({ type: EARNING_HISTORY_SELECT_FAILURE, errorMessage: response.error?.message });
        return;
      }

      const { data } = response;

      yield put({ type: EARNING_HISTORY_SELECT_SUCCESS, earnings, event: data, name });
      return;
    }

    yield put({ type: EARNING_HISTORY_SELECT_FAILURE, error: 'EARNING_EVENT_ID_NOT_FOUND' });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EARNING_HISTORY_SELECT_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchEarnings() {
  while (true) {
    const { siteId } = yield take(EARNINGS_HISTORY_REQUEST);
    yield fork(earnings, siteId);
  }
}

export function* watchEarningSelect() {
  while (true) {
    const { siteId, earnings, name } = yield take(EARNING_HISTORY_SELECT_REQUEST);
    yield fork(earningSelect, siteId, earnings, name);
  }
}

export default function* earningsHistorySaga() {
  yield all([fork(watchEarnings), fork(watchEarningSelect)]);
}
