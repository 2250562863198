import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { Wrapper } from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import { FillPrimary, LinkButton, SecondaryButton } from '@/designSystem/components/buttons';
import { ProgramName } from '@/types/enrollment';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import { useSwitchSiteEnrollment } from '@/hooks/enrollment/useSwitchSiteEnrollment';
import { ButtonContainer, Buttons, Container, Title } from './SiteEnroll.styles';
import { Description, Logo } from './SiteEnrollFailed.styles';

const SiteEnrollFailed = () => {
  const {
    sites: {
      data: { currentSite, enrollingSiteId },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');

  const supportEmail = t(`programs.CLIMATE_RESPONSE.support.email`);
  const navigate = useNavigate();

  const switchSiteEnrollment = useSwitchSiteEnrollment(
    {
      onSuccess: () => navigate(PATHS.SCREEN_SETTING_SITE_ENROLL_SUCCESS),
      onFail: () => navigate(PATHS.SCREEN_SETTING_SITE_ENROLL_FAILED),
    },
    [navigate],
  );

  const handleMailLink = () => {
    sentEvent('click', PATHS.EMAIL_SUPPORT);
    window.open(`mailto:${supportEmail}`);
  };

  const handleReturnDashboard = () => {
    navigate(PATHS.SCREEN_DASHBOARD.pathname);
  };

  return (
    <Wrapper>
      <Container>
        <Logo>
          <Img src={images.warning} />
        </Logo>
        <Title>{t('screens.errorScreen.enrollmentOfSiteError.title')}</Title>
        <Description>{t('screens.errorScreen.enrollmentOfSiteError.description')}</Description>
        <Buttons>
          <ButtonContainer>
            <SecondaryButton
              title={t('common.generalButtons.tryAgain')}
              onClick={() =>
                switchSiteEnrollment(enrollingSiteId, currentSite?.gbiIntegrationId || '', ProgramName.CLIMATE_RESPONSE)
              }
            />
          </ButtonContainer>
        </Buttons>
        <Description>{t('screens.errorScreen.enrollmentOfSiteError.furtherAction')}</Description>

        <LinkButton title={supportEmail} onClick={handleMailLink} padding="12px 12px" />
        <ButtonContainer style={{ marginTop: '20px' }}>
          <FillPrimary
            title={t('common.generalButtons.returnToDashboard')}
            onClick={handleReturnDashboard}
            padding="15px"
          />
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

export default SiteEnrollFailed;
