import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReqSetting } from '@/apis/auth/types';
import { changeMomentLocale } from '@/helpers/LanguageHelper';
import { useEnrollment, useSetting } from '@/hooks/index';
import { RootState } from '@/stores/index';
import { Language, TempScale } from '@/types/setting';
import { GBCStatus } from '@/types/userInfo';
import i18next from 'i18next';
import { Outlet } from 'react-router-dom';
import AuthTemplateView from './index.view';

const AuthTemplateContainer = () => {
  const {
    userInfo: {
      data: {
        userInfo,
        userInfo: { gbcStatus, zipcode },
      },
    },
    enrollment: { utilityPrograms },
    setting: { data: setting },
    misc: { appLogin },
  } = useSelector((state: RootState) => state);

  const { i18n } = useTranslation();
  const { fetchLanguage, fetchSetting } = useSetting();
  const { fetchProgramRequest } = useEnrollment();

  useEffect(() => {
    if (gbcStatus !== GBCStatus.completed && zipcode && Object.keys(utilityPrograms).length === 0) {
      fetchProgramRequest(zipcode);
    }
  }, [zipcode]);

  const handleChangeLanguage = (language: Language) => {
    if (userInfo.gbcStatus === GBCStatus.none) {
      fetchLanguage(language);
    } else if (setting.language !== language || i18next.language.toLowerCase() !== language.toLowerCase()) {
      // To change language settings on a server while logged in
      const payload: ReqSetting = {
        language,
        tempScale: TempScale.celsius,
      };
      fetchSetting(payload);
    }
    i18n.changeLanguage(language.toLowerCase());
    changeMomentLocale(language);
  };

  return (
    <AuthTemplateView appLogin={appLogin} language={setting.language} onChangeLanguage={handleChangeLanguage}>
      <Outlet />
    </AuthTemplateView>
  );
};

export default AuthTemplateContainer;
