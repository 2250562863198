import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 0 30px;
  ${media.lg`
    padding: 20px 30px 10px 30px;
  `}
`;

export const HeadNavigation = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationTitle = styled.p`
  ${typography.md};
  color: ${colors.text};
  font-weight: 900;
  line-height: 24px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  background-color: ${colors.white};
`;

export const Title = styled.p`
  ${typography.xl1};
  color: ${colors.text};
  font-weight: 900;
  line-height: 29px;
`;

export const Content = styled.div``;

export const Description = styled.p`
  ${typography.sm};
  line-height: 24px;
  margin-bottom: 20px;
  color: ${colors.blackGrey};
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
`;
export const Button = styled.div`
  display: flex;
  flex: 1;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;
