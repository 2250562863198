import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import {
  IStyleButtonsContainerProps,
  IStyleContainerProps,
  IStyleNavigationPath,
  IStyleWrapperProps,
  IStyleImageContainerProps,
  IStyleBody,
} from './index.types';

export const Wrapper = styled.div<IStyleWrapperProps>(
  ({ maxWidth = '400px', margin = '0px' }) => css`
    ${helpers.flexSet('column', 'center', 'center')};

    width: 100%;
    max-width: ${maxWidth};

    margin-bottom: 50px;

    ${media.sm`
      margin-bottom: initial;
    `}

    ${media.xl`
      margin: ${margin};
    `}
  `,
);

export const Container = styled.div<IStyleContainerProps>(
  ({ theme, appLogin, showBoxShadow = true }) => css`
    ${!appLogin &&
    css`
      border-radius: ${theme.borders.default.radius};
      ${showBoxShadow ? `box-shadow: ${theme.shadow.web.boxShadow}` : ''};
    `}
    background: ${theme.shadow.web.backgroundColor};

    width: 100%;
    height: 100%;
  `,
);

export const Logo = styled.div`
  height: 48px;

  > img {
    height: 100%;
  }
`;

export const Header = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};

  position: relative;
  height: 100px;
`;

export const Body = styled.div<IStyleBody>(
  ({ padding }) => css`
    padding: ${padding ?? '10px 30px 50px 30px'};

    ${media.sm`
    padding: ${padding ?? '0 60px 100px 60px'};
  `}
  `,
);

export const Content = styled.div`
  padding: 30px 0;
`;

export const Left = styled.div`
  position: absolute;
  left: 20px;
  top: 60px;
`;

export const Title = styled.p(
  ({ theme }) => css`
    font-size: ${theme.typography.h5.fontSize};
    color: ${theme.colors.text[100]};
    text-align: center;
  `,
);

export const NavigationPath = styled.h1<IStyleNavigationPath>(
  ({ theme, underline }) => css`
    font-size: 24px;
    color: ${theme.colors.primary[75]};
    margin-top: 20px;
    font-weight: 600;

    ${underline &&
    css`
      text-decoration: underline;
      text-decoration-color: ${theme.colors.primary[75]};
      text-underline-offset: 5px;
    `};
  `,
);

export const Footer = styled.div`
  max-width: 400px;
  width: 100%;
  align-self: center;
  margin: 0 auto;
`;

export const ButtonsContainer = styled.div<IStyleButtonsContainerProps>(
  ({ padding = 0 }) => `
  padding: ${padding};
`,
);

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  height: 50px;
`;

export const ImageContainer = styled.div<IStyleImageContainerProps>(
  ({ flex = 0 }) => css`
    flex: ${flex};
    padding: 5px 0 20px;
  `,
);

export const Right = styled.button`
  align-self: flex-end;
  margin-right: 20px;
  margin-top: 50px;
  cursor: pointer;
  display: flex;
  border: 0;
  outline: 0;
  background-color: transparent;
`;

export const Support = styled.p(
  ({ theme }) => css`
    color: ${theme.colors.text[80]};
    padding-left: 10px;
  `,
);
