import React from 'react';
import { Button, Img } from './Stroke.styles';

type StrokeButtonProps = {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  padding?: string;
  image?: {
    active: {
      src: string;
      alt: string;
    };
    disabled: {
      src: string;
      alt: string;
    };
  };
  flex?: number;
  showBorder?: boolean;
  warning?: boolean;
  ariaLabel?: string;
  maxWidth?: string;
};

export const Stroke = ({
  title,
  onClick,
  disabled,
  padding,
  image,
  flex,
  showBorder = true,
  warning = false,
  ariaLabel = title,
  maxWidth,
}: StrokeButtonProps) => {
  return (
    <Button
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
      aria-label={ariaLabel}
      disabled={disabled}
      padding={padding}
      flex={flex}
      showBorder={showBorder}
      warning={warning}
      maxWidth={maxWidth}
    >
      {title}
      {image && (
        <Img
          src={disabled ? image.disabled.src : image.active.src}
          alt={disabled ? image.disabled.alt : image.active.alt}
          draggable={false}
        />
      )}
    </Button>
  );
};
