import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  padding: 0;
`;

export const MessagesWrapper = styled.div`
  ${media.lg`
    background-color: ${colors.white};
    padding: 24px;
    margin-top: 20px;
  `}
  margin-top: 8px;
  border-radius: 8px;
`;

export const Container = styled.div`
  display: flex;
  gap: 10px;
`;

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 680px;
  max-height: calc(100vh - 80px);
  gap: 6px;
  overflow: auto;

  ${media.lg`
    gap: 0;
    max-height: 600px;
    overflow: auto;
    padding-right: 24px;
    
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: ${colors.white};
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(114, 116, 118, 0.5);
      border-radius: 100px;
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  `}
`;
export const Detail = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  ${media.md`
    padding-left: 30px;
    padding-right: 30px;
  `}
`;

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 620px;
  padding: 24px;
`;

export const LogoContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(245, 245, 245, 0.5);
  padding: 26px;
`;

export const Empty = styled.p`
  margin-top: 10px;
  ${typography.sm};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.blackGrey};
`;

export const Description = styled.p`
  margin-top: 5px;
  ${typography.xs};
  font-weight: 400;
  line-height: 24px;
  color: ${colors.blackGrey};
`;
