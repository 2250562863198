import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { IStyleItemProps } from './Achievement.types';

export const Container = styled.div`
  flex: 1;
  ${helpers.flexSet('row', 'space-between', 'flex-end')};
  gap: 8px;
  padding: 0 10px;
`;

export const MoneyValue = styled.p`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 32px;
  text-align: center;
  color: ${colors.accent};
`;

export const MoneyDescription = styled.p`
  ${typography.md};
  font-weight: 400;
  text-align: center;
  color: ${colors.grey};
  line-height: normal;
`;

export const Item = styled.div<IStyleItemProps>(
  ({ theme, underline }) => css`
    flex: 1;
    width: 100%;

    ${helpers.flexSet('column', 'center', 'center')};
    ${underline &&
    helpers.borderSet({
      borderDirection: 'bottom',
      borderWidth: theme.borders.default.width,
      borderColor: theme.colors.border,
    })};
    gap: 15px;
  `,
);

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;

  ${media.lg`
    gap: 20px;
  `}
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const SectionTitle = styled.p`
  ${typography.md};
  font-weight: 700;
  line-height: normal;
  color: ${colors.text};
`;

export const Help = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  ${media.lg`
    display: none;
  `}
`;

export const DescriptionContainer = styled.div`
  border-top: solid ${colors.lightGrey};
  border-top-width: 1.5px;
  padding-top: 10px;
`;

export const Description = styled.p`
  ${typography.xs};
  font-weight: 400;
  line-height: normal;
  color: ${colors.grey};
`;

export const DataOnlyText = styled.p`
${typography.sm};
color: colors.blackGrey,
paddingTop: 8,
`;
