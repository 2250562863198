import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { IStyleButtonProps, IStyleTextProps } from './index.types';

export const Button = styled.button<IStyleButtonProps>(
  ({
    width,
    height,
    maxWidth,
    minWidth,
    backgroundColor,
    transparent,
    borderRadius,
    borderColor,
    borderWidth,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    showLoadingCursor,
  }) => css`
    ${helpers.colorSet({ backgroundColor, transparent })};
    ${helpers.sizeSet({ width, height, maxWidth, minWidth })};
    ${helpers.marginSet({ marginTop, marginRight, marginBottom, marginLeft })};
    ${helpers.paddingSet({ paddingTop, paddingRight, paddingBottom, paddingLeft })};
    ${helpers.borderSet({ borderWidth, borderRadius, borderColor })};
    ${showLoadingCursor ? 'cursor: wait;' : ' cursor: pointer;'}
  `,
);

export const Container = styled.span`
  ${helpers.flexSet('row', 'space-between;', 'center')};
  flex: 1;
`;

export const Text = styled.span<IStyleTextProps>(
  ({ theme, align, color, fontSize, labelWeight = 400 }) => css`
    flex: 1;
    font-weight: ${labelWeight};
    ${helpers.textSet({ fontSize, align, fontFamily: theme.font.type.primary })}
    ${helpers.colorSet({ color })}
  `,
);

export const Left = styled.span`
  margin-right: 10px;
`;

export const Right = styled.span`
  margin-left: 10px;
`;
