import styled from 'styled-components';
import { colors } from '@/designSystem/index';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${colors.lightGrey};
  border-radius: 8px;

  &:hover {
    border: 1px solid ${colors.primary};
  }
`;

export const PhoneText = styled.div`
  display: flex;
  height: 40px;
  justify-content: flex-start;
  padding: 8px 16px;
  border-width: 1px;
  border-color: ${colors.lightGrey};
  border-radius: 8px;
  width: 168px;
  align-items: center;
  font-size: 17px;
  color: ${colors.grey};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 200px;
  padding: 0 16px;
`;
