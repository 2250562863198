import React from 'react';
import { Label } from './statusLabel.styles';

interface StatusLabelProps {
  ariaLabel: string;
  text: string;
  backgroundColor: string;
  color: string;
}

const StatusLabel = ({ ariaLabel, text, backgroundColor, color }: StatusLabelProps) => (
  <Label aria-label={ariaLabel} backgroundColor={backgroundColor} color={color}>
    {text}
  </Label>
);

export default StatusLabel;
