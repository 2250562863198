import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  MessagesWrapper,
  EmptyContainer,
  Empty,
  Container,
  ListItemContainer,
  LogoContainer,
  Description,
} from '@/screens/Main/Messages/MessagesScreen/MessagesScreen.styles';
import useMessage from '@/hooks/useMessage';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import { sentEvent } from '@/helpers/GAHelper';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import MessageItem from './MessageItem/MessageItem';

const MessagesScreen = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.messages.messagesScreen.title')} - ${contents.name}`;
  const {
    userInfo: {
      data: { userInfo },
    },
    messages: {
      data: { messages, selectedMessageId },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const { fetchMessages, fetchMessageSelect } = useMessage();

  const handleClick = (messageId: string) => {
    if (isMobileWindowSize) {
      sentEvent('click', PATHS.SELECT_MESSAGE(messageId));
      navigate(`${PATHS.SCREEN_MESSAGE.pathname}/${messageId}`);
      return;
    }
    if (selectedMessageId === messageId) {
      return;
    }

    sentEvent('click', PATHS.SELECT_MESSAGE(messageId));
    fetchMessageSelect(messageId);
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  return (
    <Wrapper>
      <MessagesWrapper>
        {messages.length === 0 ? (
          <EmptyContainer>
            <LogoContainer>
              <Img src={images.emptyMessages} />
              <Empty>{t('screens.main.messages.messagesScreen.empty')}</Empty>
              <Description>{t('screens.main.messages.messagesScreen.description')}</Description>
            </LogoContainer>
          </EmptyContainer>
        ) : (
          <Container>
            <ListItemContainer>
              {messages.map((message) => (
                <MessageItem
                  key={message.id}
                  message={message}
                  selected={message.id === selectedMessageId}
                  onClick={handleClick}
                />
              ))}
            </ListItemContainer>
          </Container>
        )}
      </MessagesWrapper>
    </Wrapper>
  );
};

export default MessagesScreen;
