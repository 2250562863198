import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { RootState } from '@/stores/index';
import { DrNearestEvent, TargetDirectionEnum, UserStatusEnum } from '@/types/dr';
import { imageResouce } from '@/helpers/ResourceHelper';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import Tabs, { TabButtonType, TabType } from '@/molecules/Tabs';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { IdeaTabType, SavingsIdea } from './EnergySavingIdeas.types';
import {
  Container,
  Description,
  DescriptionContainer,
  Suggest,
  TabContainer,
  Title,
  TitleContainer,
  Wrapper,
} from './EnergySavingIdeas.styles';

const EnergySavingIdeas = () => {
  const {
    sites: {
      data: { currentSite },
    },
    dr: {
      events: {
        data: { nearestDrEvents },
      },
    },
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation('common');
  const [state, setState] = useState<{ tabs: IdeaTabType[]; selectedTab: string; nearestDrEvent: DrNearestEvent }>({
    tabs: [],
    selectedTab: '',
    nearestDrEvent: {
      eventTimeRange: '',
      length: '',
      end: '',
      canceled: false,
      userEventId: '',
      start: '',
      hasBeenNotified: false,
      userStatus: UserStatusEnum.DefaultEvent,
      targetDirection: TargetDirectionEnum.loadIncrease,
    },
  });

  const programName = getProgramNameFromSite(currentSite);

  const handleClick = (value: string) => {
    sentEvent('click', PATHS.SAVING_IDEAS(value));
    setState({ ...state, selectedTab: value });
  };

  const initializeTabs = () => {
    const ideas = t<string, string[]>(`programs.${programName}.savingsEventsScreen.ideas`, {
      returnObjects: true,
    });

    const tabs = ideas.map((key) => {
      const title = key;

      /* How to get image
       *  const title = batter
       *  const imageName = Battery;
       *  const active = imageResouce('applianceBatteryActive');
       */
      const id = nanoid();
      const imageName = `appliance${title.charAt(0).toUpperCase() + title.slice(1)}`;
      const active = imageResouce(`${imageName}Active`);
      const deactive = imageResouce(`${imageName}Deactive`);

      return { id, title, active, deactive };
    });

    setState({ ...state, tabs, selectedTab: tabs[0].title });
  };

  const textSuggest = () => {
    const suggest = state.nearestDrEvent.targetDirection === TargetDirectionEnum.loadDecrease ? 'normal' : 'loadShift';

    return t(
      `screens.main.savingsEvents.savingsEventsScreen.savingIdeas.ideas.${state.selectedTab}.suggest.${suggest}`,
    );
  };

  const textDescription = () => {
    return t(`screens.main.savingsEvents.savingsEventsScreen.savingIdeas.ideas.${state.selectedTab}.description`);
  };

  const titleLabel = (title: string, isSelected: boolean) => {
    if (isSelected) {
      const suggest = textSuggest();
      const description = savingsAccessibilityLabel();
      return `${description}, ${suggest}`;
    }
    return t(`screens.main.savingsEvents.savingsEventsScreen.savingIdeas.ideas.${title}.label`);
  };

  const savingsAccessibilityLabel = () => {
    return t(
      `screens.main.savingsEvents.savingsEventsScreen.savingIdeas.ideas.${state.selectedTab}.accessibilityLabel`,
    );
  };

  const tabItemRender = (): TabType[] => {
    return state.tabs.map(({ id, title, active, deactive }) => {
      return {
        id,
        tabName: title as SavingsIdea,
        image: {
          active: {
            src: active?.image,
            alt: active?.text,
          },
          disabled: {
            src: deactive?.image,
            alt: deactive?.text,
          },
        },
        padding: state.selectedTab === title ? '10px 10px 17px 10px' : '10px 10px 20px 10px',
        ariaLabel: titleLabel(title, state.selectedTab === title),
      };
    });
  };

  useEffect(() => {
    const nearestDrEvent = nearestDrEvents[0];
    setState({
      ...state,
      nearestDrEvent,
    });
  }, [nearestDrEvents]);

  useEffect(() => {
    initializeTabs();
  }, [currentSite?.id]);

  return (
    <Wrapper>
      <TitleContainer>
        <Title>{t(`screens.main.savingsEvents.savingsEventsScreen.savingIdeas.title`)}</Title>
      </TitleContainer>
      <Container>
        <TabContainer>
          <Tabs
            fullWidth
            tabButtonType={TabButtonType.Icon}
            tabs={tabItemRender()}
            selected={state.selectedTab}
            onClick={(tabName) => handleClick(tabName)}
          />
        </TabContainer>
        <DescriptionContainer tabIndex={0}>
          <Description aria-label={savingsAccessibilityLabel()}>{textDescription()}</Description>
          <Suggest aria-label={textSuggest()}>{textSuggest()}</Suggest>
        </DescriptionContainer>
      </Container>
    </Wrapper>
  );
};

export default EnergySavingIdeas;
