import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Img from '@/atoms/Img';
import { Modal } from '@/designSystem/components';
import { images } from '@/assets/styles';
import { FillPrimary } from '@/designSystem/components/buttons';
import { IDREndedEventProps } from './DREndedEventPopup.types';
import {
  Body,
  Container,
  Logo,
  Time,
  Title,
  Upcoming,
  UpcomingContainer,
  Wrapper,
  styleCalendarIcon,
  styleClockIcon,
  DateText,
  styleButton,
  ButtonContainer,
} from './DREndedEventPopup.styles';

const DREndedEventPopup = ({ onPress, eventEndedStart, eventEndedEnd, programName }: IDREndedEventProps) => {
  const { t } = useTranslation('common');

  return (
    <Modal show showClose onClose={onPress} small>
      <Wrapper>
        <Container>
          <Logo>
            <Img src={images.drEventEnded} />
          </Logo>
          <Body>
            <UpcomingContainer>
              <Upcoming>
                <Img src={images.calendarBlack} style={styleCalendarIcon} />
                <DateText>{moment(eventEndedStart).format('dddd, MMMM DD')}</DateText>
              </Upcoming>
              <Upcoming>
                <Img src={images.clockGrey} style={styleClockIcon} />
                <Time>{`${moment(eventEndedStart).format('h:mm a')} - ${moment(eventEndedEnd).format('h:mm a')}`}</Time>
              </Upcoming>
            </UpcomingContainer>
          </Body>
          <Title>{t(`common:programs.${programName}.savingsEventsScreen.endEvent.title`)}</Title>
        </Container>
        <ButtonContainer>
          <FillPrimary
            title={t('common.generalButtons.ok')}
            onClick={onPress}
            padding={styleButton.padding}
            maxWidth={styleButton.maxWidth}
          />
        </ButtonContainer>
      </Wrapper>
    </Modal>
  );
};

export default DREndedEventPopup;
