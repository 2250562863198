import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

interface ButtonProps {
  padding?: string;
  warning?: boolean;
  iconFirst?: boolean;
  gap?: string;
  reverse?: boolean;
  flex?: number | 'unset';
  borderRadius?: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  ${typography.sm};
  color: ${(props) => (props.warning ? colors.bad : colors.primary)};
  font-weight: 900;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
  flex: ${(props) => props.flex};
  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `}
  &:hover {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
    box-shadow: ${(props) =>
      props.warning ? '0px 0px 0px 4px rgba(215, 2, 26, 0.2);' : '0px 0px 0px 4px rgba(69, 129, 29, 0.2)'};
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
