import styled from 'styled-components';
import { Wrapper as OriginWrapper } from '@/atoms/Input/index.styles';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled(OriginWrapper)`
  padding: 17px 0;
`;

export const Description = styled.div`
  ${typography.md};
  line-height: 21px;
  font-weight: 400;
  white-space: pre-line;
  margin-bottom: 60px;
`;
