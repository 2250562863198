import React, { AriaRole } from 'react';
import { TabIconImageType } from '@/molecules/Tabs';
import { Button, Img, ButtonWrapper } from './IconTabButton.styles';

type IconButtonProps = {
  title?: string;
  onClick?: () => void;
  padding?: string;
  image?: TabIconImageType;
  warning?: boolean;
  ariaLabel?: string;
  gap?: string;
  reverse?: boolean;
  role?: AriaRole;
  selected: boolean;
};

export const IconTabButton = ({
  title,
  onClick,
  image,
  padding,
  warning,
  ariaLabel,
  gap,
  reverse,
  role = 'tab',
  selected,
}: IconButtonProps) => {
  return (
    <ButtonWrapper selected={selected}>
      <Button
        tabIndex={0}
        onClick={(e) => {
          if (onClick) {
            onClick();
          }
          (e.target as HTMLButtonElement).blur();
        }}
        aria-label={ariaLabel}
        aria-selected={selected}
        padding={padding}
        warning={warning}
        gap={gap}
        reverse={reverse}
        role={role}
        selected={selected}
      >
        {title}
        {image && (
          <Img
            width={image.width}
            height={image.height}
            src={selected ? image.active.src : image.disabled?.src}
            alt={selected ? image.active.alt : image.disabled?.alt}
          />
        )}
      </Button>
    </ButtonWrapper>
  );
};
