import styled, { css } from 'styled-components';
import { global, helpers } from '@/assets/styles';

export const Description = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
  `,
);

export const Tip = styled(global.Caption)(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.primary[75] })};
    text-align: left;
  `,
);

export const SecondDescription = styled(Description)`
  margin-top: 40px;
`;

export const SmallDescription = styled.p`
  margin-top: 2px;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.text[80]};
  font-style: italic;
`;

export const Container = styled.div`
  margin-bottom: 30px;
`;

export const Validate = styled.p(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.status.danger })};
    font-size: 10px;
    font-style: italic;
  `,
);
