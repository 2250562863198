import { images } from '@/assets/styles';
import { AchievementLevel } from '@/types/dashboard';
import { colors } from '@/designSystem/colors';

type AchievementEnumProps = 'NO_ACTION' | 'GOLD' | 'BRONZE' | 'SILVER';

export const totalAchievementIcon = (level: AchievementEnumProps) => {
  switch (level) {
    case AchievementLevel.bronze:
      return images.levelTotalBronze;

    case AchievementLevel.silver:
      return images.levelTotalSilver;

    case AchievementLevel.gold:
      return images.levelTotalGold;

    case AchievementLevel.noAct:
    default:
      return images.levelTotalGold;
  }
};

export const achievementIcon = (level: AchievementEnumProps) => {
  switch (level) {
    case AchievementLevel.bronze:
      return images.levelBronze;

    case AchievementLevel.silver:
      return images.levelSilver;

    case AchievementLevel.gold:
      return images.levelGold;

    case AchievementLevel.noAct:
    default:
      return images.levelNoAction;
  }
};

export const getColorByLevel = (level: AchievementEnumProps) => {
  switch (level) {
    case AchievementLevel.bronze:
      return colors.gold;
    case AchievementLevel.silver:
      return colors.silver;
    case AchievementLevel.gold:
      return colors.bronze;
    case AchievementLevel.noAct:
    default:
      return colors.grey;
  }
};
