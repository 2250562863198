import React from 'react';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { IconButton } from '@/designSystem/components/buttons';
import { leftButtonImage, rightButtonImage } from '@/constants/seasonSelectorImages';
import { SeasonSelectorContainer, SeasonYear } from './seasonSelector.styles';

type Props = {
  selectedSeason: string;
  onPressArrowLeft: () => void;
  onPressArrowRight: () => void;
  isLastSeasonSelected: boolean;
  isCurrentSeasonSelected: boolean;
  backgroundColor?: string;
};

const SeasonSelector = ({
  selectedSeason,
  onPressArrowLeft,
  onPressArrowRight,
  isLastSeasonSelected,
  isCurrentSeasonSelected,
  backgroundColor,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <SeasonSelectorContainer backgroundColor={backgroundColor}>
      <IconButton
        ariaLabel={t('screens.main.savingsEvents.savingsEventsHistoryScreen.seasons.previousButton')}
        onClick={() => {
          onPressArrowLeft();
          sentEvent('previous_season', PATHS.PREVIOUS_SEASON);
        }}
        image={leftButtonImage}
        disabled={isLastSeasonSelected}
      />
      <SeasonYear
        tabIndex={0}
        aria-label={`${selectedSeason} ${t(
          'screens.main.savingsEvents.savingsEventsHistoryScreen.seasons.seasonTitle.ariaLabel',
        )}`}
      >{`${selectedSeason}
         `}</SeasonYear>
      <IconButton
        ariaLabel={t('screens.main.savingsEvents.savingsEventsHistoryScreen.seasons.nextButton')}
        onClick={() => {
          onPressArrowRight();
          sentEvent('next_season', PATHS.NEXT_SEASON);
        }}
        image={rightButtonImage}
        disabled={isCurrentSeasonSelected}
      />
    </SeasonSelectorContainer>
  );
};

export default SeasonSelector;
