import { SummaryState } from '@/types/dashboard';
import { EarningSummary, MoneyEarnedItem, PendingMoneyEarnedItem } from '@/types/dr';

type Seasons = {
  [year: string]: {
    summary: EarningSummary;
    history: Array<MoneyEarnedItem | PendingMoneyEarnedItem>;
  };
};

export const seasonsUpdateEarnings = (state: Seasons, seasons: Seasons): Seasons => {
  const seasonsYears = Object.keys(seasons);
  if (!seasonsYears.length) {
    return state;
  }
  const dummyEarningsSummary = {
    totalMoneyEarned: 0,
    eventMoneyEarned: 0,
    otherMoneyEarned: 0,
    numEvents: 0,
    summaryState: SummaryState.none,
    emissionImpact: 0,
  };

  const updatedSeasons: Seasons = {};

  seasonsYears.forEach((seasonYear: string) => {
    const selectedSeason = seasons[seasonYear];
    const selectedSeasonProperties = Object.keys(selectedSeason);
    if (!selectedSeasonProperties.length) {
      updatedSeasons[seasonYear] = {
        summary: dummyEarningsSummary,
        history: [],
      };
      return;
    }
    updatedSeasons[seasonYear] = selectedSeason;
  });

  return updatedSeasons;
};
