import React, { useEffect, useState } from 'react';
import { GBCStatus } from '@/types/userInfo';
import { useSelector } from 'react-redux';
import { useAuth } from '@/hooks/index';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useTranslation } from 'react-i18next';
import contents from '@/assets/config/appInfo';
import { APP_ENV } from '@/constants/environment';
import { appEnv } from '@/helpers/CompnayHelper';
import Img from '@/atoms/Img';
import { images, theme } from '@/assets/styles';
import Button from '@/atoms/Button';
import { NAVIGATED_FROM_INVITATION_LANDING_KEY } from '@/constants/sessionStorageKeys';
import { isUtilityEdition } from '@/helpers/Edition';
import { Container, Env, Logo, LogosContainer, Title, Wrapper } from './Login.styles';

const Login = () => {
  const {
    setting: {
      data: { language },
    },
    auth: {
      data: { authUrl },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const imageAltText = t(`common.image.text.logo${contents.edition}WebGreen`);
  const fromInvitationLanding = sessionStorage.getItem(NAVIGATED_FROM_INVITATION_LANDING_KEY);

  const [state, setState] = useState({ run: false });
  const { fetchResetAppData, fetchLogin, fetchRegister, fetchLogout } = useAuth();

  const isLogin = () => {
    return userInfo.gbcStatus !== GBCStatus.none;
  };

  const handleStarted = () => {
    sentEvent('click', PATHS.NAVIGATION_GET_STARTED);
    setState({ ...state, run: true });
    fetchResetAppData();
    fetchRegister(language);
  };

  const handleSignIn = () => {
    sentEvent('click', PATHS.NAVIGATION_SIGN_IN);
    setState({ ...state, run: true });
    fetchResetAppData();
    fetchLogin(language);
  };

  const handleContinue = () => {
    sentEvent('click', PATHS.NAVIGATION_CONTINUE);
    setState({ ...state, run: true });
    fetchLogin(language);
  };

  const handleLogout = () => {
    sentEvent('click', PATHS.REQUEST_LOGOUT);
    fetchLogout(userInfo.oidcIdToken, userInfo.id);
  };

  useEffect(() => {
    if (state.run && authUrl) {
      window.location.href = authUrl;
    }
  }, [state, authUrl]);

  return (
    <Wrapper>
      <Container>
        {APP_ENV !== 'production' && <Env>{appEnv()}</Env>}
        <Logo>
          <Img src={images.logoOlivineWebGreen.image} altAttr={imageAltText} />
          {isUtilityEdition && (
            <LogosContainer>
              <Img src={images.logoPGE} style={{ maxHeight: 60, marginLeft: 15, marginRight: 15, maxWidth: 60 }} />
              <Img src={images.logoSCE} style={{ maxHeight: 60, marginLeft: 15, marginRight: 15, maxWidth: 45.5 }} />
            </LogosContainer>
          )}
        </Logo>
        <Title>{t('screens.auth.welcomeScreen.title')}</Title>
        {isLogin() ? (
          <>
            <Button
              label={t('screens.auth.welcomeScreen.continue')}
              fontSize={theme.typography.button.fontSize}
              color={theme.colors.text[0]}
              backgroundColor={theme.colors.primary[100]}
              borderRadius={theme.borders.button.radius}
              borderColor={theme.colors.primary[100]}
              width="100%"
              height={50}
              marginTop={20}
              onClick={handleContinue}
            />
            <Button
              label={t('screens.auth.welcomeScreen.logout')}
              fontSize={theme.typography.button.fontSize}
              color={theme.colors.palette.highest}
              transparent
              width="100%"
              height={50}
              marginTop={20}
              onClick={handleLogout}
            />
          </>
        ) : (
          <>
            {!fromInvitationLanding && (
              <Button
                label={t('screens.auth.welcomeScreen.getStarted')}
                fontSize={theme.typography.button.fontSize}
                color={theme.colors.text[0]}
                backgroundColor={theme.colors.primary[100]}
                borderRadius={theme.borders.button.radius}
                width="100%"
                height={50}
                onClick={handleStarted}
              />
            )}
            <Button
              label={t('screens.auth.welcomeScreen.signIn')}
              fontSize={theme.typography.button.fontSize}
              color={theme.colors.primary[100]}
              backgroundColor={theme.colors.primary[0]}
              borderRadius={theme.borders.button.radius}
              borderColor={theme.colors.primary[100]}
              borderWidth={theme.borders.button.width}
              width="100%"
              height={50}
              marginTop={20}
              onClick={handleSignIn}
            />
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default Login;
