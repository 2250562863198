import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ReqDeleteUser, ReqSetting, ReqUpdateUserInfo } from '@/apis/auth/types';
import {
  userInfoRequest,
  updateUserInfoRequest,
  deleteUserRequest,
  activateInvitedUserRequest,
} from '@/stores/userInfo';
import { UserInfo } from '@/types/userInfo';
import { IResponse } from '@/apis/api';

export default function useUserInfo() {
  const dispatch = useDispatch();

  const fetchUserInfo = useCallback(
    (callback: (userInfo: UserInfo) => void, settingInfo?: ReqSetting) =>
      dispatch(userInfoRequest(callback, settingInfo)),
    [dispatch],
  );

  const fetchUpdateUserInfo = useCallback(
    (userInfo: ReqUpdateUserInfo, callback?: (response: IResponse) => void) =>
      dispatch(updateUserInfoRequest(userInfo, callback)),
    [dispatch],
  );

  const fetchDeleteUser = useCallback(
    (userInfo: ReqDeleteUser, callback?: () => void) => dispatch(deleteUserRequest(userInfo, callback)),
    [dispatch],
  );

  const fetchActivateInvitedUserRequest = useCallback(() => dispatch(activateInvitedUserRequest()), [dispatch]);

  return {
    fetchUserInfo,
    fetchUpdateUserInfo,
    fetchDeleteUser,
    fetchActivateInvitedUserRequest,
  };
}
