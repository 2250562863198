import styled from 'styled-components';
import { helpers, theme } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const styleCalendarIcon = { width: '23px', height: '24px' };
export const styleClockIcon = { width: '17px', height: '16px' };
export const styleButton = {
  padding: '12px 16px',
  maxWidth: '100px',
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 25px;
  height: 100%;
  background-color: ${colors.white};
`;

export const Container = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px 0px 0px;
  gap: 20px;
`;

export const Logo = styled.div`
  width: 100px;
  height: 109px;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const UpcomingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Upcoming = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const DateText = styled.p`
  ${typography.xl2};
  font-weight: 700;
  line-height: 24px;
  color: ${colors.text};
`;

export const Time = styled.p`
  ${typography.lg};
  font-weight: 600;
  line-height: 16px;
  color: ${colors.grey};
  text-transform: uppercase;
`;
export const Title = styled.p`
  ${typography.lg};
  color: ${colors.text};
  text-align: center;
`;

export const Description = styled.p`
  ${helpers.textSet({ fontSize: theme.typography.h5.fontSize })};
  ${helpers.colorSet({ color: theme.colors.text[80] })};
  text-align: center;
`;

export const Body = styled.div``;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
