import { Screen, EnrollmentInfo } from '@/types/enrollment';

export const updateScreenSteps = (screenSteps: Screen[], enrollmentInfo: EnrollmentInfo) => {
  let updatedScreenSteps = [...screenSteps];

  updatedScreenSteps = enrollmentInfo.areAllServiceAccountsAuthorized
    ? updatedScreenSteps.filter((screen: Screen) => screen.name !== 'NAV_CPA_VERIFY')
    : updatedScreenSteps;

  return updatedScreenSteps;
};
