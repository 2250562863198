import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import contents from '@/assets/config/appInfo';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import { FillPrimary } from '@/designSystem/components/buttons';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import {
  Wrapper,
  TitleContainer,
  Title,
  SiteManagerDescriptionContainer,
  SiteManagerDescription,
  ActionList,
  Action,
  ButtonContainer,
} from './MySites.styles';

const MySitesScreen = () => {
  const { t } = useTranslation('common');
  // My Sites - ClimateResponse®
  document.title = `${t('screens.main.settings.mySites.mySitesScreen.title')} - ${contents.name}`;
  const navigate = useNavigate();

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'My Sites',
      screen_class: 'my_sites',
    });
  }, []);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t('screens.main.settings.mySites.mySitesScreen.title')}</Title>
      </TitleContainer>
      <Wrapper>
        <SiteManagerDescriptionContainer>
          <SiteManagerDescription>
            {t('screens.main.settings.mySites.mySitesScreen.description')}
          </SiteManagerDescription>
        </SiteManagerDescriptionContainer>
        <ActionList>
          <Action>{t('screens.main.settings.mySites.mySitesScreen.actions.locate')}</Action>
          <Action>{t('screens.main.settings.mySites.mySitesScreen.actions.edit')}</Action>
          {/* <Action>{t('screens.main.settings.mySites.mySitesScreen.actions.delete')}</Action> */}
        </ActionList>
        <ButtonContainer>
          <FillPrimary
            title={t('screens.main.settings.mySites.mySitesScreen.continue')}
            onClick={() => navigate(PATHS.SCREEN_SETTING_MANAGE_SITE.pathname)}
            padding="12px"
          />
        </ButtonContainer>
      </Wrapper>
    </SettingTemplate>
  );
};

export default MySitesScreen;
