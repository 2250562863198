import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { DrHistoryItem, UserStatusEnum } from '@/types/dr';
import { useTranslation } from 'react-i18next';
import { NoData, NoDataWrapper, Wrapper } from '@/organisms/EventList/index.styles';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { useNavigate } from 'react-router-dom';
import { useEventsHistory } from '@/hooks/index';
import EventItem from '@/molecules/EventItem';
import { PerEventDisclaimer } from '@/helpers/PerEventDisclaimer';

const EmissionsImpactList = ({ emissionsHistory }: { emissionsHistory: Array<DrHistoryItem> }) => {
  const { t } = useTranslation('common');
  const {
    sites: {
      data: { currentSite },
    },
    dr: {
      history: {
        events: {
          data: { selectedEvent },
        },
      },
    },
  } = useSelector((state: RootState) => state);

  const [localSelectedEvent, setLocalSelectedEvent] = useState<{ eventId?: string }>({
    eventId: selectedEvent?.eventId ?? undefined,
  });

  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const navigate = useNavigate();

  const { fetchEventHistorySelect, fetchEventHistorySelectReset } = useEventsHistory();

  const handleSelectEvent = (item: DrHistoryItem) => {
    if (!isMobileWindowSize && (!item.eventId || localSelectedEvent.eventId === item.eventId)) {
      return;
    }

    setLocalSelectedEvent({ ...localSelectedEvent, eventId: item.eventId });

    if (currentSite) {
      fetchEventHistorySelect(currentSite.id, item);
    }

    sentEvent('select_emission', PATHS.SELECT_EVENT(localSelectedEvent.eventId));

    if (isMobileWindowSize) {
      navigate(PATHS.SCREEN_MOBILE_HISTORY_DETAIL.pathname);
    }
  };

  useEffect(() => {
    if (!selectedEvent && emissionsHistory?.length > 0) {
      const firstMatchingEvent = emissionsHistory.find((item) => {
        const checkPending = item.hadJoined && !item.hasUsageData;
        const checkNoReply = !item.hadJoined && item.userStatus === UserStatusEnum.DefaultEvent;
        const checkDeclined = !item.hadJoined;
        const isFinalWithErrors = item.quickViewData.moneyEarnedDisclaimer === PerEventDisclaimer.Incalculable;
        return !(checkPending || checkNoReply || checkDeclined || isFinalWithErrors);
      });
      if (firstMatchingEvent) {
        setLocalSelectedEvent({ eventId: firstMatchingEvent.eventId });
        if (currentSite) {
          fetchEventHistorySelect(currentSite.id, firstMatchingEvent);
        }
      } else {
        fetchEventHistorySelectReset();
      }
    } else {
      setLocalSelectedEvent({ eventId: selectedEvent?.eventId });
    }
  }, [selectedEvent, emissionsHistory, currentSite, fetchEventHistorySelect]);

  if (emissionsHistory?.length === 0) {
    return (
      <NoDataWrapper>
        <NoData>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.noData')}</NoData>
      </NoDataWrapper>
    );
  }

  return (
    <Wrapper>
      {emissionsHistory?.map((item, index) => (
        <EventItem
          key={item.eventId}
          item={item}
          onClick={handleSelectEvent}
          index={emissionsHistory.length - index}
          selectedEvent={localSelectedEvent}
          percentageChangeInValue={item.percentageChangeInEmission}
          changeInValue={item.quickViewData.emissionChangeInUsage}
          isEmission
        />
      ))}
    </Wrapper>
  );
};

export default EmissionsImpactList;
