import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { IStyleMenuItem } from './index.types';

export const MenuItem = styled.li<IStyleMenuItem>(
  ({ theme, selected }) => css`
    ${helpers.colorSet({ backgroundColor: selected ? colors.hoverFocus : theme.colors.text[0] })}
    display: block;
    font-size: 17px;
    ${typography.sm};
    color: ${colors.text};

    text-decoration: none;
    cursor: pointer;
    outline: none;
    border-radius: 8px;
    margin: 4px;
    padding: 8px;

    &:hover {
      cursor: pointer;
      background-color: ${colors.hoverFocus};
    }

    &:focus {
      border-color: #3a8eb8;
    }
  `,
);
