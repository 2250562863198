import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Container = styled.div<{
  alignItems: 'normal' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  flexDirection: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  gap?: string;
}>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  align-items: ${(props) => props.alignItems};
  ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
`;

export const Value = styled.p<{ color?: string; size: string }>`
  font-size: ${(props) => props.size};
  font-weight: bold;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: 'Lato';
  text-align: center;
  color: ${(props) => props.color || 'black'};
`;
export const Description = styled.p<{ color?: string; size?: string; weight?: number }>`
  font-family: 'Lato';
  font-size: ${(props) => props.size};
  text-align: center;
  ${(props) =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `}

  color: ${colors.text};
  ${media.lg`
    color: ${colors.blackGrey};
  `}
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
      ${media.lg`
        color: ${props.color};
      `}
    `}
`;
