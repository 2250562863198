import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import Img from '@/atoms/Img';
import Usage from '@/molecules/Usage';
import { totalAchievementIcon } from '@/helpers/AchievementIconHelper';
import Impact from '@/molecules/Impact';
import { PortfolioPerformance, TotalEnvironmentalImpact } from '@/types/portfolio';
import { typography } from '@/designSystem/typography';
import { ParentComponentOfImpact } from '@/molecules/Impact/ImpactComponent.styles';
import { Description } from '@/assets/styles/global';
import {
  Container,
  EarningsRow,
  Grid,
  ImpactGroup,
  ImpactLabel,
  ImpactLabelGroup,
  ImpactLine,
  InnerContainer,
  Label,
  PerformanceTitle,
  ReductionRow,
  TitleContainer,
  UsageGroup,
} from './PortfolioPerformanceTile.styles';

export interface PorfolioPerformanceTileProps {
  portfolioPerformance: PortfolioPerformance;
  totalEnvironmentalImpact: TotalEnvironmentalImpact;
  portfolioDisclaimer: string;
  portfolioSymbol: string;
}

const PorfolioPerformanceTile = ({
  portfolioPerformance,
  totalEnvironmentalImpact,
  portfolioDisclaimer,
  portfolioSymbol,
}: PorfolioPerformanceTileProps) => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <TitleContainer>
        <PerformanceTitle>{t('screens.main.savingsEvents.portfolioScreen.portfolioPerformance')}</PerformanceTitle>
        <Img src={totalAchievementIcon(portfolioPerformance.totalAchievement)} style={{ objectFit: 'contain' }} />
      </TitleContainer>
      <InnerContainer>
        <Grid>
          <ReductionRow>
            <Label>{t('screens.main.savingsEvents.portfolioScreen.totalPerformance')}</Label>
            <UsageGroup>
              {portfolioPerformance.totalPerformance > 0 && (
                <Img src={images.arrowDownPerformanceBlue} style={{ objectFit: 'contain' }} />
              )}
              <Usage
                value={portfolioPerformance.totalPerformance * 1000}
                unit="kWh"
                symbolFirst
                valueSize={typography.xl2.fontSize}
                valueLineHeight="32px"
                valueFontWeight={900}
                valueColor={colors.accent}
                descriptionColor={colors.text}
                alignSelf="center"
              />
            </UsageGroup>
          </ReductionRow>
          <EarningsRow>
            <div className="label-usage-container">
              <Label>{`${t('screens.main.savingsEvents.portfolioScreen.totalEarnings')}${portfolioSymbol}`}</Label>
              <UsageGroup>
                <Usage
                  value={portfolioPerformance.totalEarnings}
                  unit="money"
                  symbol={portfolioPerformance.totalEarnings >= 0 ? '+ ' : '- '}
                  symbolFirst
                  valueSize={typography.xl2.fontSize}
                  valueLineHeight="32px"
                  valueFontWeight={900}
                  valueColor={portfolioPerformance.totalEarnings >= 0 ? colors.primary : colors.bad}
                  descriptionColor={colors.grey}
                  alignSelf="center"
                  toFixed={2}
                />
              </UsageGroup>
            </div>
            <Description tabIndex={0}>{portfolioDisclaimer}</Description>
          </EarningsRow>
          <ImpactGroup>
            <ImpactLabel>{t('screens.main.savingsEvents.portfolioScreen.totalEnvironmentImpact')}</ImpactLabel>
            <ImpactLabelGroup>
              <Impact
                parentComponent={ParentComponentOfImpact.PORTFOLIO}
                logo={images.blueEnergy}
                title={t('screens.main.dashboard.dashboardScreen.environmentalImpact.energyImpact')}
                yourValue={totalEnvironmentalImpact.impact.reduction.yours * 1000}
                yourUnit="kWh"
                communityValue={totalEnvironmentalImpact.impact.reduction.community * 1000}
                communityUnit="kWh"
              />
              <ImpactLine />
              <Impact
                parentComponent={ParentComponentOfImpact.PORTFOLIO}
                logo={images.blueEnergy}
                title={t('screens.main.dashboard.dashboardScreen.environmentalImpact.emissions')}
                yourValue={totalEnvironmentalImpact.impact.emissions.yours}
                yourUnit="lbs"
                communityValue={totalEnvironmentalImpact.impact.emissions.community}
                communityUnit="lbs"
              />
              <ImpactLine />
              <Impact
                parentComponent={ParentComponentOfImpact.PORTFOLIO}
                logo={images.blueEnergy}
                title={t('screens.main.dashboard.dashboardScreen.environmentalImpact.miles')}
                yourValue={totalEnvironmentalImpact.impact.milesDriven.yours}
                yourUnit="miles"
                communityValue={totalEnvironmentalImpact.impact.milesDriven.community}
                communityUnit="miles"
              />
            </ImpactLabelGroup>
          </ImpactGroup>
        </Grid>
      </InnerContainer>
    </Container>
  );
};

export default PorfolioPerformanceTile;
