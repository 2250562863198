// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReqVerify, ReqVerifyPge } from '@/apis/enrollment/types';
import useEnrollment from '@/hooks/useEnrollment';
import { nextScreenStep, moveNoThanksScreen } from '@/helpers/NavigationHelper';
import { isRange } from '@/helpers/EnrollmentHelper';
import { RootState } from '@/stores/index';
import { EnrollmentStatus, PgeVerifyResponse, ProgramName, VerifyResponse } from '@/types/enrollment';
import PATHS from '@/types/navigationPaths';
import VerificationScreenView from '@/screens/Enrollment/VerificationScreen/index.view';
import { IVerificationField } from '@/organisms/Verification/index.types';
import { sentEvent } from '@/helpers/GAHelper';
import * as Rules from './rules.utility';
import { IHandleVerifySuccessResponse, VerificationFormErrorProps } from './index.types';

const VerificationScreenContainer = () => {
  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: {
      enrollmentInfo,
      programs,
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  const [state, setState] = useState<VerificationFormErrorProps>({
    disabledNext: false,
    errors: [],
  });
  const [modal, setModal] = useState({ verification: false });

  const navigate = useNavigate();
  const { fetchChangeProgram, fetchVerify, fetchVerifyPge } = useEnrollment();

  const { t, i18n } = useTranslation('common');

  const fields1exist = i18n.exists(`common:programs.${currentProgram.program}.verificationScreen.fields1`);
  const fields2exist = i18n.exists(`common:programs.${currentProgram.program}.verificationScreen.fields2`);
  const fields1 = t<string, IVerificationField[]>(
    `common:programs.${currentProgram.program}.verificationScreen.fields1`,
    { returnObjects: true },
  );
  const fields2 = t<string, IVerificationField[]>(
    `common:programs.${currentProgram.program}.verificationScreen.fields2`,
    { returnObjects: true },
  );
  const fields = [...(fields1 ?? []), ...(fields2 ?? [])];
  useEffect(() => {
    if (fields1exist || fields2exist) {
      validationCheck();
    }
  }, [enrollmentInfo]);

  const validationCheck = () => {
    const payload: ReqVerify =
      fields.length === 0
        ? {}
        : {
            ...fields.reduce((prev, curr) => ({ ...prev, [curr.name]: enrollmentInfo[curr.name] }), []),
          };

    const disabled =
      fields.length === 0
        ? !isRange(
            enrollmentInfo.verificationAccountNumber.length,
            currentProgram.accountMinLength,
            currentProgram.accountMaxLength,
          ) || enrollmentInfo.verificationName.length < 2
        : fields1.map((f) => !enrollmentInfo[f.name]).includes(true) ||
          (fields2exist && (fields2 ?? []).filter((f) => !!enrollmentInfo[f.name]).length < 2);

    const disabledNext = disabled || ((payload.serviceZipCode && payload.serviceZipCode.length !== 5) as boolean);
    setState({
      ...state,
      disabledNext,
    });
  };

  const verificationValidated = (payload: ReqVerify) => {
    const validationData = {
      ...payload,
      accountMinLength: currentProgram.accountMinLength,
      accountMaxLength: currentProgram.accountMaxLength,
    };

    const programValidation = Rules.validation[payload.program];
    const validationErrors = programValidation ? programValidation(validationData) : [];

    setState({ ...state, errors: validationErrors });
    return validationErrors;
  };

  const handleVerifyErrorResponse = () => {
    sentEvent('account_verification_submitted', {
      submit_status: 'system error',
    });
    navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
  };

  const handleVerifySuccessResponse = ({ isPge, sites, verifyResponse }: IHandleVerifySuccessResponse) => {
    const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);

    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ loading: false });
      window.ReactNativeWebView.postMessage(data);
    }

    if (verifyResponse.status !== 'active_customer') {
      sentEvent('account_verification_submitted', {
        submit_status: 'not eligible',
      });
      navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
      return;
    }

    if (verifyResponse.serviceAccounts) {
      const isEligible = verifyResponse.serviceAccounts.some((sa) => sa.programs[0].eligible === true);
      const isEnrolled = sites?.some((s) => s.enrollmentStatus === EnrollmentStatus.Enrolled);

      if (isPge && !isEligible && !isEnrolled) {
        sentEvent('account_verification_submitted', {
          submit_status: 'not eligible',
        });
        navigate(PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname);
        return;
      }
    }

    sentEvent('account_verification_submitted', {
      submit_status: 'eligible',
    });
    navigate(nextScreen);
  };

  const handleNext = () => {
    const removeSpacesRegex = /[' ']/g;
    setState((prevState) => ({ ...prevState, disabledNext: true }));

    const payload: ReqVerify = !fields.length
      ? {
          program: currentProgram.program,
          zipCode: userInfo.zipcode,
          lastName: enrollmentInfo.verificationName,
          accountNumber: enrollmentInfo.verificationAccountNumber,
        }
      : {
          program: currentProgram.program,
          zipCode: userInfo.zipcode,
          ...fields.reduce((prev, curr) => ({ ...prev, [curr.name]: enrollmentInfo[curr.name] }), []),
        };

    if (verificationValidated(payload).length === 0) {
      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ loading: true });
        window.ReactNativeWebView.postMessage(data);
      }

      payload.servicePhone = payload.servicePhone?.replaceAll(removeSpacesRegex, '');

      if (payload.program === ProgramName.PGE_ELRP_A6) {
        fetchVerifyPge(
          payload as ReqVerifyPge,
          ({ platformValidateResponseData, sites }: PgeVerifyResponse) => {
            handleVerifySuccessResponse({ isPge: true, sites, verifyResponse: platformValidateResponseData });
          },
          handleVerifyErrorResponse,
        );
      } else {
        fetchVerify(
          payload,
          (verifyResponse: VerifyResponse) => handleVerifySuccessResponse({ isPge: false, verifyResponse }),
          handleVerifyErrorResponse,
        );
      }
    }
  };

  const handleNoThanks = () => {
    setModal({ ...modal, verification: true });
  };

  const handleModalVerificationClose = () => {
    setModal({ ...modal, verification: false });
  };

  const handleModalVerificationCancel = () => {
    const nextScreen = moveNoThanksScreen(currentProgram.screenStep, programs, fetchChangeProgram);
    navigate(nextScreen);
  };

  const setErrors = (newErrors: string[] | []) => setState((prevState) => ({ ...prevState, errors: newErrors }));

  return (
    <VerificationScreenView
      enrollmentProgram={currentProgram}
      disabledNext={state.disabledNext}
      onNext={handleNext}
      onNoThanks={handleNoThanks}
      modalVerification={modal.verification}
      modalVerificationClose={handleModalVerificationClose}
      modalVerificationOk={handleModalVerificationClose}
      modalVerificationCancel={handleModalVerificationCancel}
      errors={state.errors}
      setErrors={setErrors}
      utility={enrollmentInfo.utility}
    />
  );
};

export default VerificationScreenContainer;
