import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import Tabs from '@/molecules/Tabs';
import DRQuickView from '@/organisms/DRQuickView';
import DRDetailView from '@/organisms/DRDetailView';
import { DrHistoryItem, PerformanceToDateEnum } from '@/types/dr';
import { HistoryTabs } from '@/screens/Main/SavingsEvents/SavingsEventsScreen/index.types';
import { images } from '@/assets/styles';
import { IconButton } from '@/designSystem/components/buttons';
import { UnitHistoryDetail } from '@/helpers/Edition';
import {
  Header,
  Body,
  Title,
  DateTime,
  DateTimeContainer,
  Date,
  Time,
  TabsWrapper,
  NoDataContainer,
  NoData,
  Help,
  Wrapper,
  styleHelpIcon,
} from './index.styles';

interface IHistoryItemDetailProps {
  item?: DrHistoryItem;
  tabLinkSelected: HistoryTabs;
  onTabClick: (item: HistoryTabs) => void;
  onHelp: () => void;
  selectedTab: PerformanceToDateEnum;
}

const HistoryItemDetailContainer = ({
  item,
  tabLinkSelected,
  onTabClick,
  onHelp,
  selectedTab,
}: IHistoryItemDetailProps) => {
  const { t } = useTranslation('common');
  const unit = selectedTab === PerformanceToDateEnum.emissions ? UnitHistoryDetail.lbs : UnitHistoryDetail.kwh;

  if (!item) {
    return (
      <Wrapper>
        <NoDataContainer>
          <NoData>{t(`screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.events.noData`)}</NoData>
        </NoDataContainer>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {item.hasUsageData ? (
        <>
          <Header>
            <Title>{item.name}</Title>
            <DateTimeContainer>
              <DateTime>
                <Img src={images.calendar} />
                <Date>{moment(item.date).format('MMMM Do, YYYY')}</Date>
              </DateTime>
              <DateTime>
                <Img src={images.timer} />
                <Time>{item.timeRange}</Time>
              </DateTime>
            </DateTimeContainer>
          </Header>
          <Body showPadding={tabLinkSelected === 'quickView'}>
            <TabsWrapper>
              <Tabs
                fullWidth
                tabs={[
                  {
                    id: 0,
                    title: t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.menus.quickView'),
                    tabName: HistoryTabs.QuickView,
                  },
                  {
                    id: 1,
                    title: t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.menus.detail'),
                    tabName: HistoryTabs.Details,
                  },
                ]}
                selected={tabLinkSelected || HistoryTabs.QuickView}
                onClick={(tabName) => {
                  onTabClick(tabName as HistoryTabs);
                  sentEvent('history_item_tab', PATHS.HISTORY_ITEM_TAB(tabName as HistoryTabs));
                }}
              />
            </TabsWrapper>
            {tabLinkSelected === HistoryTabs.QuickView ? <DRQuickView unit={unit} /> : <DRDetailView unit={unit} />}
            <Help>
              <IconButton ariaLabel={images.helpBtn.text} onClick={onHelp} image={styleHelpIcon} borderRadius="20px" />
            </Help>
          </Body>
        </>
      ) : (
        <NoDataContainer>
          <NoData>{t(`screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.events.noData`)}</NoData>
        </NoDataContainer>
      )}
    </Wrapper>
  );
};

export default HistoryItemDetailContainer;
