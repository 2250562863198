import React from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@/atoms/Radio';
import { isOlivineEdition } from '@/helpers/Edition';
import { Information, Container, Description } from './index.styles';
import { IConnectToUtilityViewProps } from './index.types';

const ConnectToUtilityView = ({ programName, utility, onChangeUtility }: IConnectToUtilityViewProps) => {
  const { t } = useTranslation('common');

  const utilities = isOlivineEdition
    ? [utility]
    : t(`programs.${programName}.connectToUtilityScreen.utilities`, { returnObjects: true });
  // TODO: 첫번째 전력회사 선택 (기본 선택을 사용하지 않음)
  // const selectedUtility = Object.keys(Object.values(utilities)[0])[0];

  return (
    <Container>
      {isOlivineEdition && <Description>{t('screens.enrollment.connectToUtilityScreen.description')}</Description>}
      <Radio
        items={Object.values(utilities).map((key) => {
          const typedKey = typeof key === 'string' ? key : String(key);

          return { id: typedKey, name: typedKey, text: t(`common.utilities.${key}.shortName`) };
        })}
        value={utility}
        onChange={(name) => onChangeUtility(name as string)}
      />
      <Information>
        {isOlivineEdition
          ? t(`programs.${programName}.connectToUtilityScreen.information`)
          : t(`programs.${programName}.connectToUtilityScreen.information`)}
      </Information>
    </Container>
  );
};

export default ConnectToUtilityView;
