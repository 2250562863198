import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { Wrapper } from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import { FillPrimary } from '@/designSystem/components/buttons/FillPrimary';
import { ButtonContainer, Buttons, Message, Container, Title } from './SiteEnroll.styles';

const SiteEnrollSuccessScreen = () => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const programName = getProgramNameFromSite(currentSite);
  const handleReturnDashboard = () => {
    navigate(PATHS.SCREEN_DASHBOARD.pathname);
  };

  return (
    <Wrapper>
      <Container>
        <Title>{t(`common:programs.${programName}.programDetailsScreen.confirm_enroll`)}</Title>
        <Message>{t(`common:programs.${programName}.programDetailsScreen.enroll_success`)}</Message>
      </Container>
      <Buttons>
        <ButtonContainer>
          <FillPrimary
            title={t('common.generalButtons.returnToDashboard')}
            onClick={handleReturnDashboard}
            padding="12px"
          />
        </ButtonContainer>
      </Buttons>
    </Wrapper>
  );
};

export default SiteEnrollSuccessScreen;
