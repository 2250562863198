import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

export const TextContent = styled.div``;

export const SiteName = styled.p`
  margin-bottom: 2px;
`;

export const Description = styled.p`
  margin-top: 2px;
`;
