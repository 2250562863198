import { useTranslation } from 'react-i18next';
import { Modal } from '@/designSystem/components';
import React from 'react';
import { FillPrimary, LinkButton } from '@/designSystem/components/buttons';
import {
  DeleteModalButtonContainer,
  DeleteModalButtons,
  DeleteModalContainer,
  DeleteModalHeader,
  DeleteModalText,
} from './ModalDeletePhoneNumber.styles';

const ModalDeletePhoneNumber = ({
  isVisible,
  onClose,
  onClickYes,
  onClickNo,
}: {
  isVisible: boolean;
  onClose: () => void;
  onClickYes: () => void;
  onClickNo: () => void;
}) => {
  const { t } = useTranslation('common');

  return (
    <Modal show={isVisible} onClose={onClose} showClose small>
      <DeleteModalContainer>
        <DeleteModalHeader>{t('screens.main.settings.account.accountScreen.modalDeletePhone.title')}</DeleteModalHeader>
        <DeleteModalText>
          {t('screens.main.settings.account.accountScreen.modalDeletePhone.description')}
        </DeleteModalText>
        <DeleteModalButtonContainer>
          <DeleteModalButtons>
            <LinkButton
              title={t('common.generalButtons.cancel')}
              onClick={onClickNo}
              underline={false}
              padding="10px 14px"
            />
            <FillPrimary
              title={t('screens.main.settings.account.accountScreen.modalDeletePhone.title')}
              onClick={onClickYes}
              padding="10px 14px"
              warning
            />
          </DeleteModalButtons>
        </DeleteModalButtonContainer>
      </DeleteModalContainer>
    </Modal>
  );
};
export default ModalDeletePhoneNumber;
