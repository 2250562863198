import React from 'react';
import { useTranslation } from 'react-i18next';
import MultipleSurvey from '@/organisms/MultipleSurvey';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import { IMultipleSurveyScreenViewProps } from './index.types';

const MultipleSurveyScreenView = ({ disabledNext, onNext, onNoThanks }: IMultipleSurveyScreenViewProps) => {
  const { t } = useTranslation('common');

  return (
    <EnrollmentTemplate
      disabled={disabledNext}
      underline={false}
      onNext={onNext}
      textNext={t('common.generalButtons.submit')}
      onOther={onNoThanks}
      textOther={t('common.generalButtons.skip')}
    >
      <MultipleSurvey />
    </EnrollmentTemplate>
  );
};

export default MultipleSurveyScreenView;
