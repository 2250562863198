import React from 'react';
import Img from '@/atoms/Img';
import { UnitHelper } from '@/helpers/UnitHelper';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { IUsageProps } from './index.types';
import { Wrapper, Container, Title, ValueContainer, Value, Description, Empty } from './index.styles';

const Usage = ({
  title,
  value,
  unit,
  description,
  symbol,
  symbolFirst,
  valueIcon,
  valueColor,
  valueStrong,
  valueLineHeight,
  descriptionColor,
  descriptionAlign,
  valueSize,
  valuePadding,
  valueFontWeight,
  alignSelf,
  toFixed,
  style,
  descriptionSize,
  alignItems,
  valueIconSize,
}: IUsageProps) => {
  const renderUnit = () => {
    if (typeof value === 'string') {
      return value;
    }

    if (unit === 'kWh') {
      return symbolFirst
        ? `${symbol || ''}${UnitHelper.formatEnergyValue(value || 0, toFixed)}`
        : `${UnitHelper.formatEnergyValue(value || 0, toFixed)}${symbol || ''}`;
    }

    return symbolFirst
      ? `${symbol || ''}${UnitHelper.renderUnitValue(value || 0, unit, false, toFixed)}`
      : `${UnitHelper.renderUnitValue(value || 0, unit, false, toFixed)}${symbol || ''}`;
  };

  return (
    <Wrapper alignSelf={alignSelf} tabIndex={0} style={style}>
      <Container alignItems={alignItems}>
        {title && <Title>{title}</Title>}
        <ValueContainer>
          <Value
            aria-label={accessibilityUsageLabel(renderUnit)}
            color={valueColor}
            strong={valueStrong}
            size={valueSize}
            padding={valuePadding}
            lineHeight={valueLineHeight}
            fontWeight={valueFontWeight}
          >
            {renderUnit()}
          </Value>
          {valueIcon && <Img src={valueIcon} style={valueIconSize} />}
        </ValueContainer>
        {description !== undefined && (
          <ValueContainer>
            <Description descriptionAlign={descriptionAlign} color={descriptionColor} descriptionSize={descriptionSize}>
              {description || ''}
            </Description>
            {valueIcon && <Empty valueIconSize={valueIconSize} />}
          </ValueContainer>
        )}
      </Container>
    </Wrapper>
  );
};

export default Usage;
