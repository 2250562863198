import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

interface ButtonProps {
  padding?: string;
  warning?: boolean;
  iconFirst?: boolean;
  color?: string;
  lineHeight?: string;
  selected: boolean;
  fullWidth?: boolean;
}

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lg`
    justify-content: flex-start;
  `}
  ${(props) =>
    props.selected &&
    css`
      border-bottom: 3px solid ${colors.primaryGreen};
    `}
`;

export const Button = styled.button<ButtonProps>`
  ${typography.xs};
  color: ${(props) => (props.color ? props.color : colors.text)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '24px')};
  font-weight: 400;
  cursor: pointer;
  border: none;
  background-color: transparent;
  order: ${(props) => (props.iconFirst ? 2 : 1)};

  padding: 10px 15px;
  padding: ${(props) => props.padding};
  flex: 1;

  ${(props) =>
    props.fullWidth
      ? css`
          ${media.lg`
    flex: 1;
  `}
        `
      : css`
          ${media.lg`
    flex: unset;
  `}
        `}
  ${(props) =>
    props.selected
      ? css`
          color: ${colors.primaryGreen};
          font-weight: 800;
          padding-bottom: 7px;
        `
      : css`
          padding-bottom: 10px;
        `}
  border-radius: 8px;
  &:hover {
    color: ${colors.primary};
    background-color: ${colors.hoverFocus};
  }
  &:focus {
    background-color: ${colors.hoverFocus};
    box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
  }
`;

export const Img = styled.img<{ width?: string; height?: string; iconFirst?: boolean }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  order: ${(props) => (props.iconFirst ? 1 : 2)};
`;
