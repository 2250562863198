import styled, { css } from 'styled-components';

interface ButtonProps {
  padding?: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: ${(props) => props.padding};
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;
