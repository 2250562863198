import React from 'react';
import OriginModal from 'react-modal';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './index.types';
import { Container, Header, Title, More, Body, defaultModalStyle } from './modal.styles';

export const Modal = ({
  children,
  show,
  title,
  showClose,
  onClose,
  ariaControlsId,
  small,
  fullWidth,
  style = defaultModalStyle,
}: IModalProps) => {
  const { t } = useTranslation('common');
  return (
    <OriginModal
      aria={{ modal: 'true' }}
      id={ariaControlsId}
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={show}
      onRequestClose={onClose}
      style={style}
    >
      <Container fullWidth={fullWidth}>
        <Header small={small}>
          {title && <Title>{title}</Title>}
          {showClose && (
            <More>
              <IconButton
                icon={images.modalClose}
                onClick={onClose}
                ariaLabel={`${t('common.generalButtons.close')}`}
                role="button"
                ariaControls={ariaControlsId}
              />
            </More>
          )}
        </Header>
        <Body small={small}>{children}</Body>
      </Container>
    </OriginModal>
  );
};
