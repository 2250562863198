import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useMisc } from '@/hooks/index';
import PATHS from '@/types/navigationPaths';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  ImageButtonSection,
  LeftSection,
  NoSite,
  RightSection,
  ScreenName,
  SiteName,
  Wrapper,
} from '@/templates/MainTemplate/Appbar/Appbar.styles';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import { IconButton as DesignSystemIconButton } from '@/designSystem/components/buttons';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import EnrollmentStatusLabel from '@/organisms/EnrollmentStatusLabel';
import { sizes } from '@/assets/styles/media';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { PathnameKeys, ScreenTitleByPathname } from './Appbar.types';

const image = {
  active: {
    src: images.textIconButtonArrow.image,
    alt: images.textIconButtonArrow.text,
  },
  disabled: {
    src: images.textIconButtonArrowDisabled.image,
    alt: images.textIconButtonArrowDisabled.text,
  },
};

const Appbar = () => {
  const {
    sites: {
      data: { sites, currentSite },
    },
    misc: { hamburgerOpen },
    loading: { loading },
  } = useSelector((state: RootState) => state);
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const { fetchChangeHamburger } = useMisc();
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const location = useLocation();

  const enrollmentStatus = currentSite?.enrollmentStatus;

  const handleChangeHamburger = () => {
    fetchChangeHamburger(!hamburgerOpen);
  };

  const handleManageSites = () => {
    navigate(PATHS.SCREEN_SETTING_MY_SITES.pathname);
  };

  const screenTitle = useMemo(() => {
    const currentPath = location.pathname.split('/');
    const programName = getProgramNameFromSite(currentSite);
    if (currentPath.length > 2) {
      const key = currentPath[2] as PathnameKeys;
      return t(ScreenTitleByPathname(programName)[key]);
    }
    return '';
  }, [location.pathname, t, currentSite?.program?.program]);

  const isSettingMenu = useMemo(() => {
    const currentPath = location.pathname.split('/');
    return Boolean(currentPath.includes('setting'));
  }, [location.pathname]);

  useEffect(() => {
    if (isMobileWindowSize) {
      fetchChangeHamburger(true);
    }
  }, [isMobileWindowSize]);

  return (
    <Wrapper>
      <Container>
        <ImageButtonSection>
          <IconButton icon={images.hamburger} onClick={handleChangeHamburger} />
        </ImageButtonSection>
        <LeftSection>
          <ScreenName>{screenTitle}</ScreenName>
          {sites.length === 0 && !loading ? (
            <NoSite>{t('screens.main.appbar.noSite')}</NoSite>
          ) : (
            <SiteName>{currentSite?.name}</SiteName>
          )}

          <EnrollmentStatusLabel enrollmentStatus={enrollmentStatus} />
        </LeftSection>
        <RightSection>
          {!isSettingMenu && (
            <DesignSystemIconButton
              image={image}
              title={t('screens.main.settings.mySites.manageSites.title')}
              onClick={handleManageSites}
              padding="12px 16px"
              gap="10px"
            />
          )}
        </RightSection>
        <ImageButtonSection>
          <IconButton icon={images.more} onClick={handleManageSites} />
        </ImageButtonSection>
      </Container>
    </Wrapper>
  );
};

export default Appbar;
