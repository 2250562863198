/* eslint-disable */
// typescript-eslint/no-explicit-any
/* ts-ignore */
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import _ from 'lodash';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import { images, theme } from '@/assets/styles';
import Tabs from '@/molecules/Tabs';
import Dropdown from '@/molecules/Dropdown';
import { TimePeriod } from '@/types/usagePattern';
import { useWindowSize } from '@/helpers/WindowHelper';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import { UnitHelper } from '@/helpers/UnitHelper';
import { IconButton, LinkButton } from '@/designSystem/components/buttons';
import { colors } from '@/designSystem/colors';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { IUsagePatternViewProps } from './index.types';
import {
  Wrapper,
  Header,
  GraphContainer,
  GraphDescriptionContainer,
  DropdownContainer,
  GraphSection,
  GraphTitle,
  GraphTitleContainer,
  ChartExtremeValueIndicatorContainer,
  MobileTitle,
  MobileWrapper,
} from './index.styles';
import { UsageMinMax } from './UsageMinMax/UsageMinMax';
import { ZoomSection } from './ZoomSection/ZoomSection';
import {
  getDayTableTick,
  getDayTickText,
  getMonthTableTick,
  getMonthTickText,
  getWeekTableTick,
  getWeekTickText,
  getYearTableTick,
  getYearTickText,
} from './Helpers/GetGraphTexts';
import { getMinMaxDescription, momentFormatByPeriod } from './Helpers/GetMinMaxDescription';
import { getGraphLayout } from './Helpers/GetGraphLayout';

const UsagePatternView = ({
  items,
  selectedPeriodFilter,
  onTabClick,
  onRange,
  onPrevRange,
  onNextRange,
  language,
}: IUsagePatternViewProps) => {
  const { t, i18n } = useTranslation('common');
  const [width] = useWindowSize();
  const [newWidth, setNewWidth] = useState(0);
  const [widthGraphContainer, setWidthGraphContainer] = useState<any>(0);

  const {
    timeList,
    range,
    graph = [],
    highestValue,
    lowestValue,
    lowestPeriodTimestamp,
    highestPeriodTimestamp,
  } = items;
  const [parsedGraph, setParsedGraph] = useState<any>([]);

  const refZoomOutButton = useRef<HTMLButtonElement>(null);
  const refZoomInButton = useRef<HTMLButtonElement>(null);
  const refGraphContainer = useRef();
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const endWidth = widthGraphContainer + 1000;
  const amount = (endWidth + widthGraphContainer) / 10;
  const zoom = ((newWidth - widthGraphContainer) / amount) * 10;

  const graphLayout = getGraphLayout({ width: newWidth, parsedGraph, selectedPeriodFilter, highestValue });
  const lowestPeriodDescription = getMinMaxDescription({
    timestamp: lowestPeriodTimestamp,
    format: momentFormatByPeriod[selectedPeriodFilter],
    upperCase: selectedPeriodFilter !== TimePeriod.day,
  });

  const highestPeriodDescription = getMinMaxDescription({
    timestamp: highestPeriodTimestamp,
    format: momentFormatByPeriod[selectedPeriodFilter],
    upperCase: selectedPeriodFilter !== TimePeriod.day,
  });

  useEffect(() => {
    if (graph && !!selectedPeriodFilter) {
      const helpers = getGraphHelpers(selectedPeriodFilter, zoom);
      const newTickTexts = helpers.getTicktext();
      const tableTickTexts = helpers.getTableTick();

      setParsedGraph(
        graph.map((value, indexGraph) => ({
          tickLabel: newTickTexts[indexGraph],
          id: indexGraph,
          val: value,
          tableTickLabel: tableTickTexts[indexGraph],
        })),
      );
    }
  }, [graph, newWidth, selectedPeriodFilter, zoom, amount, widthGraphContainer]);

  const getGraphHelpers = useCallback(
    (selectedPeriodFilter: TimePeriod, zoom: number) => {
      const yearList: Array<string> = Object.values(
        t('common.year.short', {
          returnObjects: true,
        }),
      );

      switch (selectedPeriodFilter) {
        case TimePeriod.day:
          return {
            getTableTick: () => getDayTableTick(),
            getTicktext: () => getDayTickText({ zoom }),
          };
        case TimePeriod.week:
          return {
            getTableTick: () => getWeekTableTick(),
            getTicktext: () => getWeekTickText(),
          };
        case TimePeriod.month:
          return {
            getTableTick: () => getMonthTableTick({ range }),
            getTicktext: () => getMonthTickText({ zoom, graphData: graph, range }),
          };
        case TimePeriod.year:
          return {
            getTableTick: () => getYearTableTick({ yearList }),
            getTicktext: () => getYearTickText({ yearList, zoom }),
          };
        default:
          return {
            getTableTick: () => [],
            getTicktext: () => [],
          };
      }
    },
    [selectedPeriodFilter],
  );

  const graphData = [
    {
      type: 'bar',
      y: parsedGraph.map((val: any) => {
        return val.val;
      }),
      marker: {
        color: colors.accent,
      },
      hovertemplate: '<b>%{y}kWh</b><extra></extra>',
    },
  ] as Plotly.Data[];

  const onClickZoomOut = () => {
    setNewWidth((w) => w - amount);
    const newZoom = Math.round(100 - zoom);
    if (newZoom >= 100) {
      refZoomInButton?.current?.focus();
      return;
    }
    refZoomOutButton?.current?.focus();
    sentEvent('zoomout_graph', PATHS.ZOOM_OUT_GRAPH);
  };

  const onClickZoomIn = () => {
    setNewWidth((w) => w + amount);
    refZoomInButton?.current?.focus();
    sentEvent('zoomin_graph', PATHS.ZOOM_IN_GRAPH);
  };

  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const makeTimeList = () => {
    if (timeList.length === 1 && timeList[0] === '') {
      return [];
    }

    return timeList.map((text: string) => ({ id: text, name: text, text }));
  };

  const weekListAccessibility: any = t('common.week.long', {
    returnObjects: true,
  });

  const graphDescriptionTableHTML = htmlTableRenderer(
    t('screens.main.usagePattern.usagePatternScreen.title'),
    parsedGraph.map((val: any) => {
      return val.tableTickLabel;
    }),
    parsedGraph.map((val: any) => {
      return val.val;
    }),
    'kWh',
    i18n.language.toLocaleLowerCase(),
    '',
    //@ts-ignore
    weekListAccessibility,
  );
  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);

  const currentIndex = timeList.findIndex((text: string) => text === range);
  const isSidebarVisible = width < 1000;
  const maxOverlapWidth = width < 500;

  const actualTimeList = makeTimeList();

  useLayoutEffect(() => {
    if (refGraphContainer) {
      // @ts-ignore
      setWidthGraphContainer(refGraphContainer.current?.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (width || widthGraphContainer) {
      setNewWidth(widthGraphContainer);
    }
  }, [width, selectedPeriodFilter, widthGraphContainer]);

  return (
    <Wrapper>
      <MobileWrapper>
        <Header>
          <DropdownContainer id="dropdownContainer">
            <IconButton
              onClick={onPrevRange}
              image={{
                active: {
                  src: images.datepickerArrowLeft.image,
                  alt: images.datepickerArrowLeft.text,
                },
                disabled: {
                  src: images.datepickerArrowLeft.image,
                  alt: images.datepickerArrowLeft.text,
                },
              }}
              disabled={currentIndex === timeList.length - 1 || actualTimeList.length === 0}
              ariaLabel={t('common.generalButtons.previous')}
              padding="6px 8px"
            />
            <Dropdown
              accessibilityLabel={t('screens.main.usagePattern.usagePatternScreen.dropdownLabel')}
              items={actualTimeList}
              placeholder={t('screens.main.usagePattern.usagePatternScreen.graph.noData')}
              values={[range]}
              onChange={(name) => onRange(name[0] as string)}
              hideBoxShadow
              hideBackground
            />
            <IconButton
              onClick={onNextRange}
              image={{
                active: {
                  src: images.datepickerArrowRight.image,
                  alt: images.datepickerArrowRight.text,
                },
                disabled: {
                  src: images.datepickerArrowRight.image,
                  alt: images.datepickerArrowRight.text,
                },
              }}
              disabled={currentIndex === 0 || actualTimeList.length === 0}
              ariaLabel={t('common.generalButtons.next')}
              padding="6px 8px"
            />
          </DropdownContainer>
          <Tabs
            tabs={[
              {
                id: 0,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.day'),
                tabName: TimePeriod.day,
              },
              {
                id: 1,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.week'),
                tabName: TimePeriod.week,
              },
              {
                id: 2,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.month'),
                tabName: TimePeriod.month,
              },
              {
                id: 3,
                title: t('screens.main.usagePattern.usagePatternScreen.graph.filter.year'),
                tabName: TimePeriod.year,
              },
            ]}
            selected={selectedPeriodFilter}
            onClick={(val) => onTabClick(val as TimePeriod)}
            hideBorder
          />
          <MobileTitle>{t('screens.main.usagePattern.usagePatternScreen.title')}</MobileTitle>
        </Header>
        <GraphSection>
          <>
            <GraphTitleContainer>
              <GraphTitle>{t('screens.main.usagePattern.usagePatternScreen.graph.title')}</GraphTitle>
              {!maxOverlapWidth && parsedGraph.length >= 8 && (
                <ZoomSection
                  refZoomOutButton={refZoomOutButton}
                  refZoomInButton={refZoomInButton}
                  onPressZoomIn={onClickZoomIn}
                  onPressZoomOut={onClickZoomOut}
                  currentZoom={`${t('screens.main.usagePattern.usagePatternScreen.graph.zoom')}: ${Math.round(
                    100 + zoom,
                  )}%`}
                  zoomOutDisabled={newWidth <= widthGraphContainer}
                  zoomInDisabled={newWidth >= widthGraphContainer + 4000}
                />
              )}
            </GraphTitleContainer>
            {/* @ts-ignore */}
            <GraphContainer ref={refGraphContainer} aria-hidden="true">
              <Plotly
                data={graphData}
                layout={graphLayout}
                config={{
                  displayModeBar: false,
                }}
                style={{
                  maxWidth:
                    width > 1920
                      ? widthGraphContainer
                      : `calc(100vw - ${isMobileWindowSize ? '0px' : '175px'} - ${isSidebarVisible ? '100' : '420'}px)`,
                  maxHeight: `400px`,
                  overflow: 'auto',
                }}
              />
            </GraphContainer>
            <GraphDescriptionContainer>
              <LinkButton
                onClick={handleGraphDescription}
                title={t('common.generalButtons.graphDescription')}
                underline={false}
                ariaLabel={t('common.generalButtons.graphDescription')}
                role="link"
                padding="12px 6px"
                fontSize={isMobileWindowSize ? '17px' : '15px'}
              />
            </GraphDescriptionContainer>
            {maxOverlapWidth && parsedGraph.length >= 8 && (
              <ZoomSection
                refZoomOutButton={refZoomOutButton}
                refZoomInButton={refZoomInButton}
                onPressZoomIn={onClickZoomIn}
                onPressZoomOut={onClickZoomOut}
                currentZoom={`${t('screens.main.usagePattern.usagePatternScreen.graph.zoom')}: ${Math.round(
                  100 - zoom,
                )}%`}
                zoomOutDisabled={newWidth <= widthGraphContainer}
                zoomInDisabled={newWidth >= widthGraphContainer + 4000}
              />
            )}
          </>
        </GraphSection>
      </MobileWrapper>
      <ChartExtremeValueIndicatorContainer>
        <UsageMinMax
          title={t(`screens.main.usagePattern.usagePatternScreen.graph.legend.highest.${selectedPeriodFilter}`)}
          icon={isMobileWindowSize ? images.arrowUpExtremeHighMobile : images.arrowUpExtremeHigh}
          textColor={theme.colors.palette.highest}
          value={UnitHelper.formatEnergyValue(highestValue * 1000, 2)}
          description={highestPeriodDescription}
        />
        <UsageMinMax
          title={t(`screens.main.usagePattern.usagePatternScreen.graph.legend.lowest.${selectedPeriodFilter}`)}
          icon={isMobileWindowSize ? images.arrowDownExtremeLowMobile : images.arrowDownExtremeLow}
          textColor={theme.colors.palette.lowest}
          value={UnitHelper.formatEnergyValue(lowestValue * 1000, 2)}
          description={lowestPeriodDescription}
        />
      </ChartExtremeValueIndicatorContainer>
    </Wrapper>
  );
};

export default UsagePatternView;
