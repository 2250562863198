/* eslint-disable camelcase */
import { regEmail as isEmailValid } from '@/helpers/RegexHelper';

const isPhoneValid = (phone: string): boolean => {
  return phone[0] !== '+1' && phone[6] !== '+1' && phone[0] !== '+0' && phone[6] !== '+0' && phone.length === 16;
};

const isTestAccount = (account: string): boolean | string => {
  return account && account.substring(0, 8) === '77777777' && account.length === 10;
};

const isAccountProperLength = (data: { account: string; min: number; max: number }): boolean => {
  const { account, min, max } = data;

  return min <= account.length && account.length <= max;
};

export const validation = {
  PGE_ELRP_A6: (data: {
    servicePhone: string;
    serviceEmail: string;
    serviceAccountNumber: string;
    accountMinLength: number;
    accountMaxLength: number;
  }): Array<string> => {
    const {
      servicePhone: phone,
      serviceEmail: email,
      serviceAccountNumber: account,
      accountMaxLength,
      accountMinLength,
    } = data;

    const errors = [];
    if (phone && !isPhoneValid(phone)) {
      errors.push('servicePhone');
    }

    if (email && !isEmailValid(email)) {
      errors.push('serviceEmail');
    }

    if (
      account &&
      !isTestAccount(account) &&
      !isAccountProperLength({ account, min: accountMinLength, max: accountMaxLength })
    ) {
      errors.push('serviceAccountNumber');
    }

    return errors;
  },

  SCE_ELRP_A6: (data: {
    serviceAccountNumber: string;
    accountMinLength: number;
    accountMaxLength: number;
    zipCode: string;
  }): Array<string> => {
    const { serviceAccountNumber: account, zipCode, accountMaxLength, accountMinLength } = data;

    const errors = [];
    if (zipCode && zipCode.length < 5) {
      errors.push('serviceZipCode');
    }

    if (
      account &&
      !isTestAccount(account) &&
      (account[0] !== '8' || !isAccountProperLength({ account, min: accountMinLength, max: accountMaxLength }))
    ) {
      errors.push('serviceAccountNumber');
    }

    return errors;
  },
};
