import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import AvailableProgramView from './index.view';

const AvailableProgramContainer = () => {
  const {
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  const { fetchAgreeAvailable } = useEnrollment();

  const handleChangeAgree = () => {
    fetchAgreeAvailable(!enrollmentInfo.agreeAvailable);
  };

  const handleLink = (url: string) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'browser', message: url });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <AvailableProgramView
      program={currentProgram.program}
      agree={enrollmentInfo.agreeAvailable}
      onChangeAgree={handleChangeAgree}
      onOpenLink={handleLink}
    />
  );
};

export default AvailableProgramContainer;
