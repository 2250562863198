import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { loginRedirectError } from '@/apis/auth';
import { APP_ENV } from '@/constants/environment';

const missingKeysSent = new Map();
const MAX_KEY_AGE = 300000; // 5 minutes
const deleteOldKeys = () => {
  const now = Date.now();
  Array.from(missingKeysSent.entries()).forEach(([key, timestamp]) => {
    if (now - timestamp > MAX_KEY_AGE) {
      missingKeysSent.delete(key);
    }
  });
};
setInterval(deleteOldKeys, 300000); // 5 minutes
/* eslint-disable global-require */
i18n.use(initReactI18next).init({
  debug: false,
  lng: 'eng',
  fallbackLng: 'eng',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    eng: {
      common: require('./eng/common.json'),
    },
    esp: {
      common: require('./esp/common.json'),
    },
    cn: {
      common: require('./cn/common.json'),
    },
  },
  saveMissing: true,
  missingKeyHandler: (lng, ns, key) => {
    try {
      const mapKey = `${lng}:${key}`;
      const now = Date.now();
      if (key && (!missingKeysSent.has(mapKey) || now - missingKeysSent.get(mapKey) > MAX_KEY_AGE)) {
        const string = `Missing translation key: ${lng}:${key}`;
        loginRedirectError(string, null);
        missingKeysSent.set(mapKey, now);
      }
    } catch (e) {
      console.log(e);
    }
  },
  parseMissingKeyHandler: (key) => {
    if (APP_ENV === 'production') {
      return '';
    }
    return key;
  },
});

export default i18n;
