import { combineReducers } from 'redux';
import events from './events';
import eventsHistory from './eventsHistory';
import moneyEarnedHistory from './earningsHistory';
import historyScreen from './historyScreen';

const historyStore = combineReducers({
  events: eventsHistory,
  earnings: moneyEarnedHistory,
});
export const drStore = combineReducers({
  events,
  history: historyStore,
  historyScreen,
});
