import styled from 'styled-components';
import { media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 0 20px;
  height: 100vh;
  ${media.lg`
    padding: 40px 20px 0 20px;
    height: unset;
  `};
`;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const TitleContainer = styled.div`
  display: none;
  padding: 0 16.5px;
  ${media.lg`
    display: block;
  `};
`;
export const Container = styled.div`
  padding: 30px;
`;

export const ShareContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 800;
  color: ${colors.blackGrey};
  padding: 20px 0;
  text-align: center;
`;

export const Env = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
  white-space: pre;
`;

export const Copyright = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
  padding: 8px 0;
`;

export const ShareIconDesc = styled.div`
  display: block;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
`;

export const Clickable = styled.div`
  display: block;
  cursor: pointer;
`;
