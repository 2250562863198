import { take, put, call, fork, all } from 'redux-saga/effects';
import * as apiPrimary from '@/apis/primary';
import { DASHBOARD_REQUEST, DASHBOARD_SUCCESS, DASHBOARD_FAILURE } from '@/stores/dashboard';
import { LOADED, LOADING } from '@/stores/loading';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* dashboard(siteId: string) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.getDashboard, { siteId });

    if (!response?.success) {
      yield put({ type: DASHBOARD_FAILURE, error: response.error?.message || '' });
      return;
    }

    const { data } = response;

    yield put({ type: DASHBOARD_SUCCESS, dashboard: data });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: DASHBOARD_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchDashboard() {
  while (true) {
    const { siteId } = yield take(DASHBOARD_REQUEST);
    yield fork(dashboard, siteId);
  }
}

export default function* settingSaga() {
  yield all([fork(watchDashboard)]);
}
