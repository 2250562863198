import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 26px;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h2`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #69686a;
`;

export const Description = styled.p`
  font-family: 'Lato';
  font-size: 18px;
  line-height: 22px;

  color: #69686a;
`;
