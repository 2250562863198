import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  background-color: ${colors.white};
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 30px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;

  width: 350px;
  height: 181px;

  margin: 0 auto;
  padding: 16px 0 16px 0;

  & > img {
    height: 100%;
  }
`;

export const ImageButtonSection = styled.div`
  display: block;
  position: fixed;
  top: 30px;
  right: 50px;

  ${media.lg`
    display: none;
    margin-right: 10px;
  `}
`;
