import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface ButtonProps {
  padding?: string;
  flex?: number;
  showBorder: boolean;
  warning: boolean;
  maxWidth?: string;
}

const warningBoxShadow = '0px 0px 0px 4px rgba(215, 2, 26, 0.2);';
const primaryBoxShadow = '0px 0px 0px 4px rgba(69, 129, 29, 0.2);';

export const Button = styled.button<ButtonProps>`
  ${typography.sm};
  color: ${(props) => (props.warning ? colors.bad : colors.primary)};
  font-weight: 900;
  border-radius: 8px;
  ${(props) => {
    if (!props.showBorder) {
      return css`
        border: none;
      `;
    }
    if (props.showBorder && props.warning) {
      return css`
        border: 1px solid ${colors.bad};
      `;
    }
    return css`
      border: 1px solid ${colors.primary};
    `;
  }};
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: pointer;
  padding: ${(props) => props.padding};
  flex: ${(props) => props.flex};
  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  &:hover {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
  }
  &:focus {
    background-color: ${(props) => (props.warning ? colors.badFocus : colors.hoverFocus)};
    box-shadow: ${(props) => (props.warning ? warningBoxShadow : primaryBoxShadow)};
  }
  &:disabled {
    cursor: default;
    ${(props) =>
      props.showBorder &&
      css`
        border: 1px solid ${colors.grey};
      `};
    color: ${colors.grey};
  }
  &:disabled:hover {
    color: ${colors.grey};
    background-color: transparent;
  }
`;

export const Img = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
