import { Programs, Program, Screen, EnrollmentInfo, ProgramName } from '@/types/enrollment';
import { updateScreenSteps } from '@/helpers/ScreenStepsHelper';

export const keepScreenStateFlag = 'keepScreenState';

export const TMP_PATHS: { [k: string]: string } = {
  ROOT: '/',
  REDIRECT: '/redirect',
  NAV_PROGRAM_ENROLLMENT: '/enrollment/programEnrollment',
  NAV_PROFILE_SETUP: '/enrollment/profile',
  SCREEN_ENROLLMENT_PHONE_SMS: '/enrollment/phonesms',
  SCREEN_ENROLLMENT_PHONE_OTP: '/enrollment/phoneotp',
  NAV_LOCATION_SETUP: '/enrollment/location',
  NAV_SELECT_UTILITY: '/enrollment/utility',
  NAV_SELECT_PROVIDER: '/enrollment/provider',
  NAV_AUTH_GUIDE_FIND: '/enrollment/findProgram',
  NAV_CPA_VERIFY: '/enrollment/verification',
  NAV_CPA_NOT_VERIFIED: '/enrollment/notVerified',
  NAV_AVAILABLE_PROGRAM_NORMAL: '/enrollment/availableProgram',
  NAV_CPA_VOICEPHONE: '/enrollment/cpaphone',
  NAV_CPA_PROGRAM_LIST: '/enrollment/cpaprogramList',
  NAV_CONNECT_UTILITY_NORMAL: '/enrollment/connectToUtility',
  NAV_CPA_SURVEY: '/enrollment/survey/multiple',
  NAV_CPA_HEAR: '/enrollment/survey/multiple',
  NAV_FRESNO_HEAR: '/enrollment/survey/single',
  NAV_SINGUP_COMPLETE: '/enrollment/signupCompleted',
  NAV_WEB_STORE: '/enrollment/webStore',
  NAV_DASHBOARD: '/main/dashboard',
  NAV_CONFIRM_PROGRAM: '/enrollment/confirmProgram',
  NAV_AVAILABLE_PROGRAM_NORMAL_NO_THANKS: '/enrollment/availableProgram/appOnly',
};

export const findProgram = (programs: Programs, programName: ProgramName | string) => {
  return programs.availablePrograms.filter((_program) => _program.program === programName)[0];
};

export const findScreen = (screens: Screen[]) => {
  const routeName = findRouteName(window.location);

  return screens.filter((screen) => screen.name === routeName)[0];
};

export const findRouteName = (location: Location) => {
  return Object.keys(TMP_PATHS).find((key) => TMP_PATHS[key] === location.pathname);
};

export const nextScreenStep = (screens: Screen[], location: Location, enrollmentInfo: EnrollmentInfo, step = 1) => {
  const routeName = findRouteName(location);
  const updatedScreenSteps = updateScreenSteps(screens, enrollmentInfo);
  const index = updatedScreenSteps.findIndex((screen) => screen.name === routeName) + step;
  const changeScreen = updatedScreenSteps[index];
  const nextScreenStep = !changeScreen && step === -1 ? TMP_PATHS.NAV_LOCATION_SETUP : TMP_PATHS[changeScreen.name];
  return nextScreenStep;
};

export const isNoThanks = (screens: Screen[]) => {
  const curScreen = findScreen(screens);

  return !!curScreen?.noThanks;
};

export const canShowNoThanks = (screens: Screen[], programs: Programs) => {
  const curScreen = findScreen(screens);

  if (!curScreen?.noThanks) {
    return false;
  }

  return programs.availablePrograms.findIndex((el) => el.program === curScreen?.noThanks) !== -1;
};

export const changeProgram = (
  programs: Programs,
  programName: ProgramName | string,
  fetchChangeProgram: (program: Program) => void,
): Program => {
  const currentProgram = findProgram(programs, programName);
  fetchChangeProgram(currentProgram);

  return currentProgram;
};

export const moveChangeScreen = (
  programs: Programs,
  programName: ProgramName,
  fetchChangeProgram: (program: Program) => void,
): Screen[] => {
  const changeScreenStep = changeProgram(programs, programName, fetchChangeProgram).screenStep;

  return changeScreenStep;
};

export const moveNoThanksScreen = (
  screens: Screen[],
  programs: Programs,
  fetchChangeProgram: (program: Program) => void,
) => {
  const curScreen = findScreen(screens);
  const noThanksScreen = curScreen?.noThanks;
  const changeScreen = changeProgram(programs, noThanksScreen, fetchChangeProgram).screenStep[0];
  return TMP_PATHS[changeScreen.name];
};
