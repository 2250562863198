import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

type TitleProps = {
  isError?: boolean;
};

export const Wrapper = styled.div`
  margin: 16px;
  border-radius: 8px;
  display: grid;
  gap: 20px;
  background-color: ${colors.white};
  ${media.lg`
    margin: unset;
    border-radius: unset;
    background-color: unset;
  `}
  padding 10px;
`;

export const Description = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};

    & > p {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  `,
);

export const AgreeBox = styled.div(
  ({ theme }) => css`
    ${helpers.flexSet('row', 'flex-start', 'flex-start')};
    ${helpers.borderSet({
      borderRadius: theme.borders.default.radius,
      borderWidth: theme.borders.default.width,
      borderColor: theme.colors.primary[100],
    })}
    ${helpers.colorSet({ backgroundColor: theme.colors.primary[50] })};

    padding: 12px 20px;
    margin: 16px 0;
  `,
);

export const Terms = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-left: 10px;
    align-self: center;
  `,
);

export const LinkText = styled.a(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.link.default })};
  `,
);

export const UpperMore = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-top: 16px;
    margin-bottom: 16px;
  `,
);

export const More = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
    margin-bottom: 44px;
  `,
);

export const Title = styled.p<TitleProps>`
  font-size: 24px;
  color: ${({ isError }) => (isError ? 'red' : `${colors.primary}`)};
  margin-bottom: 20px;
  font-weight: 600;
  white-space: pre-line;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  margin-top: 30px;
`;

export const Message = styled.span`
  font-size: ${typography}.xs;
  font-weight: 700;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: nowrap;
  font-size: 13px;

  ${media.lg`
  font-size: 15px;
`}

  ${media.xl`
  font-size: 18px;
`}
`;

export const Buttons = styled.div`
  ${helpers.flexSet('row', 'center', 'center')};
  margin-top: 20px;
  padding: 5px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const TitleContainer = styled.div`
  padding: 0 16.5px;
  display: none;
  margin-bottom: 30px;
  ${media.lg`
    display: block;
  `}
`;
