import axios from 'axios';
import { forceLogUserOut, getLocalToken } from '@/services/token';
import { OLIVINE_AUTH_HOST } from '@/constants/environment';
import withImitateUserHeader from '@/services/withImitateUserHeader';
import { store } from '@/constants/configureStore';
import { USER_INFO_SUCCESS } from '@/stores/userInfo';
import { UserInfo } from '@/types/userInfo';

export const axiosApiRefreshToken = axios.create({
  baseURL: OLIVINE_AUTH_HOST,
  withCredentials: false,
});
let requestInterceptorIdRefreshTokenApi: number | undefined;
let refreshTokenPromise: Promise<UserInfo> | undefined;
let requestInterceptorId: number | undefined;
let responseInterceptorId: number | undefined;
if (requestInterceptorIdRefreshTokenApi === undefined) {
  requestInterceptorIdRefreshTokenApi = axiosApiRefreshToken.interceptors.request.use(
    async (config) => {
      const accessTokenByCookies = getLocalToken();

      // eslint-disable-next-line no-param-reassign
      config.headers = withImitateUserHeader({
        Authorization: `Bearer ${accessTokenByCookies}`,
        Accept: 'application/json',
        'App-Version': 'web',
      });
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );
}

export const interceptor = () => {
  if (requestInterceptorId === undefined) {
    requestInterceptorId = axios.interceptors.request.use(
      async (config) => {
        const accessTokenByCookies = getLocalToken();

        // eslint-disable-next-line no-param-reassign
        config.headers = withImitateUserHeader({
          ...config.headers,
          Authorization: `Bearer ${accessTokenByCookies}`,
          'App-Version': 'web',
        });

        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  }
  if (responseInterceptorId === undefined) {
    responseInterceptorId = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err.config;
        const accessTokenByCookies = getLocalToken();

        if (originalConfig && accessTokenByCookies && err?.response?.status === 401) {
          try {
            if (!refreshTokenPromise) {
              const data = store.getState();
              refreshTokenPromise = axiosApiRefreshToken
                .post(`${OLIVINE_AUTH_HOST}/oauth2/refresh`, {
                  refreshToken: data.userInfo.data.userInfo.oidcRefreshToken,
                })
                .then(({ data }) => {
                  refreshTokenPromise = undefined;
                  return data;
                })
                .catch(() => {
                  forceLogUserOut();
                });
            }
            return refreshTokenPromise.then((data) => {
              store.dispatch({
                type: USER_INFO_SUCCESS,
                userInfo: data,
              });
              originalConfig.headers.Authorization = `Bearer ${data.oidcAccessToken}`;
              return axios(originalConfig);
            });
          } catch (err) {
            console.error('error refreshing token', err);

            forceLogUserOut();
          }
        }

        return Promise.reject(err);
      },
    );
  }
};
