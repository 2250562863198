import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import { useSelector } from 'react-redux';
import { useAuth, usePrograms } from '@/hooks/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import ModalDeleteAccount from '@/organisms/ModalDeleteAccount';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { FetchingStatus } from '@/hooks/types';
import { MenuItem } from './MenuItem';
import { Divider, EtcMenuContainer, MenuContainer, MenuWrapper } from './SettingMenu.styles';

export enum SettingMenuEnum {
  default = '',
  account = 'account',
  sites = 'sites',
  about = 'about',
  language = 'language',
  support = 'support',
  program = 'program',
  referral = 'referral',
}

interface MenuGAEventParam {
  click_type: string;
  click_description: string;
}

interface SettingMenuState {
  selectedMenu: SettingMenuEnum;
}

const SettingMenu = () => {
  const { t } = useTranslation('common');
  const {
    setting: {
      data: { language },
    },
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  const programsState = usePrograms();
  const [state, setState] = useState<SettingMenuState>({ selectedMenu: SettingMenuEnum.default });
  const [modalDeleteAccount, setModalDeleteAccount] = useState(false);
  const { fetchLogout } = useAuth();
  const navigate = useNavigate();
  const programName = getProgramNameFromSite(currentSite, userInfo.program);
  const context = getProgramContext({ programName });
  const getShouldDisplayReferral = context?.getShouldDisplayReferral();
  const handleMenu = (tabName: SettingMenuEnum, link: string, eventParam: MenuGAEventParam) => {
    if (state.selectedMenu === tabName) {
      return;
    }

    setState({ selectedMenu: tabName });
    if (link) {
      sentEvent('click', eventParam);
      navigate(link);
    }
  };
  const handleChangePassword = () => {
    sentEvent('click', PATHS.LINK_CHANGE_PASSWORD);
    window.open(`https://account.olivineinc.com/Account/ChangePassword?ui_locales=${language}`, '_blank');
  };
  const handleLogout = () => {
    sentEvent('click', PATHS.REQUEST_LOGOUT);
    fetchLogout(userInfo.oidcIdToken, userInfo.id);
  };

  const handleDeleteAccount = () => {
    sentEvent('click', PATHS.POPUP_DELETE_ACCOUNT);
    setModalDeleteAccount(true);
  };

  const handleDeleteAccountClose = (gaMessage?: string) => {
    sentEvent('click', PATHS.POPUP_CLOSE_DELETE_ACCOUNT(gaMessage));
    setModalDeleteAccount(false);
  };

  useEffect(() => {
    setState({ ...state, selectedMenu: window.location.pathname.split('/').pop() as SettingMenuEnum });
  }, [window.location.pathname]);

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      handleDeleteAccount();
    }
  }, [userInfo.isDeleted]);

  useEffect(() => {
    if (!currentSite?.program?.program && programsState.fetchingStatus === FetchingStatus.INITIAL) {
      programsState.fetch();
    }
  }, [currentSite?.program?.program]);

  const programs = programsState.fetchingStatus === FetchingStatus.SUCCESS ? programsState.data : [];
  const selectedProgram = programs?.find((programItem) => programItem.program === programName);
  const fallbackSupportAttribute = selectedProgram?.attributes?.hideSupport;
  const shouldHideSupportTab = currentSite?.program?.attributes?.hideSupport ?? fallbackSupportAttribute;

  return (
    <MenuWrapper>
      <MenuContainer>
        <MenuItem
          onClick={() =>
            handleMenu(SettingMenuEnum.account, PATHS.SCREEN_SETTING_ACCOUNT.pathname, PATHS.NAVIGATION_ACCOUNT)
          }
          title={t('screens.main.settings.account.accountScreen.navigationTitle')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={state.selectedMenu === SettingMenuEnum.account}
          padding="8px 20px"
        />
        <Divider />
        <MenuItem
          onClick={handleChangePassword}
          title={t('common.validations.changePassword.title')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={false}
          padding="8px 20px"
        />
        <Divider />
        <MenuItem
          onClick={() =>
            handleMenu(SettingMenuEnum.program, PATHS.SCREEN_SETTING_PROGRAM.pathname, PATHS.NAVIGATION_PROGRAM_DETAILS)
          }
          title={t('screens.main.settings.programDetails.title')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={state.selectedMenu === SettingMenuEnum.program}
          padding="8px 20px"
        />
        <Divider />
        <MenuItem
          onClick={() =>
            handleMenu(SettingMenuEnum.sites, PATHS.SCREEN_SETTING_MY_SITES.pathname, PATHS.NAVIGATION_MY_SITES)
          }
          title={t('screens.main.settings.mySites.mySitesScreen.title')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={state.selectedMenu === SettingMenuEnum.sites}
          padding="8px 20px"
        />
        <Divider />
        {!shouldHideSupportTab && (
          <>
            <MenuItem
              onClick={() =>
                handleMenu(SettingMenuEnum.support, PATHS.SCREEN_SETTING_SUPPORT.pathname, PATHS.NAVIGATION_SUPPORT)
              }
              title={t('screens.main.settings.support.title')}
              image={{
                active: {
                  src: images.menuActivated.image,
                  alt: images.menuActivated.text,
                },
                disabled: {
                  src: images.menuDeactivated.image,
                  alt: images.menuDeactivated.text,
                },
              }}
              selected={state.selectedMenu === SettingMenuEnum.support}
              padding="8px 20px"
            />
            <Divider />
          </>
        )}
        <MenuItem
          onClick={() =>
            handleMenu(
              SettingMenuEnum.language,
              PATHS.SCREEN_SETTING_LANGUAGE.pathname,
              PATHS.NAVIGATION_LANGUAGE_SELECTION,
            )
          }
          title={t('screens.main.settings.languageSelectionScreen.title')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={state.selectedMenu === SettingMenuEnum.language}
          padding="8px 20px"
        />
        <Divider />
        <MenuItem
          onClick={() => handleMenu(SettingMenuEnum.about, PATHS.SCREEN_SETTING_ABOUT.pathname, PATHS.NAVIGATION_ABOUT)}
          title={t('screens.main.settings.aboutScreen.title')}
          image={{
            active: {
              src: images.menuActivated.image,
              alt: images.menuActivated.text,
            },
            disabled: {
              src: images.menuDeactivated.image,
              alt: images.menuDeactivated.text,
            },
          }}
          selected={state.selectedMenu === SettingMenuEnum.about}
          padding="8px 20px"
        />
        {getShouldDisplayReferral && (
          <>
            <Divider />
            <MenuItem
              onClick={() =>
                handleMenu(SettingMenuEnum.referral, PATHS.SCREEN_SETTING_REFERRAL.pathname, PATHS.NAVIGATION_REFERRAL)
              }
              title={t('screens.main.settings.referralScreen.title')}
              image={{
                active: {
                  src: images.menuActivated.image,
                  alt: images.menuActivated.text,
                },
                disabled: {
                  src: images.menuDeactivated.image,
                  alt: images.menuDeactivated.text,
                },
              }}
              selected={state.selectedMenu === SettingMenuEnum.referral}
              padding="8px 20px"
            />
          </>
        )}
      </MenuContainer>
      <EtcMenuContainer>
        <MenuItem
          onClick={handleDeleteAccount}
          title={t('screens.main.settings.deleteAccountScreen.title')}
          selected={false}
          padding="12px 20px"
          warning
          ariaExpanded={modalDeleteAccount}
          ariaControls="delete-account-popup"
        />
        <MenuItem
          onClick={handleLogout}
          title={t('screens.auth.welcomeScreen.logout')}
          selected={false}
          padding="12px 20px"
        />
      </EtcMenuContainer>
      <ModalDeleteAccount
        show={modalDeleteAccount}
        onClose={handleDeleteAccountClose}
        ariaControlsId="delete-account-popup"
      />
    </MenuWrapper>
  );
};

export default SettingMenu;
