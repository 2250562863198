import { PerEventDisclaimer } from '@/helpers/PerEventDisclaimer';
import { AchievementLevel, SummaryState } from '@/types/dashboard';

export enum PerformanceToDateEnum {
  reduction = 'reduction',
  emissions = 'emissions',
  moneyEarned = 'moneyEarned',
}

export enum EventStatusEnum {
  NoEvent = 'NO_EVENT',
  UpcomingEvent = 'UPCOMING_EVENT',
  OnEvent = 'ON_EVENT',
}

export enum UserStatusEnum {
  DefaultEvent = 'DEFAULT_EVENT',
  EventConfirmed = 'EVENT_CONFIRMED',
  OptOut = 'OPT_OUT',
}

export enum TargetDirectionEnum {
  loadDecrease = 'load_decrease',
  loadIncrease = 'load_increase',
  mixed = 'mixed',
}

export enum OptInModeEnum {
  presumptive = 'PRESUMPTIVE',
  affirmative = 'AFFIRMATIVE',
  confirmationOnly = 'CONFIRMATION_ONLY',
}

export enum OptStateEnum {
  none = 'none',
  acknowledged = 'acknowledged',
  optIn = 'opt_in',
  optOut = 'opt_out',
}

export type DrNearestEvent = {
  eventTimeRange: string;
  length: string;
  end: string;
  canceled: boolean;
  userEventId: string;
  start: string;
  hasBeenNotified: boolean;
  userStatus: UserStatusEnum;
  targetDirection?: TargetDirectionEnum;
  programAttributes?: {
    optInMode: OptInModeEnum;
  };
};

export type TypicalEmissions = {
  time: string;
  emission: number;
};

export type UserEmissions = {
  time: string;
  emission: number;
  inRequestPeriod: boolean;
};

export type TypicalUsage = {
  time: string;
  usage: number;
};

export type UserUsage = {
  time: string;
  usage: number;
  inRequestPeriod: boolean;
};

export interface DrHistoryItem {
  eventId?: string;
  name: string;
  date: string;
  time: string;
  formattedDate?: string;
  percentageChangeInUsage: number; // calculated percentage
  percentageChangeInEmission: number;
  hasUsageData: boolean;
  hadJoined: boolean;
  actualRequestUsage: number;
  actualCo2Emission: number;
  targetDirection: TargetDirectionEnum;
  timeRange: string;
  quickViewData: {
    hadReduced: boolean;
    changeInUsage: number;
    moneyEarned: number;
    typicalDayUsage: number;
    moneyEarnedDisclaimer?: PerEventDisclaimer;
    emissionChangeInUsage: number;
    typicalCo2Impact: number;
  };
  detailData: {
    graphData: DetailGrpahItem;
  };
  userStatus: UserStatusEnum;
}

export interface MoneyEarned {
  [year: string]: {
    summary: EarningSummary;
    history: Array<MoneyEarnedItem>;
    noEarningHistory?: Array<PendingMoneyEarnedItem>;
  };
}

export interface Events {
  [year: string]: {
    summary: EventSummary;
    history: Array<DrHistoryItem>;
  };
}

export enum incentiveKindEnum {
  enrollment = 'ENROLLMENT',
  homeEnergySurvey = 'HOME_ENERGY_SURVEY',
  referral = 'REFERRAL_BONUS',
  event = 'EVENT',
  promotion = 'PROMOTIONAL',
  customerExperienceSurvey = 'CUSTOMER_EXPERIENCE_SURVEY',
  participation = 'PARTICIPATION',
}

export interface MoneyEarnedItem {
  earningId: string;
  earnedDate: string;
  runningBalance: number;
  changeInAmount: number;
  incentiveKind: incentiveKindEnum;
  incentiveKindId: number;
  moneyEarnedHadIncreased: boolean;
  sequenceNumber: number;
  eventId: string;
  name?: string;
  perEventDisclaimer: PerEventDisclaimer;
}

export interface PendingMoneyEarnedItem {
  // Both dates are the same, the backend sends "date" but we update on the frontend to "earnedDate"
  date: string;
  earnedDate: string;
  timeRange: string;
  eventId: string;
  targetDirection: string;
  incentiveKind: incentiveKindEnum;
  isPending: boolean;
}

export interface DetailGrpahItem {
  userEnergyUsage: Array<UserUsage>;
  typicalUsage: Array<TypicalUsage>;
  userEmission: Array<UserEmissions>;
  typicalEmission: Array<TypicalEmissions>;
}

export interface PerformanceToDateForEvent {
  moneyEarned: number;
  level: AchievementLevel;
  electricitySaved: number;
  numRequestsTotal: number;
  percentageChangeInUsage: number;
  historyList: DrHistoryItem[];
  targetDirection: TargetDirectionEnum;
}

export interface EventSummary {
  electricitySaved: number;
  moneyEarned: number;
  numRequestsTotal: number;
  level: AchievementLevel;
  emissionImpact: number;
}

export interface EarningSummary {
  eventMoneyEarned: number;
  otherMoneyEarned: number;
  totalMoneyEarned: number;
  numEvents: number;
  summaryState: SummaryState;
  emissionImpact: number;
}

export interface RecentlyEndedEvent {
  recentlyEndedEventNotified?: boolean;
}

export enum HistoryTabs {
  Events = 'events',
  MoneyEarned = 'moneyEarned',
  QuickView = 'quickView',
  Details = 'details',
}
