import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { ILevel } from '@/molecules/Level/Level.types';
import { AchievementLevel } from '@/types/dashboard';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

const levelColor = (level: AchievementLevel) => {
  switch (level) {
    case AchievementLevel.bronze:
      return colors.bronze;
      break;
    case AchievementLevel.silver:
      return colors.silver;
      break;
    case AchievementLevel.gold:
      return colors.gold;
      break;
    case AchievementLevel.noAct:
    default:
      return colors.grey;
  }
};

export const Wrapper = styled.div``;

export const Container = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  gap: 15px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 53px;
    height: 53px;
    ${media.lg`
    width: 38px;
    height: 38px;
  `};
  }
`;

export const Title = styled.p<ILevel>`
  ${typography.md};
  font-weight: 400;
  line-height: normal;
  color: ${(props) => levelColor(props.level)};
`;

export const TitleContainer = styled.div`
  position: relative;
`;

export const Help = styled.div`
  position: absolute;
  right: -30px;
  bottom: -3px;
`;
