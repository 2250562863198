import React from 'react';
import { useTranslation } from 'react-i18next';
import ConnectToUtility from '@/organisms/ConnectToUtility';
import ModalConnectToUtilityError from '@/organisms/ModalConnectToUtilityError';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import { IConnectToUtilityScreenViewProps } from './index.types';

const ConnectToUtilityScreenView = ({
  disabledNext,
  onNext,
  modalConnectToUtilityError,
  modalConnectToUtilityErrorTitle,
  modalConnectToUtilityErrorOK,
  previousNavigationPath,
}: IConnectToUtilityScreenViewProps) => {
  const { t } = useTranslation('common');
  return (
    <>
      <EnrollmentTemplate
        disabled={disabledNext}
        underline
        navigationTitle={t('screens.enrollment.connectToUtilityScreen.title')}
        navigationPath={PATHS.SCREEN_ENROLLMENT_CONNECT_TO_UTILITY.pathname}
        previousNavigationPath={previousNavigationPath}
        onNext={onNext}
        textNext={t('common.generalButtons.continue')}
      >
        <ConnectToUtility />
      </EnrollmentTemplate>
      <ModalConnectToUtilityError
        show={modalConnectToUtilityError}
        error={
          modalConnectToUtilityErrorTitle === 'Authorization failed: Authorization was canceled.'
            ? t('screens.enrollment.connectToUtilityScreen.error')
            : modalConnectToUtilityErrorTitle
        }
        onOk={modalConnectToUtilityErrorOK}
      />
    </>
  );
};

export default ConnectToUtilityScreenView;
