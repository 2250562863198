import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import { ILocationSetupProps } from '@/organisms/LocationSetup/index.types';
import LocationSetupView from '@/organisms/LocationSetup/index.view';

const LocationSetupContainer = ({ onNext, disabled }: ILocationSetupProps) => {
  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: { enrollmentInfo },
  } = useSelector((state: RootState) => state);

  const { fetchIsBusiness, fetchZipcode, fetchSiteName } = useEnrollment();
  const [errorArr, setErrorArr] = useState<string[] | []>([]);

  useEffect(() => {
    fetchIsBusiness(userInfo.isLocationBusiness);
    fetchZipcode(userInfo.zipcode);
    fetchSiteName(userInfo.siteName);
  }, []);

  const handleChangeBusiness = () => {
    fetchIsBusiness(!enrollmentInfo.isBusiness);
  };

  const handleChangeZipcode = (value: string) => {
    const onlyNumbersRegex = /^[0-9]*$/;
    if (onlyNumbersRegex.test(value)) {
      setErrorArr((err: string[]) => err.filter((e: string) => e !== 'zipcodeError'));
      fetchZipcode(value);
    } else {
      setErrorArr(['zipcodeError']);
    }
  };

  const handleChangeSiteName = (value: string) => {
    fetchSiteName(value);
  };

  return (
    <LocationSetupView
      isBusiness={enrollmentInfo.isBusiness}
      zipCode={enrollmentInfo.zipCode}
      siteName={enrollmentInfo.siteName}
      onChangeBusiness={handleChangeBusiness}
      onChangeZipcode={handleChangeZipcode}
      onChangeSiteName={handleChangeSiteName}
      onNext={onNext}
      disabled={disabled}
      errorArr={errorArr}
    />
  );
};

export default LocationSetupContainer;
