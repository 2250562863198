import React from 'react';
import { useTranslation } from 'react-i18next';
import LocationSetup from '@/organisms/LocationSetup';
import ModalZipcode from '@/organisms/ModalZipcode';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import ModalNoPrograms from '@/organisms/ModalNoPrograms';
import PATHS from '@/types/navigationPaths';
import { ILocationSetupScreenViewProps } from './index.types';

const LocationSetupScreenView = ({
  onNext,
  disabledNext,
  modalZipcode,
  modalZipcodeClose,
  modalZipcodeOk,
  modalZipcodeCancel,
  modalNoPrograms,
  modalNoProgramsOk,
}: ILocationSetupScreenViewProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <EnrollmentTemplate
        disabled={disabledNext}
        underline
        navigationTitle={t('screens.enrollment.locationSetupScreen.title')}
        navigationPath={PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname}
        previousNavigationPath={PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname}
        onNext={onNext}
        textNext={t('common.generalButtons.next')}
      >
        <LocationSetup onNext={onNext} disabled={disabledNext} />
      </EnrollmentTemplate>
      <ModalZipcode
        show={modalZipcode}
        onClose={modalZipcodeClose}
        onOk={modalZipcodeOk}
        onCancel={modalZipcodeCancel}
      />
      <ModalNoPrograms show={modalNoPrograms} onOk={modalNoProgramsOk} />
    </>
  );
};

export default LocationSetupScreenView;
