import moment from 'moment';
import { TimePeriod } from '@/types/usagePattern';

export const getMinMaxDescription = ({
  timestamp,
  format,
  upperCase = true,
}: {
  timestamp: string;
  format: string;
  upperCase?: boolean;
}) => {
  if (upperCase) {
    return moment(timestamp).format(format).toUpperCase();
  }
  return moment(timestamp).format(format);
};

export const momentFormatByPeriod = {
  [TimePeriod.day]: 'h a',
  [TimePeriod.week]: 'ddd',
  [TimePeriod.month]: 'M/D',
  [TimePeriod.year]: 'MMM',
};
