import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import Impact from '@/molecules/Impact';
import { images } from '@/assets/styles';
// import IconButton from '@/atoms/IconButton';
import ModalEnvironmentalImpact from '@/organisms/ModalEnvironmentalImpact';
import { useTranslation } from 'react-i18next';
import { IconButton, PrimaryButton } from '@/designSystem/components';
import { ProgramName } from '@/types/enrollment';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import useEnvironmentalImpact from '@/hooks/useEnvironmentalImpact';
import { FetchingStatus } from '@/hooks/types';
import { colors } from '@/designSystem/colors';
import ReactLoading from 'react-loading';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import {
  ImpactGroup,
  Container,
  Help,
  Section,
  SectionTitleConatiner,
  SectionTitle,
  SectionDescription,
  SectionHeader,
  ImpactLine,
  LoadingContainer,
  ErrorDescription,
  ErrorContainer,
} from './EnvironmentalImpact.styles';
import { EnvironmentImpactProps } from './EnvironmentalImpact.types';
import SeasonSelector from '../SeasonSelector/seasonSelector';

const EnvironmentalImpact = ({ ariaControls }: EnvironmentImpactProps) => {
  const { t } = useTranslation('common');
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  const [stateModal, setStateModal] = useState({ environmentalImpact: false });
  const [currentSeason, setCurrentSeason] = useState(new Date().getFullYear());
  const environmentalImpactState = useEnvironmentalImpact(currentSite?.id || '', currentSeason);
  const programName = getProgramNameFromSite(currentSite);
  const context = getProgramContext({ programName });
  const shouldDisplayCO2FeatureChanges = context?.getShouldDisplayCO2Emissions();
  const suffixForCO2Languages = shouldDisplayCO2FeatureChanges ? 'CO2' : '';

  const handleHelp = () => {
    sentEvent('click', PATHS.HELP_ENVIRONMENTAL_IMPACT);
    setStateModal({ ...stateModal, environmentalImpact: true });
  };

  const handleModalEnvironmentalImpactClose = () => {
    setStateModal({ ...stateModal, environmentalImpact: false });
  };

  const isLoading = environmentalImpactState.fetchingStatus === FetchingStatus.LOADING;
  const isError = environmentalImpactState.fetchingStatus === FetchingStatus.ERROR;
  const nextSeasonExists =
    environmentalImpactState.fetchingStatus === FetchingStatus.SUCCESS &&
    environmentalImpactState.data.nextSeasonExists;
  const previousSeasonExists =
    environmentalImpactState.fetchingStatus === FetchingStatus.SUCCESS &&
    environmentalImpactState.data.previousSeasonExists;

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionTitleConatiner>
            <SectionTitle>{t('screens.main.dashboard.dashboardScreen.environmentalImpact.title')}</SectionTitle>
            {programName !== ProgramName.DATA_ONLY && (
              <SectionDescription>
                {t('screens.main.dashboard.dashboardScreen.environmentalImpact.description')}
              </SectionDescription>
            )}
          </SectionTitleConatiner>
          {programName === ProgramName.DATA_ONLY ? (
            ''
          ) : (
            <Help>
              <IconButton
                image={{
                  active: {
                    src: images.helpBtn.image,
                    alt: t('screens.main.dashboard.dashboardScreen.achievement.help'),
                  },
                  width: '30px',
                  height: '30px',
                }}
                onClick={handleHelp}
                ariaControls={ariaControls}
                ariaExpanded={stateModal.environmentalImpact}
                ariaLabel={t('screens.main.dashboard.dashboardScreen.environmentalImpact.help')}
                borderRadius="20px"
              />
            </Help>
          )}
        </SectionHeader>
        <SeasonSelector
          backgroundColor="#f6f6f6"
          selectedSeason={`${currentSeason}`}
          onPressArrowLeft={() => setCurrentSeason(currentSeason - 1)}
          onPressArrowRight={() => setCurrentSeason(currentSeason + 1)}
          isLastSeasonSelected={!previousSeasonExists || isLoading}
          isCurrentSeasonSelected={!nextSeasonExists || isLoading}
        />
        {isLoading && (
          <LoadingContainer>
            <ReactLoading type="spin" color={colors.primary} height="5%" width="5%" />
          </LoadingContainer>
        )}

        {isError && (
          <ErrorContainer>
            <ErrorDescription>{t('screens.errorScreen.500.description')}</ErrorDescription>
            <PrimaryButton title={t('common.generalButtons.tryAgain')} onClick={environmentalImpactState.refetch} />
          </ErrorContainer>
        )}
        <Container>
          {programName === ProgramName.DATA_ONLY ? (
            <DataOnlyMessage />
          ) : (
            <>
              {environmentalImpactState.fetchingStatus === FetchingStatus.SUCCESS && (
                <ImpactGroup>
                  <Impact
                    logo={images.blueEnergy}
                    title={t('screens.main.dashboard.dashboardScreen.environmentalImpact.energyImpact')}
                    yourValue={environmentalImpactState.data.impact.reduction.yours * 1000}
                    yourUnit="kWh"
                    communityValue={environmentalImpactState.data.impact.reduction.community * 1000}
                    communityUnit="kWh"
                  />
                  <ImpactLine />
                  <Impact
                    logo={images.blueEnergy}
                    title={t(
                      `screens.main.dashboard.dashboardScreen.environmentalImpact.emissions${suffixForCO2Languages}`,
                    )}
                    yourValue={environmentalImpactState.data.impact.emissions.yours}
                    yourUnit="lbs"
                    communityValue={environmentalImpactState.data.impact.emissions.community}
                    communityUnit="lbs"
                  />
                  <ImpactLine />
                  <Impact
                    logo={images.blueEnergy}
                    title={t('screens.main.dashboard.dashboardScreen.environmentalImpact.miles')}
                    yourValue={environmentalImpactState.data.impact.milesDriven.yours}
                    yourUnit="miles"
                    communityValue={environmentalImpactState.data.impact.milesDriven.community}
                    communityUnit="miles"
                  />
                </ImpactGroup>
              )}
            </>
          )}
        </Container>
      </Section>
      <ModalEnvironmentalImpact
        show={stateModal.environmentalImpact}
        onClose={handleModalEnvironmentalImpactClose}
        ariaControlsId={ariaControls}
      />
    </>
  );
};

export default EnvironmentalImpact;
