import React from 'react';
import { Container, Description, SiteName, TextContent } from './SiteItem.styles';

type Props = {
  description: string;
  siteId: string;
  title?: string;
};

export const SiteItem = ({ description, title, siteId }: Props) => {
  return (
    <Container key={siteId} tabIndex={0}>
      <TextContent>
        <SiteName>{title}</SiteName>
        <Description>{description}</Description>
      </TextContent>
    </Container>
  );
};
