import React from 'react';
import { useTranslation } from 'react-i18next';
import NotVerified from '@/organisms/NotVerified';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import { INotVerifiedScreenViewProps } from './index.types';
import { ITemplateParams } from '../VerificationScreen/index.types';

const NotVerifiedScreenView = ({
  enrollmentProgram,
  onTryAgain,
  onNoThanks,
  textNext,
  descriptionText,
  descriptionText2,
  descriptionText3,
  descriptionText4,
}: INotVerifiedScreenViewProps) => {
  const { t } = useTranslation('common');
  const templateParams = t<string, ITemplateParams>(
    `common:programs.${enrollmentProgram.program}.verificationScreen.templateParams`,
    { returnObjects: true },
  );

  return (
    <EnrollmentTemplate
      disabled={false}
      underline={false}
      navigationTitle={
        templateParams.title ?? t('screens.enrollment.verificationScreen.title', { program: enrollmentProgram.program })
      }
      navigationPath={PATHS.SCREEN_ENROLLMENT_NOT_VERIFIED.pathname}
      onNext={onTryAgain}
      textNext={t(textNext)}
      onOther={templateParams.showNoThanks ? onNoThanks : undefined}
      textOther={templateParams.showNoThanks ? t('common.generalButtons.noThanks') : undefined}
      hideBack
    >
      <NotVerified
        descriptionText={descriptionText}
        descriptionText2={descriptionText2}
        descriptionText3={descriptionText3}
        descriptionText4={descriptionText4}
      />
    </EnrollmentTemplate>
  );
};

export default NotVerifiedScreenView;
