import React, { CSSProperties } from 'react';
import LoadingOverlay from 'react-loading-overlay/es6/LoadingOverlay';
import { ILoadingProps } from './index.types';

// react-loading-overlay has bugs which aren't being resolved.  One of which is
// incorrectly declared propTypes which this works around.
// eslint-disable-next-line react/forbid-foreign-prop-types
delete LoadingOverlay.propTypes;

const LoadingContainer = ({ children, loading }: ILoadingProps) => {
  return (
    <LoadingOverlay
      active={loading}
      spinner
      styles={{
        overlay: (base: CSSProperties) => ({
          ...base,
          position: 'fixed',
          background: 'rgba(0, 0, 0, 0.2)',
        }),
        wrapper: () => ({
          height: '100vh',
        }),
      }}
    >
      {children}
    </LoadingOverlay>
  );
};

export default LoadingContainer;
