import React from 'react';
import ModalWrapper from '@/molecules/Modal';
import { useTranslation } from 'react-i18next';
import { Container } from './ModalActivateUserRequestError.styles';

interface IModalActivateUserRequestError {
  show: boolean;
  onClose: () => void;
  ariaControlsId?: string;
}
const ModalActivateUserRequestError = ({ show, onClose, ariaControlsId }: IModalActivateUserRequestError) => {
  const { t } = useTranslation('common');

  return (
    <ModalWrapper
      title={t('screens.auth.invitationLandingScreen.error.title')}
      show={show}
      onClose={onClose}
      showClose
      ariaControlsId={ariaControlsId}
    >
      <Container>{t('screens.auth.invitationLandingScreen.error.content')}</Container>
    </ModalWrapper>
  );
};

export default ModalActivateUserRequestError;
