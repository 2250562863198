import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

const setSize = (size: 'extraSmall' | 'small' | 'medium' | 'large') => {
  if (size === 'extraSmall') {
    return { width: 21, height: 21 };
  }
  if (size === 'small') {
    return { width: 28, height: 25 };
  }

  if (size === 'medium') {
    return { width: 60, height: 54 };
  }

  return { width: 90, height: 81 };
};

export enum ParentComponentOfImpact {
  DASHBOARD = 'dashboard',
  PORTFOLIO = 'portfolio',
}

interface IStyleItemProps {
  underline?: boolean;
}

interface IStyleParentComponent {
  parentComponent: ParentComponentOfImpact;
}

const getSize = (parentComponent: ParentComponentOfImpact, dashboardSize: string, defaultSize: string): string => {
  return parentComponent === ParentComponentOfImpact.DASHBOARD ? dashboardSize : defaultSize;
};

export const valueSize = (parentComponent: ParentComponentOfImpact) => getSize(parentComponent, '17px', '15px');
export const descriptionSize = (parentComponent: ParentComponentOfImpact) => getSize(parentComponent, '17px', '13px');

export const Item = styled.div<IStyleItemProps & IStyleParentComponent>(
  ({ theme, underline, parentComponent }) => css`
    ${helpers.flexSet('column', 'center')};
    gap: ${parentComponent === ParentComponentOfImpact.DASHBOARD ? '8px' : '5px'};
    ${underline &&
    helpers.borderSet({
      borderDirection: 'bottom',
      borderWidth: theme.borders.default.width,
      borderColor: theme.colors.border,
    })};
  `,
);

export const Header = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
  gap: 10px;
`;

export const Title = styled.p<IStyleParentComponent>`
  ${(props) =>
    props.parentComponent === ParentComponentOfImpact.DASHBOARD
      ? css`
          ${typography.sm};
          color: ${colors.text};
          line-height: normal;
          font-weight: 400;
        `
      : css`
          ${typography.xs};
          color: ${colors.text};
          line-height: 18px;
          font-weight: 400;
        `}
`;

export const UsageGroup = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
  flex: 1;
`;
export const Logo = styled.div<{ size: 'extraSmall' | 'small' | 'medium' | 'large' }>(
  ({ size }) => css`
    ${helpers.sizeSet(setSize(size))};

    & > img {
      width: 100%;
      height: 100%;
    }
  `,
);
