import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Container = styled.div``;

export const Description = styled.p`
  ${typography.xs};
  font-weight: 400;
  line-height: 18px;
  color: ${colors.blackGrey};
  ${media.lg`
    ${typography.sm};
    line-height: 20px;
  `}
`;

export const Subtitle = styled.p`
  ${typography.sm};
  font-weight: 600;
  line-height: 20px;
  color: ${colors.text};
  margin: 40px 0 9px 0;
`;

export const BulletContainer = styled.div`
  margin: 40px 0 40px 0;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const MoreInformationContainer = styled.div`
  margin-top: 40px;
`;
