import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useEnrollment from '@/hooks/useEnrollment';
import { moveChangeScreen, nextScreenStep } from '@/helpers/NavigationHelper';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import FindProgramScreenView from './index.view';

const FindProgramScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    enrollment: {
      enrollmentInfo: { utility },
      programs,
      programs: { currentProgram },
      enrollmentInfo,
    },
  } = useSelector((state: RootState) => state);

  // Find Program - {programName} - {ContentsName}
  document.title = `${t('screens.enrollment.findProgramScreen.title')} - ${t(
    `common.utilities.${utility}.shortName`,
  )} - ${contents.name}`;

  const navigate = useNavigate();
  const { fetchChangeProgram } = useEnrollment();

  const handleNext = () => {
    const changedScreenSteps = moveChangeScreen(programs, currentProgram.program, fetchChangeProgram);
    const nextScreen = nextScreenStep(changedScreenSteps, window.location, enrollmentInfo);
    navigate(nextScreen);
  };

  return <FindProgramScreenView onNext={handleNext} />;
};

export default FindProgramScreenContainer;
