import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import contents from '@/assets/config/appInfo';
import { RootState } from '@/stores/index';
import { GBCStatus, UserInfo } from '@/types/userInfo';
import { useUserInfo } from '@/hooks/index';
import PATHS from '@/types/navigationPaths';
import SingUpCompletedScreenView from './index.view';

const SingUpCompletedScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: {
        userInfo: { utility, program },
      },
    },
    enrollment: {
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const { fetchUserInfo } = useUserInfo();
  const programName = currentProgram.program ? currentProgram.program : program;
  // Thank you for completing the sign up process!
  document.title = `${t(`programs.${programName}.signupCompleted.title`)} - ${t(
    `common.utilities.${utility}.shortName`,
  )} - ${contents.name}`;

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { data } = event;
      const { appState } = data;
      if (appState === 'active') {
        fetchUserInfo((userInfo: UserInfo) => {
          if ([GBCStatus.notStarted].includes(userInfo.gbcStatus)) {
            navigate(PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname);
          }
        });
      }
    };
    if (window.ReactNativeWebView) {
      /** android */
      document.addEventListener('message', listener as (e: Event) => void);
      /** ios */
      window.addEventListener('message', listener);
    }
    return () => {
      window.removeEventListener('message', listener);
      document.removeEventListener('message', listener as (e: Event) => void);
    };
  }, []);
  return <SingUpCompletedScreenView />;
};

export default SingUpCompletedScreenContainer;
