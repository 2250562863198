import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import { logEvent } from '@/helpers/GAHelper';
import { TMP_PATHS, nextScreenStep } from '@/helpers/NavigationHelper';
import { useSites } from '@/hooks/index';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import { SiteItem as SiteItemType } from '@/types/sites';
import { ProgramName } from '@/types/enrollment';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import {
  Description,
  ListContainer,
  ListContainerCheckbox,
  ListItem,
  Terms,
  AgreeBox,
  LinkText,
  More,
} from './index.styles';
import { HomesSection, SectionDescriptionModal, SiteCheckboxItem, SiteItem } from './components';
import { SelectSiteForEnrollmentErrorModal } from './components/SelectSiteForEnrollmentErrorModal';

const formatSiteAddress = (site: SiteItemType) =>
  [site.addressStreet1, site.addressStreet2, site.city, site.state, site.zipcode].join(' ');

const ProvisionalProgramsScreenContainer = () => {
  const { t } = useTranslation('common');
  const { fetchSelectSitesForEnrollment, fetchSites } = useSites();
  const [showEnrolledHomesModal, setShowEnrolledHomesModal] = useState(false);
  const [showIneligibleHomesModal, setShowIneligibleHomesModal] = useState(false);
  const [hasSelectableSites, setHasSelectableSites] = useState<boolean | undefined>(undefined);
  const [showSelectSiteError, setShowSelectSiteError] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);

  const navigate = useNavigate();

  const {
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram },
    },
    sites: {
      data: { gbcIntegration },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);
  document.title = `Provisional Programs - ${contents.name}`;

  const programName = gbcIntegration?.program;
  const sites = gbcIntegration?.sites;
  const gbcIntegrationId = gbcIntegration?.id || '';
  const isClimateResponse = programName === ProgramName.CLIMATE_RESPONSE;
  const enrollingFirstSite = sites?.length === 1 && hasSelectableSites;
  const skipScreen = enrollingFirstSite;

  useEffect(() => {
    if (skipScreen) {
      if (isClimateResponse) {
        handleConfirmClimateResponse();
        return;
      }
      const sitesIds = enrollingFirstSite ? [sites[0].id] : [];
      handleConfirm(sitesIds);
    }
  }, [skipScreen, enrollingFirstSite, isClimateResponse]);

  const [selectedHomesIds, setSelectedHomesIds] = useState<Array<string>>([]);

  const eligiblesAndNotEnrolledHomes: Array<SiteItemType> = [];
  const enrolledHomes: Array<SiteItemType> = [];
  const ineligibleHomes: Array<SiteItemType> = [];

  sites?.forEach((site) => {
    if (site.enrollmentStatus === 'enrolled') {
      enrolledHomes.push(site);
      return;
    }
    if (!site.gbiProgramEligible) {
      ineligibleHomes.push(site);
      return;
    }
    eligiblesAndNotEnrolledHomes.push(site);
  });

  const onClickCheckbox = (selectedHomeId: string) => {
    if (!selectedHomesIds.includes(selectedHomeId)) {
      setSelectedHomesIds([...selectedHomesIds, selectedHomeId]);
      return;
    }
    setSelectedHomesIds(selectedHomesIds.filter((siteId) => siteId !== selectedHomeId));
  };

  useEffect(() => {
    fetchSites(userInfo.siteName, (result) => {
      setHasSelectableSites(
        result?.gbcIntegration?.sites?.some(
          (aSite) => aSite.enrollmentStatus !== 'enrolled' && aSite.gbiProgramEligible,
        ),
      );
    });

    logEvent(
      'custom_page_view',
      'custom_page_view',
      `Provisional Programs - ${t(`common.utilities.${currentProgram.utilityProvider}.shortName`)} - ${contents.name}`,
    );
  }, []);

  const handleConfirm = (selectedIds: Array<string>) => {
    fetchSelectSitesForEnrollment(selectedIds, gbcIntegrationId, (responseOrError) => {
      if (!(responseOrError instanceof Error) && responseOrError.success) {
        const nextScreen = nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo);
        navigate(nextScreen);
        return;
      }
      setShowSelectSiteError(true);
    });
  };

  const confirmProgram = useCallback(handleConfirm, [gbcIntegrationId, currentProgram, enrollmentInfo]);

  const handleConfirmClimateResponse = () => {
    fetchSelectSitesForEnrollment([...selectedHomesIds], gbcIntegrationId, (responseOrError) => {
      if (!(responseOrError instanceof Error) && responseOrError.success) {
        navigate(TMP_PATHS.NAV_SINGUP_COMPLETE);
        return;
      }
      setShowSelectSiteError(true);
    });
  };

  const confirmProgramClimateResponse = useCallback(handleConfirmClimateResponse, [gbcIntegrationId, selectedHomesIds]);

  const handleTermsLink = (url: string) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'browser', message: url });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(url, '_blank');
    }
  };

  const noSitesChecked = selectedHomesIds.length === 0;
  const noSitesEnrolled = enrolledHomes.length === 0;
  const eligiblesHomesDescription = noSitesEnrolled
    ? t('screens.enrollment.provisionalProgramsScreen.eligible.descriptionWithoutEnrolledSites')
    : t('screens.enrollment.provisionalProgramsScreen.eligible.descriptionWithEnrolledSites');
  const shouldDisplayTermsAndConditions = !!selectedHomesIds?.length && !!gbcIntegration?.displayTermsAndConditions;
  const nextButtonDisabled = (noSitesChecked && noSitesEnrolled) || (shouldDisplayTermsAndConditions && !termsAgreed);

  if (skipScreen) return null;

  return (
    <>
      <EnrollmentTemplate
        navigationTitle={t('screens.enrollment.provisionalProgramsScreen.title')}
        disabled={nextButtonDisabled}
        underline={false}
        onNext={isClimateResponse ? confirmProgramClimateResponse : () => confirmProgram(selectedHomesIds)}
        textNext={t('screens.enrollment.provisionalProgramsScreen.confirm')}
      >
        <Description tabIndex={0}>{t('screens.enrollment.provisionalProgramsScreen.description')}</Description>
        <HomesSection
          title={t('screens.enrollment.provisionalProgramsScreen.eligible.title')}
          description={eligiblesHomesDescription}
        >
          <ListContainerCheckbox>
            {eligiblesAndNotEnrolledHomes.map((site) => (
              <ListItem key={site.id}>
                <SiteCheckboxItem
                  siteId={site.id}
                  title={site.name}
                  isChecked={selectedHomesIds.includes(site.id)}
                  onClickCheck={() => onClickCheckbox(site.id)}
                  description={formatSiteAddress(site)}
                />
              </ListItem>
            ))}
          </ListContainerCheckbox>
        </HomesSection>
        {enrolledHomes.length > 0 && (
          <HomesSection
            title={t('screens.enrollment.provisionalProgramsScreen.enrolled.title')}
            onClickHelp={() => setShowEnrolledHomesModal(true)}
          >
            <ListContainer>
              {enrolledHomes.map((site) => (
                <ListItem key={site.id}>
                  <SiteItem title={site.name} description={formatSiteAddress(site)} siteId={site.id} />
                </ListItem>
              ))}
            </ListContainer>
          </HomesSection>
        )}
        {ineligibleHomes.length > 0 && (
          <HomesSection
            title={t('screens.enrollment.provisionalProgramsScreen.ineligible.title')}
            onClickHelp={() => setShowIneligibleHomesModal(true)}
          >
            <ListContainer>
              {ineligibleHomes.map((site) => (
                <ListItem key={site.id}>
                  <SiteItem title={site.name} description={formatSiteAddress(site)} siteId={site.id} />
                </ListItem>
              ))}
            </ListContainer>
          </HomesSection>
        )}
        {!!selectedHomesIds?.length && !!gbcIntegration?.displayTermsAndConditions && (
          <div>
            <AgreeBox>
              <IconButton
                role="checkbox"
                ariaSelected={termsAgreed}
                height={20}
                width={20}
                icon={termsAgreed ? images.checkboxChecked.image : images.checkbox.image}
                onClick={() => setTermsAgreed(!termsAgreed)}
              />
              <Terms>
                {t(`programs.${programName}.availableProgramScreen.terms.sentence1`)}
                <LinkText
                  onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.terms.link`))}
                  role="link"
                  tabIndex={0}
                >
                  {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn`)}
                </LinkText>
                {t(`programs.${programName}.availableProgramScreen.terms.sentence2`)}
                <LinkText
                  onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.terms.link2`))}
                  role="link"
                  tabIndex={0}
                >
                  {t(`programs.${programName}.availableProgramScreen.terms.sentenceBtn2`)}
                </LinkText>
              </Terms>
            </AgreeBox>
            <More>
              {t(`programs.${programName}.availableProgramScreen.more.sentence1`)}
              <LinkText
                onClick={() => handleTermsLink(t(`programs.${programName}.availableProgramScreen.more.link`))}
                role="link"
                tabIndex={0}
              >
                {t(`programs.${programName}.availableProgramScreen.more.sentenceBtn`)}
              </LinkText>
              {t(`programs.${programName}.availableProgramScreen.more.sentence2`)}
            </More>
          </div>
        )}
      </EnrollmentTemplate>
      <SectionDescriptionModal
        showModal={showEnrolledHomesModal}
        closeModal={() => setShowEnrolledHomesModal(false)}
        title="Enrolled Home(s)"
      >
        <p>
          {t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.regularText`, {
            programName: t(`programs.${programName}.title`),
          })}
          <a
            href={t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.link.href`)}
            target="_blank"
            rel="noreferrer"
          >
            {t(`programs.${programName}.provisionalProgramsScreen.enrolled.modalDescription.link.text`)}
          </a>
        </p>
      </SectionDescriptionModal>
      <SectionDescriptionModal
        showModal={showIneligibleHomesModal}
        closeModal={() => setShowIneligibleHomesModal(false)}
        title="Ineligible Home(s)"
      >
        <p>{t(`programs.${programName}.provisionalProgramsScreen.ineligible.modalDescription.regularText`)}</p>
      </SectionDescriptionModal>
      <SelectSiteForEnrollmentErrorModal
        showModal={showSelectSiteError}
        title={t('screens.errorScreen.enrollmentOfSiteError.title')}
        closeModal={() => setShowSelectSiteError(false)}
      />
    </>
  );
};

export default ProvisionalProgramsScreenContainer;
