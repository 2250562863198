import React, { useMemo, useState } from 'react';
import PATHS from '@/types/navigationPaths';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@/atoms/IconButton';
import { IconButton as RenewIconButton } from '@/designSystem/components/buttons';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import ModalProgramInformation from '@/organisms/ModalProgramInformation';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { keepScreenStateFlag } from '@/helpers/NavigationHelper';
import {
  Container,
  ImageContainer,
  ScreenName,
  ScreenNameContainer,
  Wrapper,
  styleInformationIcon,
  EmptyForLayout,
} from './NestedAppbar.styles';
import { HeaderShowBorder, PathnameKeys, ScreenTitleByPathname } from './NestedAppbar.types';

const handleNavigation = (path: string[], navigate: ReturnType<typeof useNavigate>) => {
  const routeMap: { [key: string]: string } = {
    message: PATHS.SCREEN_MESSAGE.pathname,
    detail: PATHS.SCREEN_MOBILE_EVENT.pathname,
    event: PATHS.SCREEN_SAVING_EVENT.pathname,
    sites: path.includes('manage') ? PATHS.SCREEN_SETTING_MY_SITES.pathname : PATHS.SCREEN_SETTING.pathname,
    dashboard: PATHS.SCREEN_DASHBOARD.pathname,
  };

  const matchedRoute = Object.keys(routeMap).find((route) => path.includes(route));
  navigate(matchedRoute ? routeMap[matchedRoute] : PATHS.SCREEN_SETTING.pathname);
};

const NestedAppbar = () => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split('/');
  const [modalProgramInformationVisibility, setModalProgramInformationVisibility] = useState(false);

  const handleBackButton = (): void => {
    const currentPathIsHistoryDetails = currentPath.join('/') === PATHS.SCREEN_MOBILE_HISTORY_DETAIL.pathname;
    if (currentPathIsHistoryDetails) {
      navigate(`${PATHS.SCREEN_MOBILE_EVENT.pathname}?${keepScreenStateFlag}=true`);
      return;
    }

    handleNavigation(currentPath, navigate);
  };

  const titleKey = useMemo(() => {
    return currentPath.slice(2, currentPath.length > 4 ? 5 : 4).join('/') as PathnameKeys;
  }, [location.pathname]);

  const screenTitle = useMemo(() => {
    let key;
    if (currentPath.length > 4) {
      key = `${currentPath[3]}/${currentPath[4]}` as PathnameKeys;
    } else if (currentPath.length > 3) {
      key = currentPath[3] as PathnameKeys;
    } else if (currentPath.length > 2) {
      key = currentPath[2] as PathnameKeys;
    }
    return key && ScreenTitleByPathname[key] ? t(ScreenTitleByPathname[key]) : '';
  }, [location.pathname, t]);
  const headerBorder = HeaderShowBorder[titleKey];
  const informationButton = titleKey.includes(PathnameKeys.ManageSites);

  const programName = getProgramNameFromSite(currentSite);
  const handleModalProgramInformationOpen = () => {
    sentEvent('click', PATHS.HELP_PROGRAM_INFORMATION(programName));
    setModalProgramInformationVisibility(true);
  };

  const handleModalProgramInformationClose = () => {
    setModalProgramInformationVisibility(false);
  };

  return (
    <Wrapper>
      <Container showBorder={headerBorder}>
        <ImageContainer>
          <IconButton icon={images.navigationBlackBack} onClick={handleBackButton} />
        </ImageContainer>
        <ScreenNameContainer>
          <ScreenName>{screenTitle}</ScreenName>
        </ScreenNameContainer>
        {informationButton ? (
          <RenewIconButton
            ariaLabel={images.informationBtn.text}
            onClick={handleModalProgramInformationOpen}
            image={styleInformationIcon}
          />
        ) : (
          <EmptyForLayout />
        )}
      </Container>
      <ModalProgramInformation
        programName={programName}
        show={modalProgramInformationVisibility}
        onClose={handleModalProgramInformationClose}
        ariaControlsId="program-information-popup"
      />
    </Wrapper>
  );
};

export default NestedAppbar;
