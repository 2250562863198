// Types
export const CHANGE_HAMBURGER = 'misc/CHANGE_HAMBURGER';
export const CHANGE_WEB_LOGIN = 'misc/CHANGE_WEB_LOGIN';

interface ChangeHamburgerAction {
  type: typeof CHANGE_HAMBURGER;
  open: boolean;
}

interface ChangeAppLoginAction {
  type: typeof CHANGE_WEB_LOGIN;
  appLogin: boolean;
}

type InitActionTypes = ChangeAppLoginAction | ChangeHamburgerAction;

export interface MiscState {
  hamburgerOpen: boolean;
  appLogin: boolean;
}

const initialState: MiscState = {
  hamburgerOpen: false,
  appLogin: false,
};

// eslint-disable-next-line default-param-last
export default (state: MiscState = initialState, action: InitActionTypes): MiscState => {
  switch (action.type) {
    case CHANGE_HAMBURGER:
      return {
        ...state,
        hamburgerOpen: action.open,
      };

    case CHANGE_WEB_LOGIN:
      return {
        ...state,
        appLogin: action.appLogin,
      };

    default:
      return state;
  }
};

// Actions

export const changeHamburger = (open: boolean) => ({
  type: CHANGE_HAMBURGER,
  open,
});

export const changeAppLogin = (appLogin: boolean) => ({ type: CHANGE_WEB_LOGIN, appLogin });
