import { DependencyList, useCallback } from 'react';
import { ProgramName } from '@/types/enrollment';
import useSites from '@/hooks/useSites';

/**
 * Note when using this hook, this will only work for supported programs on the backend. (DATA_ONLY -> CLIMATE_RESPONSE)
 */
export const useSwitchSiteEnrollment: (
  config: {
    /**
     * Any react stateful variables used in these call backs need to be defined in the deps.
     */
    onSuccess?: () => void;
    onFail?: () => void;
  },
  deps?: DependencyList,
) => (siteId: string | undefined, gbcIntegrationId: string, program: ProgramName) => void = (config, deps = []) => {
  const { fetchSelectSitesForEnrollment, fetchSubmitTermsAndConditionsInSites, fetchSites } = useSites();

  return useCallback(
    async (siteId, gbcIntegrationId, program) => {
      if (!siteId) {
        return;
      }

      try {
        await new Promise((resolve, reject) => {
          fetchSelectSitesForEnrollment([siteId], gbcIntegrationId, (item) =>
            item instanceof Error ? reject(item) : resolve(item),
          );
        });

        await new Promise((resolve, reject) => {
          fetchSubmitTermsAndConditionsInSites(program, (item) =>
            item instanceof Error ? reject(item) : resolve(item),
          );
        });
      } catch (e) {
        config.onFail?.();
        return;
      }

      fetchSites();

      config.onSuccess?.();
    },
    [fetchSelectSitesForEnrollment, fetchSubmitTermsAndConditionsInSites, fetchSites, ...deps],
  );
};
