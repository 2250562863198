import React from 'react';
import Modal from '@/molecules/Modal';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@/designSystem/components/buttons';
import { Content, Description } from './SelectSiteForEnrollmentErrorModal.styles';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  title: string;
};

export const SelectSiteForEnrollmentErrorModal = ({ showModal, title, closeModal }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Modal show={showModal} title={title} showClose onClose={closeModal}>
      <Content>
        <Description>{t('screens.errorScreen.enrollmentOfSiteError.description')}</Description>
        <PrimaryButton title={t('common.generalButtons.close')} onClick={closeModal} />
      </Content>
    </Modal>
  );
};
