import React from 'react';
import { useTranslation } from 'react-i18next';
import { incentiveKindEnum } from '@/types/dr';
import { images } from '@/assets/styles';
import moment from 'moment';
import { UnitHelper } from '@/helpers/UnitHelper';
import { perEventDisclaimer } from '@/helpers/PerEventDisclaimer';
import { isOlivineEdition } from '@/helpers/Edition';
import {
  Balance,
  Date,
  Incentive,
  Wrapper,
  EarningsTitleConainer,
  EarningsTitle,
  DateContainer,
  Img,
  ValueContainer,
  EarningsRowContainer,
  Earnings,
  Description,
  EarningsDescription,
  Button,
  ImgArrow,
  Divider,
  PerEventDisclaimer,
  Container,
} from './MoneyEarnedItem.styles';
import { IMoneyEarnedItemProps } from './MoneyEarnedItem.types';
import PendingMoneyEarnedItem from '../PendingMoneyEarnedItem';

const MoneyEarnedItem = ({ item, onClick, selectedEarning, index }: IMoneyEarnedItemProps) => {
  const { t } = useTranslation('common');

  const getIconSrc = () => {
    return item.incentiveKind === incentiveKindEnum.event
      ? images.moneyEarnedEvent.image
      : images.moneyEarnedOther.image;
  };

  const renderIncentive = (index: number) => {
    const key = Object.values(incentiveKindEnum).find((val) => val === item.incentiveKind);

    if (typeof key === 'string') {
      return `${t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.${key.toLowerCase()}.title`,
      )} #${index}`;
    }
    return '';
  };

  if ('isPending' in item) {
    return <PendingMoneyEarnedItem pendingItem={item} index={index} />;
  }

  const { disclaimerNamespace, disclaimerSymbol } = perEventDisclaimer({
    perEventDisclaimer: item?.perEventDisclaimer,
  });
  const disclaimer = t(disclaimerNamespace);

  return (
    <Button
      disabled={isOlivineEdition}
      onClick={() => onClick(item, renderIncentive(index))}
      selected={selectedEarning.earningId === item.earningId}
    >
      <Container>
        <Wrapper>
          <EarningsTitleConainer>
            <EarningsTitle>
              <Img src={getIconSrc()} />
              <Incentive>{renderIncentive(index)}</Incentive>
            </EarningsTitle>
            <DateContainer>
              <Img src={images.calendarGrey.image} alt={images.calendarGrey.text} />
              <Date>{moment(item.earnedDate).format('MMMM Do, YYYY')}</Date>
            </DateContainer>
          </EarningsTitleConainer>
          <Divider />
          <ValueContainer>
            <EarningsRowContainer>
              <Earnings>{UnitHelper.formatMoneyValue(item.changeInAmount)}</Earnings>
              <EarningsDescription>
                {`${t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.title')}${disclaimerSymbol}`}
              </EarningsDescription>
            </EarningsRowContainer>
            <EarningsRowContainer>
              <Balance>{UnitHelper.formatMoneyValue(item.runningBalance)}</Balance>
              <Description>{t('screens.main.savingsEvents.portfolioScreen.totalEarnings')}</Description>
            </EarningsRowContainer>
          </ValueContainer>
        </Wrapper>
        <PerEventDisclaimer>{disclaimer}</PerEventDisclaimer>
      </Container>
      <ImgArrow
        src={images.arrowRightGreen.image}
        alt={images.arrowRightGreen.text}
        style={{ width: '32px', height: '32px' }}
      />
    </Button>
  );
};
export default MoneyEarnedItem;
