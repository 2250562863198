import React from 'react';
import { useTranslation } from 'react-i18next';
import SingleSurvey from '@/organisms/SingleSurvey';
import EnrollmentTemplate from '@/templates/EnrollmentTemplate';
import PATHS from '@/types/navigationPaths';
import { ISingleSurveyScreenViewProps } from './index.types';

const SingleSurveyScreenView = ({ disabledNext, onNext, onSkip }: ISingleSurveyScreenViewProps) => {
  const { t } = useTranslation('common');

  return (
    <EnrollmentTemplate
      disabled={disabledNext}
      underline={false}
      navigationPath={PATHS.SCREEN_ENROLLMENT_SINGLE_SURVEY.pathname}
      onNext={onNext}
      textNext={t('common.generalButtons.submit')}
      onOther={onSkip}
      textOther={t('common.generalButtons.skip')}
    >
      <SingleSurvey />
    </EnrollmentTemplate>
  );
};

export default SingleSurveyScreenView;
