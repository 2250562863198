import styled from 'styled-components';
import { colors, typography } from '@/designSystem/index';
import { helpers } from '@/assets/styles';

export const EventSelectorContainer = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
  background-color: ${colors.background};
  border-radius: 5px;
  width: 100%;
  max-width: 150px;
  padding: 10px;
`;

export const SelectorArrow = styled.div`
  flex: 1;
`;

export const EventYear = styled.p`
  ${typography.sm};
  width: 100%;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${colors.primary};
  flex: 1 0 40px;
`;
