import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface PerformanceText {
  color: string;
}

interface EventsHistoryColumnProps {
  noBorder?: boolean;
}

export const EventsHistoryContainer = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  font-size: 5px;
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 1px;
  margin-top: 20px;
  padding: 15px;
  ${media.lg`
    padding: 20px
  `};
`;

export const EventsHistoryTitleWrapper = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
  padding: 5px;
  width: 100%;
  margin-bottom: 20px;
`;

export const EventsHistoryGridWrapper = styled.div`
  ${helpers.flexSet('column', 'flex-start', 'space-between')};
  background-color: ${colors.background};
  border-radius: 8px;
  padding: 11px 12px;
  max-width: 1320px;
  gap: 15px;

  ${media.sm`
  max-height: 550px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 27px;
    height: 28px;
  }

  &::-webkit-scrollbar-track {
    // background-color: #eceef2;
    border-radius: 180px;
  }
  
  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    border-right-width: 8px;
    border-left-width: 8px;
    border-radius: 100px;
    background-color: ${`${colors.grey}50`};
    background-clip: content-box;
    height: 30%;
  }
  `}
`;

export const EventsHistoryGrid = styled.div`
  align-self: center;
  grid-template-columns: max-content;
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};

  ${media.sm`
  display: grid;
  grid-template-columns:minmax(50px, 250px) minmax(50px,250px) minmax(50px,350px);
  grid-template-rows: auto;
  justify-content: flex-start;
  grid-row-gap: 20px;
  width: 100%;
  border-radius: 8px;
  grid-gap: 50px;
  `}
`;

export const EventsHistoryTitle = styled.div`
  ${typography.xl};
  color: ${colors.text};
  font-weight: 700;
  align-self: center;
  width: 100%;
`;

export const EventsHistoryColumn = styled.div<EventsHistoryColumnProps>(
  ({ noBorder }) => css`
    ${helpers.flexSet('column', 'center', 'flex-start')};
    row-gap: 5px;
    width: 100%;
    max-width: 300px;
    min-height: 75px;
    margin: 15px 0 15px;
    padding: 10px;

    ${media.md`
    border-right: ${noBorder ? 'none' : `${colors.lightGrey} solid 1px`};
  `};
  `,
);

export const ImpactRow = styled.div`
  ${helpers.flexSet('row ', 'flex-start', 'center')};
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 250px;
  column-gap: 5px;
`;

export const EventName = styled.div`
  ${typography.xs};
  color: ${colors.text};
  white-space: nowrap;
`;

export const EventDateWrapper = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const EventDate = styled.div`
  ${typography.xs};
  color: ${colors.blackGrey};
`;

export const PerformanceText = styled.div<PerformanceText>`
  ${typography.xs};
  color: ${(props) => props.color};
  margin-left: 5px;
  white-space: nowrap;
`;

export const UsageWrapper = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
  width: 100%;
`;

export const ImpactText = styled.div`
  ${typography.xxs};
  color: ${colors.grey};
  margin-left: 5px;
  flex: 1;

  ${media.sm`
  flex: 0 2 150px;
  `}
`;

export const ImageContainer = styled.div`
  width: 11px;
  height: 11px;
`;

export const NoDataWrapper = styled.div`
  overflow: auto;
  padding: 10px 5.5px 30px 5.5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 434px;
  align-items: center;
  justify-content: center;
`;

export const NoData = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize, fontWeight: theme.typography.title.fontWeight })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
    margin-top: 20px;
  `,
);
