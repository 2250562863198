import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReqIntegrations } from '@/apis/enrollment/types';
import { RootState } from '@/stores/index';
import useEnrollment from '@/hooks/useEnrollment';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { sentEvent } from '@/helpers/GAHelper';
import { useTranslation } from 'react-i18next';
import ConnectToUtilityScreenView from './index.view';

const ConnectToUtilityScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    enrollment: {
      enrollmentInfo,
      programs: { currentProgram },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  // Connect to Utility - {program name} - ClimateResponse
  document.title = `${t('screens.enrollment.connectToUtilityScreen.title')} - ${t(
    `common.utilities.${enrollmentInfo.utility}.shortName`,
  )} - ${contents.name}`;

  const [state, setState] = useState({ disabledNext: true, later: false });
  const [modal, setModal] = useState({ error: { show: false, content: '' } });

  const location = useLocation();
  const navigate = useNavigate();
  const { fetchGBC, fetchGBCResult } = useEnrollment();

  useEffect(() => {
    // Utility 페이지에서 리턴되었을 때
    if (window.location.search) {
      fetchGBCResult(window.location.search);
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname === PATHS.SCREEN_ENROLLMENT_CONNECT_TO_UTILITY.pathname &&
      // location.search === '' &&
      enrollmentInfo.gbcUrlResult !== ''
    ) {
      utilityConfirmAccount();
    }
  }, [enrollmentInfo.gbcUrlResult]);

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo]);

  const utilityConfirmAccount = () => {
    if (enrollmentInfo.gbcUrlResult === '') {
      return;
    }

    const urlSearchParams = new URLSearchParams(enrollmentInfo.gbcUrlResult);
    const code = urlSearchParams.get('code');
    const error = urlSearchParams.get('error');

    if (code === 'success') {
      sentEvent('connect_utility_submit', {
        submit_status: 'success',
      });
      navigate(PATHS.SCREEN_ENROLLMENT_SINGLE_SURVEY.pathname);
    }

    if (error) {
      sentEvent('connect_utility_submit', {
        submit_status: 'failed',
      });
      let errorContent = error.replace(/[-|+]/g, ' ');
      if (errorContent.slice(-2) === '..') {
        errorContent = errorContent.slice(0, -1);
      }
      setModal({ ...modal, error: { show: true, content: errorContent } });
    }
  };

  const validationCheck = () => {
    if (enrollmentInfo.utility !== '') {
      setState({ ...state, disabledNext: false });
    }
  };

  const handleNext = () => {
    const payload: ReqIntegrations = {
      program: currentProgram.program,
      provider: enrollmentInfo.utility || userInfo?.utility,
    };

    fetchGBC(payload, (url: string) => {
      window.location.href = url;
    });
  };

  const handleConnectToUtilityErrorOK = () => {
    // 로그인 페이지 이동 후에 나타나는 경우
    // setState({ ...state, later: true });
    setModal({ ...modal, error: { show: false, content: '' } });

    fetchGBCResult('');
  };

  return (
    <ConnectToUtilityScreenView
      enrollmentProgram={currentProgram}
      disabledNext={state.disabledNext && !enrollmentInfo.utility && !userInfo?.utility}
      onNext={handleNext}
      modalConnectToUtilityError={modal.error.show}
      modalConnectToUtilityErrorTitle={modal.error.content}
      modalConnectToUtilityErrorOK={handleConnectToUtilityErrorOK}
    />
  );
};

export default ConnectToUtilityScreenContainer;
