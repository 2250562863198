import { HistoryTabs } from './dr';

type ScreenTypes = Record<
  string,
  {
    pathname?: string;
    gaparam?:
      | {
          screen_name: string;
          screen_class: string;
        }
      | Record<string, unknown>;
  }
>;

const SCREEN_PATHS = {
  SCREEN_ROOT: {
    pathname: '/',
    gaparam: {
      screen_name: 'Welcome',
      screen_class: 'welcome',
      screen_type: 'login',
      usage_screen: 'login',
      screen_level: 'login',
    },
  },
  SCREEN_APP_LOGIN: {
    pathname: '/appLogin',
    gaparam: {
      screen_name: 'App Login',
      screen_class: 'app_login',
      screen_type: 'login',
      usage_screen: 'login',
      screen_level: 'login',
    },
  },
  SCREEN_AUTH_REDIRECT: {
    pathname: '/redirect',
    gaparam: {
      screen_name: 'Redirect',
      screen_class: 'redirect',
      screen_type: 'redirect',
      usage_screen: 'enrollment',
      screen_level: 'redirect',
    },
  },
  SCREEN_REDIRECT_ERROR: {
    pathname: '/redirect_error',
    gaparam: {
      screen_name: 'RedirectError',
      screen_class: 'redirect_error',
      screen_type: 'redirect',
      usage_screen: 'enrollment',
      screen_level: 'redirect',
    },
  },
  SCREEN_NO_ENROLLMENT_ERROR: {
    pathname: '/no_enrollment_error',
    gaparam: {
      screen_name: 'NoEnrollmentError',
      screen_class: 'no_enrollment_error',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_INVITATION: {
    pathname: '/elrp',
    gaparam: {
      screen_name: 'Invitaion Landing',
      screen_class: 'invitation_landing',
      screen_type: 'login',
      usage_screen: 'login',
      screen_level: 'login',
    },
  },
  SCREEN_CI: {
    pathname: '/ci',
    gaparam: {
      screen_name: 'CI Landing',
      screen_class: 'ci_landing',
      screen_type: 'login',
      usage_screen: 'login',
      screen_level: 'login',
    },
  },

  // enrollment
  SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT: {
    // Program Enrollment screen is only for Climate Response(Olivine Edition).
    pathname: '/enrollment/programEnrollment',
    gaparam: {
      screen_name: 'Program Enrollment',
      screen_class: 'program_enrollment',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_PROFILE_SETUP: {
    pathname: '/enrollment/profile',
    gaparam: {
      screen_name: 'Profile Setup',
      screen_class: 'profile_setup',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_PHONE_SMS: { pathname: '/enrollment/phonesms', gaparam: {} }, // not used (cpa)
  SCREEN_ENROLLMENT_PHONE_OTP: { pathname: '/enrollment/phoneotp', gaparam: {} }, // not used (cpa)
  SCREEN_ENROLLMENT_LOCATION_SETUP: {
    pathname: '/enrollment/location',
    gaparam: {
      screen_name: 'Location Setup',
      screen_class: 'location_setup',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_SELECT_UTILITY: {
    pathname: '/enrollment/utility',
    gaparam: {
      screen_name: 'Select Energy Provider',
      screen_class: 'select_energy_provider',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_FIND_PROGRAM: {
    pathname: '/enrollment/findProgram',
    gaparam: {
      screen_name: 'Find Program',
      screen_class: 'find_program',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_VERIFICATION: {
    pathname: '/enrollment/verification',
    gaparam: {
      screen_name: 'Account Verification',
      screen_class: 'account_verification',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_NOT_VERIFIED: {
    pathname: '/enrollment/notVerified',
    gaparam: {
      screen_name: 'Not Verified',
      screen_class: 'not_verified',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_AVAILABLE_PROGRAM: {
    pathname: '/enrollment/availableProgram',
    gaparam: {
      screen_name: 'Terms and Conditions',
      screen_class: 'terms_and_conditions',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_AVAILABLE_PROGRAM_NO_THANKS: {
    pathname: '/enrollment/availableProgram/appOnly',
    gaparam: {
      screen_name: 'Program Refusal',
      screen_class: 'available_program_no_thanks',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_CONNECT_TO_UTILITY: {
    pathname: '/enrollment/connectToUtility',
    gaparam: {
      screen_name: 'Connect to Utility',
      screen_class: 'connect_to_utility',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_MULTIPLE_SURVEY: {
    pathname: '/enrollment/survey/multiple',
    gaparam: {
      screen_name: 'Enrollment Survey',
      screen_class: 'enrollment_survey',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_SINGLE_SURVEY: {
    pathname: '/enrollment/survey/single',
    gaparam: {
      screen_name: 'Enrollment Survey',
      screen_class: 'enrollment_survey',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_WEB_STORE: { pathname: '/enrollment/webStore', gaparam: {} },
  SCREEN_ENROLLMENT_SINGUP_COMPLETE: {
    pathname: '/enrollment/signupCompleted',
    gaparam: {
      screen_name: 'Enrollment Completed',
      screen_class: 'enrollment_completed',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  SCREEN_ENROLLMENT_CONFIRM_PROGRAM: {
    pathname: '/enrollment/confirmProgram',
    gaparam: {
      screen_name: 'Provisional Programs',
      screen_class: 'provisional_programs',
      screen_type: 'enrollment',
      usage_screen: 'enrollment',
      screen_level: 'enrollment',
    },
  },
  // main
  SCREEN_DASHBOARD: {
    pathname: '/main/dashboard',
    gaparam: {
      screen_name: 'Dashboard',
      screen_class: 'dashboard',
      screen_type: 'post_enrollment',
      usage_screen: 'dashboard',
      screen_level: 'parent',
    },
  },
  // Mobile window only
  SCREEN_MOBILE_ENVIRONMENTAL_IMPACT: {
    pathname: '/main/dashboard/environmentalImpact',
    gaparam: {
      screen_name: 'Environmental Impact',
      screen_class: 'environmental_impact',
      screen_type: 'post_enrollment',
      usage_screen: 'dashboard',
      screen_level: 'child',
    },
  },
  // Mobile window only
  SCREEN_MOBILE_CURRENT_GRID_EMISSIONS: {
    pathname: '/main/dashboard/currentGridEmissions',
    gaparam: {
      screen_name: 'Current Grid Emissions',
      screen_class: 'current_grid_emissions',
      screen_type: 'post_enrollment',
      usage_screen: 'dashboard',
      screen_level: 'child',
    },
  },
  SCREEN_USAGE_PATTERN: {
    pathname: '/main/usagePattern',
    gaparam: {
      screen_name: 'Energy Usage',
      screen_class: 'energy_usage',
      screen_type: 'post_enrollment',
      usage_screen: 'energy_usage',
      screen_level: 'parent',
    },
  },
  SCREEN_SAVING_EVENT: {
    pathname: '/main/dr',
    gaparam: {
      screen_name: 'Savings Event',
      screen_class: 'savings_event',
      screen_type: 'post_enrollment',
      usage_screen: 'energy_events',
      screen_level: 'parent',
    },
  },
  // Mobile window only
  SCREEN_MOBILE_EVENT: {
    pathname: '/main/dr/event',
    gaparam: {
      screen_name: 'Events History',
      screen_class: 'events_history',
      screen_type: 'post_enrollment',
      usage_screen: 'energy_events',
      screen_level: 'child',
    },
  },
  // Mobile window only
  SCREEN_MOBILE_HISTORY_DETAIL: {
    pathname: '/main/dr/event/detail',
    gaparam: {
      screen_name: 'History Detail',
      screen_class: 'history_detail',
      screen_type: 'post_enrollment',
      usage_screen: 'energy_events',
      screen_level: 'child',
    },
  },
  SCREEN_PORTFOLIO: {
    pathname: '/main/dr/portfolio',
    gaparam: {
      screen_name: 'Portfolio',
      screen_class: 'portfolio',
      screen_type: 'post_enrollment',
      usage_screen: 'energy_events',
      screen_level: 'child',
    },
  },
  SCREEN_MESSAGE: {
    pathname: '/main/message',
    gaparam: {
      screen_name: 'Messages',
      screen_class: 'messages',
      screen_type: 'post_enrollment',
      usage_screen: 'messages',
      screen_level: 'parent',
    },
  },
  // Mobile window only
  SCREEN_SETTING: {
    pathname: '/setting',
    gaparam: {
      screen_name: 'Setting',
      screen_class: 'setting',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'parent',
    },
  },
  SCREEN_SETTING_ACCOUNT: {
    pathname: '/setting/account',
    gaparam: {
      screen_name: 'Account',
      screen_class: 'account',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_PROGRAM: {
    pathname: '/setting/program',
    gaparam: {
      screen_name: 'Program Detail',
      screen_class: 'program_detail',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_MY_SITES: {
    pathname: '/setting/sites',
    gaparam: {
      screen_name: 'My Sites',
      screen_class: 'my_sites',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_INVITE_USERS: {
    pathname: '/setting/inviteUsers',
    gaparam: {
      screen_name: 'Invite Users',
      screen_class: 'invite_users',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_MANAGE_SITE: {
    pathname: '/setting/sites/manage',
    gaparam: {
      screen_name: 'Manage Sites',
      screen_class: 'manage_sites',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_EDIT_SITE: {
    pathname: '/setting/sites/edit',
    gaparam: {
      screen_name: 'Edit Site',
      screen_class: 'edit_site',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_SITE_ENROLL: {
    pathname: '/setting/sites/enroll',
    gaparam: {
      screen_name: 'Enroll Site',
      screen_class: 'enroll_site',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_SITE_ENROLL_SUCCESS: {
    pathname: '/setting/sites/enroll_success',
    gaparam: {
      screen_name: 'Enroll Site Success',
      screen_class: 'enroll_site_success',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_SITE_ENROLL_FAILED: {
    pathname: '/setting/sites/enroll_failed',
    gaparam: {
      screen_name: 'Enroll Site Failed',
      screen_class: 'enroll_site_failed',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_SUPPORT: {
    pathname: '/setting/support',
    gaparam: {
      screen_name: 'Support',
      screen_class: 'support',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_LANGUAGE: {
    pathname: '/setting/language',
    gaparam: {
      screen_name: 'Language Selection',
      screen_class: 'language_selection',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_ABOUT: {
    pathname: '/setting/about',
    gaparam: {
      screen_name: 'About',
      screen_class: 'about',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_SETTING_REFERRAL: {
    pathname: '/setting/referral',
    gaparam: {
      screen_name: 'Referral',
      screen_class: 'referral',
      screen_type: 'post_enrollment',
      usage_screen: 'settings',
      screen_level: 'child',
    },
  },
  SCREEN_NOT_FOUND: {
    gaparam: {
      screen_name: 'Not Found',
      screen_class: 'not_found',
      screen_type: 'not_applicable',
      usage_screen: 'not_applicable',
      screen_level: 'not_applicable',
    },
  },
};

const EVENT_PARAMS = {
  /**
   * Google Analytics Event Parameters
   */
  POPUP_DELETE_ACCOUNT: {
    click_type: 'popup',
    click_description: 'Popup Delete Account',
  },
  POPUP_CLOSE_DELETE_ACCOUNT: (gaMessage?: string) => {
    return {
      click_type: 'popup',
      click_description: `Popup close Delete Account ${gaMessage || ''}`,
    };
  },
  POPUP_CONTINUE_DELETE_ACCOUNT: {
    click_type: 'continue',
    click_description:
      'The user entered the text "DELETE" to proceed with account deletion and clicked the Continue button',
  },
  EVENT_PARTICIPATION_ACCEPT: (userEventId: string) => {
    return {
      click_type: 'dr_event',
      click_description: 'Accept event participation',
      user_event_id: userEventId,
    };
  },
  EVENT_PARTICIPATION_DECLINE: (userEventId: string) => {
    return {
      click_type: 'dr_event',
      click_description: 'Decline event participation',
      user_event_id: userEventId,
    };
  },
  SAVING_IDEAS: (value: string) => {
    return {
      click_type: 'saving_ideas',
      click_description: value,
    };
  },
  HELP_ACHIEVEMENT: {
    click_type: 'help',
    click_description: 'Help on Achievement',
  },
  HELP_ENVIRONMENTAL_IMPACT: {
    click_type: 'help',
    click_description: 'Help on Environmental Impact',
  },
  HELP_ENERGY_EVENT: {
    click_type: 'help',
    click_description: 'Help on Energy Event',
  },
  HELP_GRID_EMISSIONS: {
    click_type: 'help',
    click_description: 'Help on Grid Emissions',
  },
  HELP_PROGRAM_INFORMATION: (program: string) => {
    return {
      click_type: 'help',
      click_description: 'Help on Program Information',
      program_name: program,
    };
  },
  HELP_ENERGY_INFORMATION: {
    click_type: 'help',
    click_description: 'Help on Energy Information',
  },
  LOGOUT_FROM_REDIRECT: {
    click_type: 'logout_from_redirect',
    click_description: 'Logout from redirect',
  },
  REQUEST_DELETE_MY_ACCOUNT: {
    click_type: 'delete',
    click_description: 'Request Delete My Account',
  },
  TAB_LINK: (link: string) => {
    return {
      click_type: 'tab',
      click_description: link,
    };
  },
  DATE: (description: string) => {
    return {
      click_type: 'date',
      click_description: description,
    };
  },
  EMAIL_SUPPORT: {
    click_type: 'email_support',
    click_description: 'Email support',
  },
  SHOW_CHAT_SUPPORT: {
    click_type: 'show_chat_support',
    click_description: 'Show chat support',
  },
  OPEN_HAMBURGER: {
    click_type: 'open_hamburger',
    click_description: 'Open hamburger',
  },
  MANAGE_SITES: {
    click_type: 'manage_sites',
    click_description: 'Manage sites',
  },
  CHANGE_SITE: (siteId: string) => {
    return {
      click_type: 'change_enrolled_site',
      site_id: siteId,
    };
  },
  APP_DOWNLOAD_APP_STORE: {
    click_type: 'app_downloads',
    click_description: 'Apple App Store',
  },
  APP_DOWNLOAD_PLAY_STORE: {
    click_type: 'app_downloads',
    click_description: 'Google Play Store',
  },
  NAVIGATION_GET_STARTED: {
    click_type: 'navigation',
    click_description: 'Sign Up',
  },
  NAVIGATION_SIGN_IN: {
    click_type: 'navigation',
    click_description: 'Sign In',
  },
  CHANGE_LANGUAGE: {
    click_type: 'language',
    click_description: 'Change Language',
  },
  NAVIGATION_CONTINUE: {
    click_type: 'navigation',
    click_description: 'Continue to Enrollment or Dashboard',
  },
  REQUEST_LOGOUT: {
    click_type: 'logout',
    click_description: 'Request Logout',
  },
  NAVIGATION_DASHBOARD: {
    click_type: 'navigation',
    click_description: 'Navigate to Dashboard',
  },
  NAVIGATION_ENERGY_USAGE: {
    click_type: 'navigation',
    click_description: 'Navigate to Energy Usage',
  },
  NAVIGATION_ENERGY_EVENTS: {
    click_type: 'navigation',
    click_description: 'Navigate to Energy Events',
  },
  NAVIGATION_MESSAGES: {
    click_type: 'navigation',
    click_description: 'Navigate to Messages',
  },
  NAVIGATION_SETTINGS: {
    click_type: 'navigation',
    click_description: 'Navigate to Settings',
  },
  SAVE_ACCOUNT: {
    click_type: 'save_account',
    click_description: 'Save account',
  },
  EDIT_PHONE_NUMBER: {
    click_type: 'edit_phone_number',
    click_description: 'Edit phone number',
  },
  DELETE_PHONE_NUMBER: {
    click_type: 'delete_phone_number',
    click_description: 'Delete phone number',
  },
  ADD_PHONE_NUMBER: {
    click_type: 'add_phone_number',
    click_description: 'Add phone number',
  },
  SEND_CODE: {
    click_type: 'send_code',
    click_description: 'Send code',
  },
  VERIFY_CODE: {
    click_type: 'verify_code',
    click_description: 'Verify code',
  },
  RESEND_CODE: {
    click_type: 'resend_code',
    click_description: 'Re-send code',
  },
  NAVIGATION_ACCOUNT: {
    click_type: 'navigation',
    click_description: 'Navigate to Account',
  },
  LINK_CHANGE_PASSWORD: {
    click_type: 'link',
    click_description: 'Open Link Change Password',
  },
  NAVIGATION_PROGRAM_DETAILS: {
    click_type: 'navigation',
    click_description: 'Navigate to Program Details',
  },
  NAVIGATION_MY_SITES: {
    click_type: 'navigation',
    click_description: 'Navigate to My Sites',
  },
  NAVIGATION_SUPPORT: {
    click_type: 'navigation',
    click_description: 'Navigate to Support',
  },
  NAVIGATION_LANGUAGE_SELECTION: {
    click_type: 'navigation',
    click_description: 'Navigate to Language Selection',
  },
  NAVIGATION_ABOUT: {
    click_type: 'navigation',
    click_description: 'Navigate to About',
  },
  NAVIGATION_REFERRAL: {
    click_type: 'navigation',
    click_description: 'Navigate to Referrals',
  },
  SELECT_MESSAGE: (messageId: string) => {
    return {
      click_type: 'select_message',
      message_id: messageId,
    };
  },
  LINK_TERMS_OF_SERVICE: {
    click_type: 'link',
    click_description: 'Open Link Terms of Service',
  },
  LINK_PRIVACY_POLICY: {
    click_type: 'link',
    click_description: 'Open Link Privacy Policy',
  },
  LINK_DISENROLL_PSR_PROGRAM: (programName?: string) => {
    return {
      click_type: 'link',
      click_description: `Open Link Disenroll from the Power Saver Rewards Program (${programName || ''})`,
    };
  },
  EDIT_SITE: {
    click_type: 'edit_site',
    click_description: 'Edit site',
  },
  EDIT_DONE: {
    click_type: 'edit_done',
    click_description: 'Done editing',
  },
  EDIT_SELECT_SITE: (siteId: string) => {
    return {
      click_type: 'edit_select_site',
      click_description: 'Edit selected site',
      site_id: siteId,
    };
  },
  SEARCH_MY_SITES_FILTER: (searchFilter: string) => {
    return {
      click_type: 'search_my_sites_filter',
      click_description: 'Search bar for my sites filter',
      search_filter: searchFilter,
    };
  },
  REMOVE_SEARCH_TERM: (removedSearchTerm: string) => {
    return {
      click_type: 'remove_search_term',
      click_description: 'Removed search term',
      removed_search_term: removedSearchTerm,
    };
  },
  CLEAR_SEARCH_FIELD: (clearedTerm: string) => {
    return {
      click_type: 'clear_search_field',
      click_description: 'Clear site id search field',
      cleared_search_term: clearedTerm,
    };
  },
  SELECT_SITE: (siteId: string) => {
    return {
      click_type: 'select_site',
      click_description: 'Select differrent site',
      site_id: siteId,
    };
  },
  LOWER_SITES_PAGES: {
    click_type: 'lower_sites_pages',
    click_description: 'Lower sites pages',
  },
  UPPER_SITES_PAGES: {
    click_type: 'upper_sites_pages',
    click_description: 'Upper sites pages',
  },
  BACK_TO_SETTINGS: {
    click_type: 'back_to_settings',
    click_description: 'Back to settings',
  },
  DELETE_SITE_MODAL: {
    click_type: 'delete_site_modal',
    click_description: 'Delete site modal',
  },
  CANCEL_EDIT_SITE: {
    click_type: 'cancel_edit_site',
    click_description: 'Cancel edit site',
  },
  CANCEL_DELETE_SITE: {
    click_type: 'cancel_delete_site',
    click_description: 'Cancel delete site',
  },
  SAVE_EDIT_SITE: (editedName: string) => {
    return {
      click_type: 'save_edit_site',
      click_description: 'Save edit site',
      edited_name: editedName,
    };
  },
  DELETE_SITE: (siteId: string) => {
    return {
      click_type: 'delete_site',
      click_description: 'Delete site',
      site_id: siteId,
    };
  },
  NEXT_SEASON: {
    click_type: 'next_season',
    click_description: 'Next season arrow',
  },
  PREVIOUS_SEASON: {
    click_type: 'previous_season',
    click_description: 'Previous season arrow',
  },
  SELECT_EVENT: (eventId?: string) => {
    return {
      click_type: 'select_event',
      event_id: eventId || '',
    };
  },
  SELECT_MONEY_EARNED: (earningId?: string) => {
    return {
      click_type: 'selected_money_earned',
      earning_id: earningId || '',
    };
  },
  HISTORY_ITEM_TAB: (historyItem: HistoryTabs) => {
    return {
      click_type: 'history_item_tab',
      history_item: historyItem,
    };
  },
  ZOOM_OUT_GRAPH: {
    click_type: 'zoomout_graph',
    click_description: 'Zoom out on graph',
  },
  ZOOM_IN_GRAPH: {
    click_type: 'zoomin_graph',
    click_description: 'Zoom in on graph',
  },
  OPEN_GRAPH_DESCRIPTION: {
    click_type: 'open_graph_description',
    click_description: 'Open graph description',
  },
  ENROLLMENT_LINK: {
    click_type: 'enrollment_link',
    click_description: 'Enrollment link',
  },
};

const PATHS = {
  ...SCREEN_PATHS,
  ...EVENT_PARAMS,
} as const;

export default PATHS;
export { EVENT_PARAMS, SCREEN_PATHS };
export type { ScreenTypes };
