import styled from 'styled-components';
import { helpers } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const PaginationWrapper = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
  max-width: 320px;
  width: 100%;
`;

export const PageIndicator = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
  margin: 0 8px;
`;
export const CurrentPage = styled.p`
  ${typography.number};
  font-weight: 400;
  color: ${colors.text};
`;
export const TotalPage = styled.p`
  ${typography.number};
  font-weight: 400;
  color: ${colors.grey};
  margin-left: 4px;
`;
