import styled, { css } from 'styled-components';
import { helpers, images, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const styleHelpIcon = {
  active: {
    src: images.helpBtn.image,
    alt: images.helpBtn.text,
  },
  width: '30px',
  height: '30px',
};
export const Wrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.white};
  gap: 40px;
  ${media.lg`
    padding: 11px;
    gap: 15px;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  ${media.lg`
    align-items: flex-start;
    gap: 5px;
  `}
`;

export const Body = styled.div<{ showPadding?: boolean }>`
  flex: 1;
  position: relative;
  ${(props) =>
    props.showPadding &&
    css`
      padding-bottom: 40px;
    `}
`;

export const Title = styled.p`
  ${typography.lg};
  line-height: 24px;
  font-weight: 900;
  color: ${colors.text};
  ${media.lg`
    ${typography.md};
    font-weight: 700;
    color: ${colors.primary};
    opacity: 0.9;
  `}
`;

export const DateTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;

export const Icon = styled.div`
  img {
    height: 100%;
  }
`;

export const Date = styled.p`
  ${typography.xs};
  color: ${colors.grey};
  font-weight: 500;
  ${media.lg`
    ${typography.xxs};
    color: ${colors.text};
  `};
  line-height: 16px;
`;

export const Time = styled(Date)``;

export const TabsWrapper = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
`;

export const NoDataContainer = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
  min-height: 413px;
`;

export const NoData = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize, fontWeight: theme.typography.title.fontWeight })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
  `,
);

export const Help = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  ${media.lg`
    right: 10px;
    top: 55px;
    float: unset;
    margin-top: unset;
  `}
`;
