import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`;

export const TextContent = styled.div`
  margin-left: 10px;
`;

export const SiteName = styled.p`
  margin-bottom: 2px;
`;

export const Description = styled.p`
  margin-top: 2px;
`;
