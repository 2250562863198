import { osName, OsTypes } from 'react-device-detect';

export enum Platform {
  web = 'web',
  android = 'android',
  ios = 'ios',
}

export const getActivePlatform = () => {
  if (osName === OsTypes.Android) {
    return Platform.android;
  }
  if (osName === OsTypes.IOS) {
    return Platform.ios;
  }
  return Platform.web;
};
