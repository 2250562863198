import { take, put, call, fork, all } from 'redux-saga/effects';
import * as apiPrimary from '@/apis/primary';
import {
  EVENTS_HISTORY_REQUEST,
  EVENTS_HISTORY_SUCCESS,
  EVENTS_HISTORY_FAILURE,
  EVENT_HISTORY_SELECT_REQUEST,
  EVENT_HISTORY_SELECT_SUCCESS,
  EVENT_HISTORY_SELECT_FAILURE,
} from '@/stores/dr/eventsHistory';
import { DrHistoryItem } from '@/types/dr';
import { LOADED, LOADING } from '@/stores/loading';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* events(siteId: string) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiPrimary.getDrEvents, { siteId });

    if (!response.success) {
      yield put({ type: EVENTS_HISTORY_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;

    yield put({ type: EVENTS_HISTORY_SUCCESS, events: data });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EVENTS_HISTORY_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* eventSelect(siteId: string, event: DrHistoryItem) {
  try {
    yield put({ type: LOADING });
    const response: IResponse = yield call(apiPrimary.getDrEvent, siteId, event?.eventId || '');

    if (!response.success) {
      yield put({ type: EVENT_HISTORY_SELECT_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;

    yield put({ type: EVENT_HISTORY_SELECT_SUCCESS, event: { ...data, name: event.name } });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: EVENT_HISTORY_SELECT_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchEvents() {
  while (true) {
    const { siteId } = yield take(EVENTS_HISTORY_REQUEST);
    yield fork(events, siteId);
  }
}

export function* watchEventSelect() {
  while (true) {
    const { siteId, event } = yield take(EVENT_HISTORY_SELECT_REQUEST);
    yield fork(eventSelect, siteId, event);
  }
}

export default function* drSaga() {
  yield all([fork(watchEvents), fork(watchEventSelect)]);
}
