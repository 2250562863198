import { EarningsHistorySeasons, EarningsSeasonByYearAPIResponse } from '@/stores/dr/earningsHistory';
import { MoneyEarnedItem, PendingMoneyEarnedItem } from '@/types/dr';

export const formatPendingEarnings = (data: EarningsSeasonByYearAPIResponse) => {
  const years = Object.keys(data);
  if (!years.length) {
    return data;
  }

  const formattedData: EarningsHistorySeasons = {};

  years.forEach((year) => {
    const yearData = data[year];
    if (yearData?.noEarningHistory?.length) {
      const earningsHistory = yearData.history;
      const pendingEarnings = fixPendingEarningsResponse(yearData.noEarningHistory);
      const sortedEarnings = sortEarningsList(earningsHistory, pendingEarnings);

      formattedData[year] = {
        summary: yearData.summary,
        history: sortedEarnings,
      };
    } else {
      formattedData[year] = {
        ...data[year],
      };
    }
  });

  return formattedData;
};

const fixPendingEarningsResponse = (pendingEarnings: Array<PendingMoneyEarnedItem>) => {
  return pendingEarnings?.map((pending) => {
    return {
      ...pending,
      earnedDate: pending.date,
      isPending: true,
    };
  });
};

const sortEarningsList = (earnings: Array<MoneyEarnedItem>, pendingEarnings: Array<PendingMoneyEarnedItem>) => {
  return [...earnings, ...pendingEarnings].sort((earningItemA, earningItemB) => {
    if ('sequenceNumber' in earningItemA && 'sequenceNumber' in earningItemB) {
      return (
        earningItemB.sequenceNumber - earningItemA.sequenceNumber ||
        new Date(earningItemB.earnedDate).valueOf() - new Date(earningItemA.earnedDate).valueOf()
      );
    }
    return new Date(earningItemB.earnedDate).valueOf() - new Date(earningItemA.earnedDate).valueOf();
  });
};
