import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';

export const Container = styled.div`
  display: grid;
  height: 390px;
`;

export const OriginDescription = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};
  `,
);

export const Information = styled(OriginDescription)(
  ({ theme }) => css`
    font-size: ${theme.typography.description.fontSize};
    color: ${theme.colors.text[80]};
    align-self: flex-end;
  `,
);

export const Description = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[100] })};

    & > p {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  `,
);
