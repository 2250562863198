import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import { RootState } from '@/stores/index';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { Container, Description, Title } from './index.styles';
import GBCPendingContainer from '../GBCPending/index.container';

const SignUpCompletedView = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: {
        userInfo: { program: userProgramName },
      },
    },
    enrollment: {
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  const programName = currentProgram.program ? currentProgram.program : userProgramName;
  const programContext = getProgramContext({ programName });
  const shouldDisplayReferralBlock = programContext?.getShouldDisplayReferralBlock();

  return (
    <>
      <Container>
        <Img src={images.logoOlivineGreen.image} style={{ height: '80px', width: 'auto', margin: '0 0 20px' }} />
        <Title>{t(`programs.${programName}.signupCompleted.title`)}</Title>
        <Description>{t(`programs.${programName}.signupCompleted.description`)}</Description>
      </Container>
      {shouldDisplayReferralBlock && (
        <GBCPendingContainer shareTextColor="black" minHeight="unset" containerPadding="30px 0" titleMargin="0" />
      )}
    </>
  );
};

export default SignUpCompletedView;
