import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { IStyleContainerProps, IStyleDateSelectProps } from './index.types';

export const Wrapper = styled.div``;

export const Container = styled.button<IStyleContainerProps>(
  ({
    theme,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    hideBackground,
    hideBoxShadow,
    width,
    isBorder,
  }) => css`
    ${helpers.flexSet('row', 'space-between', 'center')};
    ${!hideBackground && helpers.colorSet({ backgroundColor: theme.shadow.web.backgroundColor })};
    ${helpers.marginSet({ marginTop, marginRight, marginBottom, marginLeft })};
    ${helpers.paddingSet({ paddingTop, paddingRight, paddingBottom, paddingLeft })};
    ${isBorder
      ? helpers.borderSet({
          borderRadius: theme.borders.default.radius,
          borderWidth: 1,
          borderColor: theme.colors.border,
        })
      : helpers.borderSet({ borderRadius: theme.borders.default.radius })};
    ${!hideBoxShadow && `box-shadow: ${theme.shadow.web.boxShadow}`};
    background: inherit;
    cursor: pointer;
    ${width && `width: ${width}`};
  `,
);

export const OpenedDropdown = styled.div(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.text[80] })}
    ${helpers.flexSet('column')};
    visibility: visible;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      background-color: ${theme.colors.text[25]};
    }
  `,
);

export const SelectedDate = styled.span<IStyleDateSelectProps>(
  ({ textAlign }) => css`
    ${typography.sm};
    font-weight: 400;
    line-height: 20px;
    color: ${colors.text};
    border-width: 0;
    ${textAlign && `text-align: ${textAlign}`}
    flex: 1;
    padding-right: 3px;
    background-color: inherit;

    &:focus {
      outline: none;
    }
  `,
);
export const ItemWrapper = styled.div`
  position: relative;
`;

export const ItemContainer = styled.ul`
  position: absolute;
  top: 10px;
  left: 0;
  min-width: 250px;
  width: 100%;
  margin: 4px 0;

  z-index: 2;

  background: ${colors.white};
  border-radius: 12px;
  box-shadow: 0px 8px 24px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.4);

  max-height: 300px;
  overflow: auto;
`;
