import styled, { css } from 'styled-components';
import { global, theme } from '@/assets/styles';

export const Description = styled(global.Title)``;

export const ContactContainer = styled.div`
  padding: 10px 0 10px 0;
  position: relative;
`;
export const ContactTitle = styled(global.Title)`
  font-weight: 600;
`;

export const ContactEventWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 25px;
  cursor: pointer;
`;

export const ContactBody = styled.span<{ mobile?: boolean }>`
  font-size: ${theme.typography.title.fontSize};
  line-height: ${theme.typography.title.lineHeight};
  letter-spacing: ${theme.typography.title.letterSpacing};

  ${(props) => {
    if (props.mobile) {
      return css`
        color: ${theme.colors.link.default};
      `;
    }
    return css``;
  }}
`;
