import React, { useState } from 'react';
import { Button, Img } from './HoverIconButton.styles';

type HoverIconButtonProps = {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  padding?: string;
  image: {
    active: {
      src: string;
      alt: string;
    };
    hover: {
      src: string;
      alt: string;
    };
    width?: string;
    height?: string;
  };
};

export const HoverIconButton = ({ title, onClick, disabled, image, padding }: HoverIconButtonProps) => {
  const [srcState, setSrcState] = useState(image.active.src);
  return (
    <Button
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
      disabled={disabled}
      padding={padding}
    >
      {title}
      {image && (
        <Img
          width={image.width}
          height={image.height}
          src={srcState}
          onMouseOver={() => setSrcState(image.hover.src)}
          onMouseOut={() => setSrcState(image.active.src)}
          alt={image.active.alt}
        />
      )}
    </Button>
  );
};
