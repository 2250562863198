import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div<{ fullWidth: boolean }>`
  width: 100%;

  ${(props) =>
    props.fullWidth
      ? css`
          ${media.lg`
    width: 100%;
  `}
        `
      : css`
          ${media.lg`
    width: unset;
  `}
        `}
  border-bottom: 0.5px solid ${colors.lightGrey};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 15px;
`;
