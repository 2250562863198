import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { LinkButton } from '@/designSystem/components/buttons/link';
import { Container, Message } from './index.styles';

const DataOnlyMessage = ({ isProgramDetails }: { isProgramDetails?: boolean }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleNavigateToSites = () => {
    navigate(PATHS.SCREEN_SETTING_MANAGE_SITE.pathname);
  };
  return (
    <Container>
      <Message>
        {t(`programs.DATA_ONLY.${isProgramDetails ? 'programDetailsScreen' : 'dataOnlyMessage'}.description`)}
      </Message>
      <LinkButton
        onClick={() => handleNavigateToSites()}
        title={t('programs.DATA_ONLY.dataOnlyMessage.link_text')}
        underline={false}
      />
    </Container>
  );
};

export default DataOnlyMessage;
