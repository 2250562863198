import moment from 'moment';
import _ from 'lodash';
import { Language } from '@/types/setting';

export const getDayTexts = ({ filter }: { filter?: (hour: number) => string }) => {
  return Array.from(
    {
      length: 24,
    },
    (_, hour) =>
      moment()
        .startOf('day')
        .hours(hour)
        .format(filter ? filter(hour) : 'ha'),
  );
};

export const getDayTableTick = () => {
  const filter = () => 'ha';

  return getDayTexts({ filter });
};

export const getDayTickText = ({ zoom }: { zoom: number }) => {
  const filter = (hour: number) => {
    if (zoom > 30) {
      return 'ha';
    }
    if ([24, 12, 6, 3].find((h) => hour % h === 0)) {
      return 'ha';
    }
    return ' ';
  };

  return getDayTexts({ filter });
};

const getWeekTexts = () =>
  moment
    .weekdaysShort(false)
    .slice(1)
    .concat(moment.weekdaysShort(false)[0])
    .map((day) => day.toUpperCase());

export const getWeekTableTick = getWeekTexts;
export const getWeekTickText = getWeekTexts;

export const getMonthTexts = ({
  filter,
  range,
}: {
  filter?: (index: number, month: number) => string;
  range: string;
}) => {
  return Array.from({ length: moment(range, 'MMMM YYYY').daysInMonth() }, (value, index) => {
    const month = moment(range, 'MMMM YYYY').month() + 1;
    if (!filter) {
      return `${month}/${index + 1}`;
    }
    return filter(index, month);
  });
};

export const getMonthTableTick = ({ range }: { range: string }) => {
  const filter = (index: number, month: number) => {
    return `${month}/${index + 1}`;
  };

  return getMonthTexts({ filter, range });
};

export const getMonthTickText = ({
  zoom,
  graphData,
  range,
}: {
  zoom: number;
  graphData: Array<number>;
  range: string;
}) => {
  const filter = (index: number, month: number) => {
    if (zoom > 40 || index === 0 || index === graphData.length - 1) {
      return `${month}/${index + 1}`;
    }
    const days = _.range(1, graphData.length);
    const divisor = zoom > 20 ? 2 : 5;
    if (days.filter((h) => h % divisor === 0).includes(index)) {
      return `${month}/${index + 1}`;
    }
    return ' ';
  };

  return getMonthTexts({ filter, range });
};

export const getYearTexts = ({ filter, yearList }: { filter?: (day: string) => string; yearList: Array<string> }) => {
  if (!filter) {
    return yearList;
  }
  return moment.months().map((day) => filter(day));
};

export const getYearTableTick = ({ yearList }: { yearList: Array<string> }) => {
  const filter = (day: string) => {
    return day.toUpperCase();
  };
  return getYearTexts({ filter, yearList });
};

export const getYearTickText = ({ yearList, zoom }: { yearList: Array<string>; zoom: number }) => {
  const filter = (day: string) => {
    if (zoom > 60) {
      return day.toUpperCase();
    }
    return day.substring(0, 3).toUpperCase();
  };
  return getYearTexts({ filter, yearList });
};
