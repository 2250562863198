import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  padding: 0 20px;
  height: 100vh;
  ${media.lg`
    padding: 40px 20px 0 20px;
    height: unset;
  `};
`;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const TitleContainer = styled.div`
  display: none;
  padding: 0 16.5px;
  ${media.lg`
    display: block;
  `};
`;

export const Container = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px 0;
`;

export const Description = styled.div`
  ${typography.md};
  line-height: 22px;
  font-weight: 800;
  color: ${colors.text};
`;

export const ButtonContainer = styled.div`
  padding: 12px 0;
  display: flex;
`;

export const Button = styled.div`
  display: flex;
  flex: 1;
`;
