export enum Language {
  eng = 'ENG',
  esp = 'ESP',
  cn = 'CN',
}

export enum TempScale {
  fahrenheit = 'FAHRENHEIT',
  celsius = 'CELSIUS',
}
