import React from 'react';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import { Wrapper, Checkbox, Text } from './index.styles';
import { ISingleCheckboxProps } from './index.types';

const SingleCheckboxContainer = ({ items, value, onChange, checkboxLabel }: ISingleCheckboxProps) => {
  return (
    <Wrapper>
      {items.map(({ id, name, text }) => {
        const isSelect = name === value;
        return (
          <Checkbox
            key={id}
            onClick={() => {
              if (onChange) {
                onChange(name, text);
              }
            }}
            role="checkbox"
            aria-checked={isSelect !== undefined}
          >
            <Img src={isSelect ? images.checkboxChecked.image : images.checkbox.image} altAttr={checkboxLabel} />
            {text && text !== '' && <Text selected={!!isSelect}>{text}</Text>}
          </Checkbox>
        );
      })}
    </Wrapper>
  );
};
export default SingleCheckboxContainer;
