import { EDITION } from '@/constants/environment';
import { colors } from '../designSystem';

export const isOlivineEdition = EDITION === 'Olivine';
export const isUtilityEdition = EDITION === 'Utility';

export const shouldDisplayCO2FeatureChanges = isOlivineEdition;
export const suffixForCO2Languages = shouldDisplayCO2FeatureChanges ? 'CO2' : '';

export enum UnitHistoryDetail {
  lbs = 'lbs',
  kwh = 'kwh',
}

export const quickViewTextsByUnit = {
  kwh: {
    title: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.title',
    unitText: 'Wh',
    unitLabel: 'kilowatt hours',
    leftBar: {
      text: `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.typicalDay${suffixForCO2Languages}`,
      color: colors.grey,
    },
    rightBar: {
      text: `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      color: colors.accent,
    },
    legend: {
      text: `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.reduction${suffixForCO2Languages}`,
    },
  },
  lbs: {
    title: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.title',
    unitText: 'lbs CO2',
    unitLabel: 'pounds of CO2',
    leftBar: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.typicalDay',
      color: colors.grey,
    },
    rightBar: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.legend.energyEvent',
      color: colors.accent,
    },
    legend: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.reduction',
    },
  },
};

export const detailViewTextsByUnit = {
  kwh: {
    title: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.title',
    unitText: 'kWh',
    unitLabel: 'kilowatt hours',
    typical: {
      text: `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.typicalDay${suffixForCO2Languages}`,
      color: colors.grey,
    },
    beforeAndAfter: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.bnaEnergyEvent',
      color: colors.primary,
    },
    event: {
      text: `screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.legend.energyEvent${suffixForCO2Languages}`,
      color: colors.accent,
    },
  },
  lbs: {
    title: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.title',
    unitText: 'lbs CO2',
    unitLabel: 'pounds of CO2',
    typical: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.typicalDay',
      color: colors.grey,
    },
    beforeAndAfter: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.legend.bnaEnergyEvent',
      color: colors.primary,
    },
    event: {
      text: 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graphCO2.legend.energyEvent',
      color: colors.accent,
    },
  },
};
