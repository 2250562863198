import React from 'react';
import { useTranslation } from 'react-i18next';
import InputText from '@/molecules/InputText';
import { theme } from '@/assets/styles';
import { IProfileSetupViewProps } from './index.types';

const ProfileSetupView = ({
  firstName,
  lastName,
  onFirstName,
  onLastName,
  onNext,
  disabled,
}: IProfileSetupViewProps) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <InputText
        label={t('common.validations.firstName.placeholder')}
        labelId="profile-setup-firstname"
        type="text"
        value={firstName}
        border="underline"
        fontSize={theme.typography.body.fontSize}
        onChangeText={onFirstName}
        triggerSubmit={false}
        disabled={disabled}
      />
      <InputText
        type="text"
        label={t('common.validations.lastName.placeholder')}
        labelId="profile-setup-lastname"
        value={lastName}
        border="underline"
        fontSize={theme.typography.body.fontSize}
        marginTop={50}
        onChangeText={onLastName}
        onNext={onNext}
        triggerSubmit
        disabled={disabled}
      />
    </div>
  );
};

export default ProfileSetupView;
