import styled, { css } from 'styled-components';
import { colors, typography } from '@/designSystem/index';

interface ButtonProps {
  disabled?: boolean;
  small?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${(props) =>
    props.small &&
    css`
      flex: 0 1 200px;
    `};
  width: 303px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: ${(props) => (props.disabled ? colors.grey : colors.primary)};
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: ${(props) => (props.disabled ? 'none' : 'pointer')};
`;

export const Title = styled.div`
  ${typography.sm};
  color: ${colors.white};
  font-weight: 900;
`;
