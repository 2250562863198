import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  padding: 0;
`;

export const MessageWrapper = styled.div`
  background-color: ${colors.white};
  height: calc(100vh - 80px);
  padding: 30px 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const DateText = styled.p`
  ${typography.xs};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.grey};
`;

export const Contents = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
`;
