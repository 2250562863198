import styled, { css } from 'styled-components';
import { helpers, images, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const styleInformationIcon = {
  active: {
    src: images.informationBtn.image,
    alt: images.informationBtn.text,
  },
  width: '18px',
  height: '18px',
};
export const Wrapper = styled.div`
  ${media.lg`
    display: none;
  `}
`;

export const Container = styled.div<{ showBorder?: boolean }>`
  ${helpers.flexSet('row', 'space-between', 'center')};
  padding: 16px 24px;

  background: ${colors.white};
  ${(props) =>
    props.showBorder &&
    css`
      border-bottom: 2px solid;
      border-color: rgba(0, 0, 0, 0.1);
    `}

  ${media.lg`
    border-radius: 8px;
    border-bottom: 0;
    align-items: center;
  `}
  ${media.md`
    padding: 24px;
    align-items: center;
  `}
  ${media.xs`
    padding: 24px;
    align-items: center;
  `}
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ScreenNameContainer = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const ScreenName = styled.p`
  ${typography.md}
  color: ${colors.text};
  font-weight: 900;
  ${media.lg`
    ${typography.xl};
  `};
`;

export const EmptyForLayout = styled.div`
  width: 32px;
  height: 32px;
`;

export const ImageContainer = styled.div`
  display: block;
  ${media.lg`
    display: none;
    margin-right: 10px;
  `}
`;
