/* eslint-disable global-require */
import { isUtilityEdition } from '@/helpers/Edition';

const images = {
  androidQR: {
    image: isUtilityEdition
      ? require('@/assets/images/qr-ANDROID-utility.png')
      : require('@/assets/images/qr-ANDROID-olivine.png'),
    text: 'common.image.text.androidQR',
  },
  iosQR: {
    image: isUtilityEdition
      ? require('@/assets/images/qr-IOS-utility.png')
      : require('@/assets/images/qr-IOS-olivine.png'),
    text: 'common.image.text.iosQR',
  },
  logoOlivineGreen: {
    image: isUtilityEdition
      ? require('@/assets/images/logo-olivine-green.png')
      : require('@/assets/images/logo-olivine-green-olivine-edition.png'),
    text: 'common.image.text.logoOlivineGreen',
  },
  logoOlivineWhite: {
    image: require('@/assets/images/logo-olivine-white.png'),
    text: 'common.image.text.logoOlivineWhite',
  },
  logoOlivineWebGreen: {
    image: isUtilityEdition
      ? require('@/assets/images/logo-olivine-web-green-utility.png')
      : require('@/assets/images/OlivineCR_VPP_Logo_Stacked.png'),
    text: 'common.image.text.logoOlivineWebGreen',
  },
  logoOlivineWebWhite: {
    image: isUtilityEdition
      ? require('@/assets/images/logo-olivine-web-white-utility.png')
      : require('@/assets/images/OlivineCR_VPP_Logo_Stacked.png'),
    text: 'common.image.text.logoOlivineWebWhite',
  },
  hamburger: {
    image: require('@/assets/images/icon-hamburger.png'),
    text: 'common.image.text.hamburger',
  },
  menuDashboardActive: {
    image: require('@/assets/images/icon-menu-dashboard-active.svg'),
    text: 'common.image.text.menuDashboardActive',
  },
  menuDashboardDeactive: {
    image: require('@/assets/images/icon-menu-dashboard-deactive.svg'),
    text: 'common.image.text.menuDashboardDeactive',
  },
  menuUsagePatternActive: {
    image: require('@/assets/images/icon-menu-usage-pattern-active.svg'),
    text: 'common.image.text.menuUsagePatternActive',
  },
  menuUsagePatternDeactive: {
    image: require('@/assets/images/icon-menu-usage-pattern-deactive.svg'),
    text: 'common.image.text.menuUsagePatternDeactive',
  },
  menuDrActive: {
    image: require('@/assets/images/icon-menu-dr-active.svg'),
    text: 'common.image.text.menuDrActive',
  },
  menuDrDeactive: {
    image: require('@/assets/images/icon-menu-dr-deactive.svg'),
    text: 'common.image.text.menuDrDeactive',
  },
  menuMessagesActive: {
    image: require('@/assets/images/icon-menu-messages-active.svg'),
    text: 'common.image.text.menuMessagesActive',
  },
  menuMessagesDeactive: {
    image: require('@/assets/images/icon-menu-messages-deactive.svg'),
    text: 'common.image.text.menuMessagesDeactive',
  },
  menuSettingsActive: {
    image: require('@/assets/images/icon-menu-settings-active.svg'),
    text: 'common.image.text.menuSettingsActive',
  },
  menuSettingsDeactive: {
    image: require('@/assets/images/icon-menu-settings-deactive.svg'),
    text: 'common.image.text.menuSettingsDeactive',
  },
  menuDot: {
    image: require('@/assets/images/icon-menu-dot.png'),
    text: 'common.image.text.menuDot',
  },
  appStore: {
    image: require('@/assets/images/icon-app-store.png'),
    text: 'common.image.text.appStore',
  },
  googlePlay: {
    image: require('@/assets/images/icon-google-play.png'),
    text: 'common.image.text.googlePlay',
  },
  moreVertical: {
    image: require('@/assets/images/icon-more-vertical.png'),
    text: 'common.image.text.moreVertical',
  },
  arrowBackBlack: {
    image: require('@/assets/images/icon-arrow-back-black.png'),
    text: 'common.image.text.arrowBackBlack',
  },
  arrowBackWhite: {
    image: require('@/assets/images/icon-arrow-back-white.png'),
    text: 'common.image.text.arrowBackWhite',
  },
  arrowRightBlue: {
    image: require('@/assets/images/icon-arrow-right-blue.png'),
    text: 'common.image.text.arrowRightBlue',
  },
  arrowRightGray: {
    image: require('@/assets/images/icon-arrow-right-gray.png'),
    text: 'common.image.text.arrowRightGray',
  },
  arrowLeftGreen: {
    image: require('@/assets/images/icon-arrow-left-green.png'),
    text: 'common.image.text.arrowLeftGreen',
  },
  arrowLeftGreenDisabled: {
    image: require('@/assets/images/icon-arrow-left-disabled.png'),
    text: 'common.image.text.arrowLeftGreen',
  },
  arrowRightGreen: {
    image: require('@/assets/images/icon-arrow-right-green.png'),
    text: 'common.image.text.arrowRightGreen',
  },
  arrowRightGreenDisabled: {
    image: require('@/assets/images/icon-arrow-right-disabled.png'),
    text: 'common.image.text.arrowRightGreen',
  },
  arrowUpGreen: {
    image: require('@/assets/images/icon-arrow-up-green.png'),
    text: 'common.image.text.arrowUpGreen',
  },
  arrowUpRedHighest: {
    image: require('@/assets/images/icon-arrow-up-red-highest.png'),
    text: 'common.image.text.arrowUpRedHighest',
  },
  arrowUpGreenLowest: {
    image: require('@/assets/images/icon-arrow-down-green-lowest.png'),
    text: 'common.image.text.arrowUpGreenLowest',
  },
  arrowDownGreen: {
    image: require('@/assets/images/icon-arrow-down-green.png'),
    text: 'common.image.text.arrowDownGreen',
  },
  arrowLeftBlack: {
    image: require('@/assets/images/icon-arrow-left-black.png'),
    text: 'common.image.text.arrowLeftBlack',
  },
  arrowBottomBlack: {
    image: require('@/assets/images/icon-arrow-bottom-black.png'),
    text: 'common.image.text.arrowBottomBlack',
  },
  checkboxCircle: {
    image: require('@/assets/images/icon-checkbox-circle.png'),
    text: 'common.image.text.checkboxCircle',
  },
  checkbox: {
    image: require('@/assets/images/icon-checkbox.png'),
    text: 'common.image.text.checkbox',
  },
  checkboxChecked: {
    image: require('@/assets/images/icon-checkbox-checked.png'),
    text: 'common.image.text.checkboxChecked',
  },
  radio: {
    image: require('@/assets/images/icon-radio.png'),
    text: 'common.image.text.radio',
  },
  radioHover: {
    image: require('@/assets/images/icon-radio-hover.png'),
    text: 'common.image.text.radio',
  },
  radioChecked: {
    image: require('@/assets/images/icon-radio-checked.png'),
    text: 'common.image.text.radioChecked',
  },
  radioCheckedHover: {
    image: require('@/assets/images/icon-radio-checked-hover.png'),
    text: 'common.image.text.radioChecked',
  },
  dropdownDown: {
    image: require('@/assets/images/icon-dropdown-down.png'),
    text: 'common.image.text.dropdownDown',
  },
  dropdownUp: {
    image: require('@/assets/images/icon-dropdown-up.png'),
    text: 'common.image.text.dropdownUp',
  },
  modalClose: {
    image: require('@/assets/images/icon-modal-close.png'),
    text: 'common.image.text.modalClose',
  },
  siteHome: {
    image: require('@/assets/images/icon-site-home.png'),
    text: 'common.image.text.siteHome',
  },
  greenChevronUp: {
    image: require('@/assets/images/green-chevron-up.png'),
    text: 'common.image.text.backChevron',
  },
  userProfile: {
    image: require('@/assets/images/icon-user-profile.png'),
    text: 'common.image.text.userProfile',
  },
  shareEmail: {
    image: require('@/assets/images/icon-share-email.png'),
    text: 'common.image.text.shareEmail',
  },
  shareSms: {
    image: require('@/assets/images/icon-share-sms.png'),
    text: 'common.image.text.shareSms',
  },
  emailGray: {
    image: require('@/assets/images/icon-email-gray.png'),
    text: 'common.image.text.emailGray',
  },
  error404: {
    image: require('@/assets/images/icon-error-404.png'),
    text: 'common.image.text.error404',
  },
  warning: {
    image: require('@/assets/images/icon-warning.png'),
    text: 'common.image.text.warning',
  },
  greenEnergy: {
    image: require('@/assets/images/icon-green-energy.png'),
    text: 'common.image.text.greenEnergy',
  },
  blueEnergy: {
    image: require('@/assets/images/icon-blue-energy@3x.png'),
    text: 'common.image.text.blueEnergy',
  },
  co2: {
    image: require('@/assets/images/icon-co2@3x.png'),
    text: 'common.image.text.co2',
  },
  car: {
    image: require('@/assets/images/icon-car.png'),
    text: 'common.image.text.car',
  },
  help: {
    image: require('@/assets/images/icon-help.png'),
    text: 'common.image.text.help',
  },
  levelNoAction: {
    image: require('@/assets/images/icon-level-no-action@2x.png'),
    text: 'common.image.text.levelNoAction',
  },
  levelBronze: {
    image: require('@/assets/images/icon-level-bronze@2x.png'),
    text: 'common.image.text.levelBronze',
  },
  levelSilver: {
    image: require('@/assets/images/icon-level-silver@2x.png'),
    text: 'common.image.text.levelSilver',
  },
  levelGold: {
    image: require('@/assets/images/icon-level-gold@2x.png'),
    text: 'common.image.text.levelGold',
  },
  levelTotalNoAction: {
    image: require('@/assets/images/icon-level-total-no-action.png'),
    text: 'common.image.text.levelTotalNoAction',
  },
  levelTotalBronze: {
    image: require('@/assets/images/icon-level-total-bronze.png'),
    text: 'common.image.text.levelTotalBronze',
  },
  levelTotalSilver: {
    image: require('@/assets/images/icon-level-total-silver.png'),
    text: 'common.image.text.levelTotalSilver',
  },
  levelTotalGold: {
    image: require('@/assets/images/icon-level-total-gold.png'),
    text: 'common.image.text.levelTotalGold',
  },
  greenEnergyDisabled: {
    image: require('@/assets/images/icon-green-energy-disabled.png'),
    text: 'common.image.text.greenEnergyDisabled',
  },
  greenEnergyEnabled: {
    image: require('@/assets/images/icon-green-energy-enabled.png'),
    text: 'common.image.text.greenEnergyEnabled',
  },
  applianceAirCondActive: {
    image: require('@/assets/images/icon-appliance-air-cond-active.png'),
    text: 'common.image.text.applianceAirCondActive',
  },
  applianceAirCondDeactive: {
    image: require('@/assets/images/icon-appliance-air-cond-deactive.png'),
    text: 'common.image.text.applianceAirCondDeactive',
  },
  applianceBatteryActive: {
    image: require('@/assets/images/icon-appliance-battery-active.png'),
    text: 'common.image.text.applianceBatteryActive',
  },
  applianceBatteryDeactive: {
    image: require('@/assets/images/icon-appliance-battery-deactive.png'),
    text: 'common.image.text.applianceBatteryDeactive',
  },
  applianceHeaterActive: {
    image: require('@/assets/images/icon-appliance-heater-active.png'),
    text: 'common.image.text.applianceHeaterActive',
  },
  applianceHeaterDeactive: {
    image: require('@/assets/images/icon-appliance-heater-deactive.png'),
    text: 'common.image.text.applianceHeaterDeactive',
  },
  applianceLampActive: {
    image: require('@/assets/images/icon-appliance-lamp-active.png'),
    text: 'common.image.text.applianceLampActive',
  },
  applianceLampDeactive: {
    image: require('@/assets/images/icon-appliance-lamp-deactive.png'),
    text: 'common.image.text.applianceLampDeactive',
  },
  applianceMicrowaveActive: {
    image: require('@/assets/images/icon-appliance-microwave-active.png'),
    text: 'common.image.text.applianceMicrowaveActive',
  },
  applianceMicrowaveDeactive: {
    image: require('@/assets/images/icon-appliance-microwave-deactive.png'),
    text: 'common.image.text.applianceMicrowaveDeactive',
  },
  applianceMonitorActive: {
    image: require('@/assets/images/icon-appliance-monitor-active.png'),
    text: 'common.image.text.applianceMonitorActive',
  },
  applianceMonitorDeactive: {
    image: require('@/assets/images/icon-appliance-monitor-deactive.png'),
    text: 'common.image.text.applianceMonitorDeactive',
  },
  appliancePlugActive: {
    image: require('@/assets/images/icon-appliance-plug-active.png'),
    text: 'common.image.text.appliancePlugActive',
  },
  appliancePlugDeactive: {
    image: require('@/assets/images/icon-appliance-plug-deactive.png'),
    text: 'common.image.text.appliancePlugDeactive',
  },
  applianceThermostatActive: {
    image: require('@/assets/images/icon-appliance-thermostat-active.png'),
    text: 'common.image.text.applianceThermostatActive',
  },
  applianceThermostatDeactive: {
    image: require('@/assets/images/icon-appliance-thermostat-deactive.png'),
    text: 'common.image.text.applianceThermostatDeactive',
  },
  applianceWasherActive: {
    image: require('@/assets/images/icon-appliance-washer-active.png'),
    text: 'common.image.text.applianceWasherActive',
  },
  applianceWasherDeactive: {
    image: require('@/assets/images/icon-appliance-washer-deactive.png'),
    text: 'common.image.text.applianceWasherDeactive',
  },
  drEventEnded: {
    image: require('@/assets/images/DR-event-ended-check.png'),
    text: '',
  },
  moneyEarnedEvent: {
    image: require('@/assets/images/icon-money-earned-event.png'),
    text: 'common.image.text.moneyEarnedEvent',
  },
  moneyEarnedOther: {
    image: require('@/assets/images/icon-money-earned-other.png'),
    text: 'common.image.text.moneyEarnedOther',
  },
  calendar: {
    image: require('@/assets/images/icon-calendar.png'),
    text: 'common.image.text.calendar',
  },
  calendarGrey: {
    image: require('@/assets/images/icon-calendar-grey.png'),
    text: 'common.image.text.calendar',
  },
  timer: {
    image: require('@/assets/images/icon-timer.png'),
    text: 'common.image.text.timer',
  },
  history: {
    image: require('@/assets/images/icon-history.png'),
    text: 'common.image.text.history',
  },
  emptyMessages: {
    image: require('@/assets/images/icon-empty-message.png'),
    text: 'common.image.text.emptyMessages',
  },
  logoSCE: {
    image: require('@/assets/images/logo-sce.png'),
    text: 'common.image.text.logoSCE',
  },
  logoSCESquare: {
    image: require('@/assets/images/logo-sce-square.png'),
    text: 'common.image.text.logoSCESquare',
  },
  logoPGE: {
    image: require('@/assets/images/logo-pge.png'),
    text: 'common.image.text.logoPGE',
  },
  logoClimateResponse: {
    image: isUtilityEdition
      ? require('@/assets/images/logo-climate-response-utility.png')
      : require('@/assets/images/OlivineCR_VPP_Logo_Horizontal.png'),
    text: 'common.image.text.logoClimateResponse',
  },
  support: {
    image: require('@/assets/images/icon-support.png'),
    text: 'common.image.text.support',
  },
  errorMask: {
    image: require('@/assets/images/ic-error-mask.png'),
    text: 'common.image.text.errorMask',
  },
  optionSelected: {
    image: require('@/assets/images/ic-option-selected.png'),
    text: 'common.image.text.optionSelected',
  },
  accountNumberImg: {
    image: require('@/assets/images/accountNumber.png'),
  },
  optionUnSelected: {
    image: require('@/assets/images/ic-option-unselected.png'),
    text: 'common.image.text.optionUnSelected',
  },
  iconEdit32px: {
    image: require('@/assets/images/icon-edit-32px.png'),
    text: 'common.image.text.editPhoneIcon',
  },
  iconDelete32px: {
    image: require('@/assets/images/icon-delete-32px.png'),
    text: 'common.image.text.deletePhoneIcon',
  },
  iconPlus24px: {
    image: require('@/assets/images/icon-plus-24px.png'),
    text: '',
  },
  iconCheckRounded32px: {
    image: require('@/assets/images/icon-check-rounded-32px.png'),
    text: 'common.image.text.iconCheckRounded32',
  },
  iconClose24px: {
    image: require('@/assets/images/icon-close-24px.png'),
  },
  textIconButtonArrow: {
    image: require('@/assets/images/ic-right-arrow-for-button.png'),
    text: '',
  },
  textIconButtonArrowLeft: {
    image: require('@/assets/images/ic-left-arrow-for-button.png'),
    text: '',
  },
  textIconButtonArrowDisabled: {
    image: require('@/assets/images/ic-right-arrow-for-button-disabled.png'),
    text: '',
  },
  textIconButtonArrowLeftDisabled: {
    image: require('@/assets/images/ic-left-arrow-for-button-disabled.png'),
    text: '',
  },
  search: {
    image: require('@/assets/images/ic-search.png'),
  },
  clear: {
    image: require('@/assets/images/ic-input-clear.png'),
    text: 'clear',
  },
  removeTextItem: {
    image: require('@/assets/images/ic-delete-green.png'),
  },
  pagePrevious: {
    image: require('@/assets/images/ic-page-previous.png'),
    text: 'common.generalButtons.back',
  },
  pageNext: {
    image: require('@/assets/images/ic-page-next.png'),
    text: 'common.generalButtons.next',
  },
  editSite: {
    image: require('@/assets/images/ic-edit-site.png'),
    text: 'screens.main.settings.mySites.mySiteDetailScreen.title',
  },
  editSiteActive: {
    image: require('@/assets/images/ic-edit-site-active.png'),
    text: 'screens.main.settings.mySites.mySiteDetailScreen.title',
  },
  navigationBack: {
    image: require('@/assets/images/icon-navigation-back.png'),
    text: 'common.generalButtons.back',
  },
  menuActivated: {
    image: require('@/assets/images/ic-menu-activated.png'),
    text: '',
  },
  menuDeactivated: {
    image: require('@/assets/images/ic-menu-deactivated.png'),
    text: '',
  },
  more: {
    image: require('@/assets/images/icon-more.png'),
    text: 'screens.main.settings.mySites.manageSites.title',
  },
  navigationBlackBack: {
    image: require('@/assets/images/icon-header-back.png'),
    text: 'common.generalButtons.back',
  },
  close: {
    image: require('@/assets/images/icon-close-button.png'),
    text: 'common.generalButtons.close',
  },
  headNavigationBack: {
    image: require('@/assets/images/icon-head-navigation-back.png'),
    text: '',
  },
  headNavigationClose: {
    image: require('@/assets/images/icon-head-navigation-close.png'),
    text: '',
  },
  editPhone: {
    image: require('@/assets/images/icon-edit-phone.png'),
    text: 'screens.main.settings.account.accountScreen.editPhoneNumber',
  },
  editPhoneHover: {
    image: require('@/assets/images/icon-edit-phone-hover.png'),
    text: 'screens.main.settings.account.accountScreen.editPhoneNumber',
  },
  removePhone: {
    image: require('@/assets/images/icon-remove-phone.png'),
    text: 'screens.main.settings.account.accountScreen.modalDeletePhone.title',
  },
  removePhoneHover: {
    image: require('@/assets/images/icon-remove-phone-hover.png'),
    text: 'screens.main.settings.account.accountScreen.modalDeletePhone.title',
  },
  iconViewPortfolio: {
    image: require('@/assets/images/icon-view-portfolio.png'),
    text: 'screens.main.savingsEvents.portfolioScreen.viewPortfolio',
  },
  datepickerArrowLeft: {
    image: require('@/assets/images/datepicker-arrow-left.png'),
    text: 'common.generalButtons.previous',
  },
  datepickerArrowRight: {
    image: require('@/assets/images/datepicker-arrow-right.png'),
    text: 'common.generalButtons.next',
  },
  arrowUpExtremeHigh: {
    image: require('@/assets/images/icon-arrow-up-extreme-high.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.highest.up',
  },
  arrowDownExtremeLow: {
    image: require('@/assets/images/icon-arrow-down-extreme-low.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  arrowUpExtremeHighMobile: {
    image: require('@/assets/images/icon-arrow-up-extreme-high-mobile.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.highest.up',
  },
  arrowDownExtremeLowMobile: {
    image: require('@/assets/images/icon-arrow-down-extreme-low-mobile.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  arrowDownPerformanceBlue: {
    image: require('@/assets/images/performance-down-arrow.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  arrowDownBlack: {
    image: require('@/assets/images/icon-arrow-down-black.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  arrowUpBlack: {
    image: require('@/assets/images/icon-arrow-up-black.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.highest.up',
  },
  calendarBlack: {
    image: require('@/assets/images/icon-calendar-black@3x.png'),
    text: 'common.image.text.date',
  },
  calendarAccent: {
    image: require('@/assets/images/icon-calendar-accent@3x.png'),
    text: 'common.image.text.date',
  },
  clockGrey: {
    image: require('@/assets/images/icon-clock-grey@3x.png'),
    text: 'common.image.text.clock',
  },
  eventEnergy: {
    image: require('@/assets/images/icon-event-energy@3x.png'),
    text: 'common.image.text.event',
  },
  helpBtn: {
    image: require('@/assets/images/icon-help-renew@2x.png'),
    text: 'common.image.text.help',
  },
  informationBtn: {
    image: require('@/assets/images/icon-information.png'),
    text: 'common.image.text.information',
  },
  removeSiteBtn: {
    image: require('@/assets/images/icon-delete-site.png'),
    text: 'screens.main.settings.mySites.mySiteDetailScreen.delete',
  },
  closeDownBtn: {
    image: require('@/assets/images/icon-close-down.png'),
    text: 'common.generalButtons.close',
  },
  impactArrowDown: {
    image: require('@/assets/images/impact-arrow-down@3x.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  leafIcon: {
    image: require('@/assets/images/icon-leaf@3x.png'),
    text: 'screens.main.dashboard.dashboardScreen.environmentalImpact.title',
  },
  iconOngoing: {
    image: require('@/assets/images/icon-ongoing@3x.png'),
    text: 'screens.main.dashboard.dashboardScreen.reminders.ongoing',
  },
  iconUpcoming: {
    image: require('@/assets/images/icon-upcoming@3x.png'),
    text: 'screens.main.dashboard.dashboardScreen.reminders.upcoming',
  },
  iconTooltip: {
    image: require('@/assets/images/icon-tooltip@2x.png'),
    text: 'common.image.text.information',
  },
  iconTooltipOn: {
    image: require('@/assets/images/icon-tooltip-hover@2x.png'),
    text: 'common.image.text.information',
  },
  bannerClose: {
    image: require('@/assets/images/icon-banner-close.png'),
    text: 'common.generalButtons.close',
  },
  logoOlivineSmall: {
    image: require('@/assets/images/logo-olivine-small.png'),
    text: 'common.image.text.logoOlivineGreen',
  },
  eventArrowDownBlue: {
    image: require('@/assets/images/eventArrowDownBlue.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.lowest.down',
  },
  eventArrowUpRed: {
    image: require('@/assets/images/eventArrowUpRed.png'),
    text: 'screens.main.usagePattern.usagePatternScreen.graph.usage.highest.up',
  },
};

export default images;
