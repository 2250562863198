import { take, put, call, all, fork } from 'redux-saga/effects';
import * as apiAuth from '@/apis/auth';
import { ReqSetting } from '@/apis/auth/types';
import { LOADED } from '@/stores/loading';
import { SETTING_REQUEST, SETTING_SUCCESS, SETTING_FAILURE } from '@/stores/setting';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* updateSetting(req: ReqSetting) {
  try {
    const response: IResponse = yield call(apiAuth.settings, req);
    if (!response?.success) {
      yield put({ type: SETTING_FAILURE, error: response.error?.message || '' });
      return;
    }

    yield put({ type: SETTING_SUCCESS, language: req.language, tempScale: req.tempScale });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: SETTING_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchUpdateSetting() {
  while (true) {
    const { req } = yield take(SETTING_REQUEST);
    yield fork(updateSetting, req);
  }
}

export default function* settingSaga() {
  yield all([fork(watchUpdateSetting)]);
}
