import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { media } from '@/assets/styles';

export const ValueColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${media.lg`
    justify-content: space-evenly;
  `}
`;

export const Value = styled.p`
  font-family: 'Lato';
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  color: ${colors.text};
`;
export const ValueTitle = styled.p`
  ${typography.md};
  line-height: 22px;
  color: ${colors.grey};
  font-weight: 400;
`;

export const Wrapper = styled.div`
  flex: 1;
`;

export const Description = styled.p`
  margin-top: 20px;
  ${typography.xxs};
  color: ${colors.grey};
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;
