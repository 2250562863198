import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

interface ResentTextProps {
  phoneStatus?: string;
}
export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 0 30px;
  ${media.lg`
    padding: 20px 30px 10px 30px;
  `}
`;

export const HeadNavigation = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const NavigationTitle = styled.p`
  ${typography.md};
  color: ${colors.text};
  font-weight: 900;
  line-height: 24px;
`;

export const VerifiedImage = styled.img`
  width: 40px;
  height: auto;
  filter: invert(35%) sepia(70%) saturate(939%) hue-rotate(62deg) brightness(70%) contrast(70%);
`;

export const VerifiedMessageContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
`;

export const VerifiedMessageText = styled.div`
  ${typography.xl2};
  font-weight: 900;
  color: ${colors.text};
  margin-left: 20px;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  background-color: ${colors.white};
`;

export const Title = styled.div`
  ${typography.xl1};
  font-weight: 900;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  ${typography.sm};
  color: ${colors.blackGrey};
`;

export const InputContainer = styled.div`
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;
export const ResentText = styled.div<ResentTextProps>`
  ${typography.sm};
  align-self: center;
  margin-top: 30px;
  color: ${(props) => (props.phoneStatus === 'resent' ? colors.good : colors.bad)};
`;
