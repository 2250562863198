import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export interface IStyleTextProps {
  selected: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Button = styled.div``;

export const Radio = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
  cursor: pointer;
  gap: 8px;
`;

export const Text = styled.p<IStyleTextProps>(
  () => css`
    ${typography.sm};
    line-height: 24px;
    font-weight: 400;
    color: ${colors.text};
    flex: 1;
    flex-wrap: wrap;
    padding: 3px 0;
  `,
);
