import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { useUserInfo } from '@/hooks/index';
import { RootState } from '@/stores/index';
import { OTPStatus } from '@/types/phoneOtp';
import { images } from '@/assets/styles';
import { hyphenPhoneNumber } from '@/helpers/RegexHelper';
import contents from '@/assets/config/appInfo';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { PhoneNumberItem } from '@/molecules/PhoneNumberItem';
import { LinkButton } from '@/designSystem/components';
import usePhoneOtp from '@/hooks/usePhoneOtp';
import { FillPrimary } from '@/designSystem/components/buttons/FillPrimary';
import ModalDeletePhoneNumber from '@/screens/Main/Settings/Account/AccountScreen/ModalDeletePhoneNumber/ModalDeletePhoneNumber';
import {
  AddPhoneWrapper,
  PhoneNumbersContainer,
  Wrapper,
  Buttons,
  TitleContainer,
  Title,
  InputLabel,
  InputComponent,
  RowContainer,
  EmailValue,
  ButtonContainer,
} from './AccountScreen.styles';
import { SMSPhoneProfileScreen } from './SMSPhoneProfile';
import { SMSPhoneVerificationScreen } from './SMSPhoneVerification';

const AccountScreen = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: {
        userInfo,
        userInfo: { phoneNumbers },
      },
    },
    setting: {
      data: { language },
    },
    phoneOtp: {
      data: { phoneStatus },
    },
  } = useSelector((state: RootState) => state);

  document.title = `${t('screens.main.settings.account.accountScreen.navigationTitle')} - ${contents.name}`;

  const [state, setState] = useState({ firstName: '', lastName: '', disabledSave: true });
  const [modalDeleteAccount, setModalDeleteAccount] = useState({ modalDeleteAccount: false });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isFirstNameFocused, setIsFirstNameFocused] = useState(false);
  const [isLastNameFocused, setIsLastNameFocused] = useState(false);
  const { fetchChangePhoneOtp, fetchChangeOldPhoneNumber, fetchUnsetOldAndNewPhoneNumbers } = usePhoneOtp();
  const { fetchUpdateUserInfo, fetchUserInfo } = useUserInfo();

  const handleFirstName = useCallback((value: string) => {
    setState((prevState) => ({
      ...prevState,
      firstName: value,
      disabledSave: userInfo.lastName === prevState.lastName && userInfo.firstName === value,
    }));
  }, []);

  const handleLastName = useCallback((value: string) => {
    setState((prevState) => ({
      ...prevState,
      lastName: value,
      disabledSave: userInfo.lastName === value && userInfo.firstName === prevState.firstName,
    }));
  }, []);

  const handleSave = () => {
    const payload: ReqUpdateUserInfo = {
      firstName: state.firstName,
      lastName: state.lastName,
    };
    fetchUpdateUserInfo(payload);
    sentEvent('click', PATHS.SAVE_ACCOUNT);
  };

  const handleChangePassword = () => {
    window.open(`https://account.olivineinc.com/Account/ChangePassword?ui_locales=${language}`, '_blank');
    sentEvent('click', PATHS.LINK_CHANGE_PASSWORD);
  };

  const handleAddPhoneNumber = () => {
    fetchChangePhoneOtp(OTPStatus.request);
    sentEvent('click', PATHS.ADD_PHONE_NUMBER);
  };

  const handleEditPhoneNumber = (phoneNumber: string) => {
    fetchChangeOldPhoneNumber(hyphenPhoneNumber(phoneNumber));
    fetchChangePhoneOtp(OTPStatus.request);
    sentEvent('click', PATHS.EDIT_PHONE_NUMBER);
  };

  const handleDeletePhoneNumber = (phoneNumberToDelete: string) => {
    const payload = { phoneNumbers: phoneNumbers?.filter((phoneNumber) => phoneNumberToDelete !== phoneNumber) };

    fetchUpdateUserInfo(payload, () =>
      fetchUserInfo(() => {
        setShowDeleteModal(false);
      }),
    );
    sentEvent('click', PATHS.DELETE_PHONE_NUMBER);
  };

  const handlerDeleteAccount = () => {
    sentEvent('click', PATHS.POPUP_DELETE_ACCOUNT);
    setModalDeleteAccount({ ...modalDeleteAccount, modalDeleteAccount: true });
  };

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      handlerDeleteAccount();
    }
  }, []);

  useEffect(() => {
    setState({ ...state, firstName: userInfo.firstName, lastName: userInfo.lastName, disabledSave: true });
  }, [userInfo.firstName, userInfo.lastName]);

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'Account',
      screen_class: 'account',
    });
  }, []);

  useEffect(() => {
    fetchUnsetOldAndNewPhoneNumbers();
  }, []);

  const modalOtpError = phoneStatus === OTPStatus.error;
  const modalOtpVerification =
    phoneStatus === OTPStatus.verification ||
    phoneStatus === OTPStatus.resent ||
    phoneStatus === OTPStatus.verified ||
    phoneStatus === OTPStatus.resendError ||
    phoneStatus === OTPStatus.notVerified;
  const modalOtpRequest = phoneStatus === OTPStatus.request;

  const shouldShowAddPhoneButton = !phoneNumbers?.length || phoneNumbers?.length < 5;

  return (
    <SettingTemplate>
      {!modalOtpVerification && !modalOtpRequest && !modalOtpError && (
        <>
          <TitleContainer>
            <Title>{t('screens.main.settings.account.accountScreen.screenTitle')}</Title>
          </TitleContainer>
          <Wrapper>
            <RowContainer>
              <InputLabel>{t('common.validations.firstName.title')}</InputLabel>
              <InputComponent
                onFocus={() => setIsFirstNameFocused(true)}
                onBlur={() => setIsFirstNameFocused(false)}
                onChange={(e) => handleFirstName(e.target.value)}
                isFocused={isFirstNameFocused}
                value={state.firstName}
                aria-label={t('common.validations.siteNickname.title')}
              />
            </RowContainer>
            <RowContainer>
              <InputLabel>{`${t('common.validations.lastName.title')}`}</InputLabel>
              <InputComponent
                onFocus={() => setIsLastNameFocused(true)}
                onBlur={() => setIsLastNameFocused(false)}
                onChange={(e) => handleLastName(e.target.value)}
                isFocused={isLastNameFocused}
                value={state.lastName}
                aria-label={t('common.validations.lastName.title')}
              />
            </RowContainer>
            <RowContainer>
              <InputLabel>{t('common.validations.email.title')}</InputLabel>
              <EmailValue>{userInfo.email}</EmailValue>
            </RowContainer>
            <RowContainer>
              <InputLabel>{t('common.validations.password.title')}</InputLabel>
              <ButtonContainer>
                <LinkButton
                  title={t('common.validations.changePassword.title')}
                  onClick={handleChangePassword}
                  padding="12px 16px"
                />
              </ButtonContainer>
            </RowContainer>
            <RowContainer>
              <InputLabel>{t('common.validations.smsPhone.title')}</InputLabel>
              <PhoneNumbersContainer>
                {phoneNumbers?.map((phoneNumber) => (
                  <>
                    <PhoneNumberItem
                      key={phoneNumber}
                      phoneNumber={phoneNumber}
                      onClickEdit={() => handleEditPhoneNumber(phoneNumber)}
                      onClickDelete={() => {
                        setShowDeleteModal(true);
                      }}
                    />
                    <ModalDeletePhoneNumber
                      isVisible={showDeleteModal}
                      onClose={() => setShowDeleteModal(false)}
                      onClickNo={() => setShowDeleteModal(false)}
                      onClickYes={() => handleDeletePhoneNumber(phoneNumber)}
                    />
                  </>
                ))}
                {shouldShowAddPhoneButton && (
                  <AddPhoneWrapper>
                    <LinkButton
                      onClick={handleAddPhoneNumber}
                      title={t('screens.main.settings.account.accountScreen.addPhoneNumber')}
                      image={{
                        active: {
                          src: images.iconPlus24px.image,
                          alt: images.iconPlus24px.text,
                        },
                        disabled: {
                          src: images.iconPlus24px.image,
                          alt: images.iconPlus24px.text,
                        },
                        imagePlace: 'left',
                      }}
                      padding="12px 16px"
                    />
                  </AddPhoneWrapper>
                )}
              </PhoneNumbersContainer>
            </RowContainer>
            <Buttons>
              <ButtonContainer>
                <FillPrimary
                  title={t('common.generalButtons.save')}
                  onClick={handleSave}
                  disabled={state.disabledSave}
                  padding="12px"
                />
              </ButtonContainer>
            </Buttons>
          </Wrapper>
        </>
      )}
      {(modalOtpRequest || modalOtpError) && <SMSPhoneProfileScreen ariaControlsId="otp-request-popup" />}
      {(modalOtpVerification || (modalOtpError && modalOtpVerification)) && (
        <SMSPhoneVerificationScreen ariaControlsId="otp-verification-popup" />
      )}
    </SettingTemplate>
  );
};
export default AccountScreen;
