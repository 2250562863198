import React, { useEffect, useRef } from 'react';
import Sidebar from '@/organisms/Sidebar';
import Appbar from '@/templates/MainTemplate/Appbar';
import NestedAppbar from '@/templates/MainTemplate/NestedAppbar';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { Wrapper, Container, Body, LeftSection } from './index.styles';
import { IMainTemplateViewProps } from './index.types';

const MainTemplateView = ({ hamburgerOpen, children }: IMainTemplateViewProps) => {
  const logoComponentRef = useRef<HTMLDivElement>(null);
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });

  const { pathname } = useLocation();
  const currentPath = pathname.split('/');

  const isMySite = currentPath.includes('setting') && currentPath.includes('sites') && currentPath.length > 3;
  const isSettingsNestedMenu = currentPath.includes('setting') && currentPath.length > 2 && isMobileWindowSize;
  const isMessageDetail =
    currentPath.includes('message') && currentPath.length > 3 && !currentPath.includes('sites') && isMobileWindowSize;
  const isEventsNestedMenu = currentPath.includes('dr') && currentPath.length > 3 && !currentPath.includes('sites');
  const isDashboardNestedMenu = currentPath.includes('dashboard') && currentPath.length > 3;

  useEffect(() => {
    if (hamburgerOpen) {
      logoComponentRef.current?.focus();
    }
  }, [hamburgerOpen]);

  return (
    <Wrapper>
      <LeftSection hamburgerOpen={hamburgerOpen}>
        <Sidebar logoComponentRef={logoComponentRef} />
      </LeftSection>
      <Container>
        {/* For Mobile screen */}
        {(isSettingsNestedMenu || isMessageDetail || isEventsNestedMenu || isDashboardNestedMenu) && <NestedAppbar />}
        {!isSettingsNestedMenu && !isMySite && !isMessageDetail && !isEventsNestedMenu && !isDashboardNestedMenu && (
          <Appbar />
        )}
        <Body>{children}</Body>
      </Container>
    </Wrapper>
  );
};

export default MainTemplateView;
