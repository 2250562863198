import styled, { css } from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { IStyleGridItem } from './SavingsEventsScreen.types';

export const Wrapper = styled.div`
  padding: 0 16px 30px 16px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.lg`
    padding: 0;
  `}
`;

export const MyEventsContainer = styled.div`
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MyEventsTitle = styled.p`
  ${typography.xl};
  font-weight: 700;
  line-height: normal;
  color: ${colors.text};
`;

export const EventsContainer = styled.div`
  padding: 11px;
  background-color: ${colors.background};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const EventProgramName = styled.p``;

export const Grid = styled.div`
  margin-top: 30px;

  gap: 20px;
  display: grid;
  grid-template-areas:
    'gl'
    'gr';

  ${media.xxxl`
    grid-template-columns: 1fr 1.5fr;
    grid-template-areas: 'gl gr';
  `}
`;
export const GridItem = styled.div<IStyleGridItem>(
  ({ area }) => css`
    grid-area: ${area};
  `,
);
export const GridLeft = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'la'
    'lb'
    'lc'
    'ld';
`;

export const Help = styled.div`
  position: absolute;
  right: 10px;
  bottom: 25px;
`;

export const ViewPortfolioContainer = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ViewPortfolioTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
export const ViewPortfolioTitle = styled.p`
  ${typography.md};
  font-weight: 400;
  line-height: 24px;
  color: ${colors.text};
`;
