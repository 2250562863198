import { PGE_ELRP_A6, SCE_ELRP_A6, CLIMATE_RESPONSE, SCE_ELRP_A1, SCE_ELRP_A1_BIP, DATA_ONLY } from '../Concrete';

interface ProgramContext {
  PGE_ELRP_A6: PGE_ELRP_A6;
  SCE_ELRP_A6: SCE_ELRP_A6;
  CLIMATE_RESPONSE: CLIMATE_RESPONSE;
  SCE_ELRP_A1: SCE_ELRP_A1;
  SCE_ELRP_A1_BIP: SCE_ELRP_A1_BIP;
  NONE: null;
  DATA_ONLY: DATA_ONLY;
  GBC: null;
}

export const ProgramContext: ProgramContext = {
  PGE_ELRP_A6: new PGE_ELRP_A6(),
  SCE_ELRP_A6: new SCE_ELRP_A6(),
  CLIMATE_RESPONSE: new CLIMATE_RESPONSE(),
  SCE_ELRP_A1: new SCE_ELRP_A1(),
  SCE_ELRP_A1_BIP: new SCE_ELRP_A1_BIP(),
  NONE: null,
  DATA_ONLY: new DATA_ONLY(),
  GBC: null,
};
