import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { helpers } from '@/assets/styles';

interface LabelProps {
  backgroundColor: string;
}

export const Label = styled.div<LabelProps>`
  ${helpers.flexSet('row', 'center')};
  ${typography.sm};
  text-align: center;
  border-radius: 8px;
  padding: 5px 25px 5px 25px;
  margin-left: 40px;
  color: ${({ color }) => color};
  font-weight: 700;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
