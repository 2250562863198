import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import Usage from '@/molecules/Usage';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import EventYearsSelector from '@/organisms/EventYearsSelector/eventYearsSelector';
import { EventsHistory } from '@/types/portfolio';
import { moneyEarnedDisclaimer } from '@/helpers/MoneyEarnedDisclaimer';
import { Description } from '@/assets/styles/global';
import {
  EventDate,
  EventDateWrapper,
  EventName,
  EventsHistoryColumn,
  EventsHistoryTitle,
  PerformanceText,
  ImpactText,
  UsageWrapper,
  EventsHistoryContainer,
  ImpactRow,
  EventsHistoryGrid,
  EventsHistoryGridWrapper,
  EventsHistoryTitleWrapper,
  NoDataWrapper,
  NoData,
} from './PortfolioEventsHistoryTile.styles';

export interface PortfolioEventsHistoryProps {
  eventsHistory: {
    [year: string]: EventsHistory[];
  };
  programName?: string;
}

const PortfolioEventsHistoryTile = ({ eventsHistory, programName }: PortfolioEventsHistoryProps) => {
  const { t } = useTranslation('common');
  const eventsYears = Object.keys(eventsHistory);
  const [selectedEventsYearIndex, setSelectedEventsYearIndex] = useState(eventsYears.length - 1);

  useEffect(() => {
    setSelectedEventsYearIndex(eventsYears.length - 1);
  }, []);

  if (!eventsYears.length) {
    return (
      <EventsHistoryContainer>
        <EventsHistoryTitleWrapper>
          <EventsHistoryTitle>{t('screens.main.savingsEvents.portfolioScreen.eventsHistory')}</EventsHistoryTitle>
        </EventsHistoryTitleWrapper>
        <EventsHistoryGridWrapper>
          <NoDataWrapper>
            <NoData>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.noData')}</NoData>
          </NoDataWrapper>
        </EventsHistoryGridWrapper>
      </EventsHistoryContainer>
    );
  }

  const selectedYear = eventsYears[selectedEventsYearIndex];
  const isCurrentEventsYearSelected = eventsYears[selectedEventsYearIndex] === eventsYears[eventsYears.length - 1];
  const isLastEventsYearSelected = selectedEventsYearIndex === 0;
  const selectedEvents = eventsHistory[selectedYear]?.length ? Object.values(eventsHistory[selectedYear]) : [];

  return (
    <EventsHistoryContainer>
      <EventsHistoryTitleWrapper>
        <EventsHistoryTitle>
          {t('screens.main.savingsEvents.portfolioScreen.eventsHistory')} {selectedYear}
        </EventsHistoryTitle>
        <EventYearsSelector
          selectedEvent={selectedYear}
          onPressArrowLeft={() => setSelectedEventsYearIndex(selectedEventsYearIndex - 1)}
          onPressArrowRight={() => setSelectedEventsYearIndex(selectedEventsYearIndex + 1)}
          isLastEventSelected={isLastEventsYearSelected}
          isCurrentEventSelected={isCurrentEventsYearSelected}
        />
      </EventsHistoryTitleWrapper>
      <EventsHistoryGridWrapper>
        {!eventsHistory[selectedYear]?.length ? (
          <NoDataWrapper>
            <NoData>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.noData')}</NoData>
          </NoDataWrapper>
        ) : (
          selectedEvents
            .sort((a: EventsHistory, b: EventsHistory) => {
              return new Date(b.eventDate).getTime() - new Date(a.eventDate).getTime();
            })
            .map((event, index) => {
              const { disclaimerNamespace, symbol: disclaimerSymbol } = moneyEarnedDisclaimer({
                summaryState: event.summaryState,
              });
              return (
                <EventsHistoryGrid key={event.eventDate.toString()}>
                  <EventsHistoryColumn>
                    {event.targetDirection ? (
                      <EventName>
                        {`${t(`programs.${programName}.savingsEventsScreen.targetDirection.${event.targetDirection}`)}`}{' '}
                        {`#${selectedEvents.length - 1 - index + 1}`}
                      </EventName>
                    ) : (
                      <EventName>{`EVENT #${selectedEvents.length - 1 - index}`}</EventName>
                    )}
                    <EventDateWrapper>
                      <Img
                        src={images.calendar}
                        style={{
                          objectFit: 'contain',
                          marginRight: '5px',
                          filter: `invert(50%) sepia(7%) saturate(134%) hue-rotate(169deg) brightness(89%) contrast(88%)`,
                        }}
                      />
                      <EventDate>{moment(event.eventDate.toString()).format('MMMM Do YYYY')}</EventDate>
                    </EventDateWrapper>
                  </EventsHistoryColumn>
                  <EventsHistoryColumn>
                    <UsageWrapper>
                      <Usage
                        value={event.moneyEarned}
                        unit="money"
                        symbol={event.moneyEarned >= 0 ? '+ ' : '- '}
                        symbolFirst
                        valueStrong
                        valueSize={parseInt(typography.xs.fontSize, 10)}
                        valueColor={event.moneyEarned >= 0 ? colors.primary : colors.bad}
                        descriptionColor={colors.grey}
                        toFixed={2}
                      />
                      <PerformanceText color={colors.primary}>
                        {`${t(
                          'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.moneyEarned',
                        )}${disclaimerSymbol}`}
                      </PerformanceText>
                    </UsageWrapper>
                    <UsageWrapper>
                      <Usage
                        value={Math.abs(event.reduction * 1000)}
                        unit="kWh"
                        symbol={event.reduction > 0 ? '↓ ' : ' '}
                        symbolFirst
                        valueStrong
                        valueSize={parseInt(typography.xs.fontSize, 10)}
                        alignSelf="flex-start"
                        valueColor={colors.accent}
                        descriptionColor={colors.text}
                      />
                      <PerformanceText color={colors.accent}>
                        {t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.reduction')}
                      </PerformanceText>
                    </UsageWrapper>
                    <Description tabIndex={0}>{t(disclaimerNamespace)}</Description>
                  </EventsHistoryColumn>
                  <EventsHistoryColumn noBorder>
                    <ImpactRow>
                      <Img src={images.blueEnergy} style={{ width: '13px', height: '13px' }} />
                      <Usage
                        value={Math.abs(event.energyImpact * 1000)}
                        unit="kWh"
                        valueStrong
                        valueSize={parseInt(typography.xxs.fontSize, 10)}
                        alignSelf="flex-start"
                        valueColor={colors.text}
                        toFixed={1}
                      />
                      <ImpactText>
                        {' '}
                        {t('screens.main.dashboard.dashboardScreen.environmentalImpact.energyImpact')}
                      </ImpactText>
                    </ImpactRow>
                    <ImpactRow>
                      <Img src={images.blueEnergy} style={{ width: '13px', height: '13px' }} />
                      <Usage
                        value={Math.abs(event.emissionsReduction)}
                        unit="lbs"
                        valueStrong
                        valueSize={parseInt(typography.xxs.fontSize, 10)}
                        alignSelf="flex-start"
                        valueColor={colors.text}
                        toFixed={1}
                      />
                      <ImpactText>
                        {t('screens.main.dashboard.dashboardScreen.environmentalImpact.emissions')}
                      </ImpactText>
                    </ImpactRow>
                    <ImpactRow>
                      <Img src={images.blueEnergy} style={{ width: '13px', height: '13px' }} />

                      <Usage
                        value={Math.abs(event.autoMilesEquivalent)}
                        unit="miles"
                        valueStrong
                        valueSize={parseInt(typography.xxs.fontSize, 10)}
                        alignSelf="flex-start"
                        valueColor={colors.text}
                        toFixed={1}
                      />
                      <ImpactText>{t('screens.main.dashboard.dashboardScreen.environmentalImpact.miles')}</ImpactText>
                    </ImpactRow>
                  </EventsHistoryColumn>
                </EventsHistoryGrid>
              );
            })
        )}
      </EventsHistoryGridWrapper>
    </EventsHistoryContainer>
  );
};

export default PortfolioEventsHistoryTile;
