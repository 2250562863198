import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@/molecules/Modal';
import { IModalSupportProps } from './index.types';
import { Description, ContactContainer, ContactTitle, ContactBody, ContactEventWrapper } from './index.styles';

const ModalSupportContainer = ({
  show,
  onClose,
  ariaControlsId,
  supportContacts,
  setShowChat,
  hasChatAccess,
}: IModalSupportProps) => {
  const { t } = useTranslation('common');

  const isMobile = !!window.ReactNativeWebView;

  const handleEmail = () => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'supportmail', message: `mailto:${supportContacts?.email}` });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(`mailto:${supportContacts?.email}`, '_blank');
    }
  };

  const handlePhone = () => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'supportphone', message: supportContacts?.phone });
      window.ReactNativeWebView.postMessage(data);
    }
  };

  return (
    <Modal
      show={show}
      showClose
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      ariaControlsId={ariaControlsId}
      small
    >
      <ContactContainer>
        <Description tabIndex={0}>{t('screens.enrollment.modalSupport.description')}</Description>
      </ContactContainer>
      <ContactContainer>
        <ContactTitle tabIndex={0}>{t('screens.enrollment.modalSupport.email.title')}</ContactTitle>
        <ContactEventWrapper tabIndex={0} role="link" aria-label={`${supportContacts?.email}`} onClick={handleEmail} />
        <ContactBody mobile>{supportContacts?.email}</ContactBody>
      </ContactContainer>
      <ContactContainer>
        <ContactTitle tabIndex={0}>{t('screens.enrollment.modalSupport.phone.title')}</ContactTitle>
        {isMobile && (
          <ContactEventWrapper
            tabIndex={0}
            role="link"
            aria-label={`${supportContacts?.phoneAccessibility}`}
            onClick={handlePhone}
          />
        )}
        <ContactBody tabIndex={isMobile ? -1 : 0} mobile={isMobile} aria-label={supportContacts?.phoneAccessibility}>
          {supportContacts?.phone}
        </ContactBody>
      </ContactContainer>
      <ContactContainer>
        <ContactEventWrapper
          tabIndex={0}
          aria-label="click to open chat"
          role="link"
          onClick={() => {
            if (setShowChat) {
              setShowChat(true);
            }
            if (onClose) {
              onClose(!show);
            }
          }}
        >
          {hasChatAccess && <ContactBody mobile>{t('screens.enrollment.modalSupport.chat.chatText')}</ContactBody>}
        </ContactEventWrapper>
      </ContactContainer>
    </Modal>
  );
};

export default ModalSupportContainer;
