import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const TitleContainer = styled.div``;
export const Title = styled.p`
  ${typography.md};
  line-height: 22px;
  font-weight: 700;
  color: ${colors.text};

  ${media.xxl`
    ${typography.xl};
    line-height: 26px;
  `}
`;

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  gap: 25px;

  ${media.xxl`
    gap: 50px;
    flex-direction: row;
    align-items: center;
  `}
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
`;

export const DescriptionContainer = styled.div`
  border-radius: 8px;
  background-color: ${colors.hoverFocus};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  gap: 7px;
  flex: 1;

  ${media.xxl`
    max-width: 400px;
  `}
`;

export const Description = styled.p`
  ${typography.sm};
  color: ${colors.text};
  line-height: 20px;
  font-weight: 800;
`;

export const Suggest = styled.p`
  ${typography.sm};
  color: ${colors.grey};
  line-height: 20px;
  font-weight: 400;
`;
