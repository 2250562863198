import React from 'react';
import Reminders from '@/organisms/Reminders';
import GridEmissions from '@/organisms/GridEmissions';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PATHS from '@/types/navigationPaths';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import { FirstColumn, Grid, TileMargin, Button, ImgArrow, SubmenuTitle, SubmenuTitleContainer } from './index.styles';

const DashboardWithDRAndGrid = () => {
  const { t } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const navigate = useNavigate();

  const handleNavigateCurrentGridEmissions = () => {
    navigate(PATHS.SCREEN_MOBILE_CURRENT_GRID_EMISSIONS.pathname);
  };

  return (
    <Grid>
      <FirstColumn>
        <TileMargin>
          <Reminders />
        </TileMargin>
        <TileMargin>
          {isMobileWindowSize ? (
            <Button padding="20px 0" onClick={handleNavigateCurrentGridEmissions}>
              <SubmenuTitleContainer>
                <Img src={images.co2} style={{ width: '24px', height: '24px' }} />
                <SubmenuTitle>
                  {t('screens.main.dashboard.dashboardScreen.gridEmissionsContainer.Cont_Title')}
                </SubmenuTitle>
              </SubmenuTitleContainer>
              <ImgArrow
                src={images.arrowRightGreen.image}
                alt={images.arrowRightGreen.text}
                style={{ width: '32px', height: '32px' }}
              />
            </Button>
          ) : (
            <GridEmissions />
          )}
        </TileMargin>
      </FirstColumn>
    </Grid>
  );
};

export default DashboardWithDRAndGrid;
