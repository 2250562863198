/* eslint-disable no-nested-ternary */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@/atoms/Button';
import InputText from '@/molecules/InputText';
import ModalAccountNumber from '@/organisms/ModalAccountNumber';
import { theme, images } from '@/assets/styles';
import { useForm, Controller } from 'react-hook-form';
import IconButton from '@/atoms/IconButton';
import Modal from '@/molecules/Modal';
import Img from '@/atoms/Img';
import { ProgramName } from '@/types/enrollment';
import { Description, Tip, SecondDescription, SmallDescription, Validate } from './index.styles';
import { IVerificationViewProps, IVerificationField } from './index.types';

const VerificationView = ({
  programName,
  isBusiness,
  accountName,
  accountNumber,
  accountMinLength,
  accountMaxLength,
  modalAccountNumber,
  modalAccountNumberClose,
  modalAccountNumberOk,
  onClickAccountNumber,
  onChangeName,
  onChangeAccountNumber,
  values,
  onChange,
  onNext,
  disabled,
  errors,
}: IVerificationViewProps) => {
  const { setFocus, control } = useForm();
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const isSCE = programName === ProgramName.SCE_ELRP_A6;

  const { t, i18n } = useTranslation('common');

  const isTip = i18n.exists(`common:programs.${programName}.verificationScreen.accountNumber`);
  const hasDescription2 = i18n.exists(`common:programs.${programName}.verificationScreen.description2`);
  const hasDescription3 = i18n.exists(`common:programs.${programName}.verificationScreen.description3`);
  const hasFields1 = i18n.exists(`common:programs.${programName}.verificationScreen.fields1`);
  const hasFields2 = i18n.exists(`common:programs.${programName}.verificationScreen.fields2`);

  const inputMapFields1 = t<string, IVerificationField[]>(`common:programs.${programName}.verificationScreen.fields1`, {
    returnObjects: true,
  });

  const inputMapFields2 = t<string, IVerificationField[]>(`common:programs.${programName}.verificationScreen.fields2`, {
    returnObjects: true,
  });

  useEffect(() => {
    if (errors?.length) {
      setFocus(errors[0]);
    }
  }, [errors, setFocus]);

  const fields1Placeholder = isBusiness
    ? t('common.validations.businessName.placeholder')
    : t('common.validations.lastNameAccountHolder.placeholder');

  return (
    <>
      <Description>{t(`programs.${programName}.verificationScreen.description`)}</Description>
      {hasDescription2 && (
        <SecondDescription>{t(`common:programs.${programName}.verificationScreen.description2`)}</SecondDescription>
      )}
      {isTip && (
        <Button transparent marginTop={20} onClick={onClickAccountNumber}>
          <Tip tabIndex={0}>{t(`programs.${programName}.verificationScreen.accountNumber`)}</Tip>
        </Button>
      )}
      {!hasFields1 ? (
        <>
          <Controller
            name={`verification-${fields1Placeholder}`}
            control={control}
            render={({ field }) => (
              <InputText
                name={field.name}
                inputRef={field.ref}
                label={fields1Placeholder}
                labelId={`verification-${fields1Placeholder}`}
                ariaLabel={fields1Placeholder}
                type="text"
                border="underline"
                value={accountName}
                marginTop={50}
                onChangeText={onChangeName}
                allowWhiteSpace
                onNext={onNext}
                disabled={disabled}
                triggerSubmit={false}
              />
            )}
          />
          <Controller
            name="verification-accountnumber"
            control={control}
            render={({ field }) => (
              <InputText
                label={t('common.validations.utilityAccount.placeholder')}
                ariaLabel={
                  field.validate && (errors ?? []).indexOf(field.name) > -1
                    ? `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${field.name}` ===
                      'serviceAccountNumber'
                      ? t(field.validate)
                      : `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${t(
                          field.validate,
                          { accountMaxLength },
                        )}`
                    : t(`common.validations.${field.placeholder ?? field.name}.placeholder`)
                }
                // ariaLabel={
                //   `${t(`common.validations.utilityAccount.placeholder`)} ${field.name}` === 'serviceAccountNumber'
                //     ? t(field.validate, { accountMaxLength })
                //     : ''
                // }
                labelId="verification-accountnumber"
                type="text"
                border="underline"
                value={accountNumber}
                minLength={accountMinLength}
                maxLength={accountMaxLength}
                marginTop={50}
                marginBottom={80}
                onChangeText={onChangeAccountNumber}
                allowWhiteSpace
                onNext={onNext}
                disabled={disabled}
                triggerSubmit={false}
              />
            )}
          />
        </>
      ) : (
        inputMapFields1.map((field) => (
          <>
            <Controller
              name={field.name}
              control={control}
              render={(renderProps) => {
                const renderField = renderProps.field;
                return (
                  <div>
                    <InputText
                      right={
                        isSCE &&
                        renderField.name === 'serviceAccountNumber' && (
                          <IconButton
                            icon={images.help}
                            onClick={() => setAccountModalVisible(true)}
                            ariaLabel={t('screens.main.dashboard.dashboardScreen.achievement.help')}
                          />
                        )
                      }
                      name={renderField.name}
                      inputRef={renderField.ref}
                      label={t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}
                      ariaLabel={
                        field.validate && (errors ?? []).indexOf(field.name) > -1
                          ? `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${
                              field.name
                            }` === 'serviceAccountNumber'
                            ? t(field.validate)
                            : `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${t(
                                field.validate,
                                { accountMaxLength },
                              )}`
                          : t(`common.validations.${field.placeholder ?? field.name}.placeholder`)
                      }
                      labelId={`verification-${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}`}
                      marginTop={50}
                      type="text"
                      value={values[field.name]}
                      border="underline"
                      fontSize={theme.typography.body.fontSize}
                      onChangeText={onChange(field.name)}
                      allowWhiteSpace={field.allowWhitespace ?? false}
                      onNext={onNext}
                      disabled={disabled}
                      inputMode={
                        field.name === 'serviceZipCode' || field.name === 'serviceAccountNumber' ? 'numeric' : 'text'
                      }
                      triggerSubmit={false}
                      maxLength={
                        field.name === 'serviceZipCode'
                          ? 5
                          : field.name === 'servicePhone'
                          ? 16
                          : field.name === 'serviceAccountNumber'
                          ? accountMaxLength
                          : 255
                      }
                    />
                  </div>
                );
              }}
            />
            {i18n.exists(`common:common.validations.${field.placeholder ?? field.name}.description`) && (
              <SmallDescription tabIndex={0}>
                {t(`common.validations.${field.placeholder ?? field.name}.description`)}
              </SmallDescription>
            )}
            {field.validate && (errors ?? []).indexOf(field.name) > -1 && (
              <Validate tabIndex={0}>
                {field.name === 'serviceAccountNumber' ? t(field.validate, { accountMaxLength }) : field.validate}
              </Validate>
            )}
          </>
        ))
      )}
      {hasDescription3 && (
        <SecondDescription
          dangerouslySetInnerHTML={{ __html: t(`common:programs.${programName}.verificationScreen.description3`) }}
        />
      )}
      {hasFields2 &&
        inputMapFields2.map((field, index, array) => (
          <>
            <Controller
              name={field.name}
              control={control}
              render={(renderProps) => {
                const renderField = renderProps.field;
                return (
                  <InputText
                    name={renderField.name}
                    inputRef={renderField.ref}
                    label={t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}
                    ariaLabel={
                      field.validate && (errors ?? []).indexOf(field.name) > -1
                        ? `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${field.name}` ===
                          'serviceAccountNumber'
                          ? t(field.validate)
                          : `${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}. ${t(
                              field.validate,
                              { accountMaxLength },
                            )}`
                        : t(`common.validations.${field.placeholder ?? field.name}.placeholder`)
                    }
                    labelId={`verification-${t(`common.validations.${field.placeholder ?? field.name}.placeholder`)}`}
                    marginTop={50}
                    type="text"
                    value={values[field.name]}
                    border="underline"
                    fontSize={theme.typography.body.fontSize}
                    onChangeText={onChange(field.name)}
                    allowWhiteSpace={field.allowWhitespace ?? false}
                    onNext={onNext}
                    disabled={disabled}
                    inputMode={
                      field.name === 'serviceZipCode' || field.name === 'serviceAccountNumber' ? 'numeric' : 'text'
                    }
                    triggerSubmit={array.length - 1 === index}
                    maxLength={
                      field.name === 'serviceZipCode'
                        ? 5
                        : field.name === 'servicePhone'
                        ? 16
                        : field.name === 'serviceAccountNumber'
                        ? accountMaxLength
                        : 255
                    }
                  />
                );
              }}
            />
            {i18n.exists(`common:common.validations.${field.placeholder ?? field.name}.description`) && (
              <SmallDescription>
                {t(`common.validations.${field.placeholder ?? field.name}.description`)}
              </SmallDescription>
            )}
            {field.validate && (errors ?? []).indexOf(field.name) > -1 && (
              <Validate tabIndex={0}>
                {field.name === 'serviceAccountNumber' ? t(field.validate, { accountMaxLength }) : field.validate}
              </Validate>
            )}
          </>
        ))}
      <ModalAccountNumber show={modalAccountNumber} onClose={modalAccountNumberClose} onOk={modalAccountNumberOk} />
      <Modal
        show={accountModalVisible}
        title={t(`programs.${programName}.verificationScreen.accountNumberModal.title`)}
        onOk={() => setAccountModalVisible(false)}
        textOk={t(`programs.${programName}.verificationScreen.accountNumberModal.okButton`)}
      >
        <Img style={{ height: 300, width: '100%', marginBottom: 16 }} src={images.accountNumberImg.image} />
      </Modal>
    </>
  );
};

export default VerificationView;
