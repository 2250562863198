import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Radio from '@/atoms/Radio';
import {
  InputContainer,
  PopupButtonsContainer,
  PopupHeaderContainer,
  PopupTitle,
  styleButton,
  Wrapper,
} from '@/organisms/ModalOptOut/ModalOptOut.styles';
import InputText from '@/molecules/InputText';
import { Modal } from '@/designSystem/components';
import { FillPrimary, Stroke } from '@/designSystem/components/buttons';
import { IModalOptOutProps } from './ModalOptOut.types';

const ModalOptOutContainer = ({ show, onClose, onOk, onCancel }: IModalOptOutProps) => {
  const { t } = useTranslation('common');
  const [state, setState] = useState({ answer: 'UNAVAIL', other: '' });

  const handleChangeRadio = (answer: string) => {
    if (state.answer === answer) {
      return;
    }
    setState({ ...state, answer, other: '' });
  };

  const handleChangeText = (text: string) => {
    setState({ ...state, other: text });
  };

  const handleOK = () => {
    onOk(state.answer, state.other);
  };

  const options = t(`screens.main.savingsEvents.savingsEventsScreen.modalOptOut.options`, {
    returnObjects: true,
  });

  return (
    <Modal show={show} onClose={onClose} showClose small>
      <Wrapper>
        <PopupHeaderContainer>
          <PopupTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.modalOptOut.title`)}</PopupTitle>
        </PopupHeaderContainer>
        <Radio
          items={(Object.values(options) as string[]).map((key) => {
            return { id: Object.keys(key)[0], name: Object.keys(key)[0], text: Object.values(key)[0] };
          })}
          value={state.answer}
          onChange={(name) => handleChangeRadio(name as string)}
        />
        {state.answer === 'OTHER' && (
          <InputContainer>
            <InputText
              type="text"
              placeholder={t('common.validations.otherSpecify.placeholder')}
              value={state.other}
              border="border"
              onChangeText={handleChangeText}
            />
          </InputContainer>
        )}
        <PopupButtonsContainer>
          <Stroke
            title={t(`screens.main.savingsEvents.savingsEventsScreen.modalOptOut.cancel`)}
            onClick={onCancel}
            padding={styleButton.padding}
            showBorder={false}
            maxWidth={styleButton.maxWidth}
          />
          <FillPrimary
            title={t(`screens.main.savingsEvents.savingsEventsScreen.modalOptOut.ok`)}
            onClick={handleOK}
            padding={styleButton.padding}
            maxWidth={styleButton.maxWidth}
          />
        </PopupButtonsContainer>
      </Wrapper>
    </Modal>
  );
};

export default ModalOptOutContainer;
