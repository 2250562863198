import { SiteItem } from './sites';

export enum EnrollmentStatus {
  Enrolled = 'enrolled',
  Pending = 'pending',
  Disenrolled = 'disenrolled',
  Rejected = 'rejected',
  None = 'none',
}

export interface Programs {
  availablePrograms: Program[];
}

export interface UtilityPrograms {
  [utility: string]: Program[];
}

export enum Attribute {
  displayNoThanks = 'displayNoThanks',
  displayLater = 'displayLater',
}

export enum ProgramName {
  NONE = 'NONE',
  PGE_ELRP_A6 = 'PGE_ELRP_A6',
  SCE_ELRP_A6 = 'SCE_ELRP_A6',
  CLIMATE_RESPONSE = 'CLIMATE_RESPONSE',
  SCE_ELRP_A1 = 'SCE_ELRP_A1',
  SCE_ELRP_A1_BIP = 'SCE_ELRP_A1_BIP',
  DATA_ONLY = 'DATA_ONLY',
  GBC = 'GBC',
}

export enum LinkEnrollmentChoice {
  NewEnrollment = 'new_enrollment',
  LinkEnrollment = 'link_enrollment',
}

export interface Program {
  type: string;
  logo: string;
  logoSmall: string;
  logoLarge: string;
  program: ProgramName;
  accountMaxLength: number;
  accountMinLength: number;
  screenStep: Screen[];
  utilityProvider: string;
  // screenVisit
  zipcodes: string[];
  attributes: ProgramAttribute;
  // options
}

export enum UtilityProviderEnum {
  PGE = 'PGE',
  SCE = 'SCE',
  SDGE = 'SDGE',
}

export interface ProgramAttribute {
  moneyEarnedInProgram: boolean;
  moneyEarnedPerEvent: boolean;
  affirmativeOptIn: boolean;
  optInFromStart: boolean;
  optOutFromStart: boolean;
  optInCutoff: number;
  optOutCutoff: number;
  hideSupport: boolean;
  displayMeterData: boolean;
  optInMode: string;
}

export interface Screen {
  name: string;
  component: string;
  // navigation: string[];
  noThanks: Program & string;
  hideBack?: boolean;
}

export interface Survey {
  question: string;
  code: string;
  answers: { [key: string]: string }[];
}

export interface SurveyAnswer {
  program: string;
  question: string;
  answer: string;
  other?: string;
  referralName?: string;
  referralEmail?: string;
  referralPhoneNumber?: string;
}

export interface ProgramRecent {
  program: string;
  provider: UtilityProviderEnum;
}

export interface EnrollmentInfo {
  firstName: string;
  lastName: string;
  serviceFirstName: string;
  serviceLastName: string;
  serviceAccountNumber: string;
  serviceAddress: string;
  serviceCity: string;
  serviceZipCode: string;
  servicePhone: string;
  serviceEmail: string;
  isBusiness: boolean;
  zipCode: string;
  program: string;
  siteName: string;
  verificationName: string;
  verificationAccountNumber: string;
  verifyResponse: VerifyResponse;
  areAllServiceAccountsAuthorized: boolean;
  agreeAvailable: boolean;
  utility: string;
  gbcUrl: string;
  gbcUrlResult: string;
  singleSurvey: SurveyAnswer;
  multipleSurvey: SurveyAnswer[];
  attributes: Record<string, unknown>;
  linkEnrollmentChoice: LinkEnrollmentChoice | undefined;
}

enum VerifyResponseCustomerClass {
  agricultural = 'agricultural',
  commercial_or_industrial = 'commercial_or_industrial',
  industrial = 'industrial',
  large_commercial = 'large_commercial',
  medium_commercial = 'medium_commercial',
  non_profit = 'non_profit',
  public_authorities = 'public_authorities',
  residential = 'residential',
  small_commercial = 'small_commercial',
  unspecified = 'unspecified',
}

export enum VerifyResponseStatus {
  active_customer = 'active_customer',
  not_customer = 'not_customer',
  pending_customer = 'pending_customer',
  try_again = 'try_again',
  unspecified = 'unspecified',
  withdrawing_customer = 'withdrawing_customer',
}

export interface VerifyResponseProgram {
  eligible: boolean;
  programId: string;
  programName: string;
}

export interface VerifyResponseServiceAccounts {
  id: string | undefined;
  class: VerifyResponseCustomerClass;
  isAuthorized: boolean;
  programs: VerifyResponseProgram[];
  secondaryIdentifier: string;
  serviceAccountNumber: string;
}

export interface VerifyResponse {
  areAllServiceAccountsAuthorized?: boolean;
  class: VerifyResponseCustomerClass;
  serviceAccounts?: VerifyResponseServiceAccounts[];
  status: VerifyResponseStatus;
}

export interface PgeVerifyResponse {
  sites: SiteItem[];
  platformValidateResponseData: VerifyResponse;
}

export enum VerifyLinkEnrollmentResponseStatus {
  ExistingEnrollmentsFound = 'EXISTING_ENROLLMENTS_FOUND',
  DisenrolledOrRejected = 'DISENROLLED_OR_REJECTED_ENROLLMENTS',
  PendingEnrollments = 'HAS_PENDING_ENROLLMENTS',
  UnexpectedVariations = 'UNEXPECTED_VARIATIONS_FOUND',
  NotFound = 'NOT_FOUND',
  ImportedEnrollments = 'IMPORTED_ENROLLMENTS',
}

export interface VerifyLinkEnrollmentResponse {
  result: VerifyLinkEnrollmentResponseStatus;
}

export interface ConfirmLinkEnrollmentResponse {
  result: VerifyLinkEnrollmentResponseStatus;
}
