import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { ReqSetting } from '@/apis/auth/types';
import { Language } from '@/types/setting';
import { changeLanguage, settingRequest } from '@/stores/setting';

export default function useSetting() {
  const dispatch = useDispatch();

  const fetchLanguage = useCallback((language: Language) => dispatch(changeLanguage(language)), [dispatch]);

  const fetchSetting = useCallback((req: ReqSetting) => dispatch(settingRequest(req)), [dispatch]);

  return {
    fetchLanguage,
    fetchSetting,
  };
}
