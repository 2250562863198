import React from 'react';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import usePortfolio from '@/hooks/usePortfolio';
import { FetchingStatus } from '@/hooks/types';
import { colors } from '@/designSystem/colors';
import { PrimaryButton } from '@/designSystem/components/buttons';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { moneyEarnedDisclaimer } from '@/helpers/MoneyEarnedDisclaimer';
import PortfolioAppbar from './PortfolioAppbar';
import PorfolioPerformanceTile from './PortfolioPerformance';
import PortfolioEventsHistoryTile from './PortfolioEventsHistory';
import {
  Container,
  ErrorContainer,
  ErrorDescription,
  ErrorTitle,
  LoadingWrapper,
  Wrapper,
} from './PortfolioScreen.styles';

const PortfolioScreen = () => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  const portfolioState = usePortfolio();
  const { t } = useTranslation('common');

  if (portfolioState.fetchingStatus === FetchingStatus.LOADING) {
    return (
      <Wrapper>
        <PortfolioAppbar />
        <LoadingWrapper>
          <ReactLoading type="spin" color={colors.primary} height="5%" width="5%" />
        </LoadingWrapper>
      </Wrapper>
    );
  }

  if (portfolioState.fetchingStatus === FetchingStatus.ERROR) {
    return (
      <Wrapper>
        <PortfolioAppbar />
        <ErrorContainer>
          <ErrorTitle>{t('screens.errorScreen.data.title')}</ErrorTitle>
          <ErrorDescription>{t('screens.errorScreen.data.description')}</ErrorDescription>
          <PrimaryButton onClick={portfolioState.refetch} title={t('common.generalButtons.tryAgain')} />
        </ErrorContainer>
      </Wrapper>
    );
  }

  if (portfolioState.fetchingStatus === FetchingStatus.SUCCESS) {
    const { portfolioPerformance, totalEnvironmentalImpact, eventsHistory } = portfolioState.data;
    const { summaryState } = portfolioPerformance;
    const programName = getProgramNameFromSite(currentSite);
    const { disclaimerNamespace, symbol } = moneyEarnedDisclaimer({
      summaryState,
    });
    const disclaimer = t(disclaimerNamespace);

    return (
      <>
        <PortfolioAppbar />
        <Container>
          <PorfolioPerformanceTile
            portfolioPerformance={portfolioPerformance}
            totalEnvironmentalImpact={totalEnvironmentalImpact}
            portfolioDisclaimer={disclaimer}
            portfolioSymbol={symbol}
          />
          <PortfolioEventsHistoryTile eventsHistory={eventsHistory} programName={programName} />
        </Container>
      </>
    );
  }
  return <div />;
};

export default PortfolioScreen;
