import React from 'react';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import ErrorScreenView from './index.view';

const ErrorScreenContainer = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.errorScreen.data.title')} - ${contents.name}`;
  return <ErrorScreenView />;
};

export default ErrorScreenContainer;
