import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { changeHamburger, changeAppLogin } from '@/stores/misc';

export default function useMisc() {
  const dispatch = useDispatch();

  const fetchChangeHamburger = useCallback((open: boolean) => dispatch(changeHamburger(open)), [dispatch]);

  const fetchChangeAppLogin = useCallback((appLogin: boolean) => dispatch(changeAppLogin(appLogin)), [dispatch]);

  return {
    fetchChangeHamburger,
    fetchChangeAppLogin,
  };
}
