import React, { CSSProperties, useEffect, useState } from 'react';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import IconButton from '@/atoms/IconButton';
import { images } from '@/assets/styles';
import { useUserInfo, useAuth } from '@/hooks/index';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { FillPrimary } from '@/designSystem/components/buttons';
import { ActivationStatus, GBCStatus } from '@/types/userInfo';
import LoadingOverlay from 'react-loading-overlay/es6/LoadingOverlay';
import { colors } from '@/designSystem/colors';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { NAVIGATED_FROM_INVITATION_LANDING_KEY } from '@/constants/sessionStorageKeys';
import { isOnApp } from '@/helpers/WindowHelper';
import { isMobile } from 'react-device-detect';
import ModalActivateUserRequestError from './ModalActivateUserRequestError/ModalActivateUserRequestError';
import {
  ButtonContainer,
  ButtonGroup,
  Buttons,
  Container,
  Description,
  MobileApp,
  QRImage,
  QRWrapper,
  RightHalfContainer,
  Title,
  Wrapper,
} from './InvitationLanding.styles';

const InvitationLandingScreen = () => {
  const { t } = useTranslation('common');

  // Invitation landing
  document.title = `${t('screens.auth.welcomeScreen.title')} - ${contents.name}`;

  const {
    auth: {
      data: { authUrl },
    },
    userInfo: {
      data: {
        activation: { activationStatus, error },
        userInfo,
      },
    },
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);
  const navigate = useNavigate();

  // run : Determine whether the login/registration process has been completed.
  const [state, setState] = useState({ run: false });
  const [errorPopupOpen, setErrorPopupOpen] = useState(false);
  const { fetchActivateInvitedUserRequest } = useUserInfo();
  const { fetchLogin } = useAuth();

  const isLogin = () => {
    return userInfo.gbcStatus !== GBCStatus.none;
  };

  const handleAppStore = () => {
    window.open(contents.APP_STORE_LINK, '_blank');
  };

  const handleGooglePlay = () => {
    window.open(contents.GOOGLE_PLAY_LINK, '_blank');
  };

  const handleContinue = () => {
    setState({ ...state, run: true });
    fetchLogin(language);
  };

  const handleClosePopup = () => {
    setErrorPopupOpen(false);
  };

  useEffect(() => {
    if (!isLogin()) {
      sessionStorage.setItem(NAVIGATED_FROM_INVITATION_LANDING_KEY, 'true');
      navigate(PATHS.SCREEN_ROOT.pathname);
    } else {
      fetchActivateInvitedUserRequest();
    }
  }, [fetchActivateInvitedUserRequest]);

  useEffect(() => {
    setErrorPopupOpen(error);
  }, [error]);

  useEffect(() => {
    // After completing the login/registration process, navigate to the Olivine common login page.
    if (state.run && authUrl) {
      window.location.href = authUrl;
    }
  }, [state, authUrl, fetchLogin, language]);

  return (
    <Wrapper>
      <Container>
        <Title>{`${contents.name}`}</Title>
        <Description>{t(`screens.auth.invitationLandingScreen.description`)}</Description>
        <MobileApp>{t(`screens.auth.invitationLandingScreen.mobileApp`)}</MobileApp>
        <ButtonGroup>
          <QRWrapper>
            <IconButton icon={images.appStore} onClick={handleAppStore} style={{ width: '159px' }} />
            {!isOnApp() && !isMobile && <QRImage src={images.iosQR.image} alt={t(images.iosQR.text) || ''} />}
          </QRWrapper>
          <QRWrapper>
            <IconButton icon={images.googlePlay} onClick={handleGooglePlay} style={{ width: '159px' }} />
            {!isOnApp() && !isMobile && <QRImage src={images.androidQR.image} alt={t(images.androidQR.text) || ''} />}
          </QRWrapper>
        </ButtonGroup>
      </Container>
      <RightHalfContainer>
        <LoadingOverlay
          active={activationStatus === ActivationStatus.started && error === false}
          spinner
          text={t('screens.auth.invitationLandingScreen.loadingText')}
          styles={{
            spinner: (base: CSSProperties) => ({
              ...base,
              '& svg circle': {
                stroke: colors.primary,
              },
            }),
            overlay: (base: CSSProperties) => ({
              ...base,
              position: 'unset',
              background: colors.white,
            }),
            wrapper: () => ({
              height: '100%',
            }),
            content: (base: CSSProperties) => ({
              ...base,
              color: colors.text,
            }),
          }}
        />
        <ButtonContainer>
          <Buttons>
            <FillPrimary
              title={t('screens.auth.welcomeScreen.continue')}
              onClick={handleContinue}
              padding="16px 0"
              disabled={activationStatus !== ActivationStatus.success}
            />
          </Buttons>
        </ButtonContainer>
      </RightHalfContainer>
      <ModalActivateUserRequestError
        show={errorPopupOpen}
        onClose={handleClosePopup}
        ariaControlsId="activation-user-error-popup"
      />
    </Wrapper>
  );
};

export default InvitationLandingScreen;
