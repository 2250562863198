import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

interface ButtonProps {
  padding?: string;
  iconFirst?: boolean;
}

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background-color: ${colors.hoverFocus};
  }
  &:focus {
    background-color: ${colors.hoverFocus};
    box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }
`;

export const Button = styled.button<ButtonProps>`
  ${typography.sm};
  color: ${colors.primary};
  font-weight: 900;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  padding: ${(props) => props.padding};
  order: ${(props) => (props.iconFirst ? 2 : 1)};
`;

export const Img = styled.img<{ width?: string; height?: string; iconFirst?: boolean }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  order: ${(props) => (props.iconFirst ? 1 : 2)};
`;
