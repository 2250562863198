import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@/designSystem/components';
import { Container, Description, MoreInformationContainer, BulletContainer } from './ProgramContents.styles';

type Props = {
  programName?: string;
};

const ProgramContentsClimateResponse = ({ programName }: Props) => {
  const { t } = useTranslation('common');
  const currentYear = new Date().getFullYear();

  const handleUrlLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Container>
      <Description>{t(`programs.${programName}.programDetailsScreen.description`)}</Description>
      <BulletContainer>
        <Description>{t(`programs.${programName}.programDetailsScreen.firstBullet`)}</Description>
        <Description>{t(`programs.${programName}.programDetailsScreen.secondBullet`)}</Description>
      </BulletContainer>
      <Description>{t(`programs.${programName}.programDetailsScreen.description2`)}</Description>
      <Description>
        {t(`programs.${programName}.programDetailsScreen.description3.sentence1`)}
        <LinkButton
          removeFlex
          title={t(`programs.${programName}.programDetailsScreen.description3.sentenceBtn`)}
          onClick={() => handleUrlLink(t(`programs.${programName}.programDetailsScreen.description3.link`))}
          padding="2px 2px"
        />
        {t(`programs.${programName}.programDetailsScreen.description3.sentence2`)}
      </Description>
      <MoreInformationContainer>
        <Description>
          {t(`programs.${programName}.programDetailsScreen.more.description`)}
          <LinkButton
            removeFlex
            title={t(`programs.${programName}.programDetailsScreen.more.linkContent.text`)}
            onClick={() => handleUrlLink(t(`programs.${programName}.programDetailsScreen.more.linkContent.link`))}
            padding="2px 2px"
          />
        </Description>
      </MoreInformationContainer>
      <MoreInformationContainer>
        <Description>{t(`programs.${programName}.programDetailsScreen.copyright`, { currentYear })}</Description>
      </MoreInformationContainer>
    </Container>
  );
};

export default ProgramContentsClimateResponse;
