import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { media } from '@/assets/styles';

interface InputComponentProps {
  isFocused?: boolean;
  error?: string;
  disabled?: boolean;

  arraySearch?: boolean;
  leftPadding?: boolean;
  right?: React.ReactNode;
}

export const editModalStyle = {
  content: {
    padding: 0,
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: '0%',
    border: 'none',
    background: '#fff',
    overflow: 'initial',
    outline: 'none',
    borderRadius: '16px',
    width: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(74, 74, 74, 0.5)',
  },
};

export const SiteItemWrapper = styled.div<{ selected?: boolean; editmode?: boolean }>`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.lightGrey};
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid ${colors.lightGrey};

  padding: 20px;
  gap: 20px;
  ${media.lg`
    border-radius: 8px;
    border: 1px solid ${colors.lightGrey};
    gap: 40px;
  `}
  ${(props) =>
    props.selected &&
    css`
      border: 1px solid ${colors.hoverFocus};
      background-color: ${colors.hoverFocus};
      ${media.lg`
        border: 1px solid ${colors.hoverFocus};
      `}
    `}
  ${(props) =>
    props.editmode &&
    props.selected &&
    css`
      border: 2px solid ${colors.hoverFocus};
      background-color: transparent;
      padding: 19px;
      ${media.lg`
        border: 2px solid ${colors.hoverFocus};
      `}
    `}
`;

export const SiteItemUpperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`;

export const SiteItemMiddleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  ${media.lg`
    margin: -30px 0 -20px 0;
  `}
`;

export const SiteItemLowerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  ${media.lg`
    flex-direction: row;
    align-items: flex-end;
    gap: unset;
    flex: 1;
  `}
`;

export const SiteItemHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`;

export const SiteItemTitle = styled.p`
  ${typography.xl};
  font-weight: 700;
  line-height: 160%;
  color: ${colors.text};
  min-height: 42px;
  margin: 1px;
`;
export const SiteAddressText = styled.p`
  ${typography.md};
  font-weight: 400;
  line-height: 160%;
  min-height: 42px;
  color: ${colors.text};
`;
export const EditButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const SiteItemButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  flex: 1;
`;
export const DeleteSiteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  flex: 1;
`;
export const ProgramInfoButtonContainer = styled.div``;

export const LastUpdateContainer = styled.div`
  flex: 1;
  ${typography.xxs};
  font-weight: 900;
  line-height: normal;
  color: rgba(114, 116, 118, 0.35);
`;

export const InputComponent = styled.input<InputComponentProps>`
  ${typography.xs};
  width: 100%;
  padding: 8px 16px;
  align-items: center;
  border: 1px solid ${colors.lightGrey};
  border-radius: 12px;
  color: ${colors.text};
  margin: 1px 1px;
  line-height: 24px;

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid ${colors.primary};
      margin: 0;
    `};

  ${(props) =>
    props.isFocused &&
    props.error &&
    css`
      border: 2px solid ${colors.bad};
    `};

  ${(props) =>
    props.leftPadding &&
    css`
      padding-left: 48px;
    `};
  ${(props) =>
    props.disabled &&
    css`
      border: 1px solid transparent;
      color: ${colors.grey};
      background-color: ${colors.background};
    `};
  outline: none;
`;

export const MobileEditPopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 10px 10px 40px 10px;
`;

export const MobileEditSiteContainer = styled.div`
  display: flex;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const PendingText = styled.p`
  ${typography.sm};
  color: ${colors.grey};
  text-align: center;
  margin: 0 0 20px 20px;
  ${media.lg`
   ${typography.md};
  padding: 20px;
  `}
`;
