import styled from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Container = styled.div`
  padding-bottom: 26px;
`;

export const DeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 10px 15px 10px;
`;

export const DeleteModalHeader = styled.div`
  ${typography.xl1};
  align-self: flex-start;
  font-weight: 900;
  margin-bottom: 20px;
  color: ${colors.text};
`;
