import React from 'react';
import { useTranslation } from 'react-i18next';
import Achievement from '@/organisms/Achievement';
import GridEmissions from '@/organisms/GridEmissions';
import EnvironmentalImpact from '@/organisms/EnvironmentalImpact';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import PATHS from '@/types/navigationPaths';
import { useNavigate } from 'react-router-dom';
import Reminders from '@/organisms/Reminders';
import {
  FirstColumn,
  Grid,
  SecondColumn,
  TileMargin,
  Button,
  ImgArrow,
  SubmenuTitle,
  SubmenuTitleContainer,
} from './index.styles';

const DashboardWithEarningsDRAndGrid = ({
  achievementDisclaimer,
  achievementSymbol,
}: {
  achievementDisclaimer: string;
  achievementSymbol: string;
}) => {
  const { t } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const navigate = useNavigate();

  const handleNavigateEnvironmentalImpact = () => {
    navigate(PATHS.SCREEN_MOBILE_ENVIRONMENTAL_IMPACT.pathname);
  };
  const handleNavigateCurrentGridEmissions = () => {
    navigate(PATHS.SCREEN_MOBILE_CURRENT_GRID_EMISSIONS.pathname);
  };

  return (
    <Grid>
      <FirstColumn>
        {isMobileWindowSize ? (
          <TileMargin>
            <Button padding="20px 0" onClick={handleNavigateCurrentGridEmissions}>
              <SubmenuTitleContainer>
                <Img src={images.co2} style={{ width: '24px', height: '24px' }} />
                <SubmenuTitle>
                  {t('screens.main.dashboard.dashboardScreen.gridEmissionsContainer.Cont_Title')}
                </SubmenuTitle>
              </SubmenuTitleContainer>
              <ImgArrow
                src={images.arrowRightGreen.image}
                alt={images.arrowRightGreen.text}
                style={{ width: '32px', height: '32px' }}
              />
            </Button>
          </TileMargin>
        ) : (
          <>
            <TileMargin>
              <Reminders />
            </TileMargin>
            <TileMargin>
              <GridEmissions />
            </TileMargin>
          </>
        )}
      </FirstColumn>
      <SecondColumn>
        <TileMargin>
          <Achievement
            ariaControls="level-help"
            disclaimer={achievementDisclaimer}
            disclaimerSymbol={achievementSymbol}
          />
        </TileMargin>
        {isMobileWindowSize ? (
          <>
            <TileMargin>
              <Reminders />
            </TileMargin>
            <TileMargin>
              <Button padding="20px 0" onClick={handleNavigateEnvironmentalImpact}>
                <SubmenuTitleContainer>
                  <Img src={images.leafIcon} style={{ width: '24px', height: '24px' }} />
                  <SubmenuTitle>{t('screens.main.dashboard.dashboardScreen.environmentalImpact.title')}</SubmenuTitle>
                </SubmenuTitleContainer>
                <ImgArrow
                  src={images.arrowRightGreen.image}
                  alt={images.arrowRightGreen.text}
                  style={{ width: '32px', height: '32px' }}
                />
              </Button>
            </TileMargin>
          </>
        ) : (
          <TileMargin>
            <EnvironmentalImpact ariaControls="environment-impact-help" />
          </TileMargin>
        )}
      </SecondColumn>
    </Grid>
  );
};

export default DashboardWithEarningsDRAndGrid;
