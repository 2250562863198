import React from 'react';
import { IconButton } from '@/designSystem/components/buttons';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { LeftSection, Navigator, ParentScreenName, RightSection, ScreenName } from './PortfolioAppbar.styles';

const image = {
  active: {
    src: images.textIconButtonArrowLeft.image,
    alt: images.textIconButtonArrowLeft.text,
  },
  disabled: {
    src: images.textIconButtonArrowLeftDisabled.image,
    alt: images.textIconButtonArrowLeftDisabled.text,
  },
};
const PortfolioAppbar = () => {
  const {
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const programName = getProgramNameFromSite(currentSite);
  const parentsScreenName = t(`programs.${programName}.savingsEventsScreen.title`);

  const handleBackEnergyEvents = () => {
    navigate(PATHS.SCREEN_SAVING_EVENT.pathname);
  };

  return (
    <Navigator>
      <LeftSection>
        <ParentScreenName>{parentsScreenName}</ParentScreenName>
        <ScreenName>{t('screens.main.savingsEvents.portfolioScreen.title')}</ScreenName>
      </LeftSection>
      <RightSection>
        <IconButton
          image={image}
          title={t('screens.main.savingsEvents.portfolioScreen.backToEnergyEvents')}
          onClick={handleBackEnergyEvents}
          padding="12px 16px"
          gap="10px"
          reverse
        />
      </RightSection>
    </Navigator>
  );
};

export default PortfolioAppbar;
