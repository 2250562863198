import { UnitType } from '@/types/index';

const energyUnitsArray = ['Wh', 'kWh', 'MWh', 'GWh'];
const energyUnitsScale = [1, 1000, 1000000, 1000000000];

export const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) {
    return 'n/a';
  }

  const i: number = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) {
    return `${bytes} ${sizes[i]}`;
  }

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const numberToSize = (value: number): string => {
  const sizes: string[] = ['', 'K', 'M', 'G', 'T'];
  if (value === 0) {
    return '0';
  }

  const i: number = parseInt(Math.floor(Math.log(value) / Math.log(1000)).toString(), 10);
  if (i === 0) {
    return value.toString();
  }

  return `${UnitHelper.comma(value / 1000 ** i, 1)}${sizes[i]}`;
};

export const UnitHelper = {
  renderUnit: (type: UnitType): string => {
    switch (type) {
      case 'number':
        return 'cnt';
      case 'percent':
        return '%';
      case 'money':
        return '$';
      case 'lbs':
        return 'lbs';
      case 'miles':
        return 'miles';
      case 'kw':
        return ' kW';
      case 'kWh':
        return ' kWh';
      case 'MWh':
        return ' MWh';
      case 'GWh':
        return ' GWh';
      case 'wh':
        return ' Wh';
      default:
        return '';
    }
  },

  comma: (val: number, fixed = 0): string => {
    const currency = new Intl.NumberFormat('en-US', { minimumFractionDigits: fixed, maximumFractionDigits: fixed });

    return currency.format(val);
  },

  positive: (val: number): boolean => {
    return val >= 0;
  },

  renderText: (val: number | string | null, symbol = false, fixed = 0): string => {
    if (val === null) {
      return 'NULL';
    }

    const currency = new Intl.NumberFormat('en-US', {
      signDisplay: symbol ? 'exceptZero' : 'never',
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
    });

    return currency.format(Number(val));
  },

  rateText: (val: number | null, symbol = false): string => {
    if (val === null) {
      return 'NULL';
    }

    if (symbol && UnitHelper.positive(val)) {
      return `+ ${UnitHelper.comma(Math.abs(val))}`;
    }
    return UnitHelper.comma(val);
  },

  formatEnergyValue: (value: number, fixed = 0): string => {
    const isPositive = UnitHelper.positive(value);
    if (value !== 0) {
      const calculatedUnitsScale = energyUnitsScale.map((unit: number) => Math.abs(value) / unit);

      const selectedIndex = calculatedUnitsScale.findIndex((value) => value >= 0 && value < 1000);

      const newValue = calculatedUnitsScale[selectedIndex];

      const fixNegativeValue = isPositive ? newValue : newValue * -1;

      const formattedValue = UnitHelper.comma(fixNegativeValue, fixed);

      return `${formattedValue} ${energyUnitsArray[selectedIndex]}`;
    }

    return `${value.toFixed(fixed)} kWh`;
  },

  formatMoneyValue: (value: number, signDisplay = false, fixed = 2) => {
    const currency = new Intl.NumberFormat('en-US', {
      signDisplay: signDisplay ? 'exceptZero' : 'never',
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed,
      currency: 'USD',
      style: 'currency',
    });

    return currency.format(value);
  },

  renderUnitValue: (value: number, type: UnitType, symbol = false, fixed = 0) => {
    if (type === 'money') {
      return `${symbol ? '+' : ''}${UnitHelper.renderUnit(type)}${UnitHelper.renderText(value, false, fixed)}`;
    }

    return `${UnitHelper.renderText(value, false, fixed)} ${UnitHelper.renderUnit(type)}`;
  },
};
