import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import { WEB_HOST } from '@/constants/environment';
import Img from '@/atoms/Img';
import contents from '@/assets/config/appInfo';
import PATHS from '@/types/navigationPaths';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import images from '@/assets/styles/images';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import {
  Wrapper,
  Container,
  TitleContainer,
  Title,
  ShareContainer,
  ShareIconDesc,
  Clickable,
} from './ReferralScreen.styles';

const ReferralScreenContainer = () => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const appLink = WEB_HOST;
  const {
    userInfo: {
      data: { userInfo },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const programName = getProgramNameFromSite(currentSite);
  // About - ClimateResponse®
  document.title = `${t('screens.main.settings.referralScreen.title')} - ${contents.name}`;

  const handleEmailClick = useCallback(() => {
    const emailTitle = encodeURIComponent(t(`programs.${programName}.dashboardScreen.pending.email.subject`));

    const emailBody = encodeURIComponent(
      t(`programs.${programName}.dashboardScreen.pending.email.body`, {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        appLink,
        breakCharacter: '\n',
      }),
    );
    const mailtoLink = `mailto:?subject=${emailTitle}&body=${emailBody}`;
    window.open(mailtoLink, '_blank');
  }, []);

  const handleSMSClick = useCallback(() => {
    const smsContent = encodeURIComponent(
      t(`programs.${programName}.dashboardScreen.pending.sms.body`, {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        appLink,
      }),
    );
    const smsUrl = `sms:?body=${smsContent}`;
    window.open(smsUrl, '_blank');
  }, []);

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'Referral',
      screen_class: 'referral',
    });
  }, []);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t('screens.main.settings.referralScreen.title')}</Title>
      </TitleContainer>
      <Wrapper>
        <Container>
          <ShareContainer>
            <Clickable onClick={handleEmailClick}>
              <Img src={images.shareEmail} />
              <ShareIconDesc>Email</ShareIconDesc>
            </Clickable>
            <Clickable onClick={handleSMSClick}>
              <Img src={images.shareSms} />
              <ShareIconDesc>SMS</ShareIconDesc>
            </Clickable>
          </ShareContainer>
        </Container>
      </Wrapper>
    </SettingTemplate>
  );
};

export default ReferralScreenContainer;
