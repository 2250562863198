import React from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import IconButton from '@/atoms/IconButton';
import { achievementIcon } from '@/helpers/AchievementIconHelper';
import { ILevelProps } from './Level.types';
import { Container, Help, Logo, Title, TitleContainer, Wrapper } from './Level.styles';

const Level = ({ level, onClick, ariaControls, ariaExpanded }: ILevelProps) => {
  const { t } = useTranslation('common');

  const renderTitle = () => {
    return t(`screens.main.dashboard.dashboardScreen.achievement.modalReward.level.${lodash.camelCase(level)}.title`);
  };

  return (
    <Wrapper>
      <Container>
        <Logo tabIndex={0}>
          <Img src={achievementIcon(level)} />
        </Logo>
        <TitleContainer>
          <Title level={level}>{renderTitle()}</Title>
          {onClick && (
            <Help>
              <IconButton
                icon={images.help}
                onClick={onClick}
                ariaControls={ariaControls}
                ariaExpanded={ariaExpanded}
                ariaLabel={t('screens.main.dashboard.dashboardScreen.achievement.help')}
              />
            </Help>
          )}
        </TitleContainer>
      </Container>
    </Wrapper>
  );
};

export default Level;
