import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { useUserInfo } from '@/hooks/index';
import { RootState } from '@/stores/index';
import PATHS from '@/types/navigationPaths';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { sentEvent } from '@/helpers/GAHelper';
import ProfileSetupScreenView from './index.view';

const ProfileSetupScreenContainer = () => {
  const { t } = useTranslation('common');

  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: { enrollmentInfo },
  } = useSelector((state: RootState) => state);

  // Profile setup
  document.title = `${t('screens.enrollment.profileSetupScreen.title')} - ${contents.name}`;

  const [state, setState] = useState({ disabledNext: true });

  const navigate = useNavigate();
  const { fetchUpdateUserInfo } = useUserInfo();

  const validationCheck = () => {
    const disabled = enrollmentInfo.firstName.length < 2 || enrollmentInfo.lastName.length < 2;
    setState({ ...state, disabledNext: disabled });
  };

  const handleNext = () => {
    const payload: ReqUpdateUserInfo = {
      firstName: enrollmentInfo.firstName,
      lastName: enrollmentInfo.lastName,
    };

    fetchUpdateUserInfo(payload, () => {
      sentEvent('profile_submitted', { user_id: userInfo.id });

      if (window.ReactNativeWebView) {
        const data = JSON.stringify({ loading: true });
        window.ReactNativeWebView.postMessage(data);
      }

      navigate(PATHS.SCREEN_ENROLLMENT_LOCATION_SETUP.pathname);
    });
  };

  useEffect(() => {
    // Olivine API를 통한 첫 로그인 시 cca에서도 인증처리를 한다
    if (userInfo.id && !userInfo.isAuthenticated) {
      const payload: ReqUpdateUserInfo = {
        isAuthenticated: true,
      };

      fetchUpdateUserInfo(payload);
    }
  }, []);

  useEffect(() => {
    validationCheck();
  }, [enrollmentInfo]);

  return <ProfileSetupScreenView disabledNext={state.disabledNext} onNext={handleNext} />;
};

export default ProfileSetupScreenContainer;
