import React from 'react';
import { useTranslation } from 'react-i18next';
import InputText from '@/molecules/InputText';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { LinkButton, Modal } from '@/designSystem/components';
import { FillPrimary, Stroke } from '@/designSystem/components/buttons';
import { ProgramName } from '@/types/enrollment';
import { IModalDeleteAccountViewProps } from './index.types';
import {
  Wrapper,
  Description,
  ValidationText,
  PopupHeader,
  PopupTitle,
  ButtonContainer,
  InputContainer,
  HightlightSpan,
  InputButtonWrapper,
} from './index.styles';

const getBorderStyle = (isCorrectWord: boolean, validation: boolean) => {
  if (isCorrectWord) {
    return 'green';
  }
  if (validation) {
    return 'border';
  }
  return 'border2';
};

const ModalDeleteAccountView = ({
  show,
  onClose,
  validationText,
  continueable,
  onChangeValidationText,
  onContinue,
  step,
  programName,
  onDeleteUser,
  validation,
  ariaControlsId,
  isCorrectWord,
}: IModalDeleteAccountViewProps) => {
  const { t } = useTranslation('common');

  const handleLink = () => {
    sentEvent('click', PATHS.LINK_DISENROLL_PSR_PROGRAM(programName));
    const mailto = programName === 'SCE_ELRP_A1' || programName === 'SCE_ELRP_A1_bip' ? 'mailto: ' : '';
    return window.open(
      `${mailto}${t(`programs.${programName}.deleteAccountScreen.deleteDescription.website.url`)}`,
      '_blank',
    );
  };

  const borderStyle = getBorderStyle(isCorrectWord, validation);

  const programLink = t(`programs.${programName}.deleteAccountScreen.deleteDescription.website.text`);

  const renderValidation = () => {
    return (
      <>
        <PopupHeader>
          <PopupTitle>{t('screens.main.settings.deleteAccountScreen.popupTitle')}</PopupTitle>
        </PopupHeader>
        <Wrapper>
          <Description>{t(`programs.${programName}.deleteAccountScreen.deleteValidation.body1`)}</Description>
          {programName !== ProgramName.DATA_ONLY && (
            <>
              <Description>
                <HightlightSpan>
                  {t('screens.main.settings.deleteAccountScreen.deleteValidation.highlight')}
                </HightlightSpan>
                {t(`programs.${programName}.deleteAccountScreen.deleteValidation.body2`)}
              </Description>
              <Description>
                {t(`programs.${programName}.deleteAccountScreen.deleteValidation.body3`)}
                <LinkButton onClick={handleLink} title={programLink} removeFlex />
                {t(`programs.${programName}.deleteAccountScreen.deleteValidation.body4`)}
              </Description>
            </>
          )}
          <InputButtonWrapper>
            <InputContainer>
              <InputText
                value={validationText}
                border={borderStyle}
                onChangeText={onChangeValidationText}
                maxLength={6}
                paddingTop={8}
                paddingRight={16}
                paddingBottom={8}
                paddingLeft={16}
                validation={validation}
                type="text"
                placeholder={t('screens.main.settings.deleteAccountScreen.deleteValidation.inputPlaceholder')}
                borderRadius={8}
              />
              {!validation && (
                <ValidationText>{t('screens.main.settings.deleteAccountScreen.referral_WarningText')}</ValidationText>
              )}
            </InputContainer>
            <ButtonContainer>
              <FillPrimary
                title={t('common.generalButtons.continue')}
                onClick={onContinue}
                disabled={!continueable}
                padding="10px 14px"
                maxWidth="72px"
              />
              <Stroke
                onClick={onClose}
                title={t('screens.main.settings.deleteAccountScreen.button.cancel')}
                padding="10px 14px"
                showBorder={false}
              />
            </ButtonContainer>
          </InputButtonWrapper>
        </Wrapper>
      </>
    );
  };

  const renderDelete = () => {
    return (
      <>
        <PopupHeader>
          <PopupTitle>{t('screens.main.settings.deleteAccountScreen.popupTitle')}</PopupTitle>
        </PopupHeader>
        <Wrapper>
          <Description>{t(`programs.${programName}.deleteAccountScreen.deleteDescription.body1`)}</Description>
          {programName !== ProgramName.DATA_ONLY && (
            <>
              <Description>{t(`programs.${programName}.deleteAccountScreen.deleteDescription.body2`)}</Description>
              <Description>
                {t(`programs.${programName}.deleteAccountScreen.deleteDescription.body3`)}
                <LinkButton onClick={handleLink} title={programLink} removeFlex />
                {t(`programs.${programName}.deleteAccountScreen.deleteDescription.body4`)}
              </Description>
            </>
          )}
          <ButtonContainer>
            <FillPrimary
              title={t('screens.main.settings.deleteAccountScreen.button.delete')}
              onClick={onDeleteUser}
              padding="10px 14px"
              maxWidth="150px"
              warning
            />
            <Stroke
              onClick={() => onClose('in the last step')}
              title={t('screens.main.settings.deleteAccountScreen.button.cancel')}
              warning
              padding="10px 14px"
              showBorder={false}
            />
          </ButtonContainer>
        </Wrapper>
      </>
    );
  };

  const renderPending = () => {
    return (
      <>
        <PopupHeader>
          <PopupTitle>{t('screens.main.settings.deleteAccountScreen.pendingTitle')}</PopupTitle>
        </PopupHeader>
        <Wrapper>
          <Description>{t('screens.main.settings.deleteAccountScreen.deleteRequestPending.body1')}</Description>
          <Description>
            <HightlightSpan>{t('screens.main.settings.deleteAccountScreen.deleteRequestPending.body2')}</HightlightSpan>
          </Description>
        </Wrapper>
      </>
    );
  };

  const renderer = () => {
    if (step === 2) {
      return renderDelete();
    }
    if (step === 3) {
      return renderPending();
    }
    return renderValidation();
  };

  return (
    <Modal show={show} ariaControlsId={ariaControlsId} onClose={onClose} showClose small>
      {renderer()}
    </Modal>
  );
};

export default ModalDeleteAccountView;
