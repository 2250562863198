import { colors } from '@/designSystem/colors';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.p`
  font-size: 16px;
  color: ${colors.text};
  margin-bottom: 30px;
`;
