import React from 'react';
import Input from '@/atoms/Input';
import InputFrame from '@/molecules/InputFrame';
import { IInputTextProps } from './index.types';

const InputTextContainer = ({
  type,
  border,
  inputNotUsed,
  readonly,
  label,
  placeholder,
  defaultValue,
  value,
  onChangeText,
  minLength,
  maxLength,
  marginTop,
  marginBottom,
  right,
  allowWhiteSpace,
  onNext,
  triggerSubmit,
  disabled,
  inputMode,
  labelId,
  ariaLabel,
  inputRef,
  name,
  paddingTop = 6,
  paddingRight = 0,
  paddingBottom = 6,
  paddingLeft = 0,
  borderRadius,
}: IInputTextProps) => {
  return (
    <InputFrame label={inputNotUsed === true ? label : undefined} marginTop={marginTop} marginBottom={marginBottom}>
      <Input
        name={name}
        inputRef={inputRef}
        type={type}
        border={border || 'border'}
        inputNotUsed={inputNotUsed}
        readonly={readonly}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChangeText={onChangeText}
        paddingTop={paddingTop}
        paddingRight={paddingRight}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        minLength={minLength}
        maxLength={maxLength}
        right={right}
        allowWhiteSpace={allowWhiteSpace}
        onNext={onNext}
        triggerSubmit={triggerSubmit}
        disabled={disabled}
        inputMode={inputMode}
        labelId={labelId}
        label={label}
        ariaLabel={ariaLabel}
        borderRadius={borderRadius}
      />
    </InputFrame>
  );
};

export default InputTextContainer;
