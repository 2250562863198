import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { IStyleTextProps } from './index.types';

export const Wrapper = styled.div<{ selected: boolean }>`
  padding: 20px;
  border-radius: 9.5px;
  ${(props) =>
    props.selected &&
    css`
      background-color: rgba(69, 129, 29, 0.05);
    `}
`;

export const Container = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
  gap: 20px;
`;

export const Logo = styled.div`
  width: 25px;
  height: 30px;
  border-radius: 50px;

  justify-content: center;
  align-items: center;
  display: flex;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.p<IStyleTextProps>(
  ({ selected }) => css`
    ${typography.lg};
    font-weight: 900;
    color: ${selected ? colors.primary : colors.grey};
  `,
);
