import styled, { css } from 'styled-components';
import { media } from '@/assets/styles';

interface IStyleWrapperProps {
  selected: boolean;
  hideBorder?: boolean;
  fullWidth: boolean;
}

export const Wrapper = styled.div<IStyleWrapperProps>`
  flex: 1;

  ${(props) =>
    props.fullWidth
      ? css`
          ${media.lg`
    width: 100%;
  `}
        `
      : css`
          ${media.lg`
    width: unset;
  `}
        `}
`;
