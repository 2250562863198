import React, { useState } from 'react';
import { ProgramName } from '@/types/enrollment';
import ProgramContentsClimateResponse from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.climate_response';
import ProgramContentsDefault from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.default';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Wrapper,
  styleInfoIcon,
  InformationIcon,
  EmptySpace,
  TooltipTitleContainer,
  TooltipTitle,
} from './ProgramInformationTooltip.styles';

const ProgramInformationTooltip = ({ programName }: { programName: ProgramName }) => {
  const { t } = useTranslation('common');

  const [tooltipOn, setTooltipOn] = useState(false);
  const [tooltipOnFixed, setTooltipOnFixed] = useState(false);

  const handleTooltipOn = () => {
    setTooltipOn(true);
  };
  const handleTooltipOff = () => {
    setTooltipOn(false);
  };
  const handleTooltipOnFixed = () => {
    setTooltipOnFixed(!tooltipOnFixed);
  };

  const renderProgramDetail = (programName: ProgramName) => {
    switch (programName) {
      case ProgramName.DATA_ONLY:
        return <DataOnlyMessage />;
      case ProgramName.CLIMATE_RESPONSE:
        return <ProgramContentsClimateResponse programName={programName} />;
      default:
        return <ProgramContentsDefault programName={programName} />;
    }
  };

  return (
    <Wrapper>
      <InformationIcon
        onClick={handleTooltipOnFixed}
        onMouseOver={handleTooltipOn}
        onMouseOut={handleTooltipOff}
        tooltipFixed={tooltipOnFixed}
      >
        <Img src={tooltipOn || tooltipOnFixed ? images.iconTooltipOn : images.iconTooltip} style={styleInfoIcon} />
      </InformationIcon>
      <EmptySpace tooltipOn={tooltipOn || tooltipOnFixed} onMouseOver={handleTooltipOn} onMouseOut={handleTooltipOff}>
        <Container>
          <TooltipTitleContainer>
            <TooltipTitle>{t(`programs.${programName}.title`)}</TooltipTitle>
          </TooltipTitleContainer>
          {renderProgramDetail(programName)}
        </Container>
      </EmptySpace>
    </Wrapper>
  );
};

export default ProgramInformationTooltip;
