import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import NotVerifiedView from './index.view';
import { NotVerifiedViewProps } from './index.types';

const NotVerifiedContainer = ({
  descriptionText,
  descriptionText2,
  descriptionText3,
  descriptionText4,
}: NotVerifiedViewProps) => {
  const {
    enrollment: {
      programs: { currentProgram },
    },
  } = useSelector((state: RootState) => state);

  return (
    <NotVerifiedView
      programName={currentProgram.program}
      descriptionText={descriptionText}
      descriptionText2={descriptionText2}
      descriptionText3={descriptionText3}
      descriptionText4={descriptionText4}
    />
  );
};

export default NotVerifiedContainer;
