import { PlotData } from 'plotly.js';

export const accessibilityUsageLabel = (renderedUsage: string | PlotData | (() => void)): string => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const usageArray = typeof renderedUsage === 'string' ? renderedUsage.split(' ') : renderedUsage().split(' ');
    let value = '';
    let unit = '';

    if (usageArray.length === 2) {
      [value, unit] = usageArray;
    } else if (usageArray.length === 1) {
      [unit] = usageArray;
    }

    switch (unit.slice(0, 3)) {
      case 'GWh':
        return `${value} Gigawatt hours`;

      case 'MWh':
        return `${value} Megawatt hours`;

      case 'kWh':
        return `${value} Kilowatt hours`;

      case 'Wh':
        return `${value} Watt hours`;

      case 'lbs':
        return `${value} pounds`;

      default:
        if (unit.includes('$')) {
          const unitModified = unit.replace(/,/g, ''); // remove commas from dollar values
          const unitArray = unitModified.includes('.') ? unitModified.split('.') : unitModified.split(' ');
          const dollar = unitArray[0].slice(1, unitArray[0].length);
          const dollarLabel = dollar === '1' ? 'dollar' : 'dollars';
          const cents = unitArray[unitArray.length - 1];

          return `${parseInt(dollar, 10)} ${dollarLabel} and ${parseInt(cents, 10)} cents`;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return typeof renderedUsage === 'string' ? renderedUsage : renderedUsage();
    }
  } catch (err) {
    return '';
  }
};

export const daysOfWeek = (day: string) => {
  switch (day) {
    case 'MON':
      return 'Monday';
    case 'TUE':
      return 'Tuesday';
    case 'WED':
      return 'Wednesday';
    case 'THU':
      return 'Thursday';
    case 'FRI':
      return 'Friday';
    case 'SAT':
      return 'Saturday';
    case 'SUN':
      return 'Sunday';
    default:
      return null;
  }
};
