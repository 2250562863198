import { take, put, call, fork, all } from 'redux-saga/effects';
import * as apiPrimary from '@/apis/primary';
import { MESSAGES_REQUEST, MESSAGES_SUCCESS, MESSAGES_FAILURE } from '@/stores/message';
import { LOADED, LOADING } from '@/stores/loading';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* message() {
  try {
    yield put({ type: LOADING });
    const response: IResponse = yield call(apiPrimary.getMessages);

    if (!response?.success) {
      yield put({ type: MESSAGES_FAILURE, error: response.error?.message || '' });
      return;
    }

    const { data } = response;
    yield put({ type: MESSAGES_SUCCESS, messages: data?.messages || [] });
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: MESSAGES_FAILURE, error: errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchMessage() {
  while (true) {
    yield take(MESSAGES_REQUEST);
    yield fork(message);
  }
}

export default function* settingSaga() {
  yield all([fork(watchMessage)]);
}
