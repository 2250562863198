import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { useTranslation } from 'react-i18next';
import {
  SectionHeader,
  SectionTitle,
  Section,
  EventsContainer,
  EventWrapper,
  ProgramInformationContainer,
  EventTypeTitle,
  EventContainer,
  InnerContainer,
  LeftSection,
  TitleContainer,
  EventTitle,
  DateTimeContainer,
  DateContainer,
  DateText,
  TimeContainer,
  EventTime,
  RightSection,
  styleCalendarIcon,
  styleClockIcon,
  styleEventIcon,
  NoEventText,
  RemainingTime,
  Timer,
  TimerDescription,
  UpcomingEventTitle,
  Divider,
  UpcomingDateTimeContainer,
} from '@/organisms/Reminders/Reminders.styles';
import moment from 'moment';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import ProgramInformationTooltip from '@/organisms/ProgramInformationTooltip';
import { DefaultImage } from '@/types/images';
import { ProgramName } from '@/types/enrollment';
import { EventDateInformation } from '@/types/dashboard';
import { useTimer } from 'react-timer-hook';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';

const OngoingEventComponent = ({
  event,
  eventTitle,
  icon,
  title,
  programName,
}: {
  event: EventDateInformation;
  eventTitle: string;
  icon: DefaultImage;
  title: string;
  programName: ProgramName;
}) => {
  const { t } = useTranslation('common');

  const { minutes, hours } = useTimer({
    expiryTimestamp: new Date(event?.end),
    autoStart: true,
    onExpire: () => console.warn('onExpire called'),
  });

  const digit = (val: number) => {
    return val.toString().padStart(2, '0');
  };

  const renderTimer = () => {
    return `${digit(hours)} : ${digit(minutes)}`;
  };

  return (
    <EventWrapper>
      <ProgramInformationContainer>
        <Img src={icon} style={styleEventIcon} />
        <EventTypeTitle>{title}</EventTypeTitle>
      </ProgramInformationContainer>
      <EventContainer>
        <InnerContainer>
          <LeftSection>
            <TitleContainer>
              <EventTitle>{eventTitle}</EventTitle>
              <ProgramInformationTooltip programName={programName} />
            </TitleContainer>
            <DateTimeContainer>
              <DateContainer>
                <Img src={images.calendarBlack} style={styleCalendarIcon} />
                <DateText>{moment(event?.start).format('dddd, MMMM DD')}</DateText>
              </DateContainer>
              <TimeContainer>
                <Img src={images.clockGrey} style={styleClockIcon} />
                <EventTime>
                  {`${moment(event?.start).format('h:mm a')} - ${moment(event?.end).format('h:mm a')}`}
                </EventTime>
              </TimeContainer>
            </DateTimeContainer>
          </LeftSection>
          <RightSection>
            <RemainingTime>
              <Timer>{renderTimer()}</Timer>
              <TimerDescription>
                {t(`common:programs.${programName}.savingsEventsScreen.onTimer.remainingTime`)}
              </TimerDescription>
            </RemainingTime>
          </RightSection>
        </InnerContainer>
      </EventContainer>
    </EventWrapper>
  );
};

const UpcomingEventComponent = ({
  event,
  eventTitle,
  icon,
  title,
  programName,
}: {
  event: EventDateInformation;
  eventTitle: string;
  icon: DefaultImage;
  title: string;
  programName: ProgramName;
}) => {
  return (
    <EventWrapper>
      <ProgramInformationContainer>
        <Img src={icon} style={styleEventIcon} />
        <EventTypeTitle>{title}</EventTypeTitle>
      </ProgramInformationContainer>
      <EventContainer>
        <InnerContainer>
          <LeftSection>
            <TitleContainer>
              <UpcomingEventTitle>{eventTitle}</UpcomingEventTitle>
              <ProgramInformationTooltip programName={programName} />
            </TitleContainer>
            <UpcomingDateTimeContainer>
              <DateContainer>
                <Img src={images.calendarBlack} style={styleCalendarIcon} />
                <DateText>{moment(event?.start).format('dddd, MMMM DD')}</DateText>
              </DateContainer>
              <Divider />
              <TimeContainer>
                <Img src={images.clockGrey} style={styleClockIcon} />
                <EventTime>
                  {`${moment(event?.start).format('h:mm a')} - ${moment(event?.end).format('h:mm a')}`}
                </EventTime>
              </TimeContainer>
            </UpcomingDateTimeContainer>
          </LeftSection>
        </InnerContainer>
      </EventContainer>
    </EventWrapper>
  );
};

const Reminders = () => {
  const {
    dashboard: {
      data: { reminder },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);
  const { t } = useTranslation('common');

  const programName = getProgramNameFromSite(currentSite);

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>{t('screens.main.dashboard.dashboardScreen.reminders.title')}</SectionTitle>
      </SectionHeader>
      {programName === ProgramName.DATA_ONLY ? (
        <DataOnlyMessage />
      ) : (
        <EventsContainer>
          {reminder.nearestActiveEvent?.start && (
            <OngoingEventComponent
              event={reminder.nearestActiveEvent}
              eventTitle={t('screens.main.savingsEvents.savingsEventsScreen.programEvent', {
                program: t(`programs.${programName}.title`),
              })}
              programName={programName}
              icon={images.iconOngoing}
              title={t('screens.main.savingsEvents.savingsEventsScreen.ongoingEvents')}
            />
          )}
          {!reminder.nearestActiveEvent?.start && reminder.nearestUpcomingEvent?.start && (
            <UpcomingEventComponent
              event={reminder.nearestUpcomingEvent}
              programName={programName}
              eventTitle={t('screens.main.savingsEvents.savingsEventsScreen.programEvent', {
                program: t(`programs.${programName}.title`),
              })}
              icon={images.iconUpcoming}
              title={t('screens.main.savingsEvents.savingsEventsScreen.upcomingEvents')}
            />
          )}
          {!reminder.nearestUpcomingEvent?.start && !reminder.nearestActiveEvent?.start && (
            <EventWrapper>
              <ProgramInformationContainer>
                <Img src={images.iconUpcoming} style={styleEventIcon} />
                <EventTypeTitle>{t('screens.main.savingsEvents.savingsEventsScreen.upcomingEvents')}</EventTypeTitle>
              </ProgramInformationContainer>
              <EventContainer>
                <InnerContainer>
                  <NoEventText>{t('screens.main.dashboard.dashboardScreen.reminders.noUpcoming')}</NoEventText>
                </InnerContainer>
              </EventContainer>
            </EventWrapper>
          )}
        </EventsContainer>
      )}
    </Section>
  );
};

export default Reminders;
