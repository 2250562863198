import cookie from 'react-cookies';
import { persistor } from '@/constants/configureStore';

export const setLocalToken = (accessToken: string) => {
  cookie.save('accessToken', accessToken, {
    path: '/',
    secure: process.env.APP_ENV !== 'local',
  });
};

export const getLocalToken = () => {
  return cookie.load('accessToken');
};

export const removeLocalToken = () => {
  cookie.remove('accessToken', { path: '/' });
};

/**
 * Clear user cache in a dirty way.
 */
const clearCache = () => {
  persistor.pause();
  localStorage.clear();
};

/**
 * Clear the user session and restart the web app. This is a "BIG RED BUTTON" kind of fix.
 * Avoid using unless its required to violate the redux state.
 *
 * TODO: remove
 */
export const forceLogUserOut = () => {
  removeLocalToken();
  clearCache();

  if (window.ReactNativeWebView) {
    const data = JSON.stringify({ to: 'logout' });
    window.ReactNativeWebView.postMessage(data);
    return;
  }

  window.location.reload();
};
