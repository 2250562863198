import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { sentEvent } from '@/helpers/GAHelper';
import { RootState } from '@/stores/index';
import { ProgramName } from '@/types/enrollment';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import {
  BodyWrapper,
  LeftSection,
  Navigator,
  NoSite,
  ScreenName,
  SiteName,
} from '@/screens/Main/Settings/MySites/ManageSites/ManageSites.styles';
import { images } from '@/assets/styles';
import { LinkButton } from '@/designSystem/components/buttons';
import { formatProgramTitle } from '@/helpers/TitleFormatHelper';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import { FillPrimary } from '@/designSystem/components/buttons/FillPrimary';
import { useSwitchSiteEnrollment } from '@/hooks/enrollment/useSwitchSiteEnrollment';
import SiteTermsConditions from './SiteTermsConditions';
import { ButtonContainer, Buttons, Wrapper } from './SiteEnroll.styles';

const SiteEnrollmentScreen = () => {
  const { t } = useTranslation('common');
  document.title = `${t('screens.main.settings.mySites.mySitesScreen.title')} - ${contents.name}`;
  const {
    sites: {
      data: { sites, currentSite, enrollingSiteId },
    },
    userInfo: {
      data: { userInfo },
    },
    loading: { loading },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();

  const switchSiteEnrollment = useSwitchSiteEnrollment(
    {
      onSuccess: () => navigate(PATHS.SCREEN_SETTING_SITE_ENROLL_SUCCESS),
      onFail: () => navigate(PATHS.SCREEN_SETTING_SITE_ENROLL_FAILED),
    },
    [navigate],
  );
  const [agreement, setAgreement] = useState(false);

  const handleChangeAgree = () => {
    setAgreement(!agreement);
  };

  const handleLink = (url: string) => {
    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ open: 'browser', message: url });
      window.ReactNativeWebView.postMessage(data);
    } else {
      window.open(url, '_blank');
    }
  };

  const handleBackSetting = () => {
    sentEvent('back_to_settings', PATHS.BACK_TO_SETTINGS);
    navigate(PATHS.SCREEN_SETTING_MY_SITES.pathname);
  };

  const programName = getProgramNameFromSite(currentSite);
  const isDataOnly = programName === ProgramName.DATA_ONLY;
  const programTitle = t(`programs.${programName}.title`);
  const programDetailTitle = formatProgramTitle(
    programTitle,
    t('screens.main.settings.mySites.mySiteDetailScreen.program', { program: programTitle }),
    programName,
  );
  // currently we only have the option to upgrade to Climate Response for a Data Only site
  // Future implementation will be needed if there are multiple available programs
  const enrollingProgram = isDataOnly ? ProgramName.CLIMATE_RESPONSE : programName;

  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  return (
    <Wrapper>
      <>
        <Navigator>
          <LeftSection>
            <ScreenName>{t('screens.main.settings.mySites.mySitesScreen.title')}</ScreenName>
            {sites.length === 0 && !loading ? (
              <NoSite>{t('screens.main.appbar.noSite')}</NoSite>
            ) : (
              !isDataOnly && <SiteName>{programDetailTitle}</SiteName>
            )}
          </LeftSection>
          <LinkButton
            onClick={handleBackSetting}
            image={{
              imagePlace: 'left',
              active: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
              disabled: { src: images.greenChevronUp.image, alt: t(images.greenChevronUp.text) },
            }}
            underline={false}
            title={t('screens.main.settings.mySites.mySitesScreen.back')}
            padding="12px 16px"
          />
        </Navigator>
        <BodyWrapper>
          <SiteTermsConditions
            program={enrollingProgram}
            agree={agreement}
            onChangeAgree={handleChangeAgree}
            onOpenLink={handleLink}
          />
          <Buttons>
            <ButtonContainer>
              <FillPrimary
                title={t('common.generalButtons.enroll')}
                onClick={() =>
                  switchSiteEnrollment(
                    enrollingSiteId,
                    currentSite?.gbiIntegrationId || '',
                    ProgramName.CLIMATE_RESPONSE,
                  )
                }
                padding="12px"
                disabled={agreement === false}
              />
            </ButtonContainer>
          </Buttons>
        </BodyWrapper>
      </>
    </Wrapper>
  );
};

export default SiteEnrollmentScreen;
