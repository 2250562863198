import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const styleCalendarIcon = { width: '17px', height: '18px' };
export const styleClockIcon = { width: '18px', height: '17px' };
export const styleEventIcon = { width: '32px', height: '32px' };

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgramInformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  background-color: ${colors.white};
  border-radius: 8px 8px 0px 0px;
  gap: 8px;
  border-bottom: 1px solid rgba(114, 116, 118, 0.2);
`;

export const EventTypeTitle = styled.p`
  ${typography.xl};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.accent};
`;

export const Container = styled.div`
  background-color: ${colors.white};
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
  gap: 20px;

  ${media.xxl`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: unset;
  `}
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  padding: 15px;
  border-radius: 8px;
  flex: 1;
  justify-content: space-between;
  gap: 20px;
  ${media.xxl`
    gap: unset;
    flex-direction: row;
  `}
`;

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;

  ${media.xxl`
    display: flex;
    flex-direction: column;
    align-items: start;
  `}
`;

export const RightSection = styled.div``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  ${media.lg`
    justify-content: start;
  `}
`;

export const Title = styled.p`
  ${typography.lg};
  line-height: 24px;
  font-weight: 700;
  color: ${colors.text};
  text-transform: capitalize;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  flex-direction: column;

  ${media.lg`
    gap: 15px;
    align-items: center;
    flex-direction: row;
  `}
`;

export const Divider = styled.div`
  height: 100%;
  border-left: 2px solid rgba(114, 116, 118, 0.2);
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const DateText = styled.p`
  ${typography.md};
  font-weight: 700;
  line-height: 24px;
  color: ${colors.text};
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Time = styled.p`
  ${typography.xs};
  font-weight: 600;
  line-height: 16px;
  color: ${colors.grey};
  text-transform: uppercase;
`;

export const RemainingTime = styled.div`
  background-color: ${colors.accent};
  border-radius: 8px;
  padding: 5px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Timer = styled.p`
  ${typography.xl6};
  font-size: 56px;
  line-height: 60px;
  font-weight: 700;
  color: ${colors.white};
`;

export const TimerDescription = styled.p`
  ${typography.xxs};
  line-height: 16px;
  font-weight: 700;
  color: ${colors.white};
  text-transform: capitalize;
`;

export const More = styled.div(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 44px;
    & > span {
      color: ${theme.colors.primary[75]};
    }
  `,
);
