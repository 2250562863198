import React from 'react';
import { Wrapper, Input, Left, Right, Label, LabelInput } from './index.styles';
import { IInputProps } from './index.types';

const IndexContainer = ({
  type,
  kind,
  inputNotUsed,
  border,
  placeholder,
  defaultValue,
  align,
  fontSize,
  color,
  left,
  right,
  minLength,
  maxLength,
  onFocus,
  onBlur,
  onChangeText,
  marginTop,
  marginLeft,
  marginRight,
  marginBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  allowWhiteSpace,
  onNext,
  triggerSubmit,
  disabled,
  inputMode,
  labelId,
  label,
  ariaLabel,
  inputRef,
  name,
  value,
  borderRadius,
}: IInputProps) => {
  return (
    <Wrapper
      border={border}
      kind={kind}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      borderRadius={borderRadius}
    >
      {left && <Left>{left}</Left>}
      {!inputNotUsed && (
        <LabelInput>
          {label && (
            <Label aria-label={ariaLabel || label} htmlFor={labelId}>
              {label}
            </Label>
          )}
          <Input
            name={name}
            ref={inputRef}
            id={labelId}
            onKeyUp={(e) => {
              if (
                e.key.toLocaleLowerCase() === 'enter' &&
                onNext &&
                typeof onNext === 'function' &&
                triggerSubmit &&
                disabled !== true
              ) {
                onNext();
              }
            }}
            type={type}
            fontSize={fontSize}
            align={align}
            color={color}
            value={value}
            placeholder={placeholder}
            defaultValue={defaultValue}
            minLength={minLength}
            maxLength={maxLength}
            onBlur={onFocus}
            onFocus={onBlur}
            inputMode={inputMode ?? 'text'}
            onChange={(event) => {
              if (onChangeText) {
                if (allowWhiteSpace) {
                  onChangeText(event.target.value);
                } else {
                  onChangeText(event.target.value.trim());
                }
              }
            }}
          />
        </LabelInput>
      )}
      {right && <Right>{right}</Right>}
    </Wrapper>
  );
};

export default IndexContainer;
