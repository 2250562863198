import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
  ${media.lg`
    padding: unset;
    gap: 20px;
  `}
`;

export const MobileTitle = styled.p`
  ${typography.md};
  line-height: 22px;
  color: ${colors.text};
  font-weight: 800;
  margin: 14px 0 10px;
  ${media.lg`
    display: none;
  `}
`;

export const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: 8px;

  ${media.lg`
    gap: 20px;
    background-color: unset;
    border-radius: unset;
  `}
`;

export const Header = styled.div`
  ${helpers.flexSet('column-reverse', 'space-between', 'center')};
  gap: 14px;
  ${media.lg`
    gap: unset;
    background-color: ${colors.white};
    ${helpers.flexSet('row', 'space-between', 'center')};
    margin-top: 27px;
    padding: 21px 0 21px 24px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  `}
`;
export const GraphDescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
`;

export const Container = styled.div`
  ${helpers.flexSet('column', 'center', 'center')};
`;

export const LegendContainer = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
`;

export const GraphContainer = styled.div`
  overflow: hidden;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
  width: 30px;
  font-weight: bold;
`;

export const Title = styled.h5`
  text-align: center;
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
`;

export const GraphSection = styled.div`
  padding: 39px 33px 20px 33px;
  ${media.lg`
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  `}
`;

export const GraphTitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${media.lg`
    justify-content: space-between;
    margin-bottom: 30px;
  `}
`;

export const GraphTitle = styled.p`
  display: none;

  ${media.lg`
    display: block;
    ${typography.lg};
    line-height: 24px;
    font-weight: 800;
    color: ${colors.text};
    padding: 2px 0;
  `}
`;

export const ChartExtremeValueIndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${media.lg`
    gap: 40px;
  `}
`;

export const ExtremeValueIndicator = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 0 14px 0;
  background-color: ${colors.white};
  border-radius: 8px;
  ${media.lg`
    gap: 20px;
    border: 1px solid ${colors.lightGrey};
    padding: 33px 20px;
  `}
`;

export const ValueTitle = styled.p`
  ${typography.sm};
  line-height: 20px;
  font-weight: 400;
  color: ${colors.text};
  margin-bottom: 20px;
  ${media.lg`
    ${typography.lg};
    line-height: 24px;
    font-weight: 800;
    margin-bottom: unset;
  `}
`;

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lg`
    flex-direction: row;
    gap: 8px;
  `}
`;
export const ValueContainerMobile = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 17px;
  gap: 3px;
  ${media.lg`
    gap: 8px;
    margin-bottom: unset;
  `}
`;

export const Value = styled.p<{ color: string }>(
  ({ color }) => css`
    font-family: 'Lato';
    ${typography.xl};
    font-weight: 800;
    line-height: 26px;
    text-align: center;
    color: ${color};
    ${media.lg`
      font-weight: 900;
      font-size: 32px;
      line-height: 38px;
    `}
  `,
);

export const Time = styled.p`
  ${typography.xs};
  line-height: 18px;
  font-weight: 400;
  color: ${colors.grey};
  ${media.lg`
    ${typography.lg};
    line-height: 24px;
  `}
`;
