import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { IStyleLeft } from './index.types';

export const Wrapper = styled.div`
  ${helpers.flexSet('row', 'flex-start')};

  max-width: 1920px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
`;

export const LeftSection = styled.div<IStyleLeft>(
  ({ hamburgerOpen }) => css`
    min-width: 300px;

    display: ${hamburgerOpen ? 'none' : 'block'};
    transition: 1s;

    border-right: 2px solid;
    border-color: rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    ${media.lg`
      position: unset;
      top: auto;
      left: auto;
      width: auto;
      height: auto;
      z-index: unset;
      display: block;
    `}
  `,
);

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 0px;
  background-color: ${colors.background};

  ${media.lg`
    padding: 20px;
   `};

  ${media.xl`
    padding: 79px 100px 94px 64px;
  `};
`;

export const Header = styled.div`
  ${helpers.flexSet('row', 'flex-end', 'center')};

  height: 80px;
  padding: 0 15px;

  ${media.xl`
    height: 80px;
    padding: 0 70px;
  `}
`;

export const Body = styled.div``;
