import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, LegendContainer } from '../index.styles';

type Props = {
  refZoomOutButton: RefObject<HTMLButtonElement>;
  refZoomInButton: RefObject<HTMLButtonElement>;
  zoomOutDisabled: boolean;
  zoomInDisabled: boolean;
  onPressZoomIn: () => void;
  onPressZoomOut: () => void;
  currentZoom: string;
};

export const ZoomSection = ({
  refZoomOutButton,
  refZoomInButton,
  zoomOutDisabled,
  zoomInDisabled,
  onPressZoomIn,
  onPressZoomOut,
  currentZoom,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <LegendContainer>
      <Button
        ref={refZoomOutButton}
        id="zoomout-graph"
        onClick={onPressZoomOut}
        disabled={zoomOutDisabled}
        aria-label={t('common.generalButtons.zoomout')}
        tabIndex={0}
      >
        -
      </Button>
      <p>{currentZoom}</p>
      <Button
        ref={refZoomInButton}
        id="zoomin-graph"
        onClick={onPressZoomIn}
        disabled={zoomInDisabled}
        aria-label={t('common.generalButtons.zoomin')}
        tabIndex={0}
      >
        +
      </Button>
    </LegendContainer>
  );
};
