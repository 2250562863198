export enum PathnameKeys {
  Dashboard = 'dashboard',
  EnergyUsage = 'usagePattern',
  EnergyEvents = 'dr',
  Messages = 'message',
  Account = 'account',
  Program = 'program',
  Support = 'support',
  Language = 'language',
  About = 'about',
  MySites = 'sites',
}
export const ScreenTitleByPathname = (programName?: string): Record<PathnameKeys, string> => ({
  dashboard: 'screens.main.dashboard.dashboardScreen.title',
  usagePattern: 'screens.main.usagePattern.usagePatternScreen.title',
  dr: `programs.${programName}.savingsEventsScreen.title`,
  message: 'screens.main.messages.messagesScreen.title',
  account: 'screens.main.settings.settingScreen.title',
  program: 'screens.main.settings.settingScreen.title',
  support: 'screens.main.settings.settingScreen.title',
  language: 'screens.main.settings.settingScreen.title',
  about: 'screens.main.settings.settingScreen.title',
  sites: 'screens.main.settings.settingScreen.title',
});
