import { useQuery } from '@tanstack/react-query';
import * as apiPrimary from '@/apis/primary';
import { EnvironmentalImpact } from '@/types/dashboard';
import { GeneralHook, FetchingStatus } from './types';

export type EnvironmentalImpactBySeasonResponse = {
  impact: EnvironmentalImpact;
  previousSeasonExists: boolean;
  nextSeasonExists: boolean;
};
const useEnvironmentalImpact = (
  siteId: string,
  seasonYear: number,
): GeneralHook<EnvironmentalImpactBySeasonResponse> => {
  const { data, error, isError, isRefetching, isFetching, refetch, isLoading } = useQuery({
    queryKey: ['apiPrimary.getEnvironmentalImpact', seasonYear],
    queryFn: () => apiPrimary.getEnvironmentalImpact({ siteId, seasonYear }),
    staleTime: 60 * 5 * 1000,
  });
  if (isLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }
  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }
  if (data?.data) {
    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: data.data,
      refetch,
    };
  }
  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};
export default useEnvironmentalImpact;
