import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { PerformanceToDateEnum } from '@/types/dr';
import { UnitHistoryDetail } from '@/helpers/Edition';
import DRQuickViewView from './index.view';

const DRQuickViewContainer = ({ unit }: { unit: UnitHistoryDetail }) => {
  const {
    dr: {
      historyScreen: { selectedTab },
      history: {
        events: {
          data: { selectedEvent },
        },
        earnings: {
          data: { selectedEarningsEvent },
        },
      },
    },
    sites: {
      data: { currentSite },
    },
  } = useSelector((state: RootState) => state);

  const isEmissions = selectedTab === PerformanceToDateEnum.emissions;

  return (
    <DRQuickViewView
      unit={unit}
      item={selectedTab === PerformanceToDateEnum.reduction || isEmissions ? selectedEvent : selectedEarningsEvent}
      moneyEarnedInProgram={currentSite?.program?.attributes?.moneyEarnedInProgram}
    />
  );
};

export default DRQuickViewContainer;
