import styled, { css } from 'styled-components';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

interface ButtonProps {
  selected?: boolean;
  padding?: string;
  warning?: boolean;
}

export const Button = styled.button<ButtonProps>`
  ${typography.sm};
  color: ${(props) => (props.warning ? colors.bad : colors.text)};
  font-weight: 400;
  cursor: pointer;
  border: none;
  background-color: ${colors.white};
  border-radius: 8px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  text-align: left;
  flex-direction: row;
  padding: ${(props) => props.padding};
  align-items: center;
  line-height: 24px;

  &:hover {
    background-color: ${colors.hoverFocus};
  }
  &:focus {
    background-color: ${colors.hoverFocus};
    box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
  }
  &:disabled {
    cursor: default;
    color: ${colors.grey};
  }
  &:disabled:hover {
    background-color: transparent;
  }

  ${(props) =>
    props.selected &&
    css`
      font-weight: 700;
      background-color: ${colors.hoverFocus};
    `}
  ${(props) =>
    props.warning &&
    css`
      &:hover {
        background-color: #ffdddd;
      }

      &:focus {
        background-color: #ffdddd;
        box-shadow: 0px 0px 0px 4px rgba(205, 169, 169, 0.2);
      }
    `}
`;

export const Img = styled.img<{ width?: string; height?: string }>`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
    `}
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;
