import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { media } from '@/assets/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: ${colors}.background;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
`;

export const Message = styled.span`
  color: ${colors}.grey;
  font-size: ${typography}.xs;
  font-weight: 700;
  margin-right: 10px;
  white-space: nowrap;
  font-size: 13px;

  ${media.lg`
    font-size: 15px;
  `}

  ${media.xl`
    font-size: 18px;
  `}
`;
