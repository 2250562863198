import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';

export const Container = styled.div``;

export const Question = styled.label(
  ({ theme }) => css`
    ${helpers.colorSet({ color: theme.colors.primary[100] })};
    font-size: ${theme.typography.h3.fontSize};
    font-weight: ${theme.typography.h3.fontWeight};
    line-height: ${theme.typography.h3.lineHeight};
    letter-spacing: ${theme.typography.h3.letterSpacing};

    margin-bottom: 34px;
  `,
);

export const InputWrapper = styled.div`
  margin: 30px 0;
`;
