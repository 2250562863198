import React from 'react';
import { TabIconImageType } from '@/molecules/Tabs';
import { Button, ButtonWrapper, Img } from './TabButton.styles';

type TabButtonProps = {
  title?: string;
  selected: boolean;
  color?: string;
  lineHeight?: string;

  onClick?: () => void;
  disabled?: boolean;
  padding?: string;
  image?: TabIconImageType;
  warning?: boolean;
  iconFirst?: boolean;
  fullWidth: boolean;
  ariaLabel?: string;
};

export const TabButton = ({
  title,
  selected,
  color,
  lineHeight,
  onClick,
  disabled,
  image,
  padding,
  warning,
  iconFirst = false,
  fullWidth,
  ariaLabel,
}: TabButtonProps) => {
  return (
    <ButtonWrapper
      onClick={(e) => {
        if (onClick) {
          onClick();
        }
        (e.target as HTMLButtonElement).blur();
      }}
      selected={selected}
    >
      <Button
        aria-label={ariaLabel}
        disabled={disabled}
        padding={padding}
        warning={warning}
        iconFirst={iconFirst}
        color={color}
        lineHeight={lineHeight}
        selected={selected}
        fullWidth={fullWidth}
        aria-selected={selected}
      >
        {title}
      </Button>
      {image && (
        <Img
          iconFirst={iconFirst}
          width={image.width}
          height={image.height}
          src={disabled ? image.disabled?.src : image.active.src}
          alt={disabled ? image.disabled?.alt : image.active.alt}
        />
      )}
    </ButtonWrapper>
  );
};
