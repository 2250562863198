import styled from 'styled-components';
import { media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 40px 20px 0 20px;
  height: fit-content;
  ${media.lg`
    height: unset;
  `}
`;

export const TitleContainer = styled.div`
  padding: 0 16.5px;
  margin-top: 20px;
  ${media.lg`
    margin-top: 0;
  `}
`;

export const Title = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 900;
  color: ${colors.text};
  ${media.lg`
    ${typography.xl1};
    line-height: 29px;
    font-weight: 800;
  `}
`;

export const SiteManagerDescriptionContainer = styled.div``;

export const SiteManagerDescription = styled.p`
  ${typography.sm};
  color: ${colors.blackGrey};
  font-weight: 400;
  line-height: normal;
`;

export const ActionList = styled.ul`
  padding: 40px 20px 40px 20px;
`;

export const Action = styled.li`
  ${typography.sm};
  color: ${colors.blackGrey};
  font-weight: 400;
  line-height: normal;
`;

export const ButtonContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;
