import { useState, useEffect } from 'react';
import { ZOHO_CHAT_PGE_ELRP_A6 } from '@/constants/environment';
import { ProgramName } from '@/types/enrollment';

export default function useZohoChat(program?: string) {
  let widgetCode = '';

  const [showChat, setShowChat] = useState(false);

  switch (program) {
    case ProgramName.PGE_ELRP_A6:
      widgetCode = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${ZOHO_CHAT_PGE_ELRP_A6}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`;
      break;
    default:
      widgetCode = '';
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = widgetCode;
    if (showChat) {
      document.body.appendChild(script);
      // wait for the chat widget to mount
      const intervalId = setInterval(() => {
        if (window?.$zoho?.salesiq.chat) {
          // Expand the chat widget
          window.$zoho.salesiq.chat.start();
          clearInterval(intervalId);
        }
      }, 500);
    }
  }, [showChat]);

  const hasChatAccess = widgetCode !== '';

  return { setShowChat, hasChatAccess };
}
