/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import IconButton from '@/atoms/IconButton';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import { Container, Description, Title, Heading } from './HomesSection.styles';

type Props = {
  title: string;
  description?: string;
  onClickHelp?: () => void;
  children: ReactNode;
};

export const HomesSection = ({ title, description = '', onClickHelp = undefined, children }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Container>
      <Heading>
        <Title id={title} tabIndex={0}>
          {title}
        </Title>
        {onClickHelp && (
          <IconButton
            ariaLabel={`${title} ${t('common.generalButtons.help')}`}
            marginLeft={8}
            icon={images.help}
            onClick={onClickHelp}
          />
        )}
      </Heading>
      {!!description && (
        <Description tabIndex={0} aria-labelledby={title}>
          {description}
        </Description>
      )}
      {children}
    </Container>
  );
};
