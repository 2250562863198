import { AxiosRequestHeaders } from 'axios';

/**
 * This function wraps the axios headers with a potential 'X-Imitate-User'
 * header if that property exists in local storage.  The purpose of this header
 * is to allow us to imitate end users in cases when they encounter bugs so we
 * can see what they see.  Note this isn't a security risk, since the only way
 * the backend actually imitates a user is when you are logged into one of the
 * accounts hardcoded in the backend.
 */
const withImitateUserHeader = (headers: AxiosRequestHeaders) => {
  const resultHeaders = { ...headers };

  const imitateUserId = localStorage.getItem('imitateUserId');
  if (imitateUserId) {
    resultHeaders['X-Imitate-User'] = imitateUserId;
  }

  return resultHeaders;
};

export default withImitateUserHeader;
