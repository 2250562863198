import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import { RootState } from '@/stores/index';
import useUsagePattern from '@/hooks/useUsagePattern';
import { useSites } from '@/hooks/index';
import { GBCStatus as GBCStatusType } from '@/types/userInfo';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import GBCStatus from '@/organisms/GBCStatus';
import { TimePeriod } from '@/types/usagePattern';
import { sentEvent } from '@/helpers/GAHelper';
import { FetchingStatus } from '@/hooks/types';
import UsagePatternView from '@/organisms/UsagePattern/index.view';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/index';
import { PrimaryButton } from '@/designSystem/components/index';
import { ErrorContainer, ErrorDescription, ErrorTitle, LoadingWrapper, Wrapper } from './index.styles';

const UsagePatternScreenContainer = () => {
  const { t } = useTranslation('common');
  const {
    userInfo: {
      data: { userInfo },
    },
    sites: {
      data: { currentSite },
    },
    setting: {
      data: { language },
    },
  } = useSelector((state: RootState) => state);

  // Energy Usage - ClimateResponse®
  document.title = `${t('screens.main.usagePattern.usagePatternScreen.title')} - ${contents.name}`;

  const [selectedPeriodFilter, setSelectedPeriodFilter] = useState(TimePeriod.day);
  const [currentRange, setCurrentRange] = useState('initial');

  const onPeriodUpdate = (newPeriod: TimePeriod) => {
    if (!currentSite || selectedPeriodFilter === newPeriod) {
      return;
    }

    setSelectedPeriodFilter(newPeriod);

    sentEvent('click', PATHS.DATE(newPeriod));
  };

  const onUpdateRange = (newRange: string) => {
    if (!currentSite || currentRange === newRange) {
      return;
    }

    setCurrentRange(newRange);
    sentEvent('click', PATHS.DATE(newRange));
  };

  const handlePrevRange = ({ timeList, range }: { timeList: Array<string>; range: string }) => {
    const currentIndex = timeList.findIndex((date: string) => date === range);
    const changeRange = timeList[Math.min(timeList.length, currentIndex + 1)];
    onUpdateRange(changeRange);
  };

  const handleNextRange = ({ timeList, range }: { timeList: Array<string>; range: string }) => {
    const currentIndex = timeList.findIndex((date: string) => date === range);
    const changeRange = timeList[Math.max(0, currentIndex - 1)];
    onUpdateRange(changeRange);
  };

  const { fetchSites } = useSites();
  const usagePatternState = useUsagePattern({
    siteId: currentSite?.id || '',
    timePeriod: selectedPeriodFilter,
    range: currentRange,
  });

  useEffect(() => {
    if (userInfo.gbcStatus === GBCStatusType.completed && !currentSite) {
      fetchSites(userInfo.siteName);
    }
  }, []);

  useEffect(() => {
    onUpdateRange('initial');
  }, [selectedPeriodFilter]);

  const navigate = useNavigate();
  useEffect(() => {
    if (userInfo.isDeleted === true) {
      navigate(PATHS.SCREEN_SETTING_ACCOUNT.pathname);
    }
  });

  if (usagePatternState.fetchingStatus === FetchingStatus.LOADING) {
    return (
      <LoadingWrapper>
        <ReactLoading type="spin" color={colors.primary} height="3%" width="3%" />
      </LoadingWrapper>
    );
  }

  if (usagePatternState.fetchingStatus === FetchingStatus.ERROR) {
    return (
      <ErrorContainer>
        <ErrorTitle>{t('screens.errorScreen.data.title')}</ErrorTitle>
        <ErrorDescription>{t('screens.errorScreen.data.description')}</ErrorDescription>
        <PrimaryButton onClick={usagePatternState.refetch} title={t('common.generalButtons.tryAgain')} />
      </ErrorContainer>
    );
  }

  if (usagePatternState.fetchingStatus === FetchingStatus.SUCCESS) {
    const range = currentRange === 'initial' ? usagePatternState.data.timeList[0] : currentRange;

    return (
      <Wrapper>
        {userInfo.gbcStatus === GBCStatusType.completed ? (
          <UsagePatternView
            items={usagePatternState.data}
            selectedPeriodFilter={selectedPeriodFilter}
            onTabClick={onPeriodUpdate}
            onRange={onUpdateRange}
            onPrevRange={() => handlePrevRange({ timeList: usagePatternState.data.timeList, range })}
            onNextRange={() => handleNextRange({ timeList: usagePatternState.data.timeList, range })}
            language={language}
          />
        ) : (
          <GBCStatus />
        )}
      </Wrapper>
    );
  }

  return <div />;
};
export default UsagePatternScreenContainer;
