import React from 'react';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import contents from '@/assets/config/appInfo';
import { images } from '@/assets/styles';
import Img from '@/atoms/Img';
import PATHS from '@/types/navigationPaths';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import IconButton from '@/atoms/IconButton';
import { useMisc } from '@/hooks/index';
import { isOlivineEdition } from '@/helpers/Edition';
import SidebarItem from './modules/SidebarItem';
import { Wrapper, Container, Logo, ImageButtonSection } from './index.styles';
import { ISidebarViewProps } from './index.types';

const SidebarView = ({ path, displayMeterData, programName, logoComponentRef }: ISidebarViewProps) => {
  const { t } = useTranslation('common');
  const imageAltText = t(`common.image.text.logo${contents.edition}WebWhite`);
  const context = getProgramContext({ programName });
  const shouldDisplayEnergyEventsTab = context?.getShouldDisplayEnergyEventsTab(displayMeterData);

  const menus = [
    {
      id: nanoid(),
      title: t('screens.main.dashboard.dashboardScreen.title'),
      img: images.menuDashboardDeactive,
      selectedImg: images.menuDashboardActive,
      link: PATHS.SCREEN_DASHBOARD.pathname,
      gaEventParam: PATHS.NAVIGATION_DASHBOARD,
    },
    {
      id: nanoid(),
      title: t('screens.main.usagePattern.usagePatternScreen.title'),
      img: images.menuUsagePatternDeactive,
      selectedImg: images.menuUsagePatternActive,
      link: PATHS.SCREEN_USAGE_PATTERN.pathname,
      hide: !shouldDisplayEnergyEventsTab,
      gaEventParam: PATHS.NAVIGATION_ENERGY_USAGE,
    },
    {
      id: nanoid(),
      title: t(`common:programs.${programName}.savingsEventsScreen.title`),
      img: images.menuDrDeactive,
      selectedImg: images.menuDrActive,
      link: PATHS.SCREEN_SAVING_EVENT.pathname,
      hide: !programName,
      gaEventParam: PATHS.NAVIGATION_ENERGY_EVENTS,
    },
    {
      id: nanoid(),
      title: t('screens.main.messages.messagesScreen.title'),
      img: images.menuMessagesDeactive,
      selectedImg: images.menuMessagesActive,
      link: PATHS.SCREEN_MESSAGE.pathname,
      gaEventParam: PATHS.NAVIGATION_MESSAGES,
    },
    {
      id: nanoid(),
      title: t('screens.main.settings.settingScreen.title'),
      img: images.menuSettingsDeactive,
      selectedImg: images.menuSettingsActive,
      link: PATHS.SCREEN_SETTING.pathname,
      gaEventParam: PATHS.NAVIGATION_SETTINGS,
    },
  ];

  const makeLink = () => {
    if (path.split('/').includes('setting')) {
      return PATHS.SCREEN_SETTING.pathname;
    }
    if (path.split('/').includes('dr')) {
      return PATHS.SCREEN_SAVING_EVENT.pathname;
    }

    return path;
  };
  const { fetchChangeHamburger } = useMisc();
  const handleCloseSidebar = () => {
    fetchChangeHamburger(true);
  };

  return (
    <Wrapper>
      <ImageButtonSection>
        <IconButton icon={images.close} onClick={handleCloseSidebar} />
      </ImageButtonSection>
      <Logo ref={logoComponentRef}>
        <Img
          style={isOlivineEdition ? { height: '100px', alignSelf: 'center', display: 'flex' } : {}}
          src={images.logoOlivineWebWhite.image}
          altAttr={imageAltText}
        />
      </Logo>
      <Container>
        {menus.map(
          ({ id, link, title, img, selectedImg, hide, gaEventParam }) =>
            !hide && (
              <SidebarItem
                key={id}
                link={link}
                selected={makeLink() === link}
                title={title}
                img={img}
                selectedImg={selectedImg}
                eventParams={gaEventParam}
              />
            ),
        )}
      </Container>
    </Wrapper>
  );
};

export default SidebarView;
