import { ReqSetting } from '@/apis/auth/types';
import { Language } from '@/types/setting';

// Types
//
// Update Setting
export const SETTING_REQUEST = 'setting/SETTING_REQUEST';
export const SETTING_SUCCESS = 'setting/SETTING_SUCCESS';
export const SETTING_FAILURE = 'setting/SETTING_FAILURE';

// Language
export const CHANGE_LANGUAGE = 'setting/CHANGE_LANGUAGE';

interface ChangeLanguageAction {
  type: typeof CHANGE_LANGUAGE;
  language: Language;
}

interface SettingRequestAction {
  type: typeof SETTING_REQUEST;
  req: ReqSetting;
}

interface SettingSuccessAction {
  type: typeof SETTING_SUCCESS;
  language: Language;
}

interface SettingFailureAction {
  type: typeof SETTING_FAILURE;
  errorMessage: string;
}

type InitActionTypes = ChangeLanguageAction | SettingRequestAction | SettingSuccessAction | SettingFailureAction;

// initial state
//
export interface SettingState {
  data: {
    language: Language;
  };
  isLoading: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialState: SettingState = {
  data: {
    language: Language.eng,
  },
  isLoading: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: SettingState = initialState, action: InitActionTypes): SettingState => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        data: {
          ...state.data,
          language: action.language,
        },
      };

    case SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SETTING_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          language: action.language,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SETTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    default:
      return state;
  }
};

// Actions
//
export const settingRequest = (req: ReqSetting) => ({
  type: SETTING_REQUEST,
  req,
});

export const changeLanguage = (language: Language) => ({ type: CHANGE_LANGUAGE, language });
