import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { colors } from '@/designSystem/colors';
import { Modal, PrimaryButton, SecondaryButton } from '@/designSystem/components';
import { ModalButtonsContainer, ModalContainer, ModalHeader, ModalText } from './SitesRefreshModal.styles';

const SitesRefreshModal = ({
  isVisible,
  onClose,
  onClickContinue,
  onClickCancel,
  isLoading,
}: {
  isVisible: boolean;
  onClose: () => void;
  onClickContinue: () => void;
  onClickCancel: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation('common');
  return (
    <Modal show={isVisible} onClose={onClose} showClose small>
      <ModalContainer>
        <ModalHeader>{t('screens.main.settings.mySites.refreshSites.title')}</ModalHeader>
        <ModalText>{t('screens.main.settings.mySites.refreshSites.refreshSitesText')}</ModalText>

        <ModalButtonsContainer>
          {isLoading ? (
            <ReactLoading type="spin" color={colors.primary} height="35px" width="35px" />
          ) : (
            <PrimaryButton title={t('common.generalButtons.continue')} onClick={onClickContinue} />
          )}
          {isLoading ? (
            <ReactLoading type="spin" color={colors.primary} height="35px" width="35px" />
          ) : (
            <SecondaryButton title={t('common.generalButtons.cancel')} onClick={onClickCancel} />
          )}
        </ModalButtonsContainer>
      </ModalContainer>
    </Modal>
  );
};

export default SitesRefreshModal;
