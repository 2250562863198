import React from 'react';
import OriginModal from 'react-modal';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import { IconButton } from '@/designSystem/components';
import { IModalBannerProps } from './ModalBanner.types';
import { Container, Header, Title, More, Body, defaultModalStyle, Message, Footer } from './ModalBanner.styles';

export const ModalBanner = ({
  children,
  show,
  title,
  showClose,
  onClose,
  ariaControlsId,
  style = defaultModalStyle,
  headerImage,
  message,
}: IModalBannerProps) => {
  const { t } = useTranslation('common');
  return (
    <OriginModal
      aria={{ modal: 'true' }}
      id={ariaControlsId}
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={show}
      onRequestClose={onClose}
      style={style}
    >
      <Container>
        <Header>
          {headerImage && (
            <Img
              src={images.logoOlivineSmall.image}
              altAttr={images.logoOlivineSmall.text}
              style={{ height: '40px', width: 'auto', marginBottom: '8px' }}
            />
          )}
          {title && <Title>{title}</Title>}
          {showClose && (
            <More>
              <IconButton
                padding="4px"
                image={{
                  active: {
                    src: images.bannerClose.image,
                    alt: images.bannerClose.text,
                  },
                  width: '16px',
                  height: '16px',
                }}
                onClick={onClose}
                ariaLabel={`${t('common.generalButtons.close')}`}
                role="button"
                ariaControls={ariaControlsId}
              />
            </More>
          )}
        </Header>
        <Body>
          <Message>{message}</Message>
        </Body>
        <Footer>{children}</Footer>
      </Container>
    </OriginModal>
  );
};
