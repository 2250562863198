export enum PerEventDisclaimer {
  Disputed = 'disputed',
  Final = 'final',
  Incalculable = 'incalculable',
  Preliminary = 'preliminary',
  None = 'none',
}

export const perEventDisclaimerDisplayedOptions = [
  PerEventDisclaimer.Disputed,
  PerEventDisclaimer.Incalculable,
  PerEventDisclaimer.Preliminary,
];

export const perEventDisclaimer = ({ perEventDisclaimer }: { perEventDisclaimer: PerEventDisclaimer }) => {
  if (!perEventDisclaimer || !perEventDisclaimerDisplayedOptions.includes(perEventDisclaimer)) {
    return { disclaimerNamespace: '', disclaimerSymbol: '' };
  }
  return { disclaimerNamespace: `common.earningsSummaryState.${perEventDisclaimer}`, disclaimerSymbol: '*' };
};
