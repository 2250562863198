import React from 'react';
import GridEmissions from '@/organisms/GridEmissions';
import { Wrapper } from './MobileCurrentGridEmissions.styles';

const MobileCurrentGridEmissions = () => {
  return (
    <Wrapper>
      <GridEmissions />
    </Wrapper>
  );
};

export default MobileCurrentGridEmissions;
