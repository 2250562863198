import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import * as apiPrimary from '@/apis/primary';
import { ReqUsagePattern } from '@/apis/primary/types';
import { TimePeriod, UsagePattern } from '@/types/usagePattern';
import { GeneralHook, FetchingStatus } from './types';

const formatWeekListRange = (timeList: string[]) => {
  const newTimeList = timeList.map((item) => {
    const arr = item.split('-');
    const date1 = moment(arr[0], 'M/D/YYYY').format('M/D/YY');
    const date2 = moment(arr[1], 'M/D/YYYY').format('M/D/YY');

    return `${date1} - ${date2}`;
  });

  return newTimeList;
};

const useUsagePattern = ({ siteId, timePeriod, range }: ReqUsagePattern): GeneralHook<UsagePattern> => {
  const { data, error, isError, isLoading, isRefetching, isFetching, refetch } = useQuery({
    queryKey: ['usagePattern', siteId, timePeriod, range],
    queryFn: () => apiPrimary.getUsagePattern({ siteId, timePeriod, range }),
    staleTime: 60 * 5 * 1000,
  });

  if (isLoading || isRefetching || isFetching) {
    return { fetchingStatus: FetchingStatus.LOADING };
  }

  if (isError) {
    return {
      fetchingStatus: FetchingStatus.ERROR,
      error,
      refetch,
    };
  }

  if (data?.data) {
    const list: string[] = data.data.timeList[`${timePeriod}List`];
    const formattedList = timePeriod === TimePeriod.week ? formatWeekListRange(list) : list;

    const usagePattern = {
      timeList: formattedList,
      selectedTimePeriod: data.data.timeList.selectedTimePeriod as TimePeriod,
      graph: data.data.graph,
      highestValue: data.data.highestValue,
      lowestValue: data.data.lowestValue,
      highestPeriodTimestamp: data.data.highestPeriodTimestamp,
      lowestPeriodTimestamp: data.data.lowestPeriodTimestamp,
      hasSufficientUsageData: data.data.hasSufficientUsageData,
      range: range === 'initial' ? formattedList[0] : range,
    };

    return {
      fetchingStatus: FetchingStatus.SUCCESS,
      data: usagePattern,
      refetch,
    };
  }

  return {
    fetchingStatus: FetchingStatus.INITIAL,
    fetch: refetch,
  };
};

export default useUsagePattern;
