import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useEnrollment from '@/hooks/useEnrollment';
import { RootState } from '@/stores/index';
import { useZohoChat, useUserInfo } from '@/hooks/index';
import PATHS from '@/types/navigationPaths';
import { findRouteName, nextScreenStep } from '@/helpers/NavigationHelper';
import { Attribute } from '@/types/enrollment';
import { ReqUpdateUserInfo } from '@/apis/auth/types';
import { IEnrollmentTemplateProps } from '@/templates/EnrollmentTemplate/index.types';
import EnrollmentTemplateView from '@/templates/EnrollmentTemplate/index.view';
import { isUtilityEdition } from '@/helpers/Edition';

const EnrollmentTemplateContainer = ({
  children,
  underline,
  disabled,
  navigationTitle,
  navigationPath,
  onNext,
  textNext,
  onOther,
  textOther,
  showBoxShadow,
  wrapperMargin,
  wrapperMaxWidth,
  buttonsContainerPadding,
  bodyPadding,
  previousNavigationPath,
  hideBack,
  onBack,
}: IEnrollmentTemplateProps) => {
  const {
    userInfo: {
      data: { userInfo },
    },
    enrollment: {
      programs: { prevProgram, currentProgram },
      enrollmentInfo,
      enrollmentInfo: { utility },
    },
    misc: { appLogin },
  } = useSelector((state: RootState) => state);

  const navigate = useNavigate();
  const { fetchRestoreProgram, fetchAgreeAvailable, fetchAttributeRequest, fetchEnrollment } = useEnrollment();
  const { fetchUpdateUserInfo } = useUserInfo();
  const { t } = useTranslation('common');
  const [stateModal, setStateModal] = useState({ support: false });

  const { setShowChat, hasChatAccess } = useZohoChat(currentProgram.program);

  const supportEmail = t<string, string>(`programs.${currentProgram.program}.support.email`, {
    returnObjects: true,
  });
  const supportPhone = t<string, string>(`programs.${currentProgram.program}.support.phone`, {
    returnObjects: true,
  });
  const supportPhoneAccessibility = t<string, string>(`programs.${currentProgram.program}.support.accessibilityPhone`, {
    returnObjects: true,
  });

  const firstScreenStepOfEnrollment = isUtilityEdition
    ? PATHS.SCREEN_ENROLLMENT_PROFILE_SETUP.pathname
    : PATHS.SCREEN_ENROLLMENT_PROGRAM_ENROLLMENT.pathname;

  useEffect(() => {
    const routeName = findRouteName(window.location);

    // 동의 취소하기
    if (routeName === 'NAV_CPA_VERIFY') {
      fetchAgreeAvailable(false);
    }

    // 유틸리티 취소하기
    // Commented because we need the Utility value in the Connect to Utility screen
    // if (routeName === 'NAV_AVAILABLE_PROGRAM_NORMAL') {
    //   fetchUtility('');
    // }

    if (routeName) {
      const newPayload: ReqUpdateUserInfo = {
        enrollmentScreen: routeName,
      };
      fetchUpdateUserInfo(newPayload);
    }

    if (window.ReactNativeWebView) {
      const data = JSON.stringify({ loading: false });
      window.ReactNativeWebView.postMessage(data);
    }

    fetchAttributeRequest(Attribute.displayLater);
    fetchAttributeRequest(Attribute.displayNoThanks);
  }, []);

  const handleBack = (navigationPath?: string, previousNavigationPath?: string) => {
    const comparePaths = [firstScreenStepOfEnrollment, PATHS.SCREEN_ENROLLMENT_CONFIRM_PROGRAM.pathname];

    if (comparePaths.indexOf(navigationPath || '') > -1) {
      if (window.ReactNativeWebView) {
        fetchEnrollment();
        const data = JSON.stringify({ to: 'login', gbcStatus: userInfo.gbcStatus });
        window.ReactNativeWebView.postMessage(data);
      } else {
        navigate(PATHS.SCREEN_ROOT.pathname, { replace: true });
      }
    } else {
      // back button fallback
      const prevScreen =
        previousNavigationPath || nextScreenStep(currentProgram.screenStep, window.location, enrollmentInfo, -1); // back a step
      navigate(prevScreen);
      if (prevProgram.length > 1) {
        fetchRestoreProgram();
      }
    }
  };

  const routeName = findRouteName(window.location);
  const currentScreenSteps = currentProgram.screenStep;

  const shouldHideBackButton = useCallback(() => {
    const currentScreenIsInScreenSteps = currentScreenSteps.some((screenStep) => screenStep.name === routeName);

    if (!currentScreenIsInScreenSteps) {
      return false;
    }

    const currentScreenStep = currentScreenSteps.find((screenStep) => screenStep.name === routeName);

    return !!currentScreenStep?.hideBack;
  }, [routeName, currentProgram]);

  return (
    <EnrollmentTemplateView
      appLogin={appLogin}
      textNext={textNext}
      underline={underline}
      disabled={disabled}
      navigationTitle={navigationTitle}
      navigationPath={navigationPath}
      onBack={onBack ?? handleBack}
      onNext={onNext}
      onOther={onOther}
      textOther={textOther}
      showBoxShadow={showBoxShadow}
      wrapperMargin={wrapperMargin}
      wrapperMaxWidth={wrapperMaxWidth}
      buttonsContainerPadding={buttonsContainerPadding}
      utility={utility || userInfo.utility}
      bodyPadding={bodyPadding}
      hideSupport={currentProgram?.attributes?.hideSupport}
      previousNavigationPath={previousNavigationPath}
      showSupport={stateModal.support}
      setShowChat={setShowChat}
      hasChatAccess={hasChatAccess}
      handleShowSupport={() => setStateModal({ ...stateModal, support: !stateModal.support })}
      supportContacts={{
        email: supportEmail,
        phone: supportPhone,
        phoneAccessibility: supportPhoneAccessibility,
      }}
      hideBack={shouldHideBackButton() || hideBack}
    >
      {children}
    </EnrollmentTemplateView>
  );
};

export default EnrollmentTemplateContainer;
