import React from 'react';
import { images } from '@/assets/styles';
import { HoverIconButton } from '@/designSystem/components/buttons/HoverIcon';
import { Container, PhoneText, ButtonContainer } from './PhoneNumberItem.styles';

type PhoneNumberItemProps = {
  phoneNumber: string;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

const PhoneNumberItem = ({ phoneNumber, onClickEdit, onClickDelete }: PhoneNumberItemProps) => {
  return (
    <Container>
      <PhoneText>{phoneNumber}</PhoneText>
      <ButtonContainer>
        <HoverIconButton
          onClick={onClickDelete}
          padding="6px 10px"
          image={{
            active: {
              src: images.removePhone.image,
              alt: images.removePhone.text,
            },
            hover: {
              src: images.removePhoneHover.image,
              alt: images.removePhoneHover.text,
            },
          }}
        />
        <HoverIconButton
          onClick={onClickEdit}
          padding="4px"
          image={{
            active: {
              src: images.editPhone.image,
              alt: images.editPhone.text,
            },
            hover: {
              src: images.editPhoneHover.image,
              alt: images.editPhoneHover.text,
            },
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

export default PhoneNumberItem;
