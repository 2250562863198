import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkButton } from '@/designSystem/components';
import { Container, Description, MoreInformationContainer, Subtitle } from './ProgramContents.styles';

type Props = {
  programName?: string;
};

const ProgramContentsDefault = ({ programName }: Props) => {
  const { t, i18n } = useTranslation('common');
  const currentYear = new Date().getFullYear();

  const programDetailsExists = i18n.exists(`common:programs.${programName}.programDetailsScreen.programDetails`);
  const programBenefitsExists = i18n.exists(`common:programs.${programName}.programDetailsScreen.programBenefits`);

  const handleUrlLink = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Container>
      <Description>{t(`programs.${programName}.programDetailsScreen.description`)}</Description>
      {programDetailsExists && (
        <>
          <Subtitle>{t(`programs.${programName}.programDetailsScreen.programDetails.title`)}</Subtitle>
          <Description>{t(`programs.${programName}.programDetailsScreen.programDetails.description`)}</Description>
        </>
      )}
      {programBenefitsExists && (
        <>
          <Subtitle>{t(`programs.${programName}.programDetailsScreen.programBenefits.title`)}</Subtitle>
          <Description>{t(`programs.${programName}.programDetailsScreen.programBenefits.description`)}</Description>
        </>
      )}
      <MoreInformationContainer>
        <Description>
          {t(`programs.${programName}.programDetailsScreen.more.description`)}
          <LinkButton
            removeFlex
            title={t(`programs.${programName}.programDetailsScreen.more.linkContent.text`)}
            onClick={() => handleUrlLink(t(`programs.${programName}.programDetailsScreen.more.linkContent.link`))}
            padding="2px 2px"
          />
        </Description>
      </MoreInformationContainer>
      <MoreInformationContainer>
        <Description>{t(`programs.${programName}.programDetailsScreen.copyright`, { currentYear })}</Description>
      </MoreInformationContainer>
    </Container>
  );
};

export default ProgramContentsDefault;
