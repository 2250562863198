import React from 'react';
import { images } from '@/assets/styles';
import IconButton from '@/atoms/IconButton';
import { Container, Description, SiteName, TextContent } from './SiteCheckboxItem.styles';

type Props = {
  isChecked: boolean;
  onClickCheck: () => void;
  title?: string;
  description?: string;
  siteId: string;
};

export const SiteCheckboxItem = ({ isChecked, title, description, onClickCheck, siteId }: Props) => {
  return (
    <Container>
      <IconButton
        ariaLabelledby={siteId}
        role="checkbox"
        ariaSelected={isChecked}
        height={20}
        width={20}
        icon={isChecked ? images.checkboxChecked.image : images.checkbox.image}
        onClick={onClickCheck}
      />
      <TextContent id={siteId}>
        <SiteName>{title}</SiteName>
        <Description>{description}</Description>
      </TextContent>
    </Container>
  );
};
