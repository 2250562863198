import capitalize from 'lodash/capitalize';

export const capitalizeWords = (word: string) => {
  if (!word) {
    return '';
  }

  const splitRegularExpression = /(-|'| )/g;
  const splittedWords = word.split(splitRegularExpression);
  const capitalizedWords = splittedWords.map(capitalize).join('');

  return capitalizedWords;
};

export const cityStateFormatter = ({ city, state }: { city?: string; state?: string }) => {
  if (!city && !state) {
    return '';
  }

  if (!city) {
    return state?.toUpperCase();
  }

  if (!state) {
    return capitalizeWords(city);
  }

  return `${capitalizeWords(city)}, ${state.toUpperCase()}`;
};
