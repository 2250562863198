import { SummaryState } from '@/types/dashboard';
import { ProgramName } from '@/types/enrollment';

export const billCreditPrograms = [ProgramName.SCE_ELRP_A6, ProgramName.SCE_ELRP_A1, ProgramName.SCE_ELRP_A1_BIP];

export const nonDisplayableStates = [SummaryState.final, SummaryState.none];

export const moneyEarnedDisclaimer = ({
  moneyEarnedPerEvent,
  summaryState,
  isSceWithEvents,
}: {
  moneyEarnedPerEvent?: boolean;
  summaryState: SummaryState;
  isSceWithEvents?: boolean;
}) => {
  if (nonDisplayableStates.includes(summaryState)) {
    return { disclaimerNamespace: '', symbol: '' };
  }

  if (summaryState) {
    return { disclaimerNamespace: `common.earningsSummaryState.${SummaryState[summaryState]}`, symbol: '*' };
  }

  if (isSceWithEvents) {
    return { disclaimerNamespace: `common.earningsSummaryState.billCreditDescription`, symbol: '*' };
  }

  if (moneyEarnedPerEvent === undefined) {
    return { disclaimerNamespace: '', symbol: '' };
  }

  return {
    disclaimerNamespace: `common.earningsSummaryState.${
      moneyEarnedPerEvent ? 'eventDescription' : 'enrollmentDescription'
    }`,
    symbol: '*',
  };
};
