import styled from 'styled-components';
import { media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  display: grid;
  ${media.lg`
    grid-template-columns: 1fr 1.5fr;
  `}
`;

export const BodyContainer = styled.div`
  padding: 15px;
  background-color: ${colors.background};
  display: flex;
  gap: 10px;
  flex-direction: column;

  ${media.xl`
    flex-direction: row;
  `}
`;
