import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Navigator = styled.div`
  border-radius: 8px;
  background: ${colors.white};
  display: none;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${media.md`
    padding: 24px;
  `}
  ${media.lg`
    display: flex;
  `}
`;
export const Container = styled.div`
  ${media.lg`
    border-radius: 8px;
    border-bottom: 0;
  `}
  ${media.md`
    padding: 24px;
  `}
  ${media.xs`
    padding: 16px 24px;
  `}
`;

export const ScreenName = styled.p`
  ${typography.md}
  color: ${colors.text};
  font-weight: 700;
  ${media.lg`
    ${typography.xl};
    line-height: 24px;
  `};
`;

export const ParentScreenName = styled.p`
  display: none;
  color: ${colors.grey};
  font-weight: 700;
  margin-right: 4px;
  &::after {
    content: ' /';
  }
  ${media.lg`
    ${typography.xl};
    line-height: 24px;
    display: block;
  `};
`;
export const LeftSection = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const RightSection = styled.div(
  ({ theme }) => css`
    display: none;
    ${helpers.textSet({
      fontSize: theme.typography.title.fontSize,
      fontWeight: theme.font.weight.regular,
    })};
    ${media.lg`
      ${helpers.flexSet('row', 'space-between', 'center')};
    `}

    color: ${theme.colors.text[80]};
  `,
);
