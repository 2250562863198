import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import { IStyleValue, IStyleWrapper } from './index.types';

export const Wrapper = styled.div<IStyleWrapper>(
  ({ alignSelf }) => css`
    ${alignSelf && `align-self: ${alignSelf};`};
  `,
);

export const Container = styled.div<{ alignItems?: string }>`
  ${(props) => helpers.flexSet('column', 'center', props.alignItems ?? 'flex-start')};
`;

export const Title = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.body.fontSize, fontWeight: theme.typography.body.fontWeight })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
    margin-bottom: 10px;
  `,
);

export const ValueContainer = styled.div`
  ${helpers.flexSet('row', 'center', 'center')};
`;

export const Value = styled.p<IStyleValue>(
  ({ theme, color, strong, size, padding, lineHeight, fontWeight }) => css`
    ${helpers.textSet({
      fontSize: size ?? theme.typography.h2.fontSize,
      fontWeight: strong ? theme.font.weight.bold : theme.font.weight.regular,
    })};
    ${helpers.colorSet({ color: color || 'black' })};
    line-height: ${lineHeight ?? lineHeight};
    font-weight: ${fontWeight ?? fontWeight};

    padding: ${padding ?? '0px'};

    ${strong && 'font-weight: bold;'}

    margin-top: 4px;
    margin-bottom: 4px;
  `,
);

export const Description = styled.p<IStyleValue>(
  ({ theme, color, descriptionAlign, descriptionSize }) => css`
    ${helpers.textSet({ fontSize: descriptionSize ?? theme.typography.body.fontSize })};
    ${helpers.colorSet({ color: color || theme.colors.text[100] })};
    text-align: ${descriptionAlign || 'left'};
  `,
);

export const Empty = styled.div<{ valueIconSize?: { width: string; height: string } }>`
  ${(props) =>
    props.valueIconSize &&
    css`
      width: ${props.valueIconSize.width};
      height: ${props.valueIconSize.height};
    `}
`;
