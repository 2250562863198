import React, { AriaRole } from 'react';
import { Button, Img } from './MenuItem.styles';

type MenuItemProps = {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  padding?: string;
  image?: {
    active: {
      src: string;
      alt: string;
    };
    disabled: {
      src: string;
      alt: string;
    };
    width?: string;
    height?: string;
  };
  warning?: boolean;
  selected: boolean;

  ariaDisabled?: boolean;
  ariaLabelledBy?: string;
  role?: AriaRole;
  ariaControls?: string;
  ariaExpanded?: boolean;
  ariaSelected?: boolean;
};

export const MenuItem = ({
  title,
  onClick,
  disabled,
  image,
  padding,
  warning,
  selected,
  ariaDisabled,
  ariaLabelledBy,
  role,
  ariaControls,
  ariaExpanded,
  ariaSelected,
}: MenuItemProps) => {
  return (
    <Button
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
      aria-label={title}
      disabled={disabled}
      padding={padding}
      warning={warning}
      selected={selected}
      aria-disabled={ariaDisabled}
      aria-labelledby={ariaLabelledBy}
      role={role}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-selected={ariaSelected}
    >
      {title}
      {image && (
        <Img
          width={image.width}
          height={image.height}
          src={selected ? image.active.src : image.disabled.src}
          alt={selected ? image.active.alt : image.disabled.alt}
        />
      )}
    </Button>
  );
};
