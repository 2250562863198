import React from 'react';
import { Container, Description, Value } from './index.styles';

type Props = {
  value: string;
  description?: string;
  valueColor?: string;
  valueAriaLabel?: string;
  valueSize?: string;
  containerAlignItems?: 'normal' | 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline';
  containerFlexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse';
  containerGap?: string;
  descriptionSize?: string;
  descriptionColor?: string;
  descriptionWeight?: number;
};

const Usage = ({
  value,
  description,
  valueColor,
  valueAriaLabel,
  containerAlignItems = 'normal',
  valueSize = '22px',
  containerFlexDirection = 'column',
  containerGap,
  descriptionColor,
  descriptionSize = '16px',
  descriptionWeight,
}: Props) => {
  return (
    <Container tabIndex={0} alignItems={containerAlignItems} flexDirection={containerFlexDirection} gap={containerGap}>
      <Value aria-label={valueAriaLabel} color={valueColor} size={valueSize}>
        {value}
      </Value>
      <Description color={descriptionColor} size={descriptionSize} weight={descriptionWeight}>
        {description}
      </Description>
    </Container>
  );
};

export default Usage;
