import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/stores/index';
import contents from '@/assets/config/appInfo';
import SettingTemplate from '@/templates/SettingTemplate';
import { sentEvent } from '@/helpers/GAHelper';
import ProgramContentsClimateResponse from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.climate_response';
import ProgramContentsDefault from '@/organisms/ModalProgramInformation/ProgramContents/ProgramContents.default';
import { getProgramNameFromSite } from '@/helpers/GetProgramName';
import DataOnlyMessage from '@/molecules/DataOnlyMessage';
import { ProgramName } from '@/types/enrollment';
import { Wrapper, TitleContainer, Title } from './ProgramDetail.styles';

const ProgramDetailScreen = () => {
  const { t } = useTranslation('common');
  const {
    sites: {
      data: { currentSite },
    },
    userInfo: {
      data: { userInfo },
    },
  } = useSelector((state: RootState) => state);

  // Program Details - ClimateResponse®
  document.title = `${t('screens.main.settings.programDetails.title')} - ${contents.name}`;

  const programName = getProgramNameFromSite(currentSite, userInfo.program);

  const renderProgramDetail = (programName: ProgramName) => {
    switch (programName) {
      case ProgramName.DATA_ONLY:
        return <DataOnlyMessage isProgramDetails />;
      case ProgramName.CLIMATE_RESPONSE:
        return <ProgramContentsClimateResponse programName={programName} />;
      default:
        return <ProgramContentsDefault programName={programName} />;
    }
  };

  useEffect(() => {
    sentEvent('screen_view', {
      screen_name: 'Program Details',
      screen_class: 'program_details',
    });
  }, []);

  return (
    <SettingTemplate>
      <TitleContainer>
        <Title>{t(`programs.${programName}.title`)}</Title>
      </TitleContainer>
      <Wrapper>{renderProgramDetail(programName)}</Wrapper>
    </SettingTemplate>
  );
};

export default ProgramDetailScreen;
