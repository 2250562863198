import { OTPStatus } from '@/types/phoneOtp';
import { ReqPhoneOtpRequest, ReqPhoneOtpVerification } from '@/apis/enrollment/types';

// Reset
export const RESET_PHONE_OTP = 'setting/RESET_PHONE_OTP';

// Phone
export const CHANGE_PHONE_OTP_INTERVAL = 'setting/CHANGE_PHONE_OTP_INTERVAL';
export const CHANGE_PHONE_OTP = 'setting/CHANGE_PHONE_OTP';

export const PHONE_OTP_VERIFICATION_REQUEST = 'setting/PHONE_OTP_VERIFICATION_REQUEST';
export const PHONE_OTP_VERIFICATION_SUCCESS = 'setting/PHONE_OTP_VERIFICATION_SUCCESS';
export const PHONE_OTP_VERIFICATION_FAILURE = 'setting/PHONE_OTP_VERIFICATION_FAILURE';

export const PHONE_OTP_RESEND_REQUEST = 'setting/PHONE_OTP_RESEND_REQUEST';
export const PHONE_OTP_RESEND_SUCCESS = 'setting/PHONE_OTP_RESEND_SUCCESS';
export const PHONE_OTP_RESEND_FAILURE = 'setting/PHONE_OTP_RESEND_FAILURE';

export const PHONE_OTP_REQUEST = 'setting/PHONE_OTP_REQUEST';
export const PHONE_OTP_SUCCESS = 'setting/PHONE_OTP_SUCCESS';
export const PHONE_OTP_FAILURE = 'setting/PHONE_OTP_FAILURE';

export const CHANGE_OLD_PHONE_NUMBER = 'temporary/CHANGE_OLD_PHONE_NUMBER';
export const CHANGE_NEW_PHONE_NUMBER = 'temporary/CHANGE_NEW_PHONE_NUMBER';
export const UNSET_OLD_AND_NEW_PHONE_NUMBERS = 'temporary/UNSET_OLD_AND_NEW_PHONE_NUMBERS';

interface ResetPhoneOtpAction {
  type: typeof RESET_PHONE_OTP;
}
interface ChangePhoneOtpIntervalAction {
  type: typeof CHANGE_PHONE_OTP_INTERVAL;
  status: OTPStatus;
}

interface ChangePhoneOtpAction {
  type: typeof CHANGE_PHONE_OTP;
  status: OTPStatus;
}

interface PhoneOtpRequestAction {
  type: typeof PHONE_OTP_REQUEST;
}

interface PhoneOtpSuccessAction {
  type: typeof PHONE_OTP_SUCCESS;
}

interface PhoneOtpFailureAction {
  type: typeof PHONE_OTP_FAILURE;
  errorMessage: string;
}

interface PhoneOtpVerificationRequestAction {
  type: typeof PHONE_OTP_VERIFICATION_REQUEST;
}

interface PhoneOtpVerificationSuccessAction {
  type: typeof PHONE_OTP_VERIFICATION_SUCCESS;
}

interface PhoneOtpVerificationFailureAction {
  type: typeof PHONE_OTP_VERIFICATION_FAILURE;
  errorMessage: string;
}

interface PhoneOtpResendRequestAction {
  type: typeof PHONE_OTP_RESEND_REQUEST;
}

interface PhoneOtpResendSuccessAction {
  type: typeof PHONE_OTP_RESEND_SUCCESS;
}

interface PhoneOtpResendFailureAction {
  type: typeof PHONE_OTP_RESEND_FAILURE;
  errorMessage: string;
}

interface ChangeOldPhoneNumberAction {
  type: typeof CHANGE_OLD_PHONE_NUMBER;
  oldPhoneNumber: string;
}

interface ChangeNewPhoneNumberAction {
  type: typeof CHANGE_NEW_PHONE_NUMBER;
  newPhoneNumber: string;
}

interface UnsetOldAndNewPhoneNumbersAction {
  type: typeof UNSET_OLD_AND_NEW_PHONE_NUMBERS;
}

type InitActionTypes =
  | ResetPhoneOtpAction
  | ChangePhoneOtpIntervalAction
  | ChangePhoneOtpAction
  | PhoneOtpRequestAction
  | PhoneOtpSuccessAction
  | PhoneOtpFailureAction
  | PhoneOtpVerificationRequestAction
  | PhoneOtpVerificationSuccessAction
  | PhoneOtpVerificationFailureAction
  | PhoneOtpResendRequestAction
  | PhoneOtpResendSuccessAction
  | PhoneOtpResendFailureAction
  | ChangeOldPhoneNumberAction
  | ChangeNewPhoneNumberAction
  | UnsetOldAndNewPhoneNumbersAction;

// initial state
//
interface ChangePhoneOtp {
  phoneStatus: OTPStatus;
  oldPhoneNumber: string;
  newPhoneNumber: string;
}

export interface PhoneOTPStateTypes {
  data: ChangePhoneOtp;
  isLoading: boolean;
  isVerifyingOTP: boolean;
  isResendingOTP: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const initialState: PhoneOTPStateTypes = {
  data: {
    phoneStatus: OTPStatus.none,
    oldPhoneNumber: '',
    newPhoneNumber: '',
  },
  isLoading: false,
  isVerifyingOTP: false,
  isResendingOTP: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};
// Reducer
//
export default (
  // eslint-disable-next-line default-param-last
  state: PhoneOTPStateTypes = initialState,
  action: InitActionTypes,
): PhoneOTPStateTypes | ChangePhoneOtp => {
  switch (action.type) {
    case RESET_PHONE_OTP:
      return {
        ...initialState,
      };

    case CHANGE_PHONE_OTP:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: action.status,
        },
      };

    case PHONE_OTP_VERIFICATION_REQUEST:
      return {
        ...state,
        isVerifyingOTP: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_VERIFICATION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.verified,
        },
        isVerifyingOTP: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_VERIFICATION_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.notVerified,
        },
        isVerifyingOTP: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case PHONE_OTP_RESEND_REQUEST:
      return {
        ...state,
        isResendingOTP: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_RESEND_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.resent,
        },
        isResendingOTP: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_RESEND_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.resendError,
        },
        isResendingOTP: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case PHONE_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.verification,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case PHONE_OTP_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          phoneStatus: OTPStatus.error,
        },
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case CHANGE_OLD_PHONE_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          oldPhoneNumber: action.oldPhoneNumber,
        },
      };

    case CHANGE_NEW_PHONE_NUMBER:
      return {
        ...state,
        data: {
          ...state.data,
          newPhoneNumber: action.newPhoneNumber,
        },
      };

    case UNSET_OLD_AND_NEW_PHONE_NUMBERS:
      return {
        ...state,
        data: {
          ...state.data,
          oldPhoneNumber: initialState.data.oldPhoneNumber,
          newPhoneNumber: initialState.data.newPhoneNumber,
        },
      };

    default:
      return state;
  }
};

// Actions
//

export const changePhoneOtpInterval = (status: OTPStatus) => ({ type: CHANGE_PHONE_OTP_INTERVAL, status });
export const changePhoneOtp = (status: OTPStatus) => ({ type: CHANGE_PHONE_OTP, status });

export const phoneOtpRequest = (payload: ReqPhoneOtpRequest) => ({ type: PHONE_OTP_REQUEST, payload });

export const phoneOtpVerification = (currentPhoneNumbers: string[], payload: ReqPhoneOtpVerification) => ({
  type: PHONE_OTP_VERIFICATION_REQUEST,
  currentPhoneNumbers,
  payload,
});

export const changeOldPhoneNumber = (oldPhoneNumber: string) => {
  return { type: CHANGE_OLD_PHONE_NUMBER, oldPhoneNumber };
};

export const changeNewPhoneNumber = (newPhoneNumber: string) => ({ type: CHANGE_NEW_PHONE_NUMBER, newPhoneNumber });

export const unsetOldAndNewPhoneNumbers = () => ({ type: UNSET_OLD_AND_NEW_PHONE_NUMBERS });
export const phoneOtpResend = (payload: ReqPhoneOtpRequest) => ({ type: PHONE_OTP_RESEND_REQUEST, payload });
