import React from 'react';
import { EnrollmentStatus, ProgramName } from '@/types/enrollment';
import { LinkButton } from '@/designSystem/components/buttons';
import { useTranslation } from 'react-i18next';
import { PendingText } from './ManageSiteItem.styles';

interface ItemBodyProps {
  enrollmentStatus: EnrollmentStatus;
  programName: ProgramName;
  pendingProgramName?: ProgramName;
  handleEnrollment: () => void;
  handleProgramNameClick: () => void;
}

const LINK_BUTTON_PROPS = {
  padding: '20px',
  role: 'link',
  underline: false,
};

/**
For DATA_ONLY users:
- Allows user to enroll site in ClimateResponse.
- If site enrollment has started but their enrollment status is "Pending", they'll be shown a non-clickable message detailing their enrollment state.

For all other users:
- Displays clickable title of the program the site is enrolled in.
- When clicked, a modal showing their program details will appear.
*/
const ItemBody = ({
  enrollmentStatus,
  programName,
  pendingProgramName,
  handleEnrollment,
  handleProgramNameClick,
}: ItemBodyProps) => {
  const { t } = useTranslation('common');
  const siteCanAttemptEnrollmentInClimateResponse =
    programName === ProgramName.DATA_ONLY &&
    (!pendingProgramName || pendingProgramName === ProgramName.CLIMATE_RESPONSE) &&
    (enrollmentStatus === EnrollmentStatus.None ||
      enrollmentStatus === EnrollmentStatus.Rejected ||
      enrollmentStatus === EnrollmentStatus.Disenrolled);

  const siteIsPendingEnrollmentInClimateResponse =
    programName === ProgramName.DATA_ONLY &&
    pendingProgramName === ProgramName.CLIMATE_RESPONSE &&
    enrollmentStatus === EnrollmentStatus.Pending;

  if (siteCanAttemptEnrollmentInClimateResponse) {
    return (
      <LinkButton
        title={t(`common:programs.${ProgramName.CLIMATE_RESPONSE}.enrollmentLink`)}
        onClick={handleEnrollment}
        {...LINK_BUTTON_PROPS}
      />
    );
  }
  if (siteIsPendingEnrollmentInClimateResponse) {
    return (
      <PendingText aria-label={t(`common:programs.${ProgramName.DATA_ONLY}.enrollmentPendingMessage`)}>
        {t(`common:programs.${ProgramName.DATA_ONLY}.enrollmentPendingMessage`)}
      </PendingText>
    );
  }
  return (
    <LinkButton
      title={t(`common:programs.${programName}.title`)}
      onClick={handleProgramNameClick}
      {...LINK_BUTTON_PROPS}
    />
  );
};

export default ItemBody;
