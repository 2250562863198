export type GeneralHook<T> =
  | { fetchingStatus: FetchingStatus.INITIAL; fetch: () => void }
  | { fetchingStatus: FetchingStatus.LOADING }
  | { fetchingStatus: FetchingStatus.ERROR; error: unknown; refetch: () => void }
  | { fetchingStatus: FetchingStatus.SUCCESS; data: T; refetch: () => void };

export enum FetchingStatus {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INITIAL = 'INITIAL',
}
