import styled from 'styled-components';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  padding: 0 16px;

  ${media.lg`
    padding: 0;
  `}
`;

export const Grid = styled.div`
  display: block;

  ${media.xl`
    display: grid;
    grid-template-areas:
      'gl'
      'gr';
    gap: 0px 20px;
    grid-template-columns: 1fr 1fr 1fr;
  `}
`;

export const FirstColumn = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
`;

export const SecondColumn = styled.div`
  grid-column: 3 / 3;
  grid-row: 1;
`;

export const TileMargin = styled.div`
  margin-top: 20px;
`;
