import React, { ReactNode } from 'react';
import { Body, MenuWrapper, Wrapper } from '@/templates/SettingTemplate/SettingTemplate.styles';
import SettingMenu from '@/organisms/SettingMenu';

interface ISettingTemplateProps {
  children: ReactNode;
}
const SettingTemplate = ({ children }: ISettingTemplateProps) => {
  return (
    <Wrapper>
      <MenuWrapper>
        <SettingMenu />
      </MenuWrapper>
      <Body>{children}</Body>
    </Wrapper>
  );
};

export default SettingTemplate;
