import styled from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';

export const Wrapper = styled.div`
  padding: 0 20px;
  height: 100vh;
  ${media.lg`
    padding: 40px 20px 0 20px;
    height: unset;
  `};
`;

export const Title = styled.p`
  ${typography.xl1};
  line-height: 29px;
  font-weight: 800;
  color: ${colors.text};
`;

export const TitleContainer = styled.div`
  display: none;
  padding: 0 16.5px;
  ${media.lg`
    display: block;
  `};
`;
export const Container = styled.div`
  ${helpers.flexSet('column', 'center', 'flex-start')};
  gap: 20px;
`;

export const Description = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
  padding: 20px 0;
`;

export const Env = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
  white-space: pre;
`;

export const Copyright = styled.p`
  ${typography.sm};
  line-height: 24px;
  font-weight: 400;
  color: ${colors.blackGrey};
  padding: 8px 0;
`;
