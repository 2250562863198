import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  sitesRequest,
  changeSiteRequest,
  updateEnrollingSiteId,
  selectSitesForEnrollmentRequest,
  updateSiteRequest,
  deleteSiteRequest,
  submitTermsAndConditionsRequestInSites,
  resetSitesError,
} from '@/stores/sites';
import { Sites } from '@/types/sites';
import { ProgramName } from '@/types/enrollment';
import { IResponse } from '@/apis/api';

export default function useSites() {
  const dispatch = useDispatch();

  const fetchSites = useCallback(
    (siteName?: string, callback?: (sites: Sites) => void) => dispatch(sitesRequest(siteName, callback)),
    [dispatch],
  );

  const fetchChangeSite = useCallback((siteId: string) => dispatch(changeSiteRequest(siteId)), [dispatch]);

  const fetchSelectSitesForEnrollment = useCallback(
    (siteIds: Array<string>, gbcIntegrationId: string, cb?: (item: IResponse | Error) => void) =>
      dispatch(selectSitesForEnrollmentRequest(siteIds, gbcIntegrationId, cb)),
    [dispatch],
  );

  const fetchSubmitTermsAndConditionsInSites = useCallback(
    (programName: ProgramName, cb?: (item: IResponse | Error) => void) => {
      dispatch(submitTermsAndConditionsRequestInSites(programName, cb));
    },
    [dispatch],
  );

  const fetchUpdateSite = useCallback(
    (siteId: string, payload: { [k: string]: string }) => dispatch(updateSiteRequest(siteId, payload)),
    [dispatch],
  );

  const fetchDeleteSite = useCallback((siteId: string) => dispatch(deleteSiteRequest(siteId)), [dispatch]);

  const fetchEnrollingSiteId = useCallback((siteId: string) => dispatch(updateEnrollingSiteId(siteId)), [dispatch]);

  const fetchResetSitesError = useCallback(() => dispatch(resetSitesError()), [dispatch]);

  return {
    fetchSites,
    fetchChangeSite,
    fetchSelectSitesForEnrollment,
    fetchUpdateSite,
    fetchDeleteSite,
    fetchSubmitTermsAndConditionsInSites,
    fetchEnrollingSiteId,
    fetchResetSitesError,
  };
}
