import React from 'react';
import SettingMenu from '@/organisms/SettingMenu';
import { Wrapper, MenuWrapper } from './MobileSettings.styles';

const MobileSettings = () => {
  return (
    <Wrapper>
      <MenuWrapper>
        <SettingMenu />
      </MenuWrapper>
    </Wrapper>
  );
};

export default MobileSettings;
