import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/stores/index';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import RecentEnergyHistoryView from './index.view';
import { RecentEnergyHistoryProps } from './index.types';

const RecentEnergyHistoryContainer = ({ ariaControls }: RecentEnergyHistoryProps) => {
  const {
    dashboard: {
      data: { history },
    },
  } = useSelector((state: RootState) => state);

  const [stateModal, setStateModal] = useState({ energyInformation: false });

  const handleHelp = () => {
    sentEvent('click', PATHS.HELP_ENERGY_INFORMATION);
    setStateModal({ ...stateModal, energyInformation: true });
  };

  const handleModalEnergyHistoryClose = () => {
    setStateModal({ ...stateModal, energyInformation: false });
  };

  return (
    <RecentEnergyHistoryView
      item={history}
      onHelp={handleHelp}
      modalEnergyHistory={stateModal.energyInformation}
      modalEnergyHistoryClose={handleModalEnergyHistoryClose}
      ariaControls={ariaControls}
    />
  );
};

export default RecentEnergyHistoryContainer;
