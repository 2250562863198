import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { theme, GlobalStyles } from '@/assets/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@/assets/locales/i18n';
import { ErrorBoundary } from 'react-error-boundary';
import { store, persistor } from '@/constants/configureStore';
import ErrorScreen from '@/screens/Error/ErrorScreen';
import Error from '@/organisms/Error';
import App from './App';

interface Error {
  error: { message: string };
}

const handleErrorBoundary = ({ error }: Error) => {
  const isRedirectError = error.message === '500' || error.message === '403';

  return isRedirectError ? <Error errorCode={error.message} isRedirectError /> : <ErrorScreen />;
};

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={handleErrorBoundary}>
              <GlobalStyles />
              <App />
            </ErrorBoundary>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
