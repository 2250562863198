import React from 'react';
import { useTranslation } from 'react-i18next';
import { incentiveKindEnum } from '@/types/dr';
import { images } from '@/assets/styles';
import moment from 'moment';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import {
  Date,
  Incentive,
  Wrapper,
  EarningsTitleConainer,
  EarningsTitle,
  DateContainer,
  Img,
  ValueContainer,
  Divider,
  Container,
  PendingEvent,
} from './PendingMoneyEarnedItem.styles';
import { IPendingMoneyEarnedItemProps } from './PendingMoneyEarnedItem.types';

const NoMoneyEarnedItem = ({ pendingItem, index }: IPendingMoneyEarnedItemProps) => {
  const { t } = useTranslation('common');

  const getIconSrc = () => {
    return pendingItem.incentiveKind === incentiveKindEnum.event
      ? images.moneyEarnedEvent.image
      : images.moneyEarnedOther.image;
  };

  const renderIncentive = () => {
    const key = Object.values(incentiveKindEnum).find((val) => val === pendingItem.incentiveKind);

    if (typeof key === 'string') {
      return `${t(
        `screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.${key.toLowerCase()}.title`,
      )} #${index}`;
    }
    return '';
  };

  return (
    <Container>
      <Wrapper>
        <EarningsTitleConainer>
          <EarningsTitle>
            <Img src={getIconSrc()} />
            <Incentive>{renderIncentive()}</Incentive>
          </EarningsTitle>
          <DateContainer>
            <Img src={images.calendarGrey.image} alt={images.calendarGrey.text} />
            <Date>{moment(pendingItem.earnedDate).format('MMMM Do, YYYY')}</Date>
          </DateContainer>
        </EarningsTitleConainer>
        <Divider />
        <ValueContainer>
          <PendingEvent
            aria-label={accessibilityUsageLabel(
              t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.pending.title'),
            )}
          >
            {t('screens.main.savingsEvents.savingsEventsHistoryScreen.reduction.pending.title')}
          </PendingEvent>
        </ValueContainer>
      </Wrapper>
    </Container>
  );
};

export default NoMoneyEarnedItem;
