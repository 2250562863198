import React from 'react';
import { useTranslation } from 'react-i18next';
import { EarningSummary } from '@/types/dr';
import { UnitHelper } from '@/helpers/UnitHelper';
import { getProgramContext } from '@/helpers/ProgramStrategy';
import { ProgramName } from '@/types/enrollment';
import { Description, ValueContainer, Value, ValueTitle, Wrapper, ValueColumn } from './moneyEarnedSummary.styles';

const MoneyEarnedSummary = ({
  earningsSummary,
  disclaimer,
  disclaimerSymbol,
  programName,
}: {
  earningsSummary: EarningSummary;
  disclaimer: string;
  disclaimerSymbol: string;
  programName: ProgramName;
}) => {
  const { t } = useTranslation('common');
  const context = getProgramContext({ programName });
  const shouldDisplayCO2FeatureChanges = context?.getShouldDisplayCO2Emissions();

  const decimalDigits = 2;
  const formattedMoneyEarned = UnitHelper.formatMoneyValue(
    earningsSummary?.totalMoneyEarned || 0,
    false,
    decimalDigits,
  );
  return (
    <Wrapper>
      <ValueContainer>
        <ValueColumn tabIndex={0}>
          <Value>{earningsSummary?.numEvents || '0'}</Value>
          <ValueTitle>
            {t('screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.summary.event')}
          </ValueTitle>
        </ValueColumn>
        <ValueColumn tabIndex={0}>
          <Value>{formattedMoneyEarned}</Value>
          <ValueTitle>
            {`${t(
              'screens.main.savingsEvents.savingsEventsHistoryScreen.moneyEarned.summary.earnings',
            )}${disclaimerSymbol}`}
          </ValueTitle>
        </ValueColumn>
        {shouldDisplayCO2FeatureChanges && (
          <ValueColumn tabIndex={0}>
            <Value>{UnitHelper.renderUnitValue(earningsSummary?.emissionImpact || 0, 'lbs', false)} CO2</Value>
            <ValueTitle>{t('screens.main.savingsEvents.savingsEventsHistoryScreen.emissions.title')}</ValueTitle>
          </ValueColumn>
        )}
      </ValueContainer>
      <Description tabIndex={0}>{disclaimer}</Description>
    </Wrapper>
  );
};

export default MoneyEarnedSummary;
