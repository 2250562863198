import React from 'react';
import contents from '@/assets/config/appInfo';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import IconButton from '@/atoms/IconButton';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import { NAVIGATED_FROM_INVITATION_LANDING_KEY } from '@/constants/sessionStorageKeys';
import { isOnApp } from '@/helpers/WindowHelper';
import { isMobile } from 'react-device-detect';
import { isUtilityEdition } from '@/helpers/Edition';
import { ButtonGroup, Description, MobileApp, QRImage, QRWrapper, Title, Wrapper } from './Welcome.styles';

const Welcome = () => {
  const { t } = useTranslation('common');
  const fromInvitationLanding = sessionStorage.getItem(NAVIGATED_FROM_INVITATION_LANDING_KEY);
  const params = new URLSearchParams(window.location.search);
  const shouldEnableAnalyticsForSCE = params.get('sitecode') === 'sce_cr_2024';
  const handleAppStore = () => {
    sentEvent('click', PATHS.APP_DOWNLOAD_APP_STORE);
    if (isUtilityEdition && shouldEnableAnalyticsForSCE) {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-10903391/demand/ensce008+standard',
      });
      window.fbq('track', 'ClimateResponse');
    }
    window.open(contents.APP_STORE_LINK, '_blank');
  };

  const handleGooglePlay = () => {
    sentEvent('click', PATHS.APP_DOWNLOAD_PLAY_STORE);
    if (isUtilityEdition && shouldEnableAnalyticsForSCE) {
      window.gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: 'DC-10903391/demand/ensce009+standard',
      });
      window.fbq('track', 'ClimateResponse');
    }
    window.open(contents.GOOGLE_PLAY_LINK, '_blank');
  };

  return (
    <Wrapper>
      <Title>{`${contents.name}`}</Title>
      {fromInvitationLanding ? (
        <Description>{t(`screens.auth.welcomeScreen.welcome.fromInvitationLanding.description`)}</Description>
      ) : (
        <>
          <Description>{t(`screens.auth.welcomeScreen.welcome.${contents.edition}.description`)}</Description>
          <MobileApp>{t(`screens.auth.welcomeScreen.welcome.${contents.edition}.mobileApp`)}</MobileApp>
          <ButtonGroup>
            <QRWrapper>
              <IconButton icon={images.appStore} onClick={handleAppStore} style={{ width: '159px' }} />
              {!isOnApp() && !isMobile && <QRImage src={images.iosQR.image} alt={t(images.iosQR.text) || ''} />}
            </QRWrapper>
            <QRWrapper>
              <IconButton icon={images.googlePlay} onClick={handleGooglePlay} style={{ width: '159px' }} />
              {!isOnApp() && !isMobile && <QRImage src={images.androidQR.image} alt={t(images.androidQR.text) || ''} />}
            </QRWrapper>
          </ButtonGroup>
        </>
      )}
    </Wrapper>
  );
};

export default Welcome;
