import React from 'react';
import { Button, ButtonWrapper, Img } from './TextIconButton.styles';

type TextIconButtonProps = {
  title?: string;
  onClick: () => void;
  disabled?: boolean;
  padding?: string;
  image?: {
    active: {
      src: string;
      alt: string;
    };
    disabled?: {
      src: string;
      alt: string;
    };
    width?: string;
    height?: string;
    imagePlace?: 'left';
  };
  iconFirst?: boolean;
};

export const TextIconButton = ({
  title,
  onClick,
  disabled,
  image,
  padding,
  iconFirst = false,
}: TextIconButtonProps) => {
  return (
    <ButtonWrapper
      onClick={(e) => {
        onClick();
        (e.target as HTMLButtonElement).blur();
      }}
    >
      <Button aria-label={title} disabled={disabled} padding={padding} iconFirst={iconFirst}>
        {title}
      </Button>
      {image && (
        <Img
          iconFirst={iconFirst}
          width={image.width}
          height={image.height}
          src={disabled ? image.disabled?.src : image.active.src}
          alt={disabled ? image.disabled?.alt : image.active.alt}
        />
      )}
    </ButtonWrapper>
  );
};
