import { ProgramName } from '@/types/enrollment';
import { SiteItem, Sites, CurrentSiteItem, GbcIntegration } from '@/types/sites';
import { IResponse } from '@/apis/api';

// Types
//
// Reset
export const SITES_RESET = 'sites/SITES_RESET';

// Request All Data
export const SITES_REQUEST = 'sites/SITES_REQUEST';
export const SITES_SUCCESS = 'sites/SITES_SUCCESS';
export const SITES_FAILURE = 'sites/SITES_FAILURE';

// Change site
export const CHANGE_SITE_REQUEST = 'sites/CHANGE_SITE_REQUEST';
export const CHANGE_SITE_SUCCESS = 'sites/CHANGE_SITE_SUCCESS';
export const CHANGE_SITE_FAILURE = 'sites/CHANGE_SITE_FAILURE';

// Select sites for enrollment
export const SELECT_SITES_FOR_ENROLLMENT_REQUEST = 'sites/SELECT_SITES_FOR_ENROLLMENT_REQUEST';
export const SELECT_SITES_FOR_ENROLLMENT_SUCCESS = 'sites/SELECT_SITES_FOR_ENROLLMENT_SUCCESS';
export const SELECT_SITES_FOR_ENROLLMENT_FAILURE = 'sites/SELECT_SITES_FOR_ENROLLMENT_FAILURE';

// Update site
export const UPDATE_SITE_REQUEST = 'sites/UPDATE_SITE_REQUEST';
export const UPDATE_SITE_SUCCESS = 'sites/UPDATE_SITE_SUCCESS';
export const UPDATE_SITE_FAILURE = 'sites/UPDATE_SITE_FAILURE';

// Delete site
export const DELETE_SITE_REQUEST = 'sites/DELETE_SITE_REQUEST';
export const DELETE_SITE_SUCCESS = 'sites/DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'sites/DELETE_SITE_FAILURE';

// Submit Terms and Conditions In Sites
export const SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST = 'misc/SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST';
export const SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS = 'misc/SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS';
export const SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE = 'misc/SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE';

// Reset sites error
export const RESET_SITES_ERROR = 'sites/RESET_SITES_ERROR';

// Update single site for enrollment
export const UPDATE_ENROLLING_SITE_ID = 'sites/UPDATE_ENROLLING_SITE_ID';

interface SitesResetAction {
  type: typeof SITES_RESET;
}

interface SitesRequestAction {
  type: typeof SITES_REQUEST;
}

interface SitesSuccessAction {
  type: typeof SITES_SUCCESS;
  sites: Sites;
  currentSite?: CurrentSiteItem;
}

interface SitesFailureAction {
  type: typeof SITES_FAILURE;
  errorMessage: string;
}

interface ChangeSiteRequestAction {
  type: typeof CHANGE_SITE_REQUEST;
  siteId: string;
}

interface ChangeSiteSuccessAction {
  type: typeof CHANGE_SITE_SUCCESS;
  currentSite: CurrentSiteItem;
}

interface ChangeSiteFailureAction {
  type: typeof CHANGE_SITE_FAILURE;
  errorMessage: string;
}

interface SelectSitesForEnrollmentRequestAction {
  type: typeof SELECT_SITES_FOR_ENROLLMENT_REQUEST;
  gbcIntegrationId: string;
  siteIds: Array<string>;
  cb: (item: unknown) => void;
}

interface SelectSitesForEnrollmentSuccessAction {
  type: typeof SELECT_SITES_FOR_ENROLLMENT_SUCCESS;
}

interface SelectSitesForEnrollmentFailureAction {
  type: typeof SELECT_SITES_FOR_ENROLLMENT_FAILURE;
  errorMessage: string;
}

interface UpdateSiteRequestAction {
  type: typeof UPDATE_SITE_REQUEST;
  siteId: string;
}

interface UpdateSiteSuccessAction {
  type: typeof UPDATE_SITE_SUCCESS;
  siteId: string;
  payload: { [k: string]: string };
}

interface UpdateSiteFailureAction {
  type: typeof UPDATE_SITE_FAILURE;
  errorMessage: string;
}

interface DeleteSiteRequestAction {
  type: typeof DELETE_SITE_REQUEST;
  siteId: string;
}

interface DeleteSiteSuccessAction {
  type: typeof DELETE_SITE_SUCCESS;
}

interface DeleteSiteFailureAction {
  type: typeof DELETE_SITE_FAILURE;
  errorMessage: string;
}

interface SubmitTermsAndConditionsInSitesRequestAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST;
  programName: ProgramName;
  cb: (item: unknown) => void;
}

interface SubmitTermsAndConditionsInSitesSuccesstAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS;
}

interface SubmitTermsAndConditionsInSitesFailureAction {
  type: typeof SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE;
  errorMessage: string;
}

interface ResetSitesErrorAction {
  type: typeof RESET_SITES_ERROR;
}
interface UpdateEnrollingSiteIdAction {
  type: typeof UPDATE_ENROLLING_SITE_ID;
  siteId: string;
}

type InitActionTypes =
  | SitesResetAction
  | SitesRequestAction
  | SitesSuccessAction
  | SitesFailureAction
  | ChangeSiteRequestAction
  | ChangeSiteSuccessAction
  | ChangeSiteFailureAction
  | SelectSitesForEnrollmentRequestAction
  | SelectSitesForEnrollmentSuccessAction
  | SelectSitesForEnrollmentFailureAction
  | UpdateSiteRequestAction
  | UpdateSiteSuccessAction
  | UpdateSiteFailureAction
  | DeleteSiteRequestAction
  | DeleteSiteSuccessAction
  | DeleteSiteFailureAction
  | UpdateEnrollingSiteIdAction
  | SubmitTermsAndConditionsInSitesRequestAction
  | SubmitTermsAndConditionsInSitesSuccesstAction
  | SubmitTermsAndConditionsInSitesFailureAction
  | ResetSitesErrorAction;

// initial state
//
export interface SitesState {
  data: {
    sites: SiteItem[];
    gbcIntegration?: GbcIntegration;
    currentSite?: CurrentSiteItem;
    enrollingSiteId: string;
  };
  isLoading: boolean;
  isUpdatingSite: boolean;
  isUpdatingSites: boolean;
  isDeletingSite: boolean;
  isSelectingSiteForEnrollment: boolean;
  error: {
    hasError: boolean;
    errorMessage: string;
  };
}

const sitesInitialState = {
  sites: [],
  currentSite: undefined,
  enrollingSiteId: '',
};

const initialState: SitesState = {
  data: sitesInitialState,
  isLoading: false,
  isUpdatingSite: false,
  isUpdatingSites: false,
  isDeletingSite: false,
  isSelectingSiteForEnrollment: false,
  error: {
    hasError: false,
    errorMessage: '',
  },
};

// Reducer
//
// eslint-disable-next-line default-param-last
export default (state: SitesState = initialState, action: InitActionTypes): SitesState => {
  switch (action.type) {
    case SITES_RESET:
      return {
        ...initialState,
      };

    case SITES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SITES_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          gbcIntegration: action.sites.gbcIntegration,
          sites: action.sites.sites,
          currentSite: action.currentSite,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SITES_FAILURE:
      return {
        ...state,
        data: sitesInitialState,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case CHANGE_SITE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case CHANGE_SITE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          currentSite: action.currentSite,
        },
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case CHANGE_SITE_FAILURE:
      return {
        ...state,
        data: sitesInitialState,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case SELECT_SITES_FOR_ENROLLMENT_REQUEST:
      return {
        ...state,
        isSelectingSiteForEnrollment: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SELECT_SITES_FOR_ENROLLMENT_SUCCESS:
      return {
        ...state,
        isSelectingSiteForEnrollment: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SELECT_SITES_FOR_ENROLLMENT_FAILURE:
      return {
        ...state,
        isSelectingSiteForEnrollment: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case UPDATE_SITE_REQUEST:
      return {
        ...state,
        isUpdatingSite: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case UPDATE_SITE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          sites: state.data.sites.map((site) => {
            if (site.id === action.siteId) {
              Object.assign(site, action.payload);
            }
            return site;
          }),
        },
      };

    case UPDATE_SITE_FAILURE:
      return {
        ...state,
        isUpdatingSite: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case DELETE_SITE_REQUEST:
      return {
        ...state,
        isDeletingSite: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DELETE_SITE_SUCCESS:
      return {
        ...state,
        isDeletingSite: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case DELETE_SITE_FAILURE:
      return {
        ...state,
        isDeletingSite: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          hasError: true,
          errorMessage: action.errorMessage,
        },
      };

    case RESET_SITES_ERROR:
      return {
        ...state,
        error: {
          hasError: false,
          errorMessage: '',
        },
      };

    case UPDATE_ENROLLING_SITE_ID:
      return {
        ...state,
        data: { ...state.data, enrollingSiteId: action.siteId },
      };

    default:
      return state;
  }
};

// Actions
//

export const sitesRequest = (siteName?: string, callback?: (site: Sites) => void) => ({
  type: SITES_REQUEST,
  siteName,
  callback,
});

export const changeSiteRequest = (siteId: string) => ({
  type: CHANGE_SITE_REQUEST,
  siteId,
});

export const selectSitesForEnrollmentRequest = (
  siteIds: Array<string>,
  gbcIntegrationId: string,
  cb?: (item: IResponse | Error) => void,
) => ({
  type: SELECT_SITES_FOR_ENROLLMENT_REQUEST,
  gbcIntegrationId,
  siteIds,
  cb,
});

export const updateSiteRequest = (siteId: string, payload: { [k: string]: string }) => ({
  type: UPDATE_SITE_REQUEST,
  siteId,
  payload,
});

export const deleteSiteRequest = (siteId: string) => ({
  type: DELETE_SITE_REQUEST,
  siteId,
});

export const submitTermsAndConditionsRequestInSites = (
  programName: ProgramName,
  cb?: (item: IResponse | Error) => void,
) => ({
  type: SUBMIT_TERMS_AND_CONDITIONS_IN_SITES_REQUEST,
  programName,
  cb,
});

export const updateEnrollingSiteId = (siteId: string) => ({
  type: UPDATE_ENROLLING_SITE_ID,
  siteId,
});

export const resetSitesError = () => ({ type: RESET_SITES_ERROR });
