export enum PathnameKeys {
  Account = 'account',
  Program = 'program',
  Support = 'support',
  Language = 'language',
  About = 'about',
  Message = 'message',
  Event = 'event',
  Portfolio = 'portfolio',
  Sites = 'sites',
  ManageSites = 'manage',
  HistoryDetail = 'event/detail',
  EnvironmentalImpact = 'environmentalImpact',
  CurrentGridEmissions = 'currentGridEmissions',
  SiteEnroll = 'enroll',
}
export const ScreenTitleByPathname: Record<PathnameKeys, string> = {
  account: 'screens.main.settings.account.accountScreen.navigationTitle',
  program: 'screens.main.settings.programDetails.title',
  support: 'screens.main.settings.support.title',
  language: 'screens.main.settings.languageSelectionScreen.title',
  about: 'screens.main.settings.aboutScreen.title',
  message: 'screens.main.messages.messagesDetailScreen.title',
  event: 'screens.main.savingsEvents.portfolioScreen.eventsHistory',
  portfolio: 'screens.main.savingsEvents.portfolioScreen.title',
  sites: 'screens.main.settings.mySites.mySitesScreen.title',
  manage: 'screens.main.settings.mySites.mySitesScreen.title',
  'event/detail': 'screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.title',
  environmentalImpact: 'screens.main.dashboard.dashboardScreen.environmentalImpact.title',
  currentGridEmissions: 'screens.main.dashboard.dashboardScreen.gridEmissionsContainer.Cont_Title',
  enroll: 'screens.main.settings.mySites.enrollmentScreen.title',
};

export const HeaderShowBorder: Record<PathnameKeys, boolean> = {
  account: true,
  program: true,
  support: true,
  language: true,
  about: true,
  message: true,
  event: false,
  portfolio: true,
  sites: true,
  manage: true,
  'event/detail': true,
  environmentalImpact: true,
  currentGridEmissions: true,
  enroll: true,
};
