import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { tabSelect } from '@/stores/dr/historyScreen';
import { PerformanceToDateEnum } from '@/types/dr';

export default function useHistoryScreen() {
  const dispatch = useDispatch();

  const fetchTabSelect = useCallback((tab: PerformanceToDateEnum) => dispatch(tabSelect(tab)), [dispatch]);
  return {
    fetchTabSelect,
  };
}
