import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import { RootState } from '@/stores/index';
import { DrNearestEvent, UserStatusEnum } from '@/types/dr';
import Img from '@/atoms/Img';
import { images } from '@/assets/styles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ProgramInformationTooltip from '@/organisms/ProgramInformationTooltip';
import {
  Wrapper,
  Title,
  DateText,
  Time,
  RemainingTime,
  Timer,
  TimerDescription,
  DateContainer,
  DateTimeContainer,
  TimeContainer,
  Container,
  LeftSection,
  RightSection,
  ProgramInformationContainer,
  styleCalendarIcon,
  styleClockIcon,
  styleEventIcon,
  EventTypeTitle,
  InnerContainer,
  Divider,
  TitleContainer,
} from './DROnEvent.styles';
import { IDROnEventProps } from './DROnEvent.types';

const DROnEvent = ({ index, userStatus, programName }: IDROnEventProps) => {
  const { t } = useTranslation('common');
  const {
    dr: {
      events: {
        data: { nearestDrEvents },
      },
    },
  } = useSelector((state: RootState) => state);

  const [state, setState] = useState<{ autoHide: boolean; nearestDrEvent: DrNearestEvent }>({
    autoHide: false,
    nearestDrEvent: {
      eventTimeRange: '',
      length: '',
      end: '',
      canceled: false,
      userEventId: '',
      start: '',
      hasBeenNotified: true,
      userStatus: UserStatusEnum.DefaultEvent,
    },
  });

  const { minutes, hours, restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => console.warn('onExpire called'),
  });

  useEffect(() => {
    if (nearestDrEvents.length === 0) {
      return;
    }

    const nearestDrEvent = nearestDrEvents[index];

    setState({
      ...state,
      nearestDrEvent,
    });

    restart(new Date(nearestDrEvent.end));
  }, []);

  const digit = (val: number) => {
    return val.toString().padStart(2, '0');
  };

  const renderTimer = () => {
    return `${digit(hours)} : ${digit(minutes)}`;
  };

  return (
    <Wrapper>
      <ProgramInformationContainer>
        <Img src={images.iconOngoing} style={styleEventIcon} />
        <EventTypeTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.ongoingEvents`)}</EventTypeTitle>
      </ProgramInformationContainer>
      <Container>
        <InnerContainer>
          <LeftSection>
            <TitleContainer>
              <Title>
                {t('screens.main.savingsEvents.savingsEventsScreen.programEvent', {
                  program: t(`programs.${programName}.title`),
                })}
              </Title>
              <ProgramInformationTooltip programName={programName} />
            </TitleContainer>
            <DateTimeContainer>
              <DateContainer>
                <Img src={images.calendarBlack} style={styleCalendarIcon} />
                <DateText>{moment(state.nearestDrEvent.start).format('dddd, MMMM DD')}</DateText>
              </DateContainer>
              <Divider />
              <TimeContainer>
                <Img src={images.clockGrey} style={styleClockIcon} />
                <Time>{`${moment(state.nearestDrEvent.start).format('h:mm a')} - ${moment(
                  state.nearestDrEvent.end,
                ).format('h:mm a')}`}</Time>
              </TimeContainer>
            </DateTimeContainer>
          </LeftSection>
          <RightSection>
            {userStatus === UserStatusEnum.EventConfirmed && (
              <RemainingTime>
                <Timer>{renderTimer()}</Timer>
                <TimerDescription>
                  {t(`common:programs.${programName}.savingsEventsScreen.onTimer.remainingTime`)}
                </TimerDescription>
              </RemainingTime>
            )}
          </RightSection>
        </InnerContainer>
      </Container>
    </Wrapper>
  );
};

export default DROnEvent;
