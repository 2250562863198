import { all, fork, take, put, call, delay } from 'redux-saga/effects';
import * as apiAuth from '@/apis/auth';
import { AuthErrorCodes, ReqDeleteUser, ReqSetting, ReqUpdateUserInfo } from '@/apis/auth/types';
import {
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  ACTIVATE_INVITED_USER_FAILURE,
  ACTIVATE_INVITED_USER_REQUEST,
  ACTIVATE_INVITED_USER_SUCCESS,
  ACTIVATE_INVITED_USER_UPDATE,
} from '@/stores/userInfo';
import { ActivationStatus, UserInfo } from '@/types/userInfo';
import { LOADED, LOADING } from '@/stores/loading';
import * as api from '@/apis/enrollment';
import { IResponse } from '@/apis/api';
import { getErrorMessage } from '@/helpers/ErrorHandling';

function* userInfo(callback: (userInfo: UserInfo) => void, settingInfo?: ReqSetting) {
  try {
    yield put({ type: LOADING });
    if (settingInfo) yield call(apiAuth.settings, settingInfo);

    const response: IResponse = yield call(apiAuth.userInfo);

    if (!response.success) {
      yield put({ type: USER_INFO_FAILURE, errorMessage: response.error?.message });
      return;
    }

    const { data } = response;

    yield put({
      type: USER_INFO_SUCCESS,
      userInfo: data,
    });

    if (callback) {
      callback(data);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: USER_INFO_FAILURE, errorMessage });
  } finally {
    yield put({ type: LOADED });
  }
}

function* updateUserInfo(userInfo: ReqUpdateUserInfo, callback?: (response?: IResponse) => void) {
  try {
    yield put({ type: LOADING });

    const response: IResponse = yield call(apiAuth.updateUserInfo, userInfo);

    if (!response.success) {
      yield put({ type: UPDATE_USER_INFO_FAILURE, errorMessage: response.error?.message });
      return;
    }

    yield put({ type: UPDATE_USER_INFO_SUCCESS, userInfo });

    if (callback) {
      callback(response);
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: UPDATE_USER_INFO_FAILURE, errorMessage });

    if (callback) {
      callback();
    }
  } finally {
    yield put({ type: LOADED });
  }
}

function* deleteUser(userInfo: ReqDeleteUser, callback?: () => void) {
  try {
    yield put({ type: LOADING });
    const response: IResponse = yield call(apiAuth.deleteUser, userInfo);

    if (!response.success) {
      yield put({ type: DELETE_USER_FAILURE, errorMessage: response.error?.message });
      return;
    }

    yield put({ type: DELETE_USER_SUCCESS, userInfo });

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    yield put({ type: DELETE_USER_FAILURE, errorMessage });

    if (callback) {
      callback();
    }
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchDeleteUser() {
  while (true) {
    const { userInfo, callback } = yield take(DELETE_USER_REQUEST);
    yield fork(deleteUser, userInfo, callback);
  }
}

export function* watchUserInfo() {
  while (true) {
    const { callback, settingInfo } = yield take(USER_INFO_REQUEST);
    yield fork(userInfo, callback, settingInfo);
  }
}

export function* watchUpdateUserInfo() {
  while (true) {
    const { userInfo, callback } = yield take(UPDATE_USER_INFO_REQUEST);
    yield fork(updateUserInfo, userInfo, callback);
  }
}

function* pollActivationStatus() {
  let activationStatus = ActivationStatus.started;
  while (activationStatus === ActivationStatus.started) {
    const { data } = yield call(api.getUserActivationStatus);
    activationStatus = data.activationStatus;

    // Dispatch the current activation status
    yield put({ type: ACTIVATE_INVITED_USER_UPDATE, activationStatus });

    if (activationStatus === ActivationStatus.success) {
      yield put({ type: ACTIVATE_INVITED_USER_SUCCESS, activationStatus });
    } else if (activationStatus === ActivationStatus.error) {
      yield put({ type: ACTIVATE_INVITED_USER_FAILURE });
    } else {
      // Wait for 3 seconds before polling again.
      yield delay(3000);
    }
  }
}

function* activateInvitedUserRequest() {
  try {
    yield put({ type: LOADING });
    yield call(api.postActivateInvitedUser);
  } catch (error) {
    if ((error as IResponse).error?.code !== AuthErrorCodes.AlreadyBeganActivation) {
      yield put({ type: ACTIVATE_INVITED_USER_FAILURE });
      yield put({ type: LOADED });
      return;
    }
  }

  try {
    yield put({ type: LOADED });
    yield call(pollActivationStatus);
  } catch (error) {
    yield put({ type: ACTIVATE_INVITED_USER_FAILURE });
  } finally {
    yield put({ type: LOADED });
  }
}

export function* watchActivateInvitedUser() {
  while (true) {
    yield take(ACTIVATE_INVITED_USER_REQUEST);
    yield fork(activateInvitedUserRequest);
  }
}

export default function* authSaga() {
  yield all([fork(watchUserInfo), fork(watchUpdateUserInfo), fork(watchDeleteUser), fork(watchActivateInvitedUser)]);
}
