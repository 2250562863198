import styled, { css } from 'styled-components';
import { global, helpers, media } from '@/assets/styles';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { IStyleSelected, IStyleTitle } from './MessageItem.types';

export const Wrapper = styled.div<IStyleSelected>(
  ({ selected }) => css`
    cursor: pointer;
    display: flex;
    padding: 16px;
    background-color: ${colors.white};
    border-radius: 8px;
    &:hover {
      background-color: ${colors.hoverFocus};
    }
    &:focus {
      box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
    }
    ${media.lg`
      padding: 26px 16px;
      background-color: unset;
      border-radius: unset;
      &:hover {
        background-color: unset;
      }
      &:focus {
        box-shadow: unset;
      }
      ${
        selected &&
        css`
          background-color: rgba(245, 245, 245, 0.5);
          border-radius: 8px;
          &:hover {
            background-color: rgba(245, 245, 245, 0.5);
          }
        `
      }
    `};
  `,
);
export const Header = styled.div`
  display: flex;
  flex: 0.8;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-width: 0;
`;

export const Body = styled.div`
  flex: 0.2;
  ${helpers.flexSet('row', 'flex-end', 'start')};
`;

export const Title = styled.div<IStyleTitle>(
  ({ newMessage }) => css`
    ${typography.sm};
    font-weight: 700;
    ${media.lg`
      font-weight: 600;
      line-height: 24px;
      ${
        newMessage &&
        css`
          font-weight: 800;
        `
      }
    `}
    color: ${colors.text};
    text-align: left;
  `,
);

export const SubTitle = styled.div<IStyleTitle>(
  ({ newMessage }) => css`
    ${typography.xs};
    font-weight: 400;
    line-height: 24px;
    color: ${colors.text};
    text-align: left;
    ${newMessage &&
    css`
      font-weight: 700;
    `}
  `,
);

export const Content = styled.div<IStyleTitle>(
  ({ selected }) => css`
    display: none;

    ${media.lg`
      ${typography.xxs};
      font-weight: 400;
      line-height: 160%;
      color: ${colors.text};
      text-align: left;
      max-width: unset;
      display: block;
      ${
        !selected &&
        css`
          display: none;
        `
      };
    `}
  `,
);

export const New = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize, fontWeight: theme.font.weight.bold })};
    ${helpers.colorSet({ color: theme.colors.primary[100] })};
  `,
);

export const DateText = styled.p<IStyleSelected>(
  () => css`
    ${typography.xxs};
    color: ${colors.grey};
    font-weight: 300;
    ${media.lg`
      color: rgba(114, 116, 118, 0.5);
      font-weight: 900;
    `}
    line-height: 16px;
  `,
);

export const Date = styled(global.Date)<IStyleSelected>(
  ({ theme, selected }) => css`
    ${helpers.textSet({ fontSize: theme.typography.title.fontSize })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
    ${selected && helpers.colorSet({ color: theme.colors.text[0] })};

    margin-left: 20px;
  `,
);
