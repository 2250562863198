export const APP_ENV = `${process.env.APP_ENV}`;

export const EDITION = `${process.env.EDITION}`;

export const GOOGLE_ANALYTICS_ID = `${process.env.GOOGLE_ANALYTICS_ID}`;
export const GOOGLE_ANALYTICS_ID_SCE = `${process.env.GOOGLE_ANALYTICS_ID_SCE}`;
export const ADOBE_ANALYTICS_ID_SCE = `${process.env.ADOBE_ANALYTICS_ID_SCE}`;

// olivine api
export const OLIVINE_ACCOUNT_URI = `${process.env.OLIVINE_ACCOUNT_URI}`;

// service host
export const GB_INTERFACE_HOST = `${process.env.GB_INTERFACE_HOST}`;
export const OLIVINE_AUTH_HOST = `${process.env.OLIVINE_AUTH_HOST}`;
export const PRIMARY_HOST = `${process.env.PRIMARY_HOST}`;
export const WEB_HOST = `${process.env.WEB_HOST}`;

// app
export const APP_DEEP_LINK = `${process.env.APP_DEEP_LINK}`;

// store link
export const APP_STORE_LINK = `${process.env.APP_STORE_LINK}`;
export const GOOGLE_PLAY_LINK = `${process.env.GOOGLE_PLAY_LINK}`;

// git version
export const GIT_TAG = `${process.env.VERSION}`;
export const GIT_COMMIT_HASH = `${process.env.COMMITHASH}`;

// zoho chat
export const ZOHO_CHAT_PGE_ELRP_A6 = `${process.env.ZOHO_CHAT_PGE_ELRP_A6}`;
