import React from 'react';
import Achievement from '@/organisms/Achievement';
import EnvironmentalImpact from '@/organisms/EnvironmentalImpact';
import GridEmissions from '@/organisms/GridEmissions';
import Reminders from '@/organisms/Reminders';
import RecentEnergyHistory from '@/organisms/RecentEnergyHistory';
import { Grid, TileMargin } from './index.styles';

const DashboardDataOnly = ({
  achievementDisclaimer,
  achievementSymbol,
}: {
  achievementDisclaimer: string;
  achievementSymbol: string;
}) => {
  return (
    <Grid>
      <TileMargin>
        <GridEmissions />
      </TileMargin>

      <TileMargin>
        <RecentEnergyHistory ariaControls="energy-history-help" />
      </TileMargin>

      <TileMargin>
        <Reminders />
      </TileMargin>

      <TileMargin>
        <Achievement
          ariaControls="level-help"
          disclaimer={achievementDisclaimer}
          disclaimerSymbol={achievementSymbol}
        />
      </TileMargin>

      <TileMargin>
        <EnvironmentalImpact ariaControls="environment-impact-help" />
      </TileMargin>
    </Grid>
  );
};

export default DashboardDataOnly;
