import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import {
  earningsHistoryRequest,
  earningsHistorySelectRequest,
  earningsHistorySelectReset,
} from '@/stores/dr/earningsHistory';
import { MoneyEarnedItem } from '@/types/dr';

export default function useEarningsHistory() {
  const dispatch = useDispatch();

  const fetchEarningsHistoryRequest = useCallback(
    (siteId: string) => dispatch(earningsHistoryRequest(siteId)),
    [dispatch],
  );

  const fetchEarningsHistorySelect = useCallback(
    (siteId: string, earnings: MoneyEarnedItem, name: string) =>
      dispatch(earningsHistorySelectRequest(siteId, earnings, name)),
    [dispatch],
  );

  const fetchEarningsHistorySelectReset = useCallback(() => dispatch(earningsHistorySelectReset()), [dispatch]);

  return {
    fetchEarningsHistoryRequest,
    fetchEarningsHistorySelect,
    fetchEarningsHistorySelectReset,
  };
}
