import React, { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { images } from '@/assets/styles';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { ISearchFilter } from './SearchFilter.types';
import {
  ArraySearchButtonContainer,
  ArraySearchContainer,
  ErrorText,
  Img,
  InputComponent,
  InputWrapper,
  LeftImage,
  RightImage,
  SearchItem,
  SearchItemName,
  SearchList,
  TextRemove,
  Wrapper,
} from './SearchFilter.styles';
import { LinkButton, TextIconButton } from '../buttons';

export const SearchFilter = ({
  error,
  searchArrayHandler,
  disabled,
  placeholder,
  ariaLabel,
  ...inputProps
}: ISearchFilter) => {
  const { t } = useTranslation('common');
  const [isFocusedOnInput, setIsFocusedOnInput] = useState(false);
  const [searchArr, setSearchArr] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const textRemoveRef = useRef<HTMLButtonElement>(null);

  const removeSearchItem = (index: number) => {
    const tempArr = [...searchArr];
    const removedSearchTerm = tempArr.splice(index, 1);
    setSearchArr(tempArr);
    sentEvent('remove_search_term', PATHS.REMOVE_SEARCH_TERM(removedSearchTerm[0]));
  };

  const onAddItem = () => {
    const { searchFilter } = getValues();
    if (searchFilter === '') {
      return;
    }
    setSearchArr([...searchArr, searchFilter]);
    resetField('searchFilter');
    setIsFocusedOnInput(false);
    sentEvent('search_my_sites_filter', PATHS.SEARCH_MY_SITES_FILTER(searchFilter));
  };

  const { control, handleSubmit, resetField, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    defaultValues: {
      searchFilter: '',
    },
  });

  const clearField = () => {
    const { searchFilter } = getValues();
    sentEvent('clear_search_field', PATHS.CLEAR_SEARCH_FIELD(searchFilter));
    resetField('searchFilter');
  };

  // Handle focus and blur events
  const onFocus = () => setIsFocused(true);
  const onBlur = () => {
    setIsFocused(false);
    setIsFocusedOnInput(false);
  };

  useEffect(() => {
    searchArrayHandler(searchArr);
  }, [searchArr]);

  // Detect Clicks outside the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        componentRef.current &&
        textRemoveRef.current &&
        event.target instanceof Node &&
        !componentRef.current.contains(event.target) &&
        !textRemoveRef.current.contains(event.target)
      ) {
        onBlur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [componentRef, textRemoveRef, onBlur]);

  return (
    <Wrapper data-testid="outside-area">
      <ArraySearchContainer isFocused={isFocusedOnInput} ref={componentRef} onFocus={onFocus}>
        <InputWrapper isFocused={isFocusedOnInput}>
          <LeftImage>
            <Img src={images.search.image} />
          </LeftImage>
          <Controller
            name="searchFilter"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field: { onChange, value } }) => (
              <form onSubmit={handleSubmit(onAddItem)}>
                <InputComponent
                  onFocus={() => setIsFocusedOnInput(true)}
                  isFocused={isFocusedOnInput}
                  onBlur={() => !isFocused && setIsFocusedOnInput(false)}
                  error={error}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  placeholder={placeholder}
                  aria-label={ariaLabel}
                  leftPadding
                  {...inputProps}
                />
              </form>
            )}
          />
          {isFocusedOnInput && (
            <RightImage>
              <TextIconButton
                onClick={clearField}
                image={{
                  active: {
                    src: images.clear.image,
                    alt: images.clear.text,
                  },
                  disabled: {
                    src: images.clear.image,
                    alt: images.clear.text,
                  },
                }}
              />
            </RightImage>
          )}
        </InputWrapper>
        {isFocusedOnInput && (
          <ArraySearchButtonContainer>
            <LinkButton title={t('common.generalButtons.done')} onClick={onAddItem} padding="16px 24px" />
          </ArraySearchButtonContainer>
        )}
      </ArraySearchContainer>
      {!!error && <ErrorText>{error}</ErrorText>}
      <SearchList isExistArray={searchArr.length > 0}>
        {searchArr.map((text: string, index: number) => {
          return (
            <SearchItem key={nanoid()}>
              <SearchItemName>{text}</SearchItemName>
              <TextRemove ref={textRemoveRef} onClick={() => removeSearchItem(index)} data-testid="remove-button">
                <Img src={images.removeTextItem.image} />
              </TextRemove>
            </SearchItem>
          );
        })}
      </SearchList>
    </Wrapper>
  );
};
