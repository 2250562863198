import React from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '@/assets/styles';
import moment from 'moment';
import { OptInModeEnum } from '@/types/dr';
import { Modal } from '@/designSystem/components';
import Img from '@/atoms/Img';
import { FillPrimary, Stroke } from '@/designSystem/components/buttons';
import {
  PopupHeaderContainer,
  PopupTitle,
  Time,
  Upcoming,
  UpcomingContainer,
  Wrapper,
  styleCalendarIcon,
  styleClockIcon,
  DateText,
  ButtonContainer,
  styleButton,
} from './ModalNewEvent.styles';
import { IModalNewEventProps } from './ModalNewEvent.types';

const ModalNewEventContainer = ({
  show,
  onClose,
  startDate,
  endDate,
  onAccept,
  onDecline,
  optInMode,
}: IModalNewEventProps) => {
  const { t } = useTranslation('common');

  const affirmativeButtonRender = () => {
    return (
      <>
        <Stroke
          showBorder={false}
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.decline`)}
          onClick={onDecline}
          padding={styleButton.padding}
          maxWidth={styleButton.maxWidth}
        />
        <FillPrimary
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.accept`)}
          onClick={onAccept}
          padding={styleButton.padding}
          maxWidth={styleButton.maxWidth}
        />
      </>
    );
  };

  const presumptiveButtonRender = () => {
    return (
      <>
        <Stroke
          showBorder={false}
          title={t(`screens.main.savingsEvents.savingsEventsScreen.participationControl.confirm.decline`)}
          onClick={onDecline}
          padding={styleButton.padding}
          maxWidth={styleButton.maxWidth}
        />
        <FillPrimary
          title={t('common.generalButtons.ok')}
          onClick={onClose}
          padding={styleButton.padding}
          maxWidth={styleButton.maxWidth}
        />
      </>
    );
  };

  const confirmationOnlyButtonRender = () => {
    return (
      <FillPrimary
        title={t('common.generalButtons.ok')}
        onClick={onClose}
        padding={styleButton.padding}
        maxWidth={styleButton.maxWidth}
      />
    );
  };

  return (
    <Modal showClose show={show} onClose={onClose} small>
      <Wrapper>
        <PopupHeaderContainer>
          <PopupTitle>{t(`screens.main.savingsEvents.savingsEventsScreen.modalNewEvent.title`)}</PopupTitle>
        </PopupHeaderContainer>
        <UpcomingContainer>
          <Upcoming>
            <Img src={images.calendarBlack} style={styleCalendarIcon} />
            <DateText>{moment(startDate).format('dddd, MMMM DD')}</DateText>
          </Upcoming>
          <Upcoming>
            <Img src={images.clockGrey} style={styleClockIcon} />
            <Time>{`${moment(startDate).format('h:mm a')} - ${moment(endDate).format('h:mm a')}`}</Time>
          </Upcoming>
        </UpcomingContainer>
        <ButtonContainer>
          {optInMode === OptInModeEnum.affirmative && affirmativeButtonRender()}
          {optInMode === OptInModeEnum.presumptive && presumptiveButtonRender()}
          {optInMode === OptInModeEnum.confirmationOnly && confirmationOnlyButtonRender()}
        </ButtonContainer>
      </Wrapper>
    </Modal>
  );
};

export default ModalNewEventContainer;
