import React from 'react';
import contents from '@/assets/config/appInfo';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '@/screens/Auth/WelcomeScreen/WelcomeScreen.styles';
import Welcome from '@/organisms/Welcome';
import Login from '@/organisms/Login';

const WelcomeScreen = () => {
  const { t } = useTranslation('common');

  // Welcome
  document.title = `${t('screens.auth.welcomeScreen.title')} - ${contents.name}`;
  return (
    <Wrapper>
      <Welcome />
      <Login />
    </Wrapper>
  );
};

export default WelcomeScreen;
