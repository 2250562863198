import React from 'react';
import { useTranslation } from 'react-i18next';
import Img from '@/atoms/Img';
import Usage from '@/molecules/Usage';
import { typography } from '@/designSystem/typography';
import { colors } from '@/designSystem/colors';
import { UnitType } from '@/types/index';
import { DefaultImage } from '@/types/images';
import { images } from '@/assets/styles';
import {
  Item,
  Header,
  Title,
  UsageGroup,
  Logo,
  ParentComponentOfImpact,
  valueSize,
  descriptionSize,
} from './ImpactComponent.styles';

interface ImpactProps {
  logo: DefaultImage;
  title: string;
  yourValue: number;
  yourUnit: UnitType;
  communityValue: number;
  communityUnit: UnitType;
  underline?: boolean;
  parentComponent?: ParentComponentOfImpact;
}

const Impact = ({
  logo,
  title,
  yourValue,
  yourUnit,
  communityValue,
  communityUnit,
  underline,
  parentComponent = ParentComponentOfImpact.DASHBOARD,
}: ImpactProps) => {
  const { t } = useTranslation('common');

  return (
    <Item underline={underline} parentComponent={parentComponent}>
      <Header>
        <Logo size="extraSmall">
          <Img src={logo} style={{ objectFit: 'contain' }} />
        </Logo>
        <Title parentComponent={parentComponent}>{title}</Title>
      </Header>
      <UsageGroup>
        <Usage
          value={Math.round(yourValue)}
          valueColor={colors.accent}
          unit={yourUnit}
          valueStrong
          valueSize={valueSize(parentComponent)}
          description={t('screens.main.dashboard.dashboardScreen.environmentalImpact.user')}
          descriptionSize={descriptionSize(parentComponent)}
          descriptionColor={colors.grey}
        />
        <Usage
          alignItems="flex-end"
          value={Math.round(communityValue)}
          unit={communityUnit}
          valueIcon={communityValue > 0 ? images.impactArrowDown : undefined}
          valueIconSize={{ width: '18px', height: '18px' }}
          valueStrong
          valueSize={parseInt(typography.sm.fontSize, 10)}
          description={t('screens.main.dashboard.dashboardScreen.environmentalImpact.community')}
          descriptionSize={descriptionSize(parentComponent)}
          descriptionColor={colors.grey}
        />
      </UsageGroup>
    </Item>
  );
};

export default Impact;
