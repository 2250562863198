import React, { ReactNode } from 'react';
import Modal from '@/molecules/Modal';

type Props = {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  children: ReactNode;
};

export const SectionDescriptionModal = ({ showModal, children, title, closeModal }: Props) => {
  return (
    <Modal show={showModal} title={title} showClose onClose={closeModal}>
      {children}
    </Modal>
  );
};
