import React from 'react';
import { LinkButton, Modal } from '@/designSystem/components';
import { FillPrimary } from '@/designSystem/components/buttons';
import { useTranslation } from 'react-i18next';
import {
  DeleteModalButtonContainer,
  DeleteModalButtons,
  DeleteModalContainer,
  DeleteModalHeader,
  DeleteModalText,
} from './ModalRemoveSite.styles';

const ModalRemoveSiteContainer = ({
  isVisible,
  onClose,
  onClickYes,
  onClickNo,
  ariaControlsId,
}: {
  isVisible: boolean;
  onClose: () => void;
  onClickYes: () => void;
  onClickNo: () => void;
  ariaControlsId: string;
}) => {
  const { t } = useTranslation('common');
  return (
    <Modal show={isVisible} onClose={onClose} showClose small ariaControlsId={ariaControlsId}>
      <DeleteModalContainer>
        <DeleteModalHeader>
          {t('screens.main.settings.mySites.mySiteDetailScreen.modalRemoveSite.title')}
        </DeleteModalHeader>
        <DeleteModalText>
          {t('screens.main.settings.mySites.mySiteDetailScreen.modalRemoveSite.description')}
        </DeleteModalText>
        <DeleteModalButtonContainer>
          <DeleteModalButtons>
            <LinkButton
              title={t('common.generalButtons.cancel')}
              onClick={onClickNo}
              underline={false}
              padding="10px 14px"
            />
            <FillPrimary
              title={t('screens.main.settings.mySites.mySiteDetailScreen.modalRemoveSite.ok')}
              onClick={onClickYes}
              padding="10px 14px"
              warning
            />
          </DeleteModalButtons>
        </DeleteModalButtonContainer>
      </DeleteModalContainer>
    </Modal>
  );
};

export default ModalRemoveSiteContainer;
