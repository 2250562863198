import styled, { css } from 'styled-components';
import { helpers } from '@/assets/styles';
import {
  Wrapper as OriginWrapper,
  Title as OriginTitle,
  Container as OriginContainer,
} from '@/organisms/GBCInProgress/index.styles';
import { IShareTextProps } from './index.types';

interface WrapperProps {
  minHeight?: string;
}

interface ContainerProps {
  padding?: string;
}

interface TitleProps {
  margin?: string;
}

export const Wrapper = styled(OriginWrapper)(
  ({ minHeight }: WrapperProps) => css`
    ${minHeight && `min-height: ${minHeight};`}
  `,
);

export const Container = styled(OriginContainer)(
  ({ padding }: ContainerProps) => css`
    ${padding && `padding: ${padding};`}
  `,
);

export const Title = styled(OriginTitle)(
  ({ margin }: TitleProps) => css`
    ${margin && `margin: ${margin};`}
  `,
);

export const Share = styled.p<IShareTextProps>(
  ({ theme, color }) => css`
    ${helpers.textSet({
      fontSize: theme.typography.body.fontSize,
      fontWeight: theme.font.weight.bold,
      align: 'center',
    })};
    ${helpers.colorSet({ color: color ?? theme.colors.primary[100] })};

    margin-top: 20px;
  `,
);

export const Buttons = styled.div`
  ${helpers.flexSet('row', 'center', 'center')};

  margin-top: 16px;
`;

export const ShareButton = styled.div`
  padding: 0 10px;
`;

export const ShareTitle = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({
      fontSize: theme.typography.body.fontSize,
      fontWeight: theme.font.weight.bold,
      align: 'center',
    })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 6px;
  `,
);
