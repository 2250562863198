import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const RowView = styled.div`
  max-width: 240px !important;
  margin-left: auto;
  margin-right: auto;
`;

export const TextLow = styled.span`
  float: left;
`;

export const TextHigh = styled.span`
  float: right;
`;

export const Title = styled.p(
  ({ theme }) => css`
    font-size: ${theme.typography.title.fontSize};
    font-weight: ${theme.typography.title.fontWeight};
    color: ${theme.colors.text[80]};
  `,
);

export const EmissionGraphContainer = styled.div(
  () => css`
    ${helpers.flexSet('row', 'center')};
  `,
);

export const PoweredBy = styled.p`
  ${typography.xxs};
  color: ${colors.grey};
  font-weight: 700;
  line-height: normal;
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const PoweredByMobile = styled.p`
  ${typography.xxs};
  color: ${colors.grey};
  font-weight: 700;
  line-height: normal;
  text-align: center;

  display: block;
  ${media.lg`
    display: none;
  `}
`;

export const Number = styled.div(
  ({ theme }) => css`
    display: block;
    font-size: ${theme.typography.h1.fontSize};
    font-weight: ${theme.typography.h1.fontWeight};
    color: ${theme.colors.text[100]};
    margin: 0;
  `,
);

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;

  ${media.lg`
    gap: 20px;
    flex-direction: row;
  `}
`;

export const SectionLeftContainer = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
export const SectionRightContainer = styled.div`
  flex: 1;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.lg`
    align-items: flex-start;
    gap: 10px;
  `}
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const SectionTitleConatiner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

export const SectionTitle = styled.p`
  ${typography.md};
  font-weight: 700;
  line-height: normal;
  color: ${colors.text};
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const SectionSubTitleConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  ${media.lg`
    align-items: flex-start;
  `}
`;

export const SectionSubTitle = styled.p`
  ${typography.sm};
  font-weight: 400;
  line-height: normal;
  color: ${colors.grey};
`;

export const Description = styled.p`
  ${typography.xxs};
  font-weight: 400;
  line-height: normal;
  color: ${colors.grey};
  text-align: center;
  margin-top: 15px;
  ${media.lg`
    text-align: unset;
    margin-top: unset;
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  background-color: ${colors.white};
  gap: 15px;

  ${media.lg`
    gap: 20px;
    flex-direction: row;
  `}
`;

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: center;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  margin: 15px;
  min-height: 250px;

  ${media.md`
    margin: 20px 0px;
  `}

  ${media.lg`
    margin: 15px; 
  `}
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${colors.white};
  border-radius: 8px;
  margin: 15px;
  flex: 1;
  min-height: 250px;

  ${media.md`
    margin: 20px 0px;
  `}

  ${media.lg`
    margin: 15px; 
  `}
`;
export const ErrorTitle = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 24, fontWeight: theme.font.weight.bold, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};
  `,
);

export const ErrorDescription = styled.p(
  ({ theme }) => css`
    ${helpers.textSet({ fontSize: 16, align: 'center' })};
    ${helpers.colorSet({ color: theme.colors.text[80] })};

    margin-top: 13px;
    margin-bottom: 30px;
  `,
);
