import styled, { css } from 'styled-components';
import { helpers, media } from '@/assets/styles';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div``;

export const Container = styled.div`
  ${helpers.flexSet('row', 'space-between', 'center')};
  position: relative;
  background: ${colors.white};
  border-bottom: 2px solid;
  border-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;

  ${media.lg`
    border-radius: 8px;
    border-bottom: 0;
    align-items: center;
  `}
  ${media.md`
    padding: 24px;
    align-items: center;
  `}
  ${media.xs`
    padding: 24px;
    align-items: center;
  `}
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const LeftSection = styled.div`
  ${helpers.flexSet('row', 'flex-start', 'center')};
`;

export const RightSection = styled.div(
  ({ theme }) => css`
    display: none;
    ${helpers.textSet({
      fontSize: theme.typography.title.fontSize,
      fontWeight: theme.font.weight.regular,
    })};
    ${media.lg`
      ${helpers.flexSet('row', 'space-between', 'center')};
    `}

    color: ${theme.colors.text[80]};
  `,
);
export const Date = styled.div`
  display: none;

  ${media.md`
    display: block;
  `}
`;

export const SiteName = styled.p`
  ${typography.md}
  color: ${colors.text};
  font-weight: 900;
  ${media.lg`
    ${typography.xl};
    line-height: 24px;
  `};
`;

export const ScreenName = styled.p`
  display: none;
  color: ${colors.grey};
  font-weight: 900;
  margin-right: 4px;
  &::after {
    content: ' /';
  }
  ${media.lg`
    ${typography.xl};
    line-height: 24px;
    display: block;
  `};
`;

export const NoSite = styled.p`
  margin-right: 8px;
`;

export const ImageButtonSection = styled.div`
  display: block;

  ${media.lg`
    display: none;
    margin-right: 10px;
  `}
`;
