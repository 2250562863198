/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import moment from 'moment';
import { images, theme } from '@/assets/styles';
import { FillPrimary, IconButton, TextIconButton } from '@/designSystem/components';
import { Usage } from '@/molecules/NewUsage';
import ModalEnergyHistory from '@/organisms/ModalEnergyHistory';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import { UnitHelper } from '@/helpers/UnitHelper';
import { colors } from '@/designSystem/colors';
import { useNavigate } from 'react-router-dom';
import PATHS from '@/types/navigationPaths';
import {
  Container,
  Help,
  UsageGroup,
  Header,
  NoDataContainer,
  NoData,
  GraphDescriptionContainer,
  UsageTitle,
  UsageItem,
  Section,
  SectionHeader,
  SectionTitle,
  ChartContainer,
  ChartDescriptionContainer,
  ChartUnit,
  Line,
  EnergyUsageButtonContainer,
} from './index.styles';
import { RecentEnergyHistoryViewProps } from './index.types';
import { PlotProps } from '../DRDetailView/index.view';

const RecentEnergyHistoryView = ({
  item,
  onHelp,
  modalEnergyHistory,
  modalEnergyHistoryClose,
  ariaControls,
}: RecentEnergyHistoryViewProps) => {
  const { t, i18n } = useTranslation('common');
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [labels, setLabels] = useState<Array<string>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (item.lastWeekGraph && item.thisWeekGraph) {
      const concatenatedWeeks = item.lastWeekGraph.concat(item.thisWeekGraph);
      const weekdaysFromMonday = moment
        .weekdaysShort(true)
        .slice(1)
        .concat(moment.weekdaysShort(true)[0])
        .map((day) => day.toUpperCase());
      const concatenatedLabels = weekdaysFromMonday.concat(weekdaysFromMonday).concat(weekdaysFromMonday);
      const reverseGraph = concatenatedWeeks.reverse();

      const removeEmptyDataAtTheBeginning = (arr: Array<number>): [Array<number>, number] => {
        let startIndex = 0;
        for (let i = 0; arr[i] === 0; i += 1) {
          startIndex = i;
        }
        if (arr[startIndex] === 0) {
          startIndex += 1;
        }
        return [arr.slice(startIndex), startIndex];
      };

      const [trimmedEnd, endFromLeft] = removeEmptyDataAtTheBeginning(reverseGraph);
      const originalGraph = trimmedEnd.reverse();
      const [trimmedGraph, startFromRight] = removeEmptyDataAtTheBeginning(originalGraph);
      if (trimmedGraph.length < 7) {
        const finalGraph = trimmedGraph;
        setLabels(concatenatedLabels.slice(startFromRight, startFromRight + 7));
        setGraphData([...finalGraph, ...new Array(7 - finalGraph.length).map(() => 0)]);
      } else {
        const finalGraph = trimmedGraph.slice(trimmedGraph.length - 7, trimmedGraph.length);
        setLabels(
          concatenatedLabels.slice(
            concatenatedWeeks.length - endFromLeft - 7 ?? 0,
            concatenatedWeeks.length - endFromLeft,
          ),
        );
        setGraphData(finalGraph);
      }
    }
  }, [item]);

  const data: PlotProps['data'] = [
    {
      name: t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.graph.legend.thisWeek'),
      type: 'bar',
      x: labels,
      y: graphData,
      marker: {
        color: theme.colors.palette.thisWeek,
      },
      hovertemplate: '<b>%{y}kWh</b><extra></extra>',
    },
  ];

  const layout: PlotProps['layout'] = {
    height: 310,
    bargap: 0.5,
    margin: {
      t: 20,
      r: 20,
      b: 50,
      l: 20,
      pad: 10,
    },
    legend: {
      orientation: 'h',
      x: 0.3,
      y: -0.2,
    },
    xaxis: {
      fixedrange: true,
      tickfont: {
        family: 'Lato',
        size: 13,
        color: colors.blackGrey,
      },
    },
    yaxis: {
      fixedrange: true,
      showticklabels: true,
      showgrid: false,
      zeroline: false,
      tickfont: {
        family: 'Lato',
        size: 13,
        color: colors.blackGrey,
      },
    },
    autosize: true,
  };

  const graphDescriptionTableHTML = htmlTableRenderer(
    t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.title'),
    labels,
    // @ts-ignore
    graphData,
    'kWh',
    i18n.language.toLocaleLowerCase(),
  );

  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);

  const handleNavigateViewAllEnergyUse = () => {
    navigate(PATHS.SCREEN_USAGE_PATTERN.pathname);
  };

  const renderGraph = (hasSufficientUsageData?: boolean) => {
    switch (hasSufficientUsageData) {
      case undefined:
        return undefined;
      case false:
        return (
          <NoDataContainer>
            <NoData>{t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.noData')}</NoData>
          </NoDataContainer>
        );
      default:
        return (
          <>
            <ChartContainer>
              <ChartUnit>kWh</ChartUnit>
              <Header>
                <Plotly
                  data={data}
                  layout={layout}
                  config={{ displayModeBar: false }}
                  style={{ width: '85%' }}
                  useResizeHandler
                />
              </Header>
              <EnergyUsageButtonContainer>
                <FillPrimary
                  title={t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.viewAllText')}
                  onClick={handleNavigateViewAllEnergyUse}
                  flex={1}
                  padding="15px"
                />
              </EnergyUsageButtonContainer>
            </ChartContainer>
            <Line />
            <ChartDescriptionContainer>
              <UsageGroup>
                <UsageItem>
                  <UsageTitle>
                    {t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.usage.typical', {
                      day: moment().format('dddd'),
                    })}
                  </UsageTitle>
                  <Usage
                    value={UnitHelper.formatEnergyValue(item.weekdayAvg * 1000)}
                    valueColor={colors.accent}
                    valueSize="15px"
                    containerFlexDirection="row"
                    containerAlignItems="baseline"
                  />
                </UsageItem>
                <UsageItem>
                  <UsageTitle>
                    {t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.usage.highest')}
                  </UsageTitle>
                  <Usage
                    value={UnitHelper.formatEnergyValue(item.lastWeekHigh * 1000)}
                    valueColor={colors.bad}
                    description={item.lastWeekHighDay}
                    valueSize="15px"
                    containerFlexDirection="row"
                    containerAlignItems="baseline"
                    containerGap="8px"
                    descriptionSize="13px"
                    descriptionWeight={400}
                    descriptionColor={colors.grey}
                  />
                </UsageItem>
                <UsageItem>
                  <UsageTitle>
                    {t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.usage.lowest')}
                  </UsageTitle>
                  <Usage
                    value={UnitHelper.formatEnergyValue(item.lastWeekLow * 1000)}
                    valueColor={colors.primary}
                    description={item.lastWeekLowDay}
                    valueSize="15px"
                    containerFlexDirection="row"
                    containerAlignItems="baseline"
                    containerGap="8px"
                    descriptionSize="13px"
                    descriptionWeight={400}
                    descriptionColor={colors.grey}
                  />
                </UsageItem>
              </UsageGroup>
            </ChartDescriptionContainer>
          </>
        );
    }
  };

  return (
    <>
      <Section>
        <SectionHeader>
          <SectionTitle>{t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.title')}</SectionTitle>
          <Help>
            <IconButton
              image={{
                active: {
                  src: images.helpBtn.image,
                  alt: t('screens.main.dashboard.dashboardScreen.achievement.help'),
                },
                width: '30px',
                height: '30px',
              }}
              onClick={onHelp}
              ariaControls={ariaControls}
              ariaExpanded={modalEnergyHistory}
              ariaLabel={t('screens.main.dashboard.dashboardScreen.recentEnergyHistory.help')}
              borderRadius="20px"
            />
          </Help>
        </SectionHeader>
        <Container>{renderGraph(item.hasSufficientUsageData)}</Container>
        {item.hasSufficientUsageData && (
          <GraphDescriptionContainer>
            <TextIconButton
              title={t('common.generalButtons.graphDescription')}
              onClick={() => window.open(graphDescriptionURL, '_blank')}
              padding="5px"
            />
          </GraphDescriptionContainer>
        )}
      </Section>
      <ModalEnergyHistory show={modalEnergyHistory} onClose={modalEnergyHistoryClose} ariaControlsId={ariaControls} />
    </>
  );
};

export default RecentEnergyHistoryView;
