import styled from 'styled-components';
import { typography } from '@/designSystem/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Description = styled.p`
  ${typography.md};
  line-height: 21px;
  font-weight: 400;
  white-space: pre-line;
`;
