import styled from 'styled-components';
import { colors } from '@/designSystem/colors';
import { media } from '@/assets/styles';

export const Wrapper = styled.div`
  display: grid;
  gap: 20px;
  padding: 16px;
  ${media.lg`
    grid-template-columns: 1fr 1.5fr;
    padding: 0;
    margin-top: 20px;
  `}
`;

export const MenuWrapper = styled.div`
  display: none;
  ${media.lg`
    display: block;
  `}
`;

export const Body = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 20px 0;
  ${media.lg`
    height: fit-content;
  `};
`;
