import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { drRequest, eventParticipate, eventNotifiedRequest } from '@/stores/dr/events';
import { ReqEventNotified, ReqEventParticipate } from '@/apis/primary/types';

export default function useEvents() {
  const dispatch = useDispatch();

  const fetchDr = useCallback((siteId: string) => dispatch(drRequest(siteId)), [dispatch]);

  const fetchEventParticipate = useCallback(
    (siteId: string, payload: ReqEventParticipate) => dispatch(eventParticipate(siteId, payload)),
    [dispatch],
  );

  const fetchEventNotifiedUpdate = useCallback(
    (siteId: string, payload: ReqEventNotified) => dispatch(eventNotifiedRequest(siteId, payload)),
    [dispatch],
  );

  return {
    fetchDr,
    fetchEventParticipate,
    fetchEventNotifiedUpdate,
  };
}
