import React from 'react';
import OriginModal from 'react-modal';
import { images, theme } from '@/assets/styles';
import Button from '@/atoms/Button';
import IconButton from '@/atoms/IconButton';
import { useTranslation } from 'react-i18next';
import { IModalProps } from './index.types';
import { Overlay, Container, Header, Title, More, Body, Footer } from './index.styles';

const Modal = ({
  children,
  show,
  title,
  textOk,
  textCancel,
  showClose,
  onClose,
  onOk,
  onCancel,
  disabledOk,
  ariaControlsId,
  small,
  large,
  customOverlay,
}: IModalProps) => {
  const { t } = useTranslation('common');

  const defaultStyle = {
    content: {
      padding: 0,
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: '#fff',
      overflow: 'initial',
      outline: 'none',
    },
    overlay: {
      ...customOverlay,
    },
  };

  return (
    <OriginModal
      aria={{ modal: 'true' }}
      id={ariaControlsId}
      appElement={document.getElementById('root') as HTMLElement}
      isOpen={show}
      onRequestClose={onClose}
      style={{
        ...defaultStyle,
      }}
    >
      <Overlay>
        <Container large={large}>
          <Header>
            {title && <Title>{title}</Title>}
            {showClose && (
              <More>
                <IconButton
                  icon={images.modalClose}
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                  }}
                  ariaLabel={`${t('common.generalButtons.close')}`}
                  role="button"
                  ariaControls={ariaControlsId}
                />
              </More>
            )}
          </Header>
          <Body small={small}>{children}</Body>
          <Footer>
            {textOk && textOk !== '' && onOk && (
              <Button
                disabled={disabledOk}
                label={textOk}
                borderRadius={100}
                backgroundColor={disabledOk ? theme.colors.primary[25] : theme.colors.primary[100]}
                color={theme.colors.text[0]}
                fontSize={theme.typography.button.fontSize}
                width="100%"
                height={50}
                onClick={() => {
                  if (onOk) {
                    onOk();
                  }
                }}
                role="button"
                ariaLabel={textOk}
              />
            )}
            {textCancel && textCancel !== '' && onCancel && (
              <Button
                label={textCancel}
                borderRadius={100}
                borderColor={theme.colors.primary[100]}
                borderWidth={theme.borders.button.width}
                backgroundColor={theme.colors.text[0]}
                color={theme.colors.primary[100]}
                fontSize={theme.typography.button.fontSize}
                width="100%"
                height={50}
                marginTop={20}
                onClick={onCancel}
                role="button"
                ariaLabel={textCancel}
              />
            )}
          </Footer>
        </Container>
      </Overlay>
    </OriginModal>
  );
};

export default Modal;
