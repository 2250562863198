export interface Dashboard {
  history: EnergyHistory;
  achievement: Achievement;
  impact: EnvironmentalImpact;
  reminder: Reminder;
}

export interface EnergyHistory {
  hasSufficientUsageData?: boolean;
  weekdayAvg: number;
  lastWeekHigh: number;
  lastWeekLow: number;
  lastWeekHighDay: string;
  lastWeekLowDay: string;
  lastWeekHighDayTimestamp: string;
  lastWeekLowDayTimestamp: string;
  lastWeekGraph: number[];
  thisWeekGraph: number[];
  lastWeekStartDate: string;
}

export enum AchievementLevel {
  gold = 'GOLD',
  silver = 'SILVER',
  bronze = 'BRONZE',
  noAct = 'NO_ACTION',
}

export enum SummaryState {
  preliminary = 'preliminary',
  disputed = 'disputed',
  preliminaryAndDisputed = 'preliminaryAndDisputed',
  final = 'final',
  none = 'none',
}

export interface Achievement {
  level: AchievementLevel;
  moneyEarned: number;
  summaryState: SummaryState;
}

export interface EnvironmentalImpact {
  reduction: {
    yours: number;
    community: number;
  };
  emissions: {
    yours: number;
    community: number;
  };
  milesDriven: {
    yours: number;
    community: number;
  };
}

export interface Reminder {
  nearestActiveEvent: EventDateInformation;
  nearestUpcomingEvent: EventDateInformation;
}

export interface EventDateInformation {
  start: string;
  end: string;
}

export enum DashboardTypes {
  DASHBOARD_WITH_DR_AND_GRID = 'DashboardWithDRAndGrid',
  DASHBOARD_WITH_EARNINGS_ENERGY_HISTORY_AND_GRID = 'DashboardWithEarningsEnergyHistoryAndGrid',
  DASHBOARD_WITH_EARNINGS_DR_AND_GRID = 'DashboardWithEarningsDRAndGrid',
  DASHBOARD_DATA_ONLY = 'DashboardDataOnly',
}
