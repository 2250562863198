import styled, { css } from 'styled-components';
import { colors } from '@/designSystem/colors';
import { typography } from '@/designSystem/typography';
import { media } from '@/assets/styles';

export const styleInfoIcon = { width: '18px', height: '18px' };

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InformationIcon = styled.div<{ tooltipFixed: boolean }>`
  display: flex;
  border-radius: 10px;
  ${(props) =>
    props.tooltipFixed &&
    css`
      background-color: ${colors.primaryHover};
      box-shadow: 0px 0px 0px 4px rgba(69, 129, 29, 0.2);
    `}
`;

export const EmptySpace = styled.div<{ tooltipOn: boolean }>`
  position: absolute;
  width: 340px;
  right: 0;
  z-index: 1;
  visibility: ${(props) => (props.tooltipOn ? 'visible' : 'hidden')};
  background-color: transparent;
  margin-top: 18px;
  padding-top: 5px;
  ${media.lg`
    position: absolute;
    right: unset;
  `}
`;

export const Container = styled.div`
  background-color: ${colors.white};
  padding: 15px 30px;
  border-radius: 8px;
  max-width: 340px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TooltipTitleContainer = styled.div``;

export const TooltipTitle = styled.p`
  ${typography.sm};
  align-self: flex-start;
  font-weight: 800;
  color: ${colors.text};
`;
