import { colors } from '@/designSystem/index';
import { TimePeriod } from '@/types/usagePattern';
import { Layout } from 'plotly.js';
import { IParsedGraph } from '../index.types';

const getBarGap = (selectedPeriodFilter: TimePeriod) => {
  if (selectedPeriodFilter === TimePeriod.day || selectedPeriodFilter === TimePeriod.month) {
    return 0.4;
  }
  return 0.5;
};

export const getGraphLayout = ({
  width,
  parsedGraph,
  selectedPeriodFilter,
  highestValue,
}: {
  width: number;
  parsedGraph: Array<IParsedGraph>;
  selectedPeriodFilter: TimePeriod;
  highestValue: number;
}): Partial<Layout> => {
  const barGap = getBarGap(selectedPeriodFilter);
  return {
    autosize: true,
    width,
    bargap: barGap,
    dragmode: false,
    margin: {
      r: 10,
      b: 50,
      l: 40,
      pad: 10,
    },
    xaxis: {
      fixedrange: true,
      range: [-1, parsedGraph.length],
      ticktext: parsedGraph.map((val: IParsedGraph) => {
        return val.tickLabel;
      }),
      tickvals: parsedGraph.map((val: IParsedGraph, i: number) => {
        return i;
      }),
      color: colors.grey,
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      constrain: 'range',
      zeroline: false,
      gridwidth: 3,
      showticklabels: true,
      ticktext: ['0', highestValue.toString()],
      color: colors.grey,
    },
    annotations: [
      {
        xref: 'paper',
        yref: 'paper',
        x: 0,
        xanchor: 'right',
        y: 1.1,
        yanchor: 'bottom',
        text: '<b>kWh</b>',
        showarrow: false,
        font: {
          family: 'Lato',
          color: colors.text,
          size: 14,
        },
      },
    ],
  };
};
