/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Plotly from 'react-plotly.js';
import { UnitHelper } from '@/helpers/UnitHelper';
import { htmlTableRenderer } from '@/helpers/HTMLTableHelper';
import { colors } from '@/designSystem/colors';
import { accessibilityUsageLabel } from '@/helpers/AccessibilityHelper';
import { Usage } from '@/molecules/NewUsage';
import { LinkButton } from '@/designSystem/components';
import { sentEvent } from '@/helpers/GAHelper';
import PATHS from '@/types/navigationPaths';
import { useMediaQuery } from 'react-responsive';
import { sizes } from '@/assets/styles/media';
import { PerEventDisclaimer, perEventDisclaimer } from '@/helpers/PerEventDisclaimer';
import { quickViewTextsByUnit, shouldDisplayCO2FeatureChanges, UnitHistoryDetail } from '@/helpers/Edition';
import { IDRQuickViewViewProps } from './index.types';
import {
  Wrapper,
  GraphContainer,
  UsageContainer,
  ChartContainer,
  GraphDescriptionContainer,
  UsageWrapper,
  Disclaimer,
  GraphTickContainer,
  GraphTick,
} from './index.styles';
import { PlotProps } from '../DRDetailView/index.view';

const formatValueByUnit = (value: number, unitText: string, unit?: UnitHistoryDetail) => {
  if (unit === UnitHistoryDetail.lbs) {
    return `${UnitHelper.renderUnitValue(value || 0, 'default', false, 2)} ${unitText}`;
  }
  return UnitHelper.formatEnergyValue(value, 2);
};

const DRQuickViewView = ({ item, moneyEarnedInProgram, unit }: IDRQuickViewViewProps) => {
  const { t, i18n } = useTranslation('common');
  const isMobileWindowSize = useMediaQuery({ maxWidth: sizes.lg });
  const textData = quickViewTextsByUnit[unit];

  // Type guard to check if item is of type DrHistoryItem
  if (
    !item ||
    (!('actualRequestUsage' in item) && unit === UnitHistoryDetail.kwh) ||
    (!('actualCo2Emission' in item) && unit === UnitHistoryDetail.lbs)
  ) {
    return null; // or handle the case where item is not of the expected type
  }

  // Type guard to check if item is of type DetailGrpahItem or DrHistoryItem
  if (!('quickViewData' in item)) {
    return null; // or handle the case where item is not of the expected type
  }

  const {
    actualRequestUsage,
    actualCo2Emission,
    quickViewData: { hadReduced, typicalDayUsage, moneyEarned, changeInUsage, typicalCo2Impact, emissionChangeInUsage },
  } = item;

  const typical = unit === UnitHistoryDetail.lbs ? Number(typicalCo2Impact) : typicalDayUsage;
  const actual = unit === UnitHistoryDetail.lbs ? Number(actualCo2Emission) : actualRequestUsage;
  const changeUsage = unit === UnitHistoryDetail.lbs ? Number(emissionChangeInUsage) : changeInUsage;

  const { disclaimerNamespace, disclaimerSymbol } = perEventDisclaimer({
    perEventDisclaimer: item?.quickViewData?.moneyEarnedDisclaimer || PerEventDisclaimer.None,
  });
  const disclaimer = t(disclaimerNamespace);

  const data: PlotProps['data'] = [
    {
      name: t(textData.leftBar.text),
      type: 'bar',
      x: [0],
      y: [typical],
      hovertemplate: `%{y:.2f}${textData.unitText}`,
      marker: {
        color: textData.leftBar.color,
      },
      width: [0.3],
    },
    {
      name: t(textData.rightBar.text),
      type: 'bar',
      x: [1],
      y: [actual],
      hovertemplate: `%{y:.2f}${textData.unitText}`,
      marker: {
        color: textData.rightBar.color,
      },
      width: [0.3],
    },
  ];

  /**
   * rangeMaxValue?
   * The 'Auto range' or 'margin' options do not consider the size of 'data.text'.
   * Therefore, if 'Auto Range' is turned off, a buffer is added to the range by increasing the determined
   * maximum value between the two data point by 15%
   * (e.g. if (typicalDayUsage > actualRequestUsage) return typicalDayUsage').
   */
  const rangeMaxValue = (typical > actual ? typical : actual) + ((typical > actual ? typical : actual) / 100) * 15;

  const layout: PlotProps['layout'] = {
    dragmode: false,
    margin: {
      l: 10,
      r: 10,
      b: 10,
      t: 15,
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    xaxis: {
      showticklabels: false,
    },
    yaxis: {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      autorange: false,
      range: [0, rangeMaxValue],
    },
    showlegend: false,
  } as Partial<Plotly.Layout>;

  const config = { displayModeBar: false, responsive: true };
  const chartStyle = { width: '100%', height: 250 };

  const graphDescriptionTableHTML = htmlTableRenderer(
    t(textData.title),
    [t(textData.leftBar.text), t(textData.rightBar.text)],
    // @ts-ignore
    [formatValueByUnit(typical, textData.unitText, unit), formatValueByUnit(actual, textData.unitText, unit)],
    '',
    i18n.language.toLocaleLowerCase(),
  );

  const graphDescriptionFile = new Blob([graphDescriptionTableHTML], { type: 'text/html' });
  const graphDescriptionURL = URL.createObjectURL(graphDescriptionFile);
  const handleGraphDescription = () => {
    const newWindow = window.open(graphDescriptionURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    sentEvent('open_graph_description', PATHS.OPEN_GRAPH_DESCRIPTION);
  };

  const formattedChangeValue = formatValueByUnit(changeUsage, textData.unitText, unit);
  const energyValueColor = hadReduced ? colors.accent : colors.bad;
  const formattedMoneyEarned = UnitHelper.formatMoneyValue(moneyEarned);

  return (
    <Wrapper>
      <ChartContainer>
        <GraphContainer>
          <Plotly data={data} layout={layout} config={config} style={chartStyle} />
        </GraphContainer>
        <GraphTickContainer>
          <div>
            <GraphTick color={textData.leftBar.color}>{t(textData.leftBar.text)}</GraphTick>
            <GraphTick marginTop="10px" color={textData.leftBar.color}>
              {formatValueByUnit(typical, textData.unitText, unit)}
            </GraphTick>
          </div>
          <div>
            <GraphTick color={textData.rightBar.color}>{t(textData.rightBar.text)}</GraphTick>
            <GraphTick marginTop="10px" color={textData.rightBar.color}>
              {formatValueByUnit(actual, textData.unitText, unit)}
            </GraphTick>
          </div>{' '}
        </GraphTickContainer>
        <GraphDescriptionContainer>
          <LinkButton
            onClick={handleGraphDescription}
            title={t('common.generalButtons.graphDescription')}
            underline={false}
            ariaLabel={t('common.generalButtons.graphDescription')}
            role="link"
            padding="12px 6px"
            fontSize={isMobileWindowSize ? '17px' : '15px'}
          />
        </GraphDescriptionContainer>
      </ChartContainer>
      <div>
        <UsageWrapper moneyEarnedInProgram={moneyEarnedInProgram}>
          <UsageContainer>
            <Usage
              value={`${hadReduced ? '↓' : '↑'}${formattedChangeValue}`}
              valueColor={energyValueColor}
              description={t(textData.legend.text)}
            />
          </UsageContainer>
          {moneyEarnedInProgram && !shouldDisplayCO2FeatureChanges && (
            <UsageContainer>
              <Usage
                value={`${formattedMoneyEarned}${disclaimerSymbol}`}
                valueColor={colors.accent}
                valueAriaLabel={accessibilityUsageLabel(formattedMoneyEarned)}
                description={t('screens.main.savingsEvents.savingsEventsHistoryScreen.historyDetail.graph.moneyEarned')}
              />
            </UsageContainer>
          )}
        </UsageWrapper>
        {!!disclaimer && <Disclaimer>{disclaimer}</Disclaimer>}
      </div>
    </Wrapper>
  );
};

export default DRQuickViewView;
